import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import {
  QuestionnaireClient,
  QuestionnaireVersionViewModel,
  TenancyOptionErrors,
} from "../../../types/auto/types";
import { Configuration } from "../../Constants";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { FetchOverride } from "../../utils/Request";

interface Props {
  questionnaireVersionToDelete: QuestionnaireVersionViewModel;
  open: boolean;
  closeModal: () => void;
  refresh: () => void;
}
const QuestionnaireVersionDeleteModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "100%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
    },
    textField: {
      width: "100%",
      marginBottom: "10px",
    },
  });
  const classes = useStyle();
  const [error, setError] = React.useState<
    TenancyOptionErrors.TenancyNotSupported | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);

  function deleteQuestionnaireVersion(
    versionToDelete: QuestionnaireVersionViewModel
  ) {
    setLoading(true);
    new QuestionnaireClient(Configuration.SERVER_ROOT, FetchOverride)
      .deleteQuestionnaireVersion(versionToDelete)
      .then(() => {
        setLoading(false);
        props.closeModal();
        props.refresh();
      })
      .catch((er) => {
        setError(er);
        setLoading(false);
      });
  }

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "80%" : "40%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          Delete Questionnaire Version
        </Typography>
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              props.closeModal();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              deleteQuestionnaireVersion(props.questionnaireVersionToDelete);
            }}
          >
            Confirm deletion
          </LoadingButton>
        </div>
        {error !== undefined && (
          <Alert severity="error">{error.toString()}</Alert>
        )}
      </Box>
    </Modal>
  );
};

export default QuestionnaireVersionDeleteModal;
