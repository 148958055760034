export function LargerThanDate(date1: Date, date2: Date): boolean {
  return (
    (date1.getDate() < date2.getDate() &&
      (date1.getMonth() > date2.getMonth() ||
        date1.getFullYear() > date2.getFullYear())) ||
    (date1.getMonth() < date2.getMonth() &&
      date1.getFullYear() > date2.getFullYear()) ||
    (date1.getDate() > date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()) ||
    (date1.getDate() > date2.getDate() &&
      (date1.getMonth() > date2.getMonth() ||
        date1.getFullYear() > date2.getFullYear()))
  );
}

export function LargerThanDateIncludingHoursMinutes(
  date1: Date,
  date2: Date
): boolean {
  return (
    LargerThanDate(date1, date2) ||
    (date1.getDate() === date2.getDate() &&
      (date1.getHours() > date2.getHours() ||
        (date1.getHours() === date2.getHours() &&
          date1.getMinutes() > date2.getMinutes())))
  );
}
