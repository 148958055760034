import React from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
} from "victory";
import { ExtendedLineViewModel } from "../../../elements/graph/GraphLegend";
import {
  DataScatter,
  GroupedLine,
  VersionChangeScatter,
  NoResponseScatter,
  updateNoResponseYValues,
} from "./LineGraphCoreHelper";

interface Props {
  width: number;
  mobileSelectedDomain:
    | {
        x?: DomainTuple | undefined;
        y?: DomainTuple | undefined;
      }
    | undefined;
  setZoomDomain: React.Dispatch<
    React.SetStateAction<
      | {
          x?: DomainTuple | undefined;
          y?: DomainTuple | undefined;
        }
      | undefined
    >
  >;
  xAxisValues: number[];
  data: ExtendedLineViewModel[];
  yDomain: DomainTuple;
}

const MobileZoomable = (props: Props): JSX.Element => (
  <VictoryChart
    padding={{ top: 0, bottom: 30, left: 50, right: 50 }}
    width={props.width}
    height={90}
    domainPadding={{ x: 20, y: 20 }}
    domain={{
      x:
        props.xAxisValues.length > 1
          ? [
              props.xAxisValues[0],
              props.xAxisValues[props.xAxisValues.length - 1],
            ]
          : undefined,
      y: props.yDomain,
    }}
    containerComponent={
      <VictoryBrushContainer
        brushDimension="x"
        brushDomain={props.mobileSelectedDomain}
        onBrushDomainChange={(b) => props.setZoomDomain(b)}
        style={{ margin: "auto" }}
        height={90}
      />
    }
    aria-label={
      "GraphMobileZoom" + props.data && props.data.length === 1
        ? props.data[0].lineName
        : "all"
    }
  >
    <VictoryAxis
      style={{
        grid: { stroke: "lightgray", strokeWidth: 0.5 },
      }}
      tickValues={props.xAxisValues}
      tickFormat={(t: number) => {
        return new Date(t).toLocaleDateString("en-GB");
      }}
      fixLabelOverlap
    />
    {GroupedLine(props.data, "mobile-zoom")}
    {DataScatter(props.data, "mobile-zoom")}
    {VersionChangeScatter(props.data, "mobile-zoom")}
    {NoResponseScatter(
      updateNoResponseYValues(
        props.data,
        typeof props.yDomain[1] === "number" ? props.yDomain[1] * 7.14 : 0.5
      ),
      "mobile-zoom"
    )}
  </VictoryChart>
);

export default MobileZoomable;
