import { Button, Container, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import {
  GoalClient,
  QuestionnaireClient,
  TimelineCardType,
  PatientTimelineDataViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { makeStyles } from "@mui/styles";
import FormErrorText from "../../elements/errors/FormErrorText";

interface Props {
  timelineEntree: PatientTimelineDataViewModel;
  refreshTimeline: () => void;
  setErrorClinicianNotLinked: React.Dispatch<React.SetStateAction<boolean>>;
}
const EditResponseDate = (props: Props): JSX.Element => {
  const [responseDate, setResponseDate] = React.useState(
    props.timelineEntree.submitted ?? new Date()
  );

  const [errorDate, setErrorDate] = React.useState(false);
  const [notADate, setNotADate] = React.useState(false);
  const useStyle = makeStyles({
    datePicker: {
      width: "100%",
      marginBottom: 10,
    },
    submitButton: {
      "&:disabled": {
        backgroundColor: "darkgrey",
      },
      width: "100%",
    },
  });
  const classes = useStyle();
  function editResponseDate() {
    if (isNaN(responseDate.getTime())) {
      setNotADate(true);
      return;
    } else {
      setNotADate(false);
    }
    if (responseDate.getFullYear() < 1900) {
      setErrorDate(true);
      return;
    }
    if (props.timelineEntree.type === TimelineCardType.Questionnaire) {
      new QuestionnaireClient(Configuration.SERVER_ROOT, FetchOverride)
        .editResponseDate(responseDate, props.timelineEntree.responseId)
        .then(() => {
          props.refreshTimeline();
          props.setErrorClinicianNotLinked(false);
        })
        .catch(() => {
          props.setErrorClinicianNotLinked(true);
        });
    } else {
      new GoalClient(Configuration.SERVER_ROOT, FetchOverride)
        .editResponseDate(
          responseDate,
          props.timelineEntree.responseId,
          props.timelineEntree.patientId
        )
        .then(() => {
          props.refreshTimeline();
        })
        .catch(() => {
          props.setErrorClinicianNotLinked(true);
        });
    }
  }
  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className={classes.datePicker}>
          <DateTimePicker
            label="Change response date"
            value={responseDate}
            onChange={(newValue: Date | null) => {
              if (newValue === null) {
                setErrorDate(false);
              } else if (newValue > new Date()) {
                setErrorDate(true);
              } else {
                setErrorDate(false);
                setResponseDate(newValue);
              }
            }}
            renderInput={(params) => <TextField {...params} />}
            disableFuture
            inputFormat="dd/MM/yyyy h:mm a"
            disableMaskedInput
          />
        </div>
      </LocalizationProvider>
      <Button
        variant="contained"
        onClick={editResponseDate}
        className={classes.submitButton}
      >
        Save response date
      </Button>
      <Container>
        <FormErrorText
          isInvalid={errorDate}
          errorText={"Date cannot be in the future or before 1900."}
        />
        <FormErrorText
          isInvalid={notADate}
          errorText={"This is an invalid date."}
        />
      </Container>
    </Container>
  );
};

export default EditResponseDate;
