import { Container, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MuiPhoneNumber from "material-ui-phone-number";
import React from "react";
import { theme } from "../../../../Theme";
import {
  PreferredContact,
  ContactDetailsViewModel,
} from "../../../../types/auto/types";
import ContactPreferences from "../../elements/contact-preferences/ContactPreferences";
import {
  IsEmailValid,
  IsMobileNumberValid,
} from "./CreateEditPatientModalHelper";

interface Props {
  details: ContactDetailsViewModel;
  setDetails: React.Dispatch<React.SetStateAction<ContactDetailsViewModel>>;
  emailError: boolean;
  setEmailError: React.Dispatch<React.SetStateAction<boolean>>;
  mobileNumberError: boolean;
  setMobileNumberError: React.Dispatch<React.SetStateAction<boolean>>;
  setReRender: React.Dispatch<React.SetStateAction<boolean>>;
  hasMobile: boolean;
}

const ContactPreferencesForm = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    subHeader: {
      fontSize: 20,
      marginBottom: 0,
      marginTop: 20,
    },
    textFieldContainer: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "row",
      gap: 10,
      width: "100%",
      marginBottom: 15,
    },
    textField: {
      width: "100%",
    },
    contactPreferenceSelectorContainer: {
      width: "100%",
      margin: 0,
      padding: 0,
      marginBottom: 15,
    },
    contactPreferencesSelector: {
      padding: 0,
      margin: 0,
    },
  });
  const classes = useStyle(theme);

  return (
    <>
      <Container className={classes.contactPreferenceSelectorContainer}>
        <Typography className={classes.subHeader}>
          Contact Preferences
        </Typography>
        <Container className={classes.contactPreferencesSelector}>
          <ContactPreferences
            fromPatientForm
            messagingPreferences={
              props.details.preferredContact === PreferredContact.Both
                ? [PreferredContact.Email, PreferredContact.Mobile]
                : props.details.preferredContact === undefined ||
                  props.details.preferredContact === null
                ? [PreferredContact.Email]
                : [props.details.preferredContact]
            }
            onChange={(_, values) => {
              // Don't accept no contact preference
              if (values.length === 0) {
                return;
              }
              // If email and mobile notifications are selected, set the preference to both
              if (values.length === 2) {
                props.setDetails((x) => {
                  x.preferredContact = PreferredContact.Both;
                  return x;
                });
              }
              // Otherwise set the preference to the selected type
              else {
                props.setDetails((x) => {
                  x.preferredContact = values[0];
                  return x;
                });
              }
              props.setReRender((x) => !x);
            }}
            hasMobile
          />
        </Container>
      </Container>
      <Container className={classes.textFieldContainer}>
        <TextField
          label="Email Address"
          className={classes.textField}
          required
          value={
            props.details.contactEmail === undefined ||
            props.details.contactEmail === null
              ? ""
              : props.details.contactEmail
          }
          onChange={(e) => {
            props.setDetails((x) => {
              x.contactEmail = e.target.value.toLowerCase();
              props.setReRender((x) => !x);
              return x;
            });
          }}
          onBlur={() =>
            props.setEmailError(!IsEmailValid(props.details.contactEmail))
          }
          error={props.emailError}
          helperText={props.emailError && "Please provide a valid email"}
        />
        <MuiPhoneNumber
          className={classes.textField}
          defaultCountry={"gb"}
          required={
            props.details.preferredContact === PreferredContact.Both ||
            props.details.preferredContact === PreferredContact.Mobile
          }
          value={props.details.mobileNumber}
          onChange={(e) => {
            props.setDetails((x) => {
              x.mobileNumber = (e as string).match(/^(\+?\d{1,3}|\d{1,4})$/)
                ? undefined
                : (e as string);
              return x;
            });
          }}
          onBlur={() => {
            props.setMobileNumberError(
              !IsMobileNumberValid(
                props.details.mobileNumber,
                props.details.preferredContact === PreferredContact.Both ||
                  props.details.preferredContact === PreferredContact.Mobile
              )
            );
          }}
          error={props.mobileNumberError}
          label="Mobile Number"
          helperText={
            props.mobileNumberError && "Please enter a valid mobile number"
          }
          variant="outlined"
        />
      </Container>
    </>
  );
};

export default ContactPreferencesForm;
