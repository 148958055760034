import { useState } from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryZoomContainer,
} from "victory";
import GraphTickLabel from "../GraphTickLabel";
import { CheckIsTablet } from "../../../../utils/MobileStatus";
import { TScoreGraph } from "../../timeline/ScoresAccordian";
import MobileZoomable from "./MobileZoomable";

interface Props {
  data: TScoreGraph;
  width: number;
  height: number;
}

const TscoreLineGraphCore = (props: Props): JSX.Element => {
  const isMobile = CheckIsTablet();
  const [mobileSelectedDomain, setMobileSelectedDomain] = useState<{
    x?: DomainTuple;
    y?: DomainTuple;
  }>();
  const [zoomDomain, setZoomDomain] = useState<{
    x?: DomainTuple;
    y?: DomainTuple;
  }>();

  return (
    <>
      <VictoryChart
        domainPadding={{ x: 20, y: 20 }}
        width={props.width}
        height={props.height}
        aria-label={props.data.lineName}
        domain={{
          x: undefined,
          y: [0, Math.max(...props.data.data.map((category) => category.y))],
        }}
        containerComponent={
          <VictoryZoomContainer
            zoomDimension="x"
            containerId="chart"
            height={props.height}
            onZoomDomainChange={(s) => {
              setMobileSelectedDomain(s);
            }}
            zoomDomain={zoomDomain}
            allowZoom={!isMobile}
          />
        }
      >
        <VictoryAxis
          dependentAxis
          style={{
            grid: { stroke: "lightgray", strokeWidth: 0.5 },
          }}
          crossAxis={false}
          tickLabelComponent={
            <GraphTickLabel chartTitle={props.data.lineName!} />
          }
        />
        <VictoryAxis
          style={{
            grid: { stroke: "lightgray", strokeWidth: 0.5 },
          }}
          fixLabelOverlap
        />
        <VictoryLine
          y={() => 65}
          style={{
            data: {
              stroke: "red",
              strokeDasharray: "5,5",
            },
          }}
        />
        <VictoryLine
          key={"Tscore-scatter-line"}
          interpolation="linear"
          style={{
            data: {
              stroke: "blue",
            },
          }}
          data={props.data.data}
        />
        <VictoryScatter
          key={"tscore-line"}
          style={{
            data: {
              stroke: "blue",
              strokeWidth: "2",
              fill: "rgb(255, 255, 255)",
            },
          }}
          size={4}
          data={props.data.data}
          labels={(data) => [
            data.datum.x === undefined ? "" : data.datum.x,

            "Score: " +
              (data.datum.y === undefined || isNaN(data.datum.y)
                ? ""
                : Math.round(data.datum.y).toString()),
          ]}
          labelComponent={
            <VictoryTooltip
              pointerLength={20}
              flyoutPadding={{
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
              }}
              flyoutStyle={{
                fill: "rgba(65, 65, 65, 0.8)",
                strokeWidth: 1,
                overflowWrap: "break-word",
              }}
              style={{
                fill: "white",
                overflowWrap: "break-word",
              }}
              renderInPortal
            />
          }
        />
      </VictoryChart>
      {/* Add a second chart on mobile to enable zooming, as the zoom container is bugged on mobile */}
      {isMobile && (
        <MobileZoomable
          width={props.width}
          mobileSelectedDomain={mobileSelectedDomain}
          setZoomDomain={setZoomDomain}
          data={props.data}
        />
      )}
    </>
  );
};

export default TscoreLineGraphCore;
