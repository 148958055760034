import { Box, Button, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import { CaseloadClient, PatientClient } from "../../../../types/auto/types";
import { useEffect, useState } from "react";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { LoadingButton } from "@mui/lab";
import { Error400 } from "../../elements/errors/Error400";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { PatientChip } from "../../../../types/PatientChip";
import { useUnmountPromise } from "react-use";
import MultiSelectChipSubset from "../../elements/form-elements/MultiSelectChipSubset";

interface Props {
  open: boolean;
  closeModal: () => void;
  caseloadId: number;
  refresh: () => void;
}
const AddPatientsToCaseloadModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    clinicianSelector: {
      width: "100%",
      marginTop: 20,
    },
    subHeader: {
      fontSize: 20,
      marginBottom: 0,
      marginTop: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
      display: isMobile ? "flex" : "block",
      flexDirection: "column",
      justifyContent: "center",
    },
  });
  const classes = useStyle(theme);
  const [patientOptions, setPatientOptions] = useState<PatientChip[]>([]);
  const [selectedPatients, setSelectedPatients] = useState<PatientChip[]>([]);
  const [allPatients, setAllPatients] = useState<Set<number>>(new Set([]));
  const [errorAssigning, setErrorAssigning] = useState(false);

  const [loading, setLoading] = useState(false);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "60%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    border: "none",
  };
  const resolveWhileMounted = useUnmountPromise();

  function searchPatients(filterText: string) {
    new PatientClient(Configuration.SERVER_ROOT, FetchOverride)
      .searchPatientByName(filterText)
      .then((x) =>
        setPatientOptions(
          x.map((y) => {
            const z = y as PatientChip;
            z.name = y.firstName + " " + y.lastName + " - " + y.nhsNumber;
            return z;
          })
        )
      );
  }

  useEffect(() => {
    setSelectedPatients([]);
    if (props.caseloadId !== 0 && props.caseloadId !== undefined) {
      const effect = async () => {
        setErrorAssigning(false);
        await resolveWhileMounted(
          new PatientClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getPatients()
        ).then((x) =>
          setPatientOptions(
            x.map((y) => {
              const z = y as PatientChip;
              z.name = y.firstName + " " + y.lastName + " - " + y.nhsNumber;
              return z;
            })
          )
        );
        await resolveWhileMounted(
          new CaseloadClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getCaseloadPatients(props.caseloadId)
        ).then((x) => {
          setAllPatients(
            new Set(
              x.map((y) => {
                return y.id;
              })
            )
          );
        });
      };
      effect();
    } else if (props.caseloadId === 0 && props.caseloadId === undefined) {
      setPatientOptions([]);
    }
  }, [props.caseloadId, props.open, resolveWhileMounted]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h5" component="h5" className={classes.subHeader}>
          Add Patients
        </Typography>
        <MultiSelectChipSubset
          label={"Add Patients"}
          options={patientOptions.filter((x) => !allPatients.has(x.id))}
          selectedOptions={selectedPatients}
          setSelectedOptions={setSelectedPatients}
          className={classes.clinicianSelector}
          onFilter={searchPatients}
          noOptionsText={"No patients"}
        />
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              props.closeModal();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              setLoading(true);
              new CaseloadClient(Configuration.SERVER_ROOT, FetchOverride)
                .assignPatientsToCaseload(
                  props.caseloadId,
                  Array.from(allPatients).concat(
                    selectedPatients.map((x) => x.id)
                  )
                )
                .then(() => {
                  setLoading(false);
                  props.refresh();
                  props.closeModal();
                })
                .catch(() => {
                  setErrorAssigning(true);
                  setLoading(false);
                });
            }}
          >
            Add to caseload
          </LoadingButton>
        </div>
        {errorAssigning && <Error400 />}
      </Box>
    </Modal>
  );
};

export default AddPatientsToCaseloadModal;
