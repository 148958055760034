import { MenuItem, Select, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../../../../Theme";
import { ArchiveCountType } from "../../../../../types/auto/types";
import { CheckIsMobile } from "../../../../utils/MobileStatus";

interface Props {
  archivesAfter: number | undefined;
  setArchivesAfter: (archivesAfter: number | undefined) => void;
  archiveCountType: ArchiveCountType | undefined;
  setArchiveCountType: (archiveCountType: ArchiveCountType | undefined) => void;
}
const minArchivedNumber = 1;

const ArchivePromptScheduling = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    container: {
      display: isMobile ? "block" : "flex",
      width: "100%",
    },
    textContainer: {
      marginRight: 10,
      marginTop: 15,
    },
    archivedNumber: {
      width: 100,
      marginRight: 5,
    },
  });
  const classes = useStyle(theme);
  return (
    <div className={classes.container}>
      <Typography className={classes.textContainer}>Archive after</Typography>
      <TextField
        type="number"
        aria-label="Archive After"
        className={classes.archivedNumber}
        InputProps={{
          inputProps: {
            min: minArchivedNumber,
            "aria-label": "Archive After",
          },
        }}
        value={props.archivesAfter}
        onFocus={(event) => {
          event.target.select();
        }}
        onChange={(event) => {
          let val = Number(event.target.value);

          // Impose min value constraint
          val = val < minArchivedNumber ? minArchivedNumber : val;
          props.setArchivesAfter(val);
        }}
      />
      <Select
        value={props.archiveCountType}
        onChange={(event) => {
          props.setArchiveCountType(event.target.value as ArchiveCountType);
        }}
      >
        <MenuItem
          key="TotalMissedReponses"
          value={ArchiveCountType.TotalMissedResponses}
          role="menuitem"
        >
          Total Missed Responses
        </MenuItem>
        <MenuItem
          key="ConsecutiveMissedResponses"
          value={ArchiveCountType.ConsecutiveMissedResponses}
          role="menuitem"
        >
          Consecutive Missed Responses
        </MenuItem>
        <MenuItem
          key="TotalAnsweredReponses"
          value={ArchiveCountType.TotalAnsweredResponses}
          role="menuitem"
        >
          Total Answered Responses
        </MenuItem>
      </Select>
    </div>
  );
};

export default ArchivePromptScheduling;
