import { QuestionType } from "../../../../types/auto/types";

export enum RequiredQuestionFields {
  Base,
  Labels,
  Snomed,
  MaxAnswers,
  Rows,
  NumberOfRows,
  Headers,
  RowObjectName,
  Format,
  GoalCategory,
}

export enum RequiredAnswerFields {
  Base,
  PlaceholderNote,
  ScreenReaderNote,
  Score,
  RangeMin,
  RangeMax,
  RangeDefault,
  ScoreCap,
  RangeDecimal,
  TableType,
  TableDropdownChoices,
  Checked,
  Regex,
}

export enum SectionObjectType {
  Undefined,
  Section,
  Question,
  Answer,
}

export enum ValidatorError {
  NoError,
  NoRangeMinValue,
  NoRangeMaxValue,
  RangeMinOutOfScope,
  RangeMaxOutOfScope,
  RangeDefaultOutOfScopeLow,
  RangeDefaultOutOfScopeHigh,
  NoTableTypeSelected,
  NoRows,
  NoHeaders,
  NoText,
  TextAlreadyExists,
  NoAnswerNumber,
}

export function getRequiredQuestionFields(qType: QuestionType) {
  var requiredQuestionFields: RequiredQuestionFields[] = [
    RequiredQuestionFields.Base,
  ];
  switch (qType) {
    case QuestionType.Range:
    case QuestionType.SkippableRange: {
      return [...requiredQuestionFields, RequiredQuestionFields.Labels];
    }
    case QuestionType.ButtonsMulti: {
      return [...requiredQuestionFields, RequiredQuestionFields.MaxAnswers];
    }
    case QuestionType.Table: {
      return [...requiredQuestionFields, RequiredQuestionFields.Rows];
    }
    case QuestionType.MultiSelectorTable: {
      return [
        ...requiredQuestionFields,
        RequiredQuestionFields.Headers,
        RequiredQuestionFields.RowObjectName,
      ];
    }
    case QuestionType.CheckBoxList: {
      return [
        ...requiredQuestionFields,
        RequiredQuestionFields.MaxAnswers,
        RequiredQuestionFields.Format,
      ];
    }
    case QuestionType.Goals: {
      return [...requiredQuestionFields, RequiredQuestionFields.GoalCategory];
    }
  }

  return requiredQuestionFields;
}

export function getRequiredAnswerFields(qType: QuestionType) {
  var requiredAnswerFields: RequiredAnswerFields[] = [
    RequiredAnswerFields.Base,
  ];
  switch (qType) {
    case QuestionType.Buttons:
    case QuestionType.ButtonsMulti: {
      return [...requiredAnswerFields, RequiredAnswerFields.Score];
    }
    case QuestionType.Textbox:
      return [
        ...requiredAnswerFields,
        RequiredAnswerFields.PlaceholderNote,
        RequiredAnswerFields.Regex,
      ];
    case QuestionType.Date: {
      return [...requiredAnswerFields, RequiredAnswerFields.PlaceholderNote];
    }
    case QuestionType.Range:
    case QuestionType.SkippableRange: {
      return [
        ...requiredAnswerFields,
        RequiredAnswerFields.RangeMin,
        RequiredAnswerFields.RangeMax,
        RequiredAnswerFields.RangeDecimal,
        RequiredAnswerFields.RangeDefault,
        RequiredAnswerFields.ScreenReaderNote,
      ];
    }
    case QuestionType.Number: {
      return [
        ...requiredAnswerFields,
        RequiredAnswerFields.PlaceholderNote,
        RequiredAnswerFields.RangeMin,
        RequiredAnswerFields.RangeMax,
      ];
    }
    case QuestionType.Table:
    case QuestionType.MultiSelectorTable: {
      return [RequiredAnswerFields.TableType];
    }
  }

  return requiredAnswerFields;
}
