import React from "react";
import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  title: string;
}

const GraphSectionTitle = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    symptomsTitleContainer: {
      marginLeft: "0",
      "@media print": { display: "none" },
    },
  }));
  const classes = useStyle();

  return (
    <Container className={classes.symptomsTitleContainer}>
      <Typography variant="h5" component="h2">
        {props.title}
      </Typography>
    </Container>
  );
};

export default GraphSectionTitle;
