import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Modal,
  Switch,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import { StaffClient } from "../../../types/auto/types";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { theme } from "../../../Theme";
import InfoHint from "../elements/form-elements/InfoHint";
import {
  CaseloadDownloadExcludeDatasets,
  CaseloadDownloadExcludeGoals,
  CaseloadDownloadGroupQuestionnaire,
} from "../../HelpText";
import { useState, useEffect } from "react";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import saveAs from "file-saver";

interface Props {
  caseloadId: number;
  enableExternalDatasets: boolean;
  open: boolean;
  closeModal: () => void;
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const IsCaseloadIdValid = (caseloadId: number | undefined): boolean =>
  caseloadId !== undefined && caseloadId >= 0;

const DownloadCaseloadModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
    },
    formControl: { marginBottom: 20 },
    toggleElement: {
      alignItems: "center",
      alignContent: "center",
    },
    hint: { alignContent: "center" },
    downloadOptionsContainer: { display: "flex", flexDirection: "column" },
  });

  const classes = useStyle(theme);
  const [loading, setLoading] = useState(false);
  const [errorDownload, setErrorDownload] = useState(false);
  const [groupByQuestionnaire, setGroupByQuestionnaire] = useState(false);
  const [excludeGoals, setExcludeGoals] = useState(false);
  const [excludeExternalDatasets, setExcludeExternalDatasets] = useState(false);

  const modalName = "Download Caseload Data";
  const buttonText = "Download Data";

  useEffect(() => {
    setLoading(false);
    setErrorDownload(false);
  }, [props.open]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          {modalName}
        </Typography>

        <Container className={classes.downloadOptionsContainer}>
          <FormControl className={classes.formControl}>
            <div className={classes.toggleElement}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      groupByQuestionnaire !== undefined && groupByQuestionnaire
                    }
                    color="secondary"
                    onChange={() => setGroupByQuestionnaire((x) => !x)}
                  />
                }
                label="Group by Questionnaire"
              />
              <InfoHint
                helpText={CaseloadDownloadGroupQuestionnaire}
                className={classes.hint}
              />
            </div>
          </FormControl>
          <FormControl className={classes.formControl}>
            <div className={classes.toggleElement}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={groupByQuestionnaire}
                    checked={
                      excludeGoals !== undefined &&
                      excludeGoals &&
                      !groupByQuestionnaire
                    }
                    color="secondary"
                    onChange={() => setExcludeGoals((x) => !x)}
                  />
                }
                label="Exclude Goals"
              />
              <InfoHint
                helpText={CaseloadDownloadExcludeGoals}
                className={classes.hint}
              />
            </div>
          </FormControl>
          {props.enableExternalDatasets && (
            <FormControl className={classes.formControl}>
              <div className={classes.toggleElement}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        excludeExternalDatasets !== undefined &&
                        excludeExternalDatasets
                      }
                      color="secondary"
                      onChange={() => setExcludeExternalDatasets((x) => !x)}
                    />
                  }
                  label="Exclude External Datasets"
                />
                <InfoHint
                  helpText={CaseloadDownloadExcludeDatasets}
                  className={classes.hint}
                />
              </div>
            </FormControl>
          )}
        </Container>
        {errorDownload && (
          <Alert severity="error">
            No patient responses for specified caseloads.
          </Alert>
        )}

        <div className={classes.actionButtonGroup}>
          <Button onClick={() => props.closeModal()}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              setLoading(true);

              // Do validation checks
              var caseloadValid = IsCaseloadIdValid(props.caseloadId);
              if (!caseloadValid) {
                setLoading(false);
                return;
              }

              new StaffClient(Configuration.SERVER_ROOT, FetchOverride)
                .downloadPatientResponsesWorkbook(
                  props.caseloadId,
                  groupByQuestionnaire,
                  excludeGoals,
                  !props.enableExternalDatasets || excludeExternalDatasets
                )
                .then((stream) => {
                  setErrorDownload(false);
                  saveAs(stream.data, "TrueColoursCaseloadData");
                  setLoading(false);
                })
                .catch(() => {
                  setErrorDownload(true);
                  setLoading(false);
                });
            }}
          >
            {buttonText}
          </LoadingButton>
        </div>
      </Box>
    </Modal>
  );
};

export default DownloadCaseloadModal;
