import React, { useEffect, useState } from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryChart,
  VictoryClipContainer,
  VictoryScatter,
  VictoryTooltip,
  VictoryZoomContainer,
} from "victory";
import { SymptomsDataPointViewModel } from "../../../../../types/auto/types";
import { CheckIsTablet } from "../../../../utils/MobileStatus";
import { ToTitleCase } from "../../../../utils/TitleCase";
import { GetXAxisDomain } from "../ChartHelper";
import GraphTickLabel from "../GraphTickLabel";
import MobileZoomable from "./MobileZoomable";
import { ExtendedBubbleViewModel } from "./SymptomsChart";

interface Props {
  data: ExtendedBubbleViewModel;
  width: number;
  fromDate: Date | undefined;
  toDate: Date | undefined;
}

const BASE_GRAPH_HEIGHT = 100;
const BUBBLE_HEIGHT = 55;

const SymptomsGraphCore = (props: Props): JSX.Element => {
  const isMobile = CheckIsTablet();
  const [xAxisValues, setXAxisValues] = useState<number[]>([]);
  const [chartHeight, setChartHeight] = useState(
    BASE_GRAPH_HEIGHT + BUBBLE_HEIGHT
  );
  const [allowZoom, setAllowZoom] = useState(true);
  const [zoomDomain, setZoomDomain] = useState<{
    x?: DomainTuple;
    y?: DomainTuple;
  }>();
  const [mobileSelectedDomain, setMobileSelectedDomain] = useState<{
    x?: DomainTuple;
    y?: DomainTuple;
  }>();

  useEffect(() => {
    setXAxisValues(GetXAxisDomain(props.fromDate, props.toDate));

    // Set the height of the graph based on the number of bubbles provided
    const dataHeight =
      ([...new Set(props.data.data?.map((x) => x.y))]?.length || 0) *
        BUBBLE_HEIGHT +
      (props.data.versions && props.data.versions.length > 0
        ? BUBBLE_HEIGHT
        : 0);
    setChartHeight(BASE_GRAPH_HEIGHT + dataHeight);

    // Allow zooming if there are more than 3 data points
    setAllowZoom(
      ([...new Set(props.data.data?.map((x) => x.x?.toLocaleString("en-GB")))]
        ?.length || 0) > 3
    );
  }, [props.data, props.fromDate, props.toDate]);

  return (
    <>
      <VictoryChart
        domainPadding={{ x: 40, y: 40 }}
        padding={{ left: 120, top: 50, right: 50, bottom: 50 }}
        width={props.width}
        height={chartHeight}
        aria-label={"Symptoms Graph For " + props.data.chartName}
        containerComponent={
          <VictoryZoomContainer
            zoomDimension="x"
            containerId="chart"
            height={chartHeight}
            disable={true || !allowZoom}
            clipContainerComponent={<VictoryClipContainer />}
            minimumZoom={{ x: 0.1 }}
            onZoomDomainChange={(s) => {
              setMobileSelectedDomain(s);
            }}
            zoomDomain={zoomDomain}
            allowZoom={!isMobile}
          />
        }
      >
        <VictoryAxis
          dependentAxis
          style={{
            grid: { stroke: "lightgray", strokeWidth: 0.5 },
          }}
          crossAxis={false}
          tickLabelComponent={
            <GraphTickLabel
              chartTitle={props.data.chartName || ""}
              style={{
                textTransform: "capitalize",
                textAlign: "center",
              }}
            />
          }
        />
        <VictoryAxis
          style={{
            grid: { stroke: "lightgray", strokeWidth: 0.5 },
            tickLabels: { angle: -5, padding: 20 },
            axisLabel: { padding: 35 },
          }}
          label="Date"
          tickValues={xAxisValues}
          tickFormat={(t: number) => {
            return new Date(t).toLocaleDateString("en-GB");
          }}
          fixLabelOverlap
        />
        <VictoryScatter
          key={"versionScatter"}
          style={{
            data: {
              stroke: props.data.colour,
              strokeWidth: "2",
              fill: "rgba(255, 255, 255)",
            },
          }}
          size={5}
          symbol="plus"
          data={props.data.versions?.map((x) => ({
            x: x.x,
            y: "Version\nChange",
          }))}
          labels={() => [
            props.data.chartName || "",
            "Questionnaire Version Changed",
          ]}
          labelComponent={
            <VictoryTooltip
              pointerLength={20}
              flyoutPadding={{
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
              }}
              flyoutStyle={{
                fill: "rgba(65, 65, 65, 0.8)",
                strokeWidth: 1,
                overflowWrap: "break-word",
              }}
              style={{
                fill: "white",
                overflowWrap: "break-word",
              }}
              renderInPortal
            />
          }
        />
        <VictoryScatter
          style={{
            data: {
              stroke: (d) =>
                d.datum.score === 0
                  ? "rgba(0, 0, 0, 0)"
                  : d.datum.score < 0
                  ? "gray"
                  : props.data.colour,
              strokeWidth: "2",
              fill: (d) =>
                d.datum.score === 0
                  ? "rgba(0, 0, 0, 0)"
                  : d.datum.score < 0
                  ? "gray"
                  : props.data.colour,
              opacity: "80%",
            },
          }}
          bubbleProperty="size"
          size={4}
          data={props.data.data?.map(
            (x) =>
              ({
                ...x,
                y: x.y?.replace(" ", "\n"), //Replace spaces with new lines to prevent labels spreading off the screen
              } as SymptomsDataPointViewModel)
          )}
          labels={(data) => [
            ToTitleCase(data.datum.y || ""),
            data.datum.x === undefined || isNaN(data.datum.x)
              ? ""
              : (data.datum.x as Date).toLocaleDateString("en-GB"),

            "Score: " +
              (data.datum.score === undefined ||
              isNaN(data.datum.score) ||
              data.datum.totalScore === undefined ||
              isNaN(data.datum.totalScore)
                ? ""
                : data.datum.score.toString() +
                  "/" +
                  data.datum.totalScore.toString()),
          ]}
          labelComponent={
            <VictoryTooltip
              pointerLength={20}
              flyoutPadding={{
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
              }}
              flyoutStyle={{
                fill: "rgba(65, 65, 65, 0.8)",
                strokeWidth: 1,
                overflowWrap: "break-word",
              }}
              style={{
                fill: "white",
                overflowWrap: "break-word",
              }}
              renderInPortal
            />
          }
        />
      </VictoryChart>
      {isMobile && allowZoom && (
        <MobileZoomable
          width={props.width}
          mobileSelectedDomain={mobileSelectedDomain}
          setZoomDomain={setZoomDomain}
          xAxisValues={xAxisValues}
          data={props.data}
        />
      )}
    </>
  );
};

export default SymptomsGraphCore;
