import { Typography, Box, Button } from "@mui/material";
import { AnswerDataModel } from "./QuestionnaireBuilderDataModelHelper";
import { makeStyles } from "@mui/styles";
import { Add, Delete, Edit } from "@mui/icons-material";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { QuestionType, TableQuestionType } from "../../../../types/auto/types";

interface Props {
  answers: AnswerDataModel[];
  handleAddAnswer: () => void;
  handleDeleteAnswer: (answerNumber: number) => void;
  handleEditAnswer: (answer: AnswerDataModel) => void;
  questionType: QuestionType;
}

const QuestionAnswers = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();

  const useStyle = makeStyles({
    mainContainer: {
      display: "flex",
      gap: 2,
      width: "100%",
      flexWrap: "wrap",
      backgroundColor: "white",
    },
    rowContainer: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "4fr 1fr",
      gridTemplateAreas: '"info buttons"',
      padding: 2,
    },
    infoContainer: {
      gridArea: "info",
      width: "100%",
    },
    buttonsContainer: {
      gridArea: "buttons",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    editButton: {
      marginLeft: "10px",
      width: isMobile ? "33%" : "",
      maxHeight: "36px",
    },
    deleteButton: {
      backgroundColor: "red",
      "&:hover": {
        backgroundColor: "crimson",
      },
      marginLeft: "10px",
      width: isMobile ? "33%" : "",
      maxHeight: "36px",
    },
    addButtonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      backgroundColor: "white",
      maxHeight: "36px",
      paddingTop: 10,
    },
  });
  const classes = useStyle();

  const returnRows: JSX.Element[] = props.answers.map((answer, index) => {
    return (
      <Box
        className={classes.rowContainer}
        key={"question-answer-container-" + answer.number + "-index-" + index}
      >
        <Box
          className={classes.infoContainer}
          key={
            "question-answer-info-container-" +
            answer.number +
            "-index-" +
            index
          }
        >
          {(props.questionType === QuestionType.Buttons ||
            props.questionType === QuestionType.ButtonsMulti) && (
            <Typography
              key={
                "question-answer-info-buttons-" +
                answer.number +
                "-index-" +
                index
              }
            >
              {answer.text +
                " (Number: " +
                answer.number +
                ", Score: " +
                (answer.score == null ? "N/A" : answer.score) +
                ")"}
            </Typography>
          )}
          {props.questionType === QuestionType.Textbox && (
            <Typography
              key={
                "question-answer-info-textbox-" +
                answer.number +
                "-index-" +
                index
              }
            >
              {"Placeholder: " +
                answer.text +
                ", Validation Regex:" +
                answer.validationRegex}
            </Typography>
          )}
          {props.questionType === QuestionType.Date && (
            <Typography
              key={
                "question-answer-info-textbox-" +
                answer.number +
                "-index-" +
                index
              }
            >
              {"Placeholder: " + answer.text}
            </Typography>
          )}
          {(props.questionType === QuestionType.DropDown ||
            props.questionType === QuestionType.CheckBoxList ||
            props.questionType === QuestionType.CheckBoxSingle) && (
            <Typography
              key={
                "question-answer-info-textbox-" +
                answer.number +
                "-index-" +
                index
              }
            >
              {answer.text}
            </Typography>
          )}
          {(props.questionType === QuestionType.Range ||
            props.questionType === QuestionType.SkippableRange) && (
            <Typography
              key={
                "question-answer-info-range-" +
                answer.number +
                "-index-" +
                index
              }
            >
              {answer.text +
                " (RangeMin: " +
                answer.rangeMin +
                ", RangeMax: " +
                answer.rangeMax +
                ", RangeDefault: " +
                answer.rangeDefault +
                ", RangeDecimal: " +
                answer.rangeDecimal +
                ")"}
            </Typography>
          )}
          {props.questionType === QuestionType.Number && (
            <Typography
              key={
                "question-answer-info-number-" +
                answer.number +
                "-index-" +
                index
              }
            >
              {"Placeholder: " +
                answer.text +
                " (RangeMin: " +
                answer.rangeMin +
                ", RangeMax: " +
                answer.rangeMax +
                ")"}
            </Typography>
          )}
          {(props.questionType === QuestionType.Table ||
            props.questionType === QuestionType.MultiSelectorTable) && (
            <Typography
              key={
                "question-answer-info-number-" +
                answer.number +
                "-index-" +
                index
              }
            >
              {"Column: " +
                (props.questionType === QuestionType.Table
                  ? answer.number! - 1
                  : answer.number!) +
                " (Input Type: " +
                TableQuestionType[answer.tableType!] +
                (answer.tableType === TableQuestionType.Number
                  ? ", RangeMin: " +
                    answer.rangeMin +
                    ", RangeMax: " +
                    answer.rangeMax +
                    ")"
                  : answer.tableType === TableQuestionType.Dropdown
                  ? ", Dropdown Options: [" +
                    answer.tableDropdownChoices?.join(", ") +
                    "])"
                  : ")")}
            </Typography>
          )}
        </Box>
        <Box
          className={classes.buttonsContainer}
          key={
            "question-answer-buttons-container-" +
            answer.number +
            "-index-" +
            index
          }
        >
          <Button
            className={classes.editButton}
            aria-label="Edit answer"
            variant="contained"
            onClick={() => {
              props.handleEditAnswer(answer);
            }}
            key={
              "question-answer-edit-button-" + answer.number + "-index-" + index
            }
          >
            <Edit
              key={
                "question-answer-edit-icon-" + answer.number + "-index-" + index
              }
            />
          </Button>
          <Button
            className={classes.deleteButton}
            aria-label="Delete answer"
            variant="contained"
            onClick={() => {
              answer.number && props.handleDeleteAnswer(answer.number);
            }}
            key={
              "question-answer-delete-button-" +
              answer.number +
              "-index-" +
              index
            }
          >
            <Delete
              key={
                "question-answer-delete-icon-" +
                answer.number +
                "-index-" +
                index
              }
            />
          </Button>
        </Box>
      </Box>
    );
  });
  if (
    props.questionType === QuestionType.Textbox ||
    props.questionType === QuestionType.Range ||
    props.questionType === QuestionType.SkippableRange ||
    props.questionType === QuestionType.Number ||
    props.questionType === QuestionType.Date ||
    props.questionType === QuestionType.CheckBoxSingle
  ) {
    returnRows.length < 1 &&
      returnRows.push(
        <Box
          className={classes.addButtonContainer}
          key={"question-answer-add-button-container" + returnRows.length}
        >
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              props.handleAddAnswer();
            }}
            key={"question-answer-add-button-" + returnRows.length}
          >
            Add answer
          </Button>
        </Box>
      );
  } else {
    returnRows.length <
      (props.questionType === QuestionType.DropDown ? 30 : 15) &&
      returnRows.push(
        <Box
          className={classes.addButtonContainer}
          key={"question-answer-add-button-container" + returnRows.length}
        >
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              props.handleAddAnswer();
            }}
            key={"question-answer-add-button-" + returnRows.length}
          >
            {props.questionType === QuestionType.Table ||
            props.questionType === QuestionType.MultiSelectorTable
              ? "Add Column"
              : "Add Answer"}
          </Button>
        </Box>
      );
  }
  if (props.questionType === QuestionType.Table) {
    returnRows.splice(0, 1);
  }

  return <Box className={classes.mainContainer}>{returnRows}</Box>;
};

export default QuestionAnswers;
