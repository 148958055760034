import React from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from "victory";
import { TScoreGraph } from "../../timeline/ScoresAccordian";

interface Props {
  width: number;
  mobileSelectedDomain:
    | {
        x?: DomainTuple | undefined;
        y?: DomainTuple | undefined;
      }
    | undefined;
  setZoomDomain: React.Dispatch<
    React.SetStateAction<
      | {
          x?: DomainTuple | undefined;
          y?: DomainTuple | undefined;
        }
      | undefined
    >
  >;
  data: TScoreGraph;
}

const MobileZoomable = (props: Props): JSX.Element => (
  <VictoryChart
    padding={{ top: 0, bottom: 30, left: 50, right: 50 }}
    width={props.width}
    height={90}
    domainPadding={{ x: 20, y: 20 }}
    domain={{
      x: undefined,
      y: [0, 141],
    }}
    containerComponent={
      <VictoryBrushContainer
        brushDimension="x"
        brushDomain={props.mobileSelectedDomain}
        onBrushDomainChange={(b) => props.setZoomDomain(b)}
        style={{ margin: "auto" }}
        height={90}
      />
    }
    aria-label={props.data.lineName}
  >
    <VictoryAxis
      style={{
        grid: { stroke: "lightgray", strokeWidth: 0.5 },
      }}
      fixLabelOverlap
    />
    <VictoryLine
      y={() => 65}
      style={{
        data: {
          stroke: "red",
          strokeDasharray: "5,5",
        },
      }}
    />
    <VictoryLine
      key={"Tscore-scatter-line"}
      interpolation="linear"
      style={{
        data: {
          stroke: "blue",
        },
      }}
      data={props.data.data}
    />
    <VictoryScatter
      key={"tscore-line"}
      style={{
        data: {
          stroke: "blue",
          strokeWidth: "2",
          fill: "rgb(255, 255, 255)",
        },
      }}
      size={4}
      data={props.data.data}
      labels={(data) => [
        data.datum.x === undefined ? "" : data.datum.x,

        "Score: " +
          (data.datum.y === undefined || isNaN(data.datum.y)
            ? ""
            : Math.round(data.datum.y).toString()),
      ]}
      labelComponent={
        <VictoryTooltip
          pointerLength={20}
          flyoutPadding={{
            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
          }}
          flyoutStyle={{
            fill: "rgba(65, 65, 65, 0.8)",
            strokeWidth: 1,
            overflowWrap: "break-word",
          }}
          style={{
            fill: "white",
            overflowWrap: "break-word",
          }}
          renderInPortal
        />
      }
    />
  </VictoryChart>
);

export default MobileZoomable;
