import React, { useState } from "react";
import { theme } from "../../../../Theme";
import { Button, Container, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Role, StaffViewModel } from "../../../../types/auto/types";
import CreateEditStaffModal from "./CreateEditStaffModal";
import { Add, Edit } from "@mui/icons-material";

interface Props {
  staff?: StaffViewModel;
  style?: React.CSSProperties;
  onSave?: () => void;
  setReRenderView?: React.Dispatch<React.SetStateAction<boolean>>;
  reloadStaff?: () => void;
  role: Role;
}

const CreateEditStaff = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    container: {
      padding: 0,
      marginTop: 10,
    },
  }));
  const classes = useStyle(theme);
  const [createEditClinicianModalOpen, setCreateEditClinicianModalOpen] =
    useState(false);
  const creating = !props.staff;

  return (
    <>
      <CreateEditStaffModal
        open={createEditClinicianModalOpen}
        closeModal={() => {
          props.reloadStaff && props.reloadStaff();
          setCreateEditClinicianModalOpen(false);
        }}
        onSave={props.onSave}
        staff={props.staff}
        setReRenderView={props.setReRenderView}
        role={props.role}
      />

      <Container className={classes.container} style={props.style}>
        <Button
          variant="contained"
          startIcon={creating ? <Add /> : <Edit />}
          onClick={() => setCreateEditClinicianModalOpen(true)}
        >
          {creating ? "Create " + Role[props.role] : "Edit Details"}
        </Button>
      </Container>
    </>
  );
};

export default CreateEditStaff;
