import { Container, FormControlLabel, Switch, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { theme } from "../../../../Theme";
import { StaffViewModel } from "../../../../types/auto/types";
import {
  IsEmailValid,
  IsNameValid,
} from "../../../utils/UserDetailsValidation";

interface Props {
  staff: StaffViewModel;
  setStaff: React.Dispatch<React.SetStateAction<StaffViewModel>>;
  firstNameError: boolean;
  setFirstNameError: React.Dispatch<React.SetStateAction<boolean>>;
  lastNameError: boolean;
  setLastNameError: React.Dispatch<React.SetStateAction<boolean>>;
  emailError: boolean;
  setEmailError: React.Dispatch<React.SetStateAction<boolean>>;
  setReRender: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateEditStaffForm = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    textFieldContainer: {
      margin: 0,
      padding: 0,
      display: "grid",
      flexDirection: "row",
      gap: 10,
      width: "100%",
      marginBottom: 5,
    },
    textField: {
      width: "100%",
    },
  });
  const classes = useStyle(theme);

  return (
    <>
      <Container className={classes.textFieldContainer}>
        <TextField
          label="First Name"
          className={classes.textField}
          required
          value={
            props.staff.firstName === undefined ? "" : props.staff.firstName
          }
          onChange={(e) => {
            props.setStaff((x) => {
              x.firstName = e.target.value;
              props.setReRender((x) => !x);
              return x;
            });
            props.setFirstNameError(!IsNameValid(e.target.value));
          }}
          error={props.firstNameError}
          helperText={
            props.firstNameError && "Please provide a valid first name"
          }
        />
        <TextField
          label="Last Name"
          className={classes.textField}
          required
          value={props.staff.lastName === undefined ? "" : props.staff.lastName}
          onChange={(e) => {
            props.setStaff((x) => {
              x.lastName = e.target.value;
              props.setReRender((x) => !x);
              return x;
            });
            props.setLastNameError(!IsNameValid(e.target.value));
          }}
          error={props.lastNameError}
          helperText={props.lastNameError && "Please provide a valid last name"}
        />
        <>
          {" "}
          <Container className={classes.textFieldContainer}>
            <TextField
              label="Email Address"
              className={classes.textField}
              required
              value={
                props.staff.contactEmail === undefined ||
                props.staff.contactEmail === null
                  ? ""
                  : props.staff.contactEmail
              }
              onChange={(e) => {
                props.setStaff((x) => {
                  x.contactEmail = e.target.value.toLowerCase();
                  props.setReRender((x) => !x);
                  return x;
                });
              }}
              onBlur={() =>
                props.setEmailError(!IsEmailValid(props.staff.contactEmail))
              }
              error={props.emailError}
              helperText={props.emailError && "Please provide a valid email"}
            />
          </Container>{" "}
        </>
        {props.staff.id !== undefined && props.staff.id !== null && (
          <FormControlLabel
            control={
              <Switch
                checked={!props.staff.active}
                onChange={(e) => {
                  props.setStaff((x) => {
                    x.active = !e.target.checked;
                    props.setReRender((x) => !x);
                    return x;
                  });
                }}
                name="archived"
              />
            }
            label="Archived"
          />
        )}
      </Container>
    </>
  );
};

export default CreateEditStaffForm;
