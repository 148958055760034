import { LoadingButton } from "@mui/lab";
import { Modal, Box, Typography, Button, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckIsMobile } from "../../utils/MobileStatus";
import MedicationDetailsForm from "./MedicationDetailsForm";
import React, { useEffect } from "react";
import {
  MedicationClient,
  TenancyOptionErrors,
  MedicationViewModel,
} from "../../../types/auto/types";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import { IsDosageValid } from "./CreateEditMedicationModalHelper";

interface Props {
  open: boolean;
  closeModal: () => void;
  medication?: MedicationViewModel;
  refresh: () => void;
  medicationCategoryId: number;
  resetMedications: () => void;
}
const CreateEditMedicationModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "80%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
    },
  });
  const classes = useStyle();
  const [, setReRender] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [medication, setMedication] = React.useState<MedicationViewModel>(
    props.medication ?? ({} as MedicationViewModel)
  );
  const [dosageError, setDosageError] = React.useState<boolean>(false);
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [unitsError, setUnitsError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<TenancyOptionErrors | undefined>(
    undefined
  );

  function resetErrors() {
    setDosageError(false);
    setNameError(false);
    setUnitsError(false);
  }
  function confirmInput(medication: MedicationViewModel | undefined): boolean {
    var correctDetails = true;
    if (medication === undefined) {
      setNameError(true);
      setDosageError(true);
      setUnitsError(true);
      return false;
    }
    if (medication.name === undefined) {
      setNameError(true);
      correctDetails = false;
    }
    if (!IsDosageValid(medication.minDosage, medication.maxDosage)) {
      setDosageError(true);
      correctDetails = false;
    }
    if (medication.units === undefined) {
      setUnitsError(true);
      correctDetails = false;
    }
    return correctDetails;
  }

  useEffect(() => {
    if (props.medication) {
      // Deep copy patient prop to fix state update problems
      setMedication(
        JSON.parse(JSON.stringify(props.medication), function (key, value) {
          return value;
        })
      );
    } else {
      setMedication({
        medicationCategoryId: props.medicationCategoryId,
      } as MedicationViewModel);
    }

    setReRender((x) => !x);
  }, [props]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          {props.medication ? "Edit Medication" : "Create Medication"}
        </Typography>
        <MedicationDetailsForm
          medication={medication}
          setMedication={setMedication}
          setReRender={setReRender}
          setDosageError={setDosageError}
          setNameError={setNameError}
          setUnitsError={setUnitsError}
          dosageError={dosageError}
          nameError={nameError}
          unitsError={unitsError}
        />
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              props.closeModal();
              setReRender((x) => !x);
              resetErrors();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={dosageError || nameError || unitsError}
            onClick={async () => {
              setLoading(true);
              if (medication.minDosage === undefined) {
                medication.minDosage = 0;
              }
              if (!confirmInput(medication)) {
                setLoading(false);
                return;
              }
              if (props.medication === undefined || props.medication === null) {
                new MedicationClient(Configuration.SERVER_ROOT, FetchOverride)
                  .createMedication(medication)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                    setReRender((x) => !x);
                    props.resetMedications();
                  })
                  .catch((e) => {
                    setLoading(false);
                    setError(e);
                  });
              } else {
                new MedicationClient(Configuration.SERVER_ROOT, FetchOverride)
                  .updateMedication(medication)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                    setReRender((x) => !x);
                    props.resetMedications();
                  })
                  .catch((e) => {
                    setLoading(false);
                    setError(e);
                  });
              }
              resetErrors();
              props.refresh();
            }}
          >
            {props.medication ? "Save Changes" : "Create Medication"}
          </LoadingButton>
        </div>
        {error !== undefined && (
          <Alert severity="error">
            {error === TenancyOptionErrors.InvalidModel
              ? "Details for medication are invalid"
              : "Tenancy does not support medication."}
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default CreateEditMedicationModal;
