import { MenuList, IconButton, MenuItem, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Menu } from "@mui/icons-material";
import { theme } from "../../../../Theme";
import Drawer from "./NavigationDrawer";
import { useEffect, useState } from "react";
import {
  ContactDetailsViewModel,
  EnumClient,
  Role,
  TenantClient,
  TenantViewModel,
  UserClient,
  UserViewModel,
} from "../../../../types/auto/types";
import { FetchOverride } from "../../../utils/Request";
import { Configuration } from "../../../Constants";
import UserManagement from "../user/UserManagement";
import NavigationOptions from "./NavigationOptions";
import {
  CheckIsMobile,
  CheckIsSmallDevice,
  CheckIsSmallerThan,
} from "../../../utils/MobileStatus";
import { useMountedState, useUnmountPromise } from "react-use";
import ContactPreferenceModal from "../user/ContactPreferenceModal";
import ChangeTenancyModal from "../user/ChangeTenancyModal";

const NavigationBar = (): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const isSmallerThan1150 = CheckIsSmallerThan(1150);
  const isSmallerThan1005 = CheckIsSmallerThan(1005);
  const isSmallerThan935 = CheckIsSmallerThan(935);
  const isSmallerThan840 = CheckIsSmallerThan(840);
  const isSmallerThan720 = CheckIsSmallerThan(720);
  const isSmallerThan660 = CheckIsSmallerThan(660);
  const [currentUser, setCurrentUser] = useState<UserViewModel>();
  const [anchorElNavDrawer, setAnchorElNavDrawer] =
    useState<null | HTMLElement>(null);

  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  const [roleDescriptions, setRoleDescriptions] = useState<{
    [key in keyof typeof Role]?: string;
  }>({});

  const [loadRole, setLoadRole] = useState(false);
  const [usingMedication, setUsingMedication] = useState(false);
  const [usingExternalData, setUsingExternalData] = useState(false);
  const [allowEditQuestionnaires, setAllowEditQuestionnaires] = useState(false);
  const [forceMobile, setForceMobile] = useState(false);
  const [optionGap, setOptionGap] = useState("3%");

  useEffect(() => {
    if (isMountedState()) {
      const effect = async () => {
        if (!currentUser) {
          setLoadRole(true);
          await resolveWhileMounted(
            new UserClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getCurrentUser()
          ).then(async (user: UserViewModel) => {
            setCurrentUser(user);
            await resolveWhileMounted(
              new EnumClient(
                Configuration.SERVER_ROOT,
                FetchOverride
              ).getRoleDescriptions()
            ).then((x) => {
              setRoleDescriptions(x);
              setLoadRole(false);
            });
          });
        }
        if (currentUser != null) {
          if (currentUser.role !== Role.Patient) {
            await resolveWhileMounted(
              new TenantClient(Configuration.SERVER_ROOT, FetchOverride)
                .getExternalDataEnabled()
                .then((externalDataEnabled) =>
                  setUsingExternalData(externalDataEnabled)
                )
            );
            await resolveWhileMounted(
              new TenantClient(Configuration.SERVER_ROOT, FetchOverride)
                .getMedicationEnabled()
                .then((medEnabled) => setUsingMedication(medEnabled))
            );
          }
          if (
            currentUser.role === Role.Admin ||
            currentUser.role === Role.SuperAdmin
          ) {
            await resolveWhileMounted(
              new TenantClient(Configuration.SERVER_ROOT, FetchOverride)
                .getEditQuestionnairesEnabled()
                .then((allowEditQuestionnaires) =>
                  setAllowEditQuestionnaires(allowEditQuestionnaires)
                )
            );
          }
        }
      };
      effect();
    }
  }, [currentUser, resolveWhileMounted, isMountedState]);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    if (
      currentUser?.role !== Role.Admin &&
      currentUser?.role !== Role.SuperAdmin
    ) {
      return;
    }

    const extraItemCount = [
      usingMedication,
      usingExternalData,
      allowEditQuestionnaires,
    ].filter(Boolean).length;

    const gap =
      extraItemCount === 3 ||
      (extraItemCount === 2 && isSmallerThan1150) ||
      (extraItemCount === 1 && isSmallerThan935) ||
      (extraItemCount === 0 && isSmallerThan720)
        ? "1%"
        : "3%";

    const mobile =
      (extraItemCount === 3 && isSmallerThan1150) ||
      (extraItemCount === 2 && isSmallerThan1005) ||
      (extraItemCount === 1 && isSmallerThan840) ||
      (extraItemCount === 0 && isSmallerThan660);

    setForceMobile(mobile);
    setOptionGap(gap);
  }, [
    isMobile,
    currentUser,
    usingMedication,
    usingExternalData,
    allowEditQuestionnaires,
    isSmallerThan1150,
    isSmallerThan1005,
    isSmallerThan935,
    isSmallerThan840,
    isSmallerThan720,
    isSmallerThan660,
  ]);

  const useStyle = makeStyles((th: Theme) => ({
    buttonToolbar: {
      display: "flex",
      gap: optionGap,
      flexDirection: "row",
      backgroundColor: th.palette.secondary.main,
      color: "white",
      borderRadius: "4px",
      overflow: "auto",
    },
    roleName: {
      fontWeight: "bolder",
      cursor: "default",
      pointerEvents: "none",
      fontSize: "22px",
      display: isSmallDevice ? "none" : "block",
    },
    button: {
      display: "inline-block",
    },
  }));
  const classes = useStyle(theme);

  const handleClickNavDrawer = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorElNavDrawer !== null) {
      setAnchorElNavDrawer(null);
    } else {
      setAnchorElNavDrawer(event.currentTarget);
    }
  };
  const handleCloseNavDrawer = () => {
    setAnchorElNavDrawer(null);
  };

  const GetRoleDescription = (
    descriptions: { [key in keyof typeof Role]?: string },
    role: Role
  ): string => {
    var keyValue = Object.entries(descriptions).filter(
      (key) => key[0] === Role[role]
    );

    if (keyValue.length > 0) {
      if (keyValue[0][1]) {
        return keyValue[0][1] as string;
      } else {
        return keyValue[0][0] as string;
      }
    } else {
      return role.toString();
    }
  };
  const [contactDetails, setContactDetails] = useState(
    new ContactDetailsViewModel()
  );
  const [userTenancies, setUserTenancies] = useState<TenantViewModel[]>([]);
  const [currentTenancy, setCurrentTenancy] = useState<number>(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [contactPrefOpen, setContactPrefOpen] = useState(false);
  const [changeTenancyOpen, setChangeTenancyOpen] = useState(false);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      const effect = async () => {
        await new UserClient(Configuration.SERVER_ROOT, FetchOverride)
          .getContactDetails()
          .then((contactDetail) => {
            setContactDetails(contactDetail);
          });

        await new UserClient(Configuration.SERVER_ROOT, FetchOverride)
          .getCurrentTenancy()
          .then((currentTenancy) => {
            setCurrentTenancy(currentTenancy);
          });

        await new TenantClient(Configuration.SERVER_ROOT, FetchOverride)
          .getUserTenancies()
          .then((userTenancies) => {
            setUserTenancies(userTenancies);
          });
      };
      effect();
    }
  }, [firstLoad]);

  return (
    <>
      {isMobile || forceMobile ? (
        <MenuList
          className={classes.buttonToolbar}
          aria-label="Page Navigation"
          role="menu"
        >
          <MenuItem id="mainMenu" aria-label="main menu item" role="menuitem">
            <IconButton onClick={handleClickNavDrawer} aria-label="main menu">
              <Menu style={{ color: "white" }} />
              <Drawer
                open={anchorElNavDrawer !== null}
                handleCloseNavDrawer={handleCloseNavDrawer}
                userRole={currentUser?.role || Role.NoAccess}
                anchorElNavDrawer={anchorElNavDrawer}
                usingMedication={usingMedication}
                usingExternalData={usingExternalData}
                allowEditQuestionnaires={allowEditQuestionnaires}
              />
            </IconButton>
          </MenuItem>
          <MenuItem
            className={classes.roleName}
            aria-label="user role"
            id="userRole"
            role="menuitem"
          >
            {Role[currentUser?.role as number]}
          </MenuItem>
          <UserManagement
            user={currentUser}
            setContactPrefOpen={setContactPrefOpen}
            setTenancyChangeOpen={setChangeTenancyOpen}
            tenancyChangeEnabled={userTenancies && userTenancies.length > 1}
          />
        </MenuList>
      ) : (
        <MenuList
          className={classes.buttonToolbar}
          aria-label="Page Navigation"
          role="menu"
        >
          <MenuItem
            className={classes.roleName}
            aria-label="user role"
            id="mainMenu"
            role="menuitem"
          >
            {loadRole ? (
              <></>
            ) : (
              GetRoleDescription(
                roleDescriptions,
                currentUser?.role ?? Role.NoAccess
              )
            )}
          </MenuItem>
          <NavigationOptions
            userRole={currentUser?.role || Role.NoAccess}
            usingMedication={usingMedication}
            usingExternalData={usingExternalData}
            allowEditQuestionnaires={allowEditQuestionnaires}
          />
          <UserManagement
            user={currentUser}
            setContactPrefOpen={setContactPrefOpen}
            setTenancyChangeOpen={setChangeTenancyOpen}
            tenancyChangeEnabled={userTenancies && userTenancies.length > 1}
          />
        </MenuList>
      )}
      <ContactPreferenceModal
        open={contactPrefOpen}
        closeModal={() => setContactPrefOpen(false)}
        details={contactDetails}
      />
      <ChangeTenancyModal
        open={changeTenancyOpen}
        closeModal={() => setChangeTenancyOpen(false)}
        userTenancies={userTenancies}
        setCurrentTenancy={(tenancy: number) => setCurrentTenancy(tenancy)}
        currentTenancy={currentTenancy}
      />
    </>
  );
};

export default NavigationBar;
