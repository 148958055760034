import { LoadingButton } from "@mui/lab";
import { Modal, Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import {
  DatasetViewModel,
  MeasurementViewModel,
  PatientDatasetClient,
  PatientDatasetViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { FetchOverride } from "../../../utils/Request";
import PatientDatasetForm from "./PatientDatasetForm";
import {
  IsMeasurementValueValid,
  MeasurementError,
} from "./PatientDatasetHelper";

interface Props {
  open: boolean;
  closeModal: () => void;
  patientDataset?: PatientDatasetViewModel;
  refresh: () => void;
  patientId: number;
  datasets: DatasetViewModel[];
}
const CreateEditPatientDatasetModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "80%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
    maxHeight: "85%",
    overflow: "auto",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
    },
  });
  const classes = useStyle();
  const [, setReRender] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [patientDataset, setPatientDataset] =
    React.useState<PatientDatasetViewModel>(
      props.patientDataset ?? ({} as PatientDatasetViewModel)
    );
  const [measurementAmountError, setMeasurementAmountError] =
    React.useState<boolean>(false);
  const [unselectedDatasetError, setUnselectedDatasetError] =
    React.useState<boolean>(false);
  const [measurementError, setMeasurementError] =
    React.useState<boolean>(false);
  const [measurements, setMeasurements] = React.useState<
    MeasurementViewModel[]
  >([]);

  const [dateError, setDateError] = React.useState<boolean>(false);

  function resetErrors() {
    setMeasurementAmountError(false);
    setUnselectedDatasetError(false);
    setMeasurementError(false);
    setDateError(false);
  }

  function checkMeasurements(patientDataset: PatientDatasetViewModel): boolean {
    if (
      patientDataset.datasetId === undefined ||
      patientDataset.datasetId === null
    ) {
      setUnselectedDatasetError(true);
      return false;
    }
    if (patientDataset.measurements !== undefined) {
      var validData = true;
      for (const measurement of patientDataset.measurements) {
        var tenantMeasurement = measurements.find(
          (x) => x.id === measurement.measurementId
        );
        if (tenantMeasurement === undefined) {
          setMeasurementError(true);
          validData = false;
          break;
        }
        if (
          IsMeasurementValueValid(
            tenantMeasurement,
            measurement.value ?? ""
          ) !== MeasurementError.NoError
        ) {
          setMeasurementError(true);
          validData = false;
          break;
        } else {
          setMeasurementError(false);
          validData = true;
        }
      }
      if (patientDataset.testDate === null) {
        setDateError(true);
        validData = false;
      }
      return validData;
    }
    return true;
  }

  useEffect(() => {
    if (props.patientDataset) {
      // Deep copy patient prop to fix state update problems
      setPatientDataset(
        JSON.parse(JSON.stringify(props.patientDataset), function (key, value) {
          return value;
        })
      );
    } else {
      setPatientDataset({
        patientId: props.patientId,
      } as PatientDatasetViewModel);
    }
    setReRender((x) => !x);
  }, [props]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          {props.patientDataset
            ? "Edit Patient Dataset"
            : "Create Patient Dataset"}
        </Typography>
        <PatientDatasetForm
          datasets={props.datasets}
          patientDataset={patientDataset}
          setPatientDataset={setPatientDataset}
          setReRender={setReRender}
          unselectedDatasetError={unselectedDatasetError}
          setUnselectedDatasetError={setUnselectedDatasetError}
          measurementAmountError={measurementAmountError}
          setMeasurementAmountError={setMeasurementAmountError}
          editing={
            props.patientDataset !== undefined && props.patientDataset !== null
          }
          measurements={measurements}
          setMeasurements={setMeasurements}
          setMeasurementError={setMeasurementError}
          onChangeCheck={checkMeasurements}
          dateError={dateError}
          setDateError={setDateError}
          measurementError={measurementError}
        />
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              props.closeModal();
              setReRender((x) => !x);
              resetErrors();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={
              measurementAmountError ||
              unselectedDatasetError ||
              measurementError ||
              dateError
            }
            onClick={async () => {
              setLoading(true);
              if (patientDataset.testDate === undefined) {
                patientDataset.testDate = new Date();
              }
              if (!checkMeasurements(patientDataset)) {
                setLoading(false);
                return;
              }
              if (props.patientDataset === undefined) {
                new PatientDatasetClient(
                  Configuration.SERVER_ROOT,
                  FetchOverride
                )
                  .createPatientDataset(patientDataset)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                    props.refresh();
                  })
                  .catch(() => setLoading(false));
              } else {
                new PatientDatasetClient(
                  Configuration.SERVER_ROOT,
                  FetchOverride
                )
                  .updatePatientDataset(patientDataset)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                    props.refresh();
                  })
                  .catch(() => setLoading(false));
              }
            }}
          >
            {props.patientDataset ? "Save Changes" : "Create Patient Dataset"}
          </LoadingButton>
        </div>
      </Box>
    </Modal>
  );
};

export default CreateEditPatientDatasetModal;
