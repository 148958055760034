import { Box, TextField, Typography } from "@mui/material";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { ScoringRuleDataModel } from "./QuestionnaireBuilderDataModelHelper";

interface Props {
  rules: ScoringRuleDataModel[] | undefined;
  rows: JSX.Element[];
  setRowCount: (count: number) => void;
}

const RulesArrayBuilder = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();

  return (
    <Box display={"flex"} width={"100%"} flexWrap={"wrap"}>
      <Box
        display={"flex"}
        width={"100%"}
        flexWrap={isMobile ? "wrap" : "nowrap"}
        gap={2}
        justifyContent={"flex-start"}
        alignContent={"center"}
        paddingBottom={2}
      >
        <Typography
          display={"flex"}
          fontWeight={"bold"}
          alignContent={"center"}
          flexWrap={"wrap"}
        >
          Select number of rules:
        </Typography>
        <TextField
          type="number"
          value={props.rows.length === 0 ? "" : props.rows.length}
          onChange={(e) => {
            if (Number(e.target.value) > 15) {
              props.setRowCount(15);
            } else if (Number(e.target.value) < 0) {
              props.setRowCount(0);
            } else {
              props.setRowCount(Number(e.target.value));
            }
          }}
        ></TextField>
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        gap={1}
        flexWrap={"wrap"}
        alignContent={"flex-start"}
        minHeight={"250px"}
        maxHeight={"250px"}
        sx={{ overflow: "hidden", overflowY: "scroll" }}
      >
        {props.rows}
      </Box>
    </Box>
  );
};
export default RulesArrayBuilder;
