import moment from "moment";
import {
  StaffViewModel,
  PatientViewModel,
  Role,
  SortType,
} from "../../../types/auto/types";
import { AbstractUserCardType, UserDescription } from "./SearchTypes";

export const PatientsToAbstractUserCardType = (
  patient: PatientViewModel[] | undefined,
  handleClick?: (patient: PatientViewModel) => void,
  handleRemoveFromCaseload?: (patient: PatientViewModel) => void
): AbstractUserCardType[] | undefined =>
  patient?.map((patient) => ({
    firstName: patient.firstName || "",
    lastName: patient.lastName || "",
    id: patient.id,
    nhsNumber: patient.nhsNumber,
    dateOfBirth: patient.dateOfBirth,
    role: Role.Patient,
    lastResponse: patient.lastResponse,
    registeredDate: patient.created,
    descriptions: [
      {
        type: SortType.TcNumber,
        name: "True Colours ID",
        value: patient?.id?.toString(),
        mobileOnly: true,
      },
      {
        type: SortType.NhsNumber,
        name: "NHS Number",
        value: patient?.nhsNumber?.toString() || "Not Provided",
      },
      {
        type: SortType.DateOfBirth,
        name: "Date of Birth",
        value: moment(patient?.dateOfBirth).format("DD/MM/YYYY"),
      },
      {
        type: SortType.LastResponse,
        name: "Last Response",
        value: patient.lastResponse
          ? moment(patient?.lastResponse).format("DD/MM/YYYY hh:mm A")
          : "No Response",
      },
    ],
    handleClick: handleClick && (() => handleClick(patient)),
    handleRemoveFromCaseload:
      handleRemoveFromCaseload && (() => handleRemoveFromCaseload(patient)),
  }));

export const CliniciansToAbstractUserCardType = (
  clinicians: StaffViewModel[] | undefined,
  handleClick?: (clinician: StaffViewModel) => void,
  handleRemoveFromCaseload?: (patient: StaffViewModel) => void
): AbstractUserCardType[] | undefined =>
  clinicians?.map((clinician) => ({
    firstName: clinician.firstName || "",
    lastName: clinician.lastName || "",
    id: clinician.id,
    role: Role.Clinician,
    descriptions: [
      {
        type: SortType.TcNumber,
        name: "True Colours ID",
        value: clinician.id.toString(),
        mobileOnly: true,
      },
    ],
    handleClick: handleClick && (() => handleClick(clinician)),
    handleRemoveFromCaseload:
      handleRemoveFromCaseload && (() => handleRemoveFromCaseload(clinician)),
  }));

export const AdminsToAbstractUserCardType = (
  admins: StaffViewModel[] | undefined,
  handleClick?: (admin: StaffViewModel) => void
): AbstractUserCardType[] | undefined =>
  admins?.map((admin) => ({
    firstName: admin.firstName || "",
    lastName: admin.lastName || "",
    id: admin.id,
    role: Role.Admin,
    descriptions: [
      {
        type: SortType.TcNumber,
        name: "True Colours ID",
        value: admin.id.toString(),
        mobileOnly: true,
      },
    ],
    handleClick: handleClick && (() => handleClick(admin)),
  }));

export const PatientSortValues: UserDescription[] = [
  {
    type: SortType.FirstName,
    value: "firstName",
    name: "First Name",
  },
  {
    type: SortType.LastName,
    value: "lastName",
    name: "Last Name",
  },
  {
    type: SortType.TcNumber,
    value: "id",
    name: "TC Number",
  },
  {
    type: SortType.NhsNumber,
    value: "nhsNumber",
    name: "NHS Number",
  },
  {
    type: SortType.DateOfBirth,
    value: "dateOfBirth",
    name: "Date of Birth",
  },
  {
    type: SortType.LastResponse,
    value: "lastResponse",
    name: "Last Response",
  },
  {
    type: SortType.RegisteredDate,
    value: "registeredDate",
    name: "Registered Date",
  },
];

export const StaffSortValues: UserDescription[] = [
  {
    type: SortType.FirstName,
    value: "firstName",
    name: "First Name",
  },
  {
    type: SortType.LastName,
    value: "lastName",
    name: "Last Name",
  },
  {
    type: SortType.TcNumber,
    value: "id",
    name: "TC Number",
  },
];
