import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, Modal, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import {
  PatientNoteViewModel,
  PatientNoteClient,
  PatientViewModel,
} from "../../../../types/auto/types";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import {
  AllowListViewPatientReasonRegex,
  Configuration,
} from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { Error400 } from "../../elements/errors/Error400";
import { IsNameValid } from "../../../utils/UserDetailsValidation";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  patient: PatientViewModel;
  closeModal: () => void;
  patientNote?: PatientNoteViewModel;
  onSave: () => void;
}
const CreateEditPatientNoteModal = (props: Props): JSX.Element => {
  const creating = !props.patientNote;
  const [patientNote, setPatientNote] = React.useState({
    patientId: props.patient.id,
  } as PatientNoteViewModel);
  const [noteContentError, setNoteContentError] = React.useState(false);
  const [invalidCharacterUsed, setInvalidCharacterUsed] = React.useState(false);
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "80%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    border: "none",
  };
  const [loading, setLoading] = React.useState(false);
  const [, rerender] = React.useState(false);
  const [errorCreatingNote, setErrorCreatingNote] = React.useState(false);
  function onClose() {
    props.setOpen(false);
    setErrorCreatingNote(false);
    setLoading(false);
    setNoteContentError(false);
  }
  const useStyle = makeStyles(() => ({
    textFieldContainer: {
      margin: 0,
      padding: 0,
      gap: 10,
      width: "100%",
      marginBottom: 15,
      display: "flex",
      flexDirection: "column",
    },
    textField: {
      padding: 5,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
      display: isMobile ? "flex" : "block",
      flexDirection: "column",
      justifyContent: "center",
    },
  }));
  const classes = useStyle();
  function handleChange(e: any) {
    var patientNote = e.target.value;
    if (patientNote.length > 0) {
      var regexp = new RegExp(AllowListViewPatientReasonRegex);
      var notInAllowList = regexp.test(patientNote);
      if (notInAllowList) {
        setNoteContentError(false);
        setInvalidCharacterUsed(true);
        return;
      } else {
        setInvalidCharacterUsed(false);
      }
    }
    setPatientNote((x) => {
      x.text = e.target.value;
      rerender((x) => !x);
      return x;
    });
    setNoteContentError(!IsNameValid(patientNote));
  }

  useEffect(() => {
    if (creating) {
      setPatientNote({
        patientId: props.patient.id,
      } as PatientNoteViewModel);
    } else {
      setPatientNote(
        () =>
          ({
            id: props.patientNote?.id,
            patientId: props.patientNote?.patientId,
            staffId: props.patientNote?.staffId,
            text: props.patientNote?.text,
            staffName: props.patientNote?.staffName,
            editedStaffId: props.patientNote?.editedStaffId,
            editedStaffName: props.patientNote?.editedStaffName,
            created: props.patientNote?.created,
            active: props.patientNote?.active,
            updated: props.patientNote?.updated,
          } as PatientNoteViewModel)
      );
    }
    rerender((x) => !x);
  }, [props, creating]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle }}>
        <Container className={classes.textFieldContainer}>
          <TextField
            label="Note"
            multiline
            required
            value={patientNote.text ?? ""}
            onChange={(e) => handleChange(e)}
            className={classes.textField}
            onBlur={() => setNoteContentError(!IsNameValid(patientNote.text))}
            error={noteContentError || invalidCharacterUsed}
            helperText={
              (noteContentError && "Please provide a valid patient note") ||
              (invalidCharacterUsed && "Invalid character used.")
            }
          />
        </Container>
        <div className={classes.actionButtonGroup}>
          <Button onClick={() => onClose()}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              const isContentValid = IsNameValid(patientNote.text);
              setNoteContentError(!isContentValid);

              if (!isContentValid) {
                setLoading(false);
                return;
              }
              if (creating) {
                new PatientNoteClient(Configuration.SERVER_ROOT, FetchOverride)
                  .createPatientNote(patientNote)
                  .then(() => {
                    onClose();
                    props.onSave!();
                  })
                  .catch(() => {
                    setErrorCreatingNote(true);
                  });
              } else {
                new PatientNoteClient(Configuration.SERVER_ROOT, FetchOverride)
                  .editPatientNote(patientNote)
                  .then(() => {
                    onClose();
                    props.onSave!();
                  })
                  .catch(() => {
                    setErrorCreatingNote(true);
                  });
              }
            }}
          >
            {creating ? "Create" : "Edit"} Patient Note
          </LoadingButton>
        </div>
        {errorCreatingNote && <Error400 />}
      </Box>
    </Modal>
  );
};

export default CreateEditPatientNoteModal;
