import {
  Alert,
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import { QuestionnaireBuilderDataModel } from "./QuestionnaireBuilderDataModelHelper";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import nameof from "../../../utils/NameOf";

interface Props {
  questionnaire: QuestionnaireBuilderDataModel;
  setQuestionnaireWIP: (field: string, value: string) => void;
}

const HeaderBuilder = (props: Props): JSX.Element => {
  const [version] = useState<string>(
    props.questionnaire.version
      ? "Version " + props.questionnaire.version
      : "Version 1"
  );

  const isMobile = CheckIsMobile();

  const useStyle = makeStyles({
    titles: {
      marginTop: "10px",
    },
    textarea: {
      backgroundColor: "#fafafa",
    },
    tooltip: {
      display: "block",
    },
    errorText: {
      color: "red",
      fontWeight: "bold",
    },
  });
  const classes = useStyle();

  const nameField = "name-field";
  const titleField = "title-field";
  const descField = "description-field";

  function handleChange(e: any) {
    if (e.target.id === nameField) {
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("name"),
        e.target.value
      );
    } else if (e.target.id === titleField) {
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("title"),
        e.target.value
      );
    } else if (e.target.id === descField) {
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("description"),
        e.target.value
      );
    }
  }

  return (
    <Box
      display={"flex"}
      padding={1}
      gap={2}
      width={isMobile ? "100%" : "70%"}
      flexWrap={"wrap"}
      key={"header-builder-container"}
    >
      <Typography
        variant="h5"
        component="h2"
        className={classes.titles}
        key={"header-builder-header"}
      >
        {(props.questionnaire.name === "" || props.questionnaire.name == null
          ? "New Questionnaire"
          : props.questionnaire.name) +
          " - " +
          version}
      </Typography>

      <Box
        display={"inline-grid"}
        gridTemplateColumns={"5fr 1fr"}
        gridTemplateAreas={'"textbox icon"'}
        width={"100%"}
        key={"header-builder-name-field-container"}
      >
        <Box gridArea={"textbox"} key={"name-field-container"}>
          <TextField
            id={nameField}
            key={nameField + props.questionnaire.id}
            label={"Name"}
            placeholder="Name"
            value={
              props.questionnaire.name === "New Questionnaire" ||
              props.questionnaire.name == null
                ? ""
                : props.questionnaire.name
            }
            onChange={(e) => {
              handleChange(e);
            }}
            className={classes.textarea}
            fullWidth
          />
        </Box>
      </Box>

      <Box
        display={"inline-grid"}
        gridTemplateColumns={"5fr 1fr"}
        gridTemplateAreas={'"textbox icon"'}
        width={"100%"}
        key={"header-builder-title-field-container"}
      >
        <Box gridArea={"textbox"} key={titleField + "-container"}>
          <TextField
            id={titleField}
            key={titleField + props.questionnaire.id}
            label={"Title"}
            placeholder="Title"
            value={props.questionnaire.title ?? ""}
            onChange={(e) => {
              handleChange(e);
            }}
            className={classes.textarea}
            fullWidth
          />
        </Box>
      </Box>

      <Box
        display={"inline-grid"}
        gridTemplateColumns={"5fr 1fr"}
        gridTemplateAreas={'"textbox icon"'}
        width={"100%"}
        key={"header-builder-description-field-container"}
      >
        <Box gridArea={"textbox"} key={descField + "-container"}>
          <TextField
            id={descField}
            key={descField + props.questionnaire.id}
            label={"Description"}
            placeholder="Description"
            value={props.questionnaire.description ?? ""}
            onChange={(e) => {
              handleChange(e);
            }}
            className={classes.textarea}
            fullWidth
            multiline
            maxRows={4}
          />
        </Box>
        <Box gridArea={"icon"} key={"description-field-icon-container"}>
          <Tooltip
            title={"This textfield accepts HTML markup."}
            enterTouchDelay={0}
            className={classes.tooltip}
            key={"description-field-tooltip-container"}
          >
            <IconButton key={"description-field-info-icon-button"}>
              <InfoIcon key={"description-field-info-icon"} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box
        display={"inline-grid"}
        width={"100%"}
        key={"header-builder-authenticated-field-container"}
      >
        {props.questionnaire.authenticatedOnly && (
          <Alert
            key={"header-builder-authenticated-field-alert"}
            severity="info"
          >
            {"Questionnaire can only be accessed by logging in."}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default HeaderBuilder;
