import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { Add, Delete, Edit } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {
  DatasetViewModel,
  PatientDatasetClient,
  PatientDatasetViewModel,
} from "../../../../types/auto/types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WarningDialog from "../../elements/dialog/WarningDialog";
import { FetchOverride } from "../../../utils/Request";
import { Configuration } from "../../../Constants";
import CreateEditPatientDatasetModal from "./CreateEditPatientDatasetModal";

interface Props {
  patientDataset?: PatientDatasetViewModel;
  patientId: number;
  refresh: () => void;
  datasets: DatasetViewModel[];
}
const CreateEditPatientDataset = (props: Props): JSX.Element => {
  const [anchorActionList, setAnchorActionList] =
    React.useState<null | HTMLElement>(null);
  const [openAddEditPatientDataset, setOpenAddEditPatientDataset] =
    React.useState(false);
  const [openDeletePatientDataset, setOpenDeletePatientDataset] =
    React.useState(false);
  const useStyle = makeStyles({
    button: {
      marginTop: 15,
    },
    vert: {
      marginLeft: "auto",
      padding: "0",
      minWidth: "24px",
    },
  });
  const classes = useStyle();
  const handleClose = () => {
    setAnchorActionList(null);
  };

  return (
    <>
      {props.patientDataset === undefined && (
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => setOpenAddEditPatientDataset(true)}
          startIcon={props.patientDataset ? "" : <Add />}
        >
          {props.patientDataset ? <Edit /> : "Add patient dataset"}
        </Button>
      )}
      {props.patientDataset !== undefined && (
        <Button
          onClick={(event) =>
            setAnchorActionList((val) =>
              val === null ? event.currentTarget : null
            )
          }
          aria-label="Open Patient Dataset Action List"
          className={classes.vert}
        >
          <MoreVertIcon />
          <Menu
            open={anchorActionList !== null}
            anchorEl={anchorActionList}
            aria-label="Patient Dataset Action List"
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => setOpenAddEditPatientDataset(true)}
              aria-label="Edit dataset"
            >
              <Edit />
              Edit dataset
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenDeletePatientDataset(true);
              }}
              aria-label="Delete dataset"
            >
              <Delete />
              Delete dataset
            </MenuItem>
          </Menu>
        </Button>
      )}
      <CreateEditPatientDatasetModal
        datasets={props.datasets}
        open={openAddEditPatientDataset}
        refresh={props.refresh}
        closeModal={() => setOpenAddEditPatientDataset(false)}
        patientDataset={props.patientDataset}
        patientId={props.patientId}
      />
      {props.patientDataset !== undefined && (
        <WarningDialog
          title="Delete patient dataset"
          errorText="Error deleting patient dataset."
          body={`This will delete the selected patient dataset. Are you sure this is intended?`}
          continueButtonText="Delete patient dataset"
          open={openDeletePatientDataset}
          onCancel={() => setOpenDeletePatientDataset(false)}
          setOpen={() => setOpenDeletePatientDataset(true)}
          onContinue={() =>
            new PatientDatasetClient(Configuration.SERVER_ROOT, FetchOverride)
              .deletePatientDataset(props.patientDataset!)
              .then(() => {
                setOpenDeletePatientDataset(false);
                props.refresh();
              })
          }
        />
      )}
    </>
  );
};
export default CreateEditPatientDataset;
