import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React from "react";

interface Props {
  open: boolean;
  autofillQuestionnaireDetails: { [key: string]: string } | undefined;
  handleAutofill: () => void;
  handleContinue: () => void;
}

function AutofillPrompt(props: Props) {
  // Function to create a string listing all questionnaire names
  const generateQuestionnaireList = (): string => {
    if (
      !props.autofillQuestionnaireDetails ||
      Object.keys(props.autofillQuestionnaireDetails).length === 0
    ) {
      return "No autofill questionnaires available";
    }
    var resultString = "\n";
    for (const [key, value] of Object.entries(
      props.autofillQuestionnaireDetails
    )) {
      resultString += `${key} : ${value}\n`;
    }
    return resultString;
  };
  var result = generateQuestionnaireList();

  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        key={"autofill-dialog"}
      >
        <DialogTitle id="alert-dialog-title" key="autofill-dialog-title">
          {"Autofill Questionnaire?"}
        </DialogTitle>
        <DialogContent key={"autofill-dialog-content"}>
          <DialogContentText
            id="alert-dialog-description"
            key="autofill-dialog-content-text"
          >
            Do you want to load autofill data from your most recent
            {props.autofillQuestionnaireDetails &&
            Object.keys(props.autofillQuestionnaireDetails).length > 1
              ? " responses "
              : " response "}
            to:
            {result.split("\n").map((line, index) => (
              <React.Fragment key={"autofill-prompt-questionnaire-" + index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleContinue}
            variant="text"
            color="error"
            key={"autofill-no"}
          >
            No
          </Button>
          <Button
            onClick={props.handleAutofill}
            variant="contained"
            key={"autofille-yes"}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AutofillPrompt;
