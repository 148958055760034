import { useEffect, useState, useContext } from "react";
import { Button, Container, Divider } from "@mui/material";
import { Error400 } from "../elements/errors/Error400";
import { Error500 } from "../elements/errors/Error500";
import FormErrorText from "../elements/errors/FormErrorText";
import QuestionnaireFooter from "./QuestionnaireFooter";
import {
  PatientTab,
  QuestionnaireResponseDataViewModel,
  QuestionnaireResponseViewModel,
  QuestionnaireViewModel,
  QuestionType,
  QuestionViewModel,
  ResponseState,
  Role,
} from "../../../types/auto/types";
import QuestionnaireHeader from "./QuestionnaireHeader";
import SaveDraftModal from "./SaveDraftModal";
import QuestionnaireSection from "./questions/QuestionnaireSection";
import SubmissionDialog from "../elements/dialog/SubmissionDialog";
import { Configuration } from "../../Constants";
import { UserContext } from "../elements/stores/UserStore";
import { useMountedState, useUnmountPromise } from "react-use";
import parse from "html-react-parser";
import { isDependsValidInternal } from "./questions/QuestionBoxHelper";
import EditDraftPrompt from "./EditDraftPrompt";
import { getQuestionTypeInternal } from "./questions/QuestionBoxHelper";
import { getCookie } from "../../utils/cookie";
import AutofillPrompt from "./AutofillPrompt";
import FormSuccessText from "../elements/errors/FormSuccessText";
import DeleteGoalsPrompt from "./DeleteGoalsPrompt";

interface Props {
  patientId: string | null;
  questionnaire: QuestionnaireViewModel | undefined;
  submitResponses: (
    responses: Array<QuestionnaireResponseViewModel>,
    date: Date,
    state: ResponseState
  ) => Promise<Boolean>;
  getNextQuestionnaire: (draft: boolean) => void;
  openSubmitDialog: boolean;
  questionnaireResponseData: QuestionnaireResponseDataViewModel | undefined;
  editing: boolean;
  editingDate: boolean;
  setEditingDate: React.Dispatch<React.SetStateAction<boolean>> | null;
  goal: boolean;
  role: Role | undefined;
  fromNotification: boolean;
  submitDraft?: (
    responses: Array<QuestionnaireResponseViewModel>,
    date: Date,
    section: number,
    state: ResponseState
  ) => Promise<Boolean>;
  responseToken: string;
  handleCancel?: (override?: boolean) => Promise<void>;
  tenantId: string | null;
  handleDiscard?: () => void;
  handleAutofill?: () => void;
  hasAutofill: boolean;
  autofillQuestionnaireDetails?: { [key: string]: string } | undefined;
  editDraftError: boolean;
  openSaveDraftModal?: boolean;
  remainingTokens?: number;
  setOpenSaveDraftModal?: React.Dispatch<React.SetStateAction<boolean>>;
  caseloadId?: number;
  combinedNotificationTokens?: string;
  responseId?: number;
  setHasUnsubmittedGoals: (x: boolean) => void;
  hasUnsubmittedGoals: boolean;
  toggleGoalDeletionWarningModal: (set: boolean, override: boolean) => void;
  goalDeletionWarningModal: boolean;
  goalDeletionWarningModalOverride: boolean;
  apiCallFinished?: boolean;
  responseState?: ResponseState;
}

const QuestionnaireForm = (props: Props): JSX.Element => {
  const [section, setSection] = useState(0);
  const [responses, setResponses] = useState(
    new Array<QuestionnaireResponseViewModel>()
  );
  const [questions, setQuestions] = useState(new Array<QuestionViewModel>());

  const [, setRerender] = useState(false);
  const [onCoverPage, setOnCoverPage] = useState(
    props.questionnaire?.coverPage || props.questionnaire?.consentCheck
      ? true
      : false
  );

  const [validationText, setValidationText] = useState("");
  const [sectionComplete, setSectionComplete] = useState(false);
  const [submissionText, setSubmissionText] = useState("");
  const [statusCode, setStatusCode] = useState<number>();
  const [submitButtonLoad, setSubmitButtonLoad] = useState(false);
  const [responseDate, setResponseDate] = useState(
    props.questionnaireResponseData?.submitted ?? new Date()
  );
  const [saveDraftButtonLoading, setSaveDraftButtonLoading] = useState(false);
  const [editDraftModalOpen, setEditDraftModalOpen] = useState(false);
  const [autofillModalOpen, setAutofillModalOpen] = useState(
    props.hasAutofill &&
      props.autofillQuestionnaireDetails !== undefined &&
      Object.keys(props.autofillQuestionnaireDetails).length !== 0
  );
  const [consentCheck, setConsentCheck] = useState(false);

  const isMountedState = useMountedState();
  const resolveWhileMounted = useUnmountPromise();

  function getBackgroundImage() {
    const sec = props.questionnaire?.sections?.at(section);
    if (sec == null) {
      return "none";
    }
    const back = sec.backgroundImage;
    if (back == null) {
      return "none";
    }
    return "url(" + back + ")";
  }

  useEffect(() => {
    setAutofillModalOpen(
      props.hasAutofill &&
        props.autofillQuestionnaireDetails !== undefined &&
        Object.keys(props.autofillQuestionnaireDetails).length !== 0
    );
    // eslint-disable-next-line
  }, [props.autofillQuestionnaireDetails]);

  useEffect(() => {
    if (
      props.questionnaireResponseData?.state === ResponseState.Draft &&
      props.questionnaireResponseData?.responses?.length !== 0
    ) {
      setEditDraftModalOpen(true);
    } else if (
      props.hasUnsubmittedGoals &&
      props.responseState === ResponseState.Draft
    ) {
      setEditDraftModalOpen(true);
    }
    // eslint-disable-next-line
  }, [props.apiCallFinished]);

  useEffect(() => {
    if (isMountedState() && props.questionnaireResponseData?.responses) {
      setResponses(props.questionnaireResponseData?.responses);
    }
  }, [setResponses, props.questionnaireResponseData, isMountedState]);

  useEffect(() => {
    let questionsToPush: QuestionViewModel[] = [];
    props.questionnaire?.sections?.forEach((element) => {
      if (element.questions !== undefined) {
        element.questions.forEach((question) => {
          questionsToPush.push(question);
        });
      }
    });
    setQuestions(questionsToPush);
  }, [props.questionnaire]);

  useEffect(() => {
    sectionCompleteCheck();
    // eslint-disable-next-line
  }, [section]);

  const getNextQuestionnaire = (draft: boolean) => {
    setResponses(new Array<QuestionnaireResponseViewModel>());
    setSection(0);
    props.getNextQuestionnaire(draft);
    setSubmissionText("Responses have been submitted successfully.");
  };

  const user = useContext(UserContext);
  const userIsPatient =
    user?.id === undefined || user.id === null || user.id <= 0;

  function getNavigationLink() {
    const viewPatientLink =
      userIsPatient || getCookie("TrueColours_Expiration").length === 0
        ? "/login/viewPatient,"
        : "/viewPatient?p=";

    return (
      Configuration.SITEBASE +
      viewPatientLink +
      props.patientId +
      (userIsPatient ? "_t=" : "&t=") +
      (props.editing || props.openSaveDraftModal
        ? PatientTab.Timeline.toString()
        : PatientTab.Questionnaires.toString())
    );
  }

  const navigateSection = async (
    newSection: number,
    override: boolean = false
  ) => {
    const navLink = getNavigationLink();
    setSubmissionText("");
    setSubmitButtonLoad(true);
    if (newSection < 0 && props.hasUnsubmittedGoals && !override) {
      props.toggleGoalDeletionWarningModal(true, false);
      setSubmitButtonLoad(false);
      return;
    } else {
      if (newSection < 0) {
        if (props.handleCancel != null) {
          await props.handleCancel();
          // go back to patient
          window.location.href = navLink;
        }
        // go back to patient
        window.location.href = navLink;
      }
    }

    var sectionValid = false;
    while (!sectionValid) {
      sectionValid = true;
      var checkedSection = props?.questionnaire?.sections?.at(newSection);
      if (
        checkedSection !== undefined &&
        checkedSection.dependsOn !== undefined
      ) {
        sectionValid = isDependsValidInternal(
          checkedSection.dependsOn,
          responses,
          questions
        );
      }
      if (!sectionValid) {
        if (newSection < section) {
          newSection--;
        } else {
          newSection++;
        }
      }

      if (newSection >= (props.questionnaire?.sections?.length ?? 0)) {
        break;
      }
      if (newSection <= 0) {
        break;
      }
    }

    if (newSection < section) {
      // Go back go a previous section
      setValidationText("");
      setSection(newSection);
      setSubmitButtonLoad(false);
      window.scrollTo({ top: 0 });
    }
    // Can only go to a future section if the responses are valid
    else {
      var validated = validateResponses(true);
      if (validated.valid) {
        const submit = async () => {
          // Submit the responses if this is the last section
          if (newSection === props.questionnaire?.sections?.length) {
            await resolveWhileMounted(
              props.submitResponses(
                validated.filtered,
                responseDate,
                ResponseState.Submitted
              )
            )
              .then(() => {
                setSubmitButtonLoad(false);
                getNextQuestionnaire(false);
              })
              .catch((e) => {
                setSubmitButtonLoad(false);
                setStatusCode(e.status);
              });
          } else {
            setSection(newSection);
            setSubmitButtonLoad(false);
            window.scrollTo({ top: 0 });
          }
        };
        submit();
      }
    }
  };

  const saveDraft = () => {
    setSaveDraftButtonLoading(true);
    const submit = async () => {
      if (props.submitDraft !== null && props.submitDraft !== undefined) {
        await resolveWhileMounted(
          props.submitDraft(
            responses,
            responseDate,
            section,
            ResponseState.Draft
          )
        )
          .then(() => {
            setSaveDraftButtonLoading(false);
            if (
              props.remainingTokens !== null &&
              props.remainingTokens !== undefined &&
              props.setOpenSaveDraftModal !== null &&
              props.setOpenSaveDraftModal !== undefined &&
              props.remainingTokens > 1
            ) {
              props.setOpenSaveDraftModal(true);
            } else {
              getNextQuestionnaire(true);
            }
          })
          .catch((e) => {
            setSubmitButtonLoad(false);
            setStatusCode(e.status);
          });
      }
    };
    submit();
  };

  const isDependsValid = (question: QuestionViewModel) => {
    var satisfied;

    // Check that each answer condition is satisfied by the actual response
    for (let k in question.dependsOn) {
      satisfied = false;
      var response = responses.find((x) => x.number === parseInt(k))?.answer;
      var splitResponse = response?.split(",") || [""];

      if (
        response === undefined &&
        getQuestionTypeInternal(questions, parseInt(k)) !== 1
      ) {
        return false;
      }

      satisfied = question.dependsOn[k].includes(
        parseInt(response ? response : "")
      );

      if (
        getQuestionTypeInternal(questions, parseInt(k)) === 13 ||
        getQuestionTypeInternal(questions, parseInt(k)) === 12
      ) {
        const foundQuestion = questions.find(
          (question) => question.number === parseInt(k)
        );
        if (
          foundQuestion &&
          foundQuestion.answers &&
          foundQuestion.answers.length > 0
        ) {
          let splitResponseCopy = splitResponse.slice();
          const dependsOnKeys = Object.keys(question.dependsOn);
          const dependsOnK = question.dependsOn[k];

          if (dependsOnKeys.includes(k)) {
            satisfied = foundQuestion.answers.some(
              (answer) =>
                splitResponseCopy.includes(answer.text || "") &&
                dependsOnK.includes(answer.number || 0)
            );
          }
        }
      }

      if (getQuestionTypeInternal(questions, parseInt(k)) === 14) {
        //if set to 0, ticked/true is not satisfied and unticked/false is
        if (question.dependsOn[k].find((x) => x.valueOf) === 0) {
          if (
            responses.find((x) => x.number === parseInt(k))?.answer === "true"
          ) {
            satisfied = false;
          } else {
            satisfied = true;
          }
          //if set to 1, ticked/true is satisfied and unticked/false is not
        } else if (question.dependsOn[k].find((x) => x.valueOf) === 1) {
          if (
            responses.find((x) => x.number === parseInt(k))?.answer === "false"
          ) {
            satisfied = false;
          } else {
            satisfied = true;
          }
        }
      }

      if (getQuestionTypeInternal(questions, parseInt(k)) === 1) {
        // If dependsOn answer is 0, satisfied is true if response is empty
        if (question.dependsOn[k].find((x) => x.valueOf) === 0) {
          if (responses.find((x) => x.number === parseInt(k))?.answer == null) {
            satisfied = true;
          } else {
            satisfied =
              responses.find((x) => x.number === parseInt(k))?.answer
                ?.length === 0 ||
              responses.find((x) => x.number === parseInt(k))?.answer === "";
          }
          //If dependsOn answer is 1, satisfied is true if response is not empty
        } else if (question.dependsOn[k].find((x) => x.valueOf) === 1) {
          if (responses.find((x) => x.number === parseInt(k))?.answer == null) {
            satisfied = false;
          } else {
            satisfied =
              responses.find((x) => x.number === parseInt(k))?.answer
                ?.length !== 0 ||
              responses.find((x) => x.number === parseInt(k))?.answer !== "";
          }
        }
      }

      if (!satisfied) {
        return false;
      }
    }

    return true;
  };

  const sectionCompleteCheck = () => {
    var validated = validateResponses(false);
    setSectionComplete(validated.valid);
  };

  const validateResponses = (updateValidationText: boolean) => {
    var missingResponses = "";
    var regexFails = "";
    var validationText = "";
    var dependsValid = false;
    var filteredResponses = new Array<QuestionnaireResponseViewModel>();
    var validAndFilteredResponses = {
      valid: true,
      filtered: new Array<QuestionnaireResponseViewModel>(),
    };
    props.questionnaire?.sections![section].questions?.forEach((x) => {
      var questionIndex = responses.findIndex((r) => r.number === x.number);
      dependsValid = isDependsValid(x);
      // Flag the response missing if mandatory and dependsOn conditions are fulfilled
      if (questionIndex < 0 && x.mandatory && dependsValid) {
        missingResponses =
          missingResponses + (x.displayName ?? x.number.toString()) + ", ";
      }
      if (
        questionIndex >= 0 &&
        dependsValid &&
        x.questionType === QuestionType.Textbox &&
        x.answers![0].validationRegex &&
        regexFails.indexOf(x.displayName ?? x.number.toString()) < 0 &&
        responses[questionIndex].answer!.match(
          new RegExp(x.answers![0].validationRegex as string)
        ) == null
      ) {
        regexFails = regexFails + (x.displayName ?? x.number.toString()) + ", ";
      }
      // Clear responses for hidden questions
      if (!dependsValid) {
        filteredResponses = filteredResponses.concat(
          responses.filter((r) => r.number === x.number)
        );
      }
    });

    var missingText =
      "Please respond to the following questions: " +
      missingResponses.slice(0, -2);
    var regexText =
      "The following answers are invalid: " + regexFails.slice(0, -2);
    validationText =
      missingResponses.length > 0 && regexFails.length > 0
        ? missingText + " / " + regexText
        : missingResponses.length > 0
        ? missingText
        : regexFails.length > 0
        ? regexText
        : "";

    if (validationText !== "") {
      updateValidationText && setValidationText(validationText);
      updateValidationText && setSubmitButtonLoad(false);
      validAndFilteredResponses.valid = false;
      return validAndFilteredResponses;
    } else if (responseDate === null || isNaN(responseDate.getTime())) {
      updateValidationText &&
        setValidationText("Please enter a valid response date.");
      updateValidationText && setSubmitButtonLoad(false);
      validAndFilteredResponses.valid = false;
      return validAndFilteredResponses;
    } else if (responseDate > new Date() || responseDate.getFullYear() < 1900) {
      updateValidationText &&
        setValidationText(
          "Response date cannot be in the future or before 1900."
        );
      updateValidationText && setSubmitButtonLoad(false);
      validAndFilteredResponses.valid = false;
      return validAndFilteredResponses;
    }

    updateValidationText && setValidationText("");
    validAndFilteredResponses.filtered = responses.filter(
      (r) => !filteredResponses.includes(r)
    );
    return validAndFilteredResponses;
  };

  function createLink() {
    const linkEnding = props.fromNotification ? "&fn=1" : "&e=1";
    return (
      Configuration.SITEBASE +
      "/questionnaire?p=" +
      props.patientId +
      "&t=" +
      (props.combinedNotificationTokens && props.fromNotification
        ? props.combinedNotificationTokens
        : props.responseToken) +
      "&tId=" +
      props.tenantId +
      linkEnding
    );
  }

  return (
    <>
      {onCoverPage && (
        <>
          {parse(props.questionnaire?.coverPage ?? "")}{" "}
          {props.questionnaire?.consentCheck && (
            <>
              <div>
                <input
                  type="checkbox"
                  id="consentCheckbox"
                  style={{ margin: "10px", marginBottom: "30px" }}
                  onChange={(e) => {
                    setConsentCheck(e.target.checked);
                  }}
                />
                <label htmlFor="consentCheckbox">
                  I consent to the collection and processing of my sensitive
                  information for the purpose of this questionnaire.
                </label>
              </div>
            </>
          )}
          <Button
            onClick={() => setOnCoverPage(false)}
            variant="contained"
            disabled={!consentCheck && props.questionnaire?.consentCheck}
          >
            Start Questionnaire
          </Button>
          <hr />
          <Button
            variant="contained"
            onClick={() => {
              navigateSection(-1);
            }}
          >
            Return to patient
          </Button>
        </>
      )}
      {!onCoverPage && (
        <Container
          sx={{
            backgroundImage: getBackgroundImage(),
            backgroundSize: "cover",
            backgroundRepeat: "repeat-y",
            backgroundPosition: "center",
          }}
        >
          <FormSuccessText
            message={submissionText}
            Show={submissionText.length !== 0}
          ></FormSuccessText>
          <SaveDraftModal
            open={props.openSaveDraftModal}
            closeModal={() => {
              navigateSection(-1, true);
            }}
            savedDraftLink={createLink()}
            fromNotification={props.fromNotification}
            remainingTokens={props.remainingTokens}
            nextQuestionnaire={() => {
              getNextQuestionnaire(true);
            }}
          ></SaveDraftModal>
          <EditDraftPrompt
            open={editDraftModalOpen}
            handleDiscard={() => {
              if (props.handleDiscard != null) {
                props.handleDiscard();
              }
            }}
            handleContinue={() => {
              setEditDraftModalOpen(false);
              props.questionnaireResponseData?.loadSection &&
                setSection(props.questionnaireResponseData?.loadSection);
              sectionCompleteCheck();
            }}
            error={props.editDraftError}
          ></EditDraftPrompt>
          <DeleteGoalsPrompt
            open={
              props.hasUnsubmittedGoals &&
              props.goalDeletionWarningModal &&
              !props.goalDeletionWarningModalOverride &&
              !props.openSaveDraftModal &&
              props.responseState === ResponseState.New
            }
            handleCancel={() => {
              props.toggleGoalDeletionWarningModal(false, true);
            }}
            handleContinue={() => {
              props.toggleGoalDeletionWarningModal(false, true);
              navigateSection(-1, true);
            }}
          ></DeleteGoalsPrompt>
          <AutofillPrompt
            open={autofillModalOpen}
            autofillQuestionnaireDetails={props.autofillQuestionnaireDetails}
            handleAutofill={() => {
              if (props.handleAutofill != null) {
                props.handleAutofill();
              }
              setAutofillModalOpen(false);
            }}
            handleContinue={() => {
              setAutofillModalOpen(false);
            }}
            key={"autofill-prompt"}
          ></AutofillPrompt>
          <QuestionnaireHeader
            questionnaire={props.questionnaire}
            editingDate={props.editingDate}
            setEditingDate={props.setEditingDate}
            responseDate={responseDate}
            setResponseDate={setResponseDate}
            role={props.role}
            saveDraft={saveDraft}
            saveDraftButtonLoading={saveDraftButtonLoading}
            goal={props.goal}
            fromNotification={props.fromNotification}
            responseState={props.questionnaireResponseData?.state}
          />
          <Divider key="header-divider" />
          <FormSuccessText
            message="This section is completed."
            Show={
              sectionComplete &&
              (props.questionnaire?.displaySectionComplete ?? false)
            }
          ></FormSuccessText>
          <QuestionnaireSection
            section={(props.questionnaire?.sections || [])[section]}
            responses={responses}
            setResponses={setResponses}
            copyright={props.questionnaire?.copyright}
            setRerender={() => setRerender((x) => !x)}
            patientId={
              props.questionnaire?.authenticatedOnly
                ? props.patientId ?? undefined
                : undefined
            }
            questionnaireId={
              props.questionnaire?.authenticatedOnly
                ? props.questionnaire.id
                : undefined
            }
            role={
              props.questionnaire?.authenticatedOnly ? props.role : undefined
            }
            questions={questions}
            caseloadId={props.caseloadId}
            sectionCompleteCheck={sectionCompleteCheck}
            questionnaireResponseId={props.responseId}
            setHasUnsubmittedGoals={(x) => props.setHasUnsubmittedGoals(x)}
          />
          <Divider key="footer-divider" />
          <FormErrorText
            errorText={validationText}
            isInvalid={validationText.length > 0}
          />
          {statusCode !== undefined &&
            (statusCode === 400 ? <Error400 /> : <Error500 />)}
          <QuestionnaireFooter
            key={"Questionnaire-footer"}
            totalSections={props.questionnaire?.sections?.length || 0}
            section={section}
            navigateSection={(section, override) =>
              navigateSection(section, override)
            }
            submitButtonLoad={submitButtonLoad}
            isNewResponse={props.responseState === ResponseState.New}
          />
          <SubmissionDialog
            open={props.openSubmitDialog}
            patientId={props.patientId}
            editing={props.editing}
            goal={props.goal}
            fromNotification={props.fromNotification}
          />
        </Container>
      )}
    </>
  );
};

export default QuestionnaireForm;
