import { Container, Pagination, Theme } from "@mui/material";
import { theme as th } from "../../../Theme";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { makeStyles } from "@mui/styles";
import React from "react";

interface Props {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const UserPagination = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles((t: Theme) => ({
    pagination: {
      marginLeft: "0 auto",
      marginTop: "30px",
    },
    paginationContainer: {
      width: "100%",
      justifyContent: isMobile ? "center" : "left",
      display: "flex",
    },
  }));
  const classes = useStyle(th);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    props.onPageChange(value);
  };

  return (
    <Container className={classes.paginationContainer}>
      <Pagination
        count={props.totalPages}
        size="large"
        color="secondary"
        sx={{
          ul: {
            "& .Mui-selected:hover": {
              backgroundColor: "#0aaef5",
            },
          },
        }}
        page={Math.max(Math.min(props.page, props.totalPages), 1)}
        onChange={handleChange}
        className={classes.pagination}
      />
    </Container>
  );
};

export default UserPagination;
