import { CircularProgress, Container, Typography, Button } from "@mui/material";
import { useUnmountPromise } from "react-use";
import { makeStyles } from "@mui/styles";
import { Add } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import {
  QuestionnaireClient,
  QuestionnaireViewModel,
  TenantClient,
  TenantOptionsViewModel,
  TenantViewModel,
} from "../../types/auto/types";
import { UserContext } from "../components/elements/stores/UserStore";
import { Configuration, HelpText } from "../Constants";
import { FetchOverride } from "../utils/Request";
import QuestionnaireCard from "../components/questionnaires/QuestionnaireCard";
import PaginatedView from "../components/search/PaginatedView";
import { useNavigate } from "react-router-dom";
import QuestionnaireBuilder from "../components/questionnaires/builder/QuestionnaireBuilder";

const questionnaireClient = new QuestionnaireClient(
  Configuration.SERVER_ROOT,
  FetchOverride
);

const tenantClient = new TenantClient(Configuration.SERVER_ROOT, FetchOverride);

const QuestionnairesSearchPage = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [questionnaires, setQuestionnaires] = useState<
    QuestionnaireViewModel[]
  >([]);
  const [refreshQuestionnaire, setRefreshQuestionnaire] = useState(false);
  const [termFilter, setTermFilter] = useState<string>("");
  const [dashboardSupported, setDashboardSupported] = useState<boolean>(false);
  const [builder, setBuilder] = useState<boolean>(false);
  const [builderQuestionnaire, setBuilderQuestionnaire] =
    useState<QuestionnaireViewModel>(new QuestionnaireViewModel());
  const [tenancyOptions, setTenancyOptions] =
    useState<TenantOptionsViewModel>();

  const useStyle = makeStyles({
    titles: {
      marginTop: "10px",
    },
    mainContainer: {
      marginTop: "10px",
    },
    createEditButton: {
      marginTop: "15px",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
      marginTop: 10,
    },
  });
  const classes = useStyle();

  const resolveWhileMounted = useUnmountPromise();

  useEffect(() => {
    const effect = async () => {
      await resolveWhileMounted(tenantClient.getDashboardEnabled()).then((x) =>
        setDashboardSupported(x)
      );
      await resolveWhileMounted(
        tenantClient.getEditQuestionnairesEnabled()
      ).then((x) => {
        if (!x) {
          navigate("/");
        }
      });
      await resolveWhileMounted(tenantClient.getTenant(undefined)).then(
        (tenant: TenantViewModel) => setTenancyOptions(tenant.tenantOptions)
      );
    };
    effect();
  }, [resolveWhileMounted, user, navigate]);

  useEffect(() => {
    const effect = async () => {
      if (user.id === undefined) {
        return;
      }
      setLoading(true);
      await resolveWhileMounted(
        questionnaireClient.getAllQuestionnaires(false)
      ).then((questionnaires) => {
        setLoading(false);
        setQuestionnaires(questionnaires);
      });
    };
    effect();
  }, [user, refreshQuestionnaire, resolveWhileMounted]);

  async function handleEdit(qId: number, v: number) {
    await resolveWhileMounted(
      questionnaireClient.getQuestionnaireViaVersion(qId, v)
    ).then((questionnaire) => {
      setLoading(false);
      setBuilderQuestionnaire(questionnaire);
      setBuilder(true);
    });
  }

  async function handleNewVersion(qId: number) {
    await resolveWhileMounted(
      questionnaireClient.getLatestQuestionnaireVersion(qId, false)
    ).then((questionnaire) => {
      questionnaire.version! += 1;
      setLoading(false);
      setBuilderQuestionnaire(questionnaire);
      setBuilder(true);
    });
  }

  return (
    <>
      {builder ? (
        <QuestionnaireBuilder
          questionnaire={builderQuestionnaire}
          allQuestionnaires={questionnaires}
          cancelBuilder={() => {
            setBuilder(false);
            setRefreshQuestionnaire((x) => !x);
          }}
          enableDashboardOptions={dashboardSupported}
        ></QuestionnaireBuilder>
      ) : (
        <Container className={classes.mainContainer} disableGutters>
          <Typography variant="h5" component="h2" className={classes.titles}>
            Questionnaires
          </Typography>

          <PaginatedView
            controlElements={
              <Container className={classes.createEditButton} disableGutters>
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => {
                    setBuilderQuestionnaire(new QuestionnaireViewModel());
                    setBuilder(true);
                  }}
                >
                  Create questionnaire
                </Button>
                {questionnaires.length === 0 && !loading && (
                  <Typography>
                    There are no questionnaires. Click the above button to add a
                    new add a new questionnaires.
                  </Typography>
                )}
              </Container>
            }
            elements={questionnaires
              .filter(
                (x) =>
                  x.name
                    ?.toLowerCase()
                    ?.includes(termFilter?.toLowerCase() ?? "") ||
                  termFilter?.trimEnd() === ""
              )
              .map((questionnaire) => (
                <QuestionnaireCard
                  questionnaire={questionnaire}
                  key={questionnaire.id}
                  setRefresh={() => setRefreshQuestionnaire((x) => !x)}
                  editQuestionnaire={(qId, v) => {
                    handleEdit(qId, v);
                  }}
                  newVersion={(qId) => {
                    handleNewVersion(qId);
                  }}
                  tenancyOptions={
                    tenancyOptions ?? new TenantOptionsViewModel()
                  }
                />
              ))}
            filterCallback={(term) => {
              setTermFilter(term);
            }}
            searchTerm={termFilter}
            helpText={HelpText.QUESTIONNAIRE_TABLE.SEARCH_QUESTIONNAIRE}
          />

          <Container>
            {loading && (
              <CircularProgress
                className={classes.loadingSpinner}
                aria-label="Loading"
              />
            )}
          </Container>
        </Container>
      )}
    </>
  );
};

export default QuestionnairesSearchPage;
