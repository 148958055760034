import { Race } from "react-use/lib/useUnmountPromise";
import {
  IdentityClient,
  PatientViewModel,
  QuestionnaireClient,
  QuestionnaireSetViewModel,
  ScheduleViewModel,
  UserViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";

export const handleContinue = async (
  doLogout: boolean,
  scheduledQuestionnaire: ScheduleViewModel,
  patient: PatientViewModel,
  setUser: (userViewModel: UserViewModel) => void,
  setValidRequest: (validRequest: boolean) => void,
  resolveWhileMounted: Race
) => {
  var identityClient = new IdentityClient(
    Configuration.SERVER_ROOT,
    FetchOverride
  );
  if (scheduledQuestionnaire.promptScheduleId !== null) {
    if (
      scheduledQuestionnaire.promptSchedule
        ?.questionnaireResponseAccessToken !== undefined &&
      scheduledQuestionnaire.promptSchedule
        ?.questionnaireResponseAccessToken !== null
    ) {
      if (doLogout) {
        setUser(new UserViewModel());
      }
      window.location.href = doLogout
        ? identityClient.getLogoutToRedirectLinkURL(
            "/questionnaire?t=" +
              scheduledQuestionnaire.promptSchedule
                ?.questionnaireResponseAccessToken +
              "&p=" +
              patient.id +
              "&tId=" +
              scheduledQuestionnaire.tenantId
          )
        : Configuration.SITEBASE +
          "/questionnaire?t=" +
          scheduledQuestionnaire.promptSchedule
            ?.questionnaireResponseAccessToken +
          "&p=" +
          patient.id +
          "&tId=" +
          scheduledQuestionnaire.tenantId;
    } else {
      var questionSetModelPrompt = new QuestionnaireSetViewModel({
        patientId: patient.id,
        caseloadId: scheduledQuestionnaire.caseloadId,
        questionnaires: [scheduledQuestionnaire.questionnaireId],
        scheduleId: scheduledQuestionnaire.id,
      });
      await resolveWhileMounted(
        new QuestionnaireClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).prepareQuestionnaireResponses(questionSetModelPrompt)
      ).then((response: string[]) => {
        if (response.length > 0) {
          var joinedTokens = response.join(",");
          if (doLogout) {
            setUser(new UserViewModel());
          }
          window.location.href = doLogout
            ? identityClient.getLogoutToRedirectLinkURL(
                "/questionnaire?t=" +
                  joinedTokens +
                  "&p=" +
                  patient.id +
                  "&tId=" +
                  scheduledQuestionnaire.tenantId
              )
            : Configuration.SITEBASE +
              "/questionnaire?t=" +
              joinedTokens +
              "&p=" +
              patient.id +
              "&tId=" +
              scheduledQuestionnaire.tenantId;
        } else {
          setValidRequest(false);
        }
      });
    }
  } else {
    var questionSetModel = new QuestionnaireSetViewModel({
      patientId: patient.id,
      caseloadId: scheduledQuestionnaire.caseloadId,
      questionnaires: [scheduledQuestionnaire.questionnaireId],
      scheduleId: scheduledQuestionnaire.id,
    });
    await resolveWhileMounted(
      new QuestionnaireClient(
        Configuration.SERVER_ROOT,
        FetchOverride
      ).prepareQuestionnaireResponses(questionSetModel)
    ).then((response: string[]) => {
      var joinedTokens = response.join(",");
      if (doLogout) {
        setUser(new UserViewModel());
      }
      window.location.href = doLogout
        ? identityClient.getLogoutToRedirectLinkURL(
            "/questionnaire?t=" +
              joinedTokens +
              "&p=" +
              patient.id +
              "&tId=" +
              scheduledQuestionnaire.tenantId
          )
        : Configuration.SITEBASE +
          "/questionnaire?t=" +
          joinedTokens +
          "&p=" +
          patient.id +
          "&tId=" +
          scheduledQuestionnaire.tenantId;
    });
  }
};
