import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { theme } from "../../../../../Theme";
import DayPicker from "../../../elements/form-elements/DayPicker";
import { Day } from "../../../../../types/auto/types";
import { CheckIsMobile } from "../../../../utils/MobileStatus";

interface Props {
  timeOfDay: string | undefined;
  setTimeOfDay: (timeOfDay: string) => void;
  daysOfWeek: Day[];
  setDaysOfWeek: (daysOfWeek: Day[]) => void;
  frequencyAmount: number;
  setFrequencyAmount: (frequencyAmount: number) => void;
}

const WeeklyPromptScheduling = (props: Props) => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    container: {
      display: isMobile ? "grid" : "flex",
      width: "100%",
    },
    textContainer: {
      marginRight: 10,
      marginTop: 15,
    },
    dayPicker: {
      minWidth: "100px",
      marginTop: 10,
    },
    timeTextComponent: {
      width: "9em",
    },
  });
  const classes = useStyle(theme);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={classes.container}>
        <Typography className={classes.textContainer}>on </Typography>
        <DayPicker
          className={classes.dayPicker}
          daysOfWeek={props.daysOfWeek}
          setDaysOfWeek={props.setDaysOfWeek}
        />
      </div>
    </LocalizationProvider>
  );
};

export default WeeklyPromptScheduling;
