import React from "react";
import {
  CardActions,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Email, PhoneAndroid } from "@mui/icons-material";
import { PreferredContact } from "../../../../types/auto/types";
import { theme } from "../../../../Theme";
import { makeStyles } from "@mui/styles";

interface Props {
  messagingPreferences: PreferredContact[];
  onChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => void;
  fromPatientForm: boolean;
  hasMobile: boolean;
}

const ContactPreferences = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    updateContactPref: {
      justifyContent: props.fromPatientForm ? undefined : "center",
      display: "flex",
      alignItems: "center",
      paddingLeft: props.fromPatientForm ? 0 : undefined,
    },
    contactPreference: {
      "&.Mui-selected": {
        color: "white",
        background: th.palette.secondary.main,
        "&:hover": {
          backgroundColor: "deepskyblue",
        },
      },
    },
  }));
  const classes = useStyle(theme);

  return (
    <CardActions className={classes.updateContactPref}>
      <ToggleButtonGroup
        value={props.messagingPreferences}
        onChange={props.onChange}
        aria-label="messaging-preferences"
      >
        <ToggleButton
          value={PreferredContact.Email}
          aria-label="email"
          className={classes.contactPreference}
        >
          <Email />
        </ToggleButton>
        <ToggleButton
          value={PreferredContact.Mobile}
          aria-label="mobile"
          className={classes.contactPreference}
          disabled={!props.hasMobile}
        >
          <PhoneAndroid />
        </ToggleButton>
      </ToggleButtonGroup>
    </CardActions>
  );
};

export default ContactPreferences;
