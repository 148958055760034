import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import {
  QuestionDataModel,
  SectionDataModel,
  AnswerDataModel,
  QuestionnaireBuilderDataModel,
  DataModelType,
  ChangeType,
} from "./QuestionnaireBuilderDataModelHelper";
import SectionBuilderQuestionTypeModal from "./SectionBuilderQuestionTypeModal";
import SectionBuilderQuestionAnswerModal from "./SectionBuilderQuestionAnswerModal";
import SectionQuestions from "./SectionQuestions";
import { QuestionnaireViewModel } from "../../../../types/auto/types";
import {
  RequiredAnswerFields,
  RequiredQuestionFields,
  SectionObjectType,
  getRequiredAnswerFields,
  getRequiredQuestionFields,
} from "./SectionBuilderQuestionAnswerHelper";
import { GoalCategory, QuestionType } from "../../../../types/auto/types";
import { Delete } from "@mui/icons-material";
import DeleteWarningModal from "./DeleteWarningModal";
import nameof from "../../../utils/NameOf";
import DependsOnBuilder, { DependsOn } from "./DependsOnBuilder";
import DependsOnEditModal from "./DependsOnEditModal";
import AutofillFromEditModal from "./AutofillFromEditModal";

interface Props {
  section: SectionDataModel;
  sectionNumber: number;
  allQuestionnaires: QuestionnaireViewModel[];
  setSectionWIP: (
    sectionNumber: number,
    field: string,
    value: string | QuestionDataModel[] | DependsOn | null
  ) => void;
  questionNumber: number;
  setQuestionNumberChange: (qNum: number, type: ChangeType) => void;
  setQuestionNumberRecalc: () => void;
  handleDeleteSection: (s: SectionDataModel, sn: number) => void;
  questionnaire: QuestionnaireBuilderDataModel;
}

const SectionBuilder = (props: Props): JSX.Element => {
  const [openQuestionTypeModal, setOpenQuestionTypeModal] =
    useState<boolean>(false);

  const [questionWIP, setQuestionWIP] = useState<QuestionDataModel>({
    answers: [],
    labels: undefined,
    headers: [],
    number: 0,
    questionType: QuestionType.Textbox,
    goalCategory: GoalCategory.General,
  });
  const [answerWIP, setAnswerWIP] = useState<AnswerDataModel>({});
  const [requiredQuestionFields, setRequiredQuestionFields] = useState<
    RequiredQuestionFields[]
  >([RequiredQuestionFields.Base]);
  const [requiredAnswerFields, setRequiredAnswerFields] = useState<
    RequiredAnswerFields[]
  >([RequiredAnswerFields.Base]);
  const [openQuestionModal, setOpenQuestionModal] = useState<boolean>(false);
  const [openAnswerModal, setOpenAnswerModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(false);
  const [openDeleteWarning, setOpenDeleteWarning] = useState<boolean>(false);
  const [deleteObjectType, setDeleteObjectType] = useState<SectionObjectType>(
    SectionObjectType.Undefined
  );
  const [deleteQuestionNumber, setDeleteQuestionNumber] = useState<number>(0);
  const [openDependsOnModal, setOpenDependsOnModal] = useState<boolean>(false);
  const [openAutofillFromModal, setOpenAutofillFromModal] =
    useState<boolean>(false);

  const isMobile = CheckIsMobile();

  const useStyle = makeStyles({
    titles: {
      marginTop: "10px",
    },
    preview: {
      marginTop: "8px",
      marginLeft: "10px",
    },
    delete: {
      marginTop: "6px",
    },
    mainContainer: {
      marginTop: "10px",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
      marginTop: 10,
    },
    textarea: {
      backgroundColor: "#fafafa",
    },
  });
  const classes = useStyle();

  function addQuestion(qType: QuestionType) {
    setOpenQuestionTypeModal(false);

    var requiredQuestionFields: RequiredQuestionFields[] =
      getRequiredQuestionFields(qType);

    var requiredAnswerFields: RequiredAnswerFields[] =
      getRequiredAnswerFields(qType);

    const sketchAnswer: AnswerDataModel = {
      number: 1,
      text: "sketch",
      score: 0,
    };

    const dateAnswer: AnswerDataModel = {
      number: 1,
      text: "date",
      score: 0,
    };

    var qM: QuestionDataModel = {
      text: undefined,
      shortText: undefined,
      number: props.questionNumber,
      displayName: undefined,
      hideNumber: false,
      answers: requiredQuestionFields.includes(RequiredQuestionFields.Rows)
        ? [{ number: 1, text: "", score: 0 }]
        : qType === QuestionType.Sketch
        ? [sketchAnswer]
        : qType === QuestionType.Date
        ? [dateAnswer]
        : [],
      questionType: qType,
      subheading: undefined,
      note: undefined,
      divider: false,
      mandatory: false,
      goalId: undefined,
      dependsOn: undefined,
      labels: qType === QuestionType.Range ? ["", "", ""] : undefined,
      snomed: undefined,
      maxAnswers: undefined,
      rows: requiredQuestionFields.includes(RequiredQuestionFields.Rows)
        ? []
        : undefined,
      rowNumber: undefined,
      headers: [],
      rowObjectName: undefined,
      format: undefined,
      disableResponses: undefined,
      autofillFrom: undefined,
      goalCategory: GoalCategory.General,
    };
    setQuestionWIP(qM);
    setRequiredQuestionFields(requiredQuestionFields);
    setRequiredAnswerFields(requiredAnswerFields);
    setOpenQuestionModal(true);
  }

  const sectionHeadingField = "section-heading-field";

  // handle changes to the section heading and dependsOn
  function handleChange(e: any) {
    if (e.target.id === sectionHeadingField) {
      props.setSectionWIP(
        props.sectionNumber,
        nameof<SectionDataModel>("heading"),
        e.target.value
      );
    }
  }

  // handle question form submission
  function handleSubmit(newQ: QuestionDataModel) {
    const newQuestions = [...props.section.questions, newQ];
    props.setSectionWIP(
      props.sectionNumber,
      nameof<SectionDataModel>("questions"),
      newQuestions
    );
    props.setQuestionNumberChange(props.questionNumber, ChangeType.Add);
    props.setQuestionNumberRecalc();
    setOpenQuestionModal(false);
  }

  function handleEdit(updatedQ: QuestionDataModel) {
    var updatedQuestions = props.section.questions.map((question) => {
      if (question.number === updatedQ.number) {
        return updatedQ;
      } else {
        return question;
      }
    });
    props.setSectionWIP(
      props.sectionNumber,
      nameof<SectionDataModel>("questions"),
      updatedQuestions
    );
    setOpenQuestionModal(false);
    setIsEdit(false);
    setQuestionWIP(updatedQ);
  }

  function handleToggleMulti(q: QuestionDataModel, qType: QuestionType) {
    const toggleChange = {
      ...q,
      [nameof<QuestionDataModel>("questionType")]:
        qType === QuestionType.Buttons
          ? QuestionType.ButtonsMulti
          : QuestionType.Buttons,
    };
    var updatedQuestions = props.section.questions.map((question) => {
      if (question.number === toggleChange.number) {
        return toggleChange;
      } else {
        return question;
      }
    });
    props.setSectionWIP(
      props.sectionNumber,
      nameof<SectionDataModel>("questions"),
      updatedQuestions
    );
  }

  function handleToggleDay(q: QuestionDataModel, qType: QuestionType) {
    const toggleChange = {
      ...q,
      [nameof<QuestionDataModel>("questionType")]:
        qType === QuestionType.Date ? QuestionType.Day : QuestionType.Date,
    };
    var updatedQuestions = props.section.questions.map((question) => {
      if (question.number === toggleChange.number) {
        return toggleChange;
      } else {
        return question;
      }
    });
    props.setSectionWIP(
      props.sectionNumber,
      nameof<SectionDataModel>("questions"),
      updatedQuestions
    );
  }

  function handleUpdateAnswer(newQ: QuestionDataModel) {
    var updatedQuestions: QuestionDataModel[] = props.section.questions.map(
      (question) => {
        if (question.number === newQ.number) {
          return newQ;
        } else {
          return question;
        }
      }
    );
    props.setSectionWIP(
      props.sectionNumber,
      nameof<SectionDataModel>("questions"),
      updatedQuestions
    );
    setOpenAnswerModal(false);
  }

  function handleDelete(objType: SectionObjectType) {
    switch (objType) {
      case SectionObjectType.Question: {
        var updatedQuestions: QuestionDataModel[] =
          props.section.questions.filter(
            (x) => x.number !== deleteQuestionNumber
          );
        props.setSectionWIP(
          props.sectionNumber,
          nameof<SectionDataModel>("questions"),
          updatedQuestions
        );
        props.setQuestionNumberChange(deleteQuestionNumber, ChangeType.Delete);
        props.setQuestionNumberRecalc();
        setOpenDeleteWarning(false);
      }
    }
  }

  function handleDuplicateQuestion(q: QuestionDataModel) {
    var insertAt = props.section.questions.indexOf(q);
    var qNum = props.section.questions[insertAt].number ?? 0;
    const deepCopy = structuredClone(q);
    var updatedQuestions: QuestionDataModel[] = [
      ...props.section.questions.slice(0, insertAt),
      deepCopy,
      ...props.section.questions.slice(insertAt),
    ];
    props.setSectionWIP(
      props.sectionNumber,
      nameof<SectionDataModel>("questions"),
      updatedQuestions
    );
    props.setQuestionNumberChange(qNum + 1, ChangeType.Add);
    props.setQuestionNumberRecalc();
  }

  function handleToggleSkippable(q: QuestionDataModel, qType: QuestionType) {
    const toggleChange = {
      ...q,
      [nameof<QuestionDataModel>("questionType")]:
        qType === QuestionType.Range
          ? QuestionType.SkippableRange
          : QuestionType.Range,
    };
    var updatedQuestions = props.section.questions.map((question) => {
      if (question.number === toggleChange.number) {
        return toggleChange;
      } else {
        return question;
      }
    });
    props.setSectionWIP(
      props.sectionNumber,
      nameof<SectionDataModel>("questions"),
      updatedQuestions
    );
  }

  return (
    <Box
      display={"flex"}
      gap={2}
      width={"100%"}
      flexWrap={"wrap"}
      paddingBottom={1}
      key={"section-main-container-" + props.sectionNumber}
    >
      <Typography variant="h5" component="h2" className={classes.titles}>
        {props.section.heading === "" || props.section.heading == null
          ? "Section " + (props.sectionNumber + 1)
          : props.section.heading}
      </Typography>

      <FormGroup
        className={classes.preview}
        key={"preview-section-" + props.sectionNumber}
      >
        <FormControlLabel
          control={
            <Switch onChange={() => setPreview((x) => !x)} checked={preview} />
          }
          label="Preview"
          key={"preview-section-form-control-" + props.sectionNumber}
        />
      </FormGroup>
      <IconButton
        className={classes.delete}
        onClick={() => {
          setDeleteObjectType(SectionObjectType.Section);
          setOpenDeleteWarning(true);
        }}
        key={"delete-section-" + props.sectionNumber}
      >
        <Delete key={"delete-section-icon-" + props.sectionNumber} />
      </IconButton>

      <Box
        display={isMobile ? "flex" : "inline-grid"}
        gridTemplateColumns={"5fr 2fr 1fr"}
        gridTemplateAreas={'"textbox dependsOn addButton"'}
        width={"100%"}
        gap={2}
        justifyItems={"center"}
        alignItems={"center"}
        flexWrap={"wrap"}
        key={"section-header-information-container-" + props.sectionNumber}
      >
        <Box
          gridArea={"textbox"}
          width={"100%"}
          key={"section-heading-textfield-container-" + props.sectionNumber}
        >
          <TextField
            id={sectionHeadingField}
            key={sectionHeadingField + props.sectionNumber}
            label={"Heading"}
            placeholder="Heading"
            value={
              props.section.heading === "Section " + props.sectionNumber ||
              props.section.heading == null
                ? ""
                : props.section.heading
            }
            onChange={(e) => {
              handleChange(e);
            }}
            className={classes.textarea}
            fullWidth
          ></TextField>
        </Box>
        <Box
          gridArea={"dependsOn"}
          width={"100%"}
          key={"section-dependsOn-dropdown-container-" + props.sectionNumber}
        >
          <DependsOnBuilder
            questionnaire={props.questionnaire}
            section={props.section}
            index={props.sectionNumber}
            type={DataModelType.Section}
            setSectionWIP={(index, field, value) => {
              props.setSectionWIP(index, field, value);
            }}
          />
        </Box>
        <Box
          gridArea={"addButton"}
          width={"100%"}
          key={"section-add-question-container-" + props.sectionNumber}
        >
          <Box
            display={"flex"}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Button
              variant="contained"
              onClick={() => setOpenQuestionTypeModal(true)}
              key={"section-add-question-button-" + props.sectionNumber}
            >
              Add Question
            </Button>
          </Box>
        </Box>
      </Box>

      <SectionQuestions
        questions={props.section.questions}
        allQuestionnaires={props.allQuestionnaires}
        handleAddAnswer={(qNum) => {
          setIsEdit(false);
          var qWIPIndex = props.section.questions.findIndex(
            (x) => x.number === qNum
          );
          var qWIP = { ...props.section.questions[qWIPIndex] };
          var aWIP: AnswerDataModel = {
            number: qWIP.answers.length + 1,
            text: undefined,
            score: 0,
            rangeMin: undefined,
            rangeMax: undefined,
            rangeDefault: undefined,
            scoreCap: undefined,
            rangeDecimal:
              qWIP.questionType === QuestionType.Range ||
              qWIP.questionType === QuestionType.SkippableRange
                ? false
                : undefined,
            tableType: undefined,
            tableDropdownChoices: undefined,
          };
          setAnswerWIP(aWIP);
          setQuestionWIP(qWIP);
          setRequiredAnswerFields(getRequiredAnswerFields(qWIP.questionType!));
          setOpenAnswerModal(true);
        }}
        handleDeleteAnswer={(qNum, aNum) => {
          var qWIPIndex = props.section.questions.findIndex(
            (x) => x.number === qNum
          );
          var qWIP = { ...props.section.questions[qWIPIndex] };
          var aIndex = 1;
          var modifiedAnswers = qWIP.answers.filter((x) => x.number !== aNum);
          modifiedAnswers.forEach((answer) => {
            answer.number = aIndex;
            aIndex++;
          });
          var updatedQuestions = props.section.questions.map((question) => {
            if (question.number === qWIP.number) {
              qWIP.answers = modifiedAnswers;
              return qWIP;
            } else {
              return question;
            }
          });
          props.setSectionWIP(
            props.sectionNumber,
            nameof<SectionDataModel>("questions"),
            updatedQuestions
          );
        }}
        handleEditAnswer={(answer, qNum) => {
          var qWIPIndex = props.section.questions.findIndex(
            (x) => x.number === qNum
          );
          var qWIP = { ...props.section.questions[qWIPIndex] };
          setIsEdit(true);
          setAnswerWIP(answer);
          setQuestionWIP(qWIP);
          setRequiredAnswerFields(getRequiredAnswerFields(qWIP.questionType!));
          setOpenAnswerModal(true);
        }}
        preview={preview}
        handleEditQuestion={(q) => {
          setQuestionWIP(q);
          setRequiredQuestionFields(getRequiredQuestionFields(q.questionType!));
          setRequiredAnswerFields(getRequiredAnswerFields(q.questionType!));
          setIsEdit(true);
          setOpenQuestionModal(true);
        }}
        handleDuplicateQuestion={(q) => {
          handleDuplicateQuestion(q);
        }}
        handleDeleteQuestion={(qNum) => {
          setDeleteObjectType(SectionObjectType.Question);
          setDeleteQuestionNumber(qNum);
          setOpenDeleteWarning(true);
        }}
        handleDependsOn={(q) => {
          setQuestionWIP(q);
          setOpenDependsOnModal(true);
        }}
        handleAutofillFrom={(q) => {
          setQuestionWIP(q);
          setOpenAutofillFromModal(true);
        }}
        handleToggleSkippable={handleToggleSkippable}
        sectionNumber={props.sectionNumber}
        handleToggleMulti={(q, qType) => handleToggleMulti(q, qType)}
        handleToggleDay={(q, qType) => handleToggleDay(q, qType)}
        key={"section-questions-main-component-" + props.sectionNumber}
      />

      {openQuestionTypeModal && (
        <SectionBuilderQuestionTypeModal
          open={openQuestionTypeModal}
          handleCancel={() => setOpenQuestionTypeModal(false)}
          handleAddQuestion={(qT) => addQuestion(qT)}
          key={"question-type-modal"}
        />
      )}
      {openQuestionModal && (
        <SectionBuilderQuestionAnswerModal
          open={openQuestionModal}
          handleCancel={() => setOpenQuestionModal(false)}
          questionWIP={questionWIP}
          requiredQuestionFields={requiredQuestionFields}
          requiredAnswerFields={requiredAnswerFields}
          handleSubmit={(newQ) => handleSubmit(newQ)}
          edit={isEdit}
          isQuestion={true}
          answerWIP={questionWIP.answers[0]}
          handleAnswerUpdate={(newQ) => {}}
          handleEdit={(updatedQ) => handleEdit(updatedQ)}
          key={"question-modal"}
          questionnaire={props.questionnaire}
        />
      )}
      {openAnswerModal && (
        <SectionBuilderQuestionAnswerModal
          open={openAnswerModal}
          handleCancel={() => setOpenAnswerModal(false)}
          questionWIP={questionWIP}
          requiredQuestionFields={requiredQuestionFields}
          requiredAnswerFields={requiredAnswerFields}
          handleSubmit={(newQ) => {}}
          edit={isEdit}
          isQuestion={false}
          answerWIP={answerWIP}
          handleAnswerUpdate={(newQ) => handleUpdateAnswer(newQ)}
          handleEdit={(updatedQ) => {}}
          key={"answer-modal"}
        />
      )}
      {openDeleteWarning && (
        <DeleteWarningModal
          open={openDeleteWarning}
          handleCancel={() => setOpenDeleteWarning(false)}
          handleDelete={(objType) => {
            if (objType === SectionObjectType.Section) {
              props.handleDeleteSection(props.section, props.sectionNumber);
              setOpenDeleteWarning(false);
            } else {
              handleDelete(objType);
            }
          }}
          objectType={deleteObjectType}
          key={"delete-warning-modal"}
        />
      )}
      {openDependsOnModal && (
        <DependsOnEditModal
          open={openDependsOnModal}
          index={questionWIP.number!}
          type={DataModelType.Question}
          questionnaire={props.questionnaire}
          question={questionWIP}
          dependsOn={questionWIP.dependsOn ?? {}}
          handleClose={() => setOpenDependsOnModal(false)}
          handleSubmit={(i, f, dO) => {
            const newQ = {
              ...questionWIP,
              [f]: dO,
            };
            handleEdit(newQ);
          }}
        />
      )}
      {openAutofillFromModal && (
        <AutofillFromEditModal
          open={openAutofillFromModal}
          index={questionWIP.number!}
          questionnaire={props.questionnaire}
          allQuestionnaires={props.allQuestionnaires}
          question={questionWIP}
          autofillFrom={questionWIP.autofillFrom}
          handleClose={() => setOpenAutofillFromModal(false)}
          handleSubmit={(i, f, af) => {
            const newQ = {
              ...questionWIP,
              [f]: af,
            };
            handleEdit(newQ);
          }}
        />
      )}
    </Box>
  );
};

export default SectionBuilder;
