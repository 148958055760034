import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  PatientDashboardOptionsModel,
  PatientDashboardRegionOptionsModel,
  QuestionnaireBuilderDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import nameof from "../../../utils/NameOf";

interface Props {
  open: boolean;
  questionnaire: QuestionnaireBuilderDataModel;
  handleClose: () => void;
  setQuestionnaireWIP: (
    field: string,
    value:
      | string
      | boolean
      | number
      | null
      | PatientDashboardOptionsModel
      | undefined
  ) => void;
}

function QuestionnaireDashboardOptionsModal(props: Props) {
  const [disclaimerText, setDisclaimerText] = useState(
    props.questionnaire.dashboardOptions?.disclaimerText
  );
  const [clearRegionScore, setClearRegionScore] = useState<number | undefined>(
    props.questionnaire.dashboardOptions?.clearRegion?.score
  );
  const [clearRegionScoreText, setClearRegionScoreText] = useState(
    props.questionnaire.dashboardOptions?.clearRegion?.scoreText
  );
  const [clearRegionInfoText, setClearRegionInfoText] = useState(
    props.questionnaire.dashboardOptions?.clearRegion?.infoText
  );
  const [stableRegionScore, setStableRegionScore] = useState<
    number | undefined
  >(props.questionnaire.dashboardOptions?.stableRegion?.score);
  const [stableRegionScoreText, setStableRegionScoreText] = useState(
    props.questionnaire.dashboardOptions?.stableRegion?.scoreText
  );
  const [stableRegionInfoText, setStableRegionInfoText] = useState(
    props.questionnaire.dashboardOptions?.stableRegion?.infoText
  );
  const [borderlineRegionScore, setBorderlineRegionScore] = useState<
    number | undefined
  >(props.questionnaire.dashboardOptions?.borderlineRegion?.score);
  const [borderlineRegionScoreText, setBorderlineRegionScoreText] = useState(
    props.questionnaire.dashboardOptions?.borderlineRegion?.scoreText
  );
  const [borderlineRegionInfoText, setBorderlineRegionInfoText] = useState(
    props.questionnaire.dashboardOptions?.borderlineRegion?.infoText
  );
  const [clinicalRegionScore, setClinicalRegionScore] = useState<
    number | undefined
  >(props.questionnaire.dashboardOptions?.clinicalRegion?.score);
  const [clinicalRegionScoreText, setClinicalRegionScoreText] = useState(
    props.questionnaire.dashboardOptions?.clinicalRegion?.scoreText
  );
  const [clinicalRegionInfoText, setClinicalRegionInfoText] = useState(
    props.questionnaire.dashboardOptions?.clinicalRegion?.infoText
  );

  const useStyle = makeStyles({
    textField: {
      margin: "16px",
      width: "100%",
    },
    checkbox: {
      margin: "16px",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
  });
  const classes = useStyle();
  const [errorText, setErrorText] = useState("");

  const saveSettings = () => {
    const newDashboardOptions: PatientDashboardOptionsModel = {
      disclaimerText: disclaimerText,
    };
    var errtxt = "";
    var clearDashboardOptions = disclaimerText === undefined;
    if (
      clearRegionScore === undefined &&
      (clearRegionInfoText !== undefined || clearRegionScoreText !== undefined)
    ) {
      errtxt =
        errtxt +
        "Cannot set Clear Region Info/Score Text without a Clear Region score set, ";
    } else if (clearRegionScore !== undefined) {
      var newClearRegion: PatientDashboardRegionOptionsModel = {
        score: clearRegionScore,
        scoreText: clearRegionScoreText,
        infoText: clearRegionInfoText,
      };
      newDashboardOptions.clearRegion = newClearRegion;
      clearDashboardOptions = false;
    }

    if (
      stableRegionScore === undefined &&
      (stableRegionInfoText !== undefined ||
        stableRegionScoreText !== undefined)
    ) {
      errtxt =
        errtxt +
        "Cannot set Stable Region Info/Score Text without a Stable Region score set, ";
    } else if (stableRegionScore !== undefined) {
      var newStableRegion: PatientDashboardRegionOptionsModel = {
        score: stableRegionScore,
        scoreText: stableRegionScoreText,
        infoText: stableRegionInfoText,
      };
      newDashboardOptions.stableRegion = newStableRegion;
      clearDashboardOptions = false;
    }

    if (
      borderlineRegionScore === undefined &&
      (borderlineRegionInfoText !== undefined ||
        borderlineRegionScoreText !== undefined)
    ) {
      errtxt =
        errtxt +
        "Cannot set Borderline Region Info/Score Text without a Borderline Region score set, ";
    } else if (borderlineRegionScore !== undefined) {
      var newBorderlineRegion: PatientDashboardRegionOptionsModel = {
        score: borderlineRegionScore,
        scoreText: borderlineRegionScoreText,
        infoText: borderlineRegionInfoText,
      };
      newDashboardOptions.borderlineRegion = newBorderlineRegion;
      clearDashboardOptions = false;
    }

    if (
      clinicalRegionScore === undefined &&
      (clinicalRegionInfoText !== undefined ||
        clinicalRegionScoreText !== undefined)
    ) {
      errtxt =
        errtxt +
        "Cannot set Clinical Region Info/Score Text without a Clinical Region score set, ";
    } else if (clinicalRegionScore !== undefined) {
      var newClinicalRegion: PatientDashboardRegionOptionsModel = {
        score: clinicalRegionScore,
        scoreText: clinicalRegionScoreText,
        infoText: clinicalRegionInfoText,
      };
      newDashboardOptions.clinicalRegion = newClinicalRegion;
      clearDashboardOptions = false;
    }
    if (errtxt.length > 0) {
      setErrorText(errtxt);
    } else {
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("dashboardOptions"),
        clearDashboardOptions ? undefined : newDashboardOptions
      );
      props.handleClose();
    }
  };

  return (
    <Dialog
      open={props.open}
      aria-labelledby="dashboard-options-settings-title"
      key={"dashboard-options-dialog"}
    >
      <DialogTitle
        id="dashboard-options-settings-title"
        key={"dashboard-options-dialog-title"}
      >
        {"Dashboard Options"}
      </DialogTitle>
      <DialogContent key={"dashboard-options-dialog-content"}>
        <Box className={classes.container} key={"dashboard-options-container"}>
          <TextField
            label="Disclaimer Text"
            value={disclaimerText ?? ""}
            onChange={(event) =>
              setDisclaimerText(
                event.target.value === "" ? undefined : event.target.value
              )
            }
            multiline
            rows={2}
            className={classes.textField}
            key={"dashboard-options-disclaimer-text"}
          />
          <Accordion
            className={classes.textField}
            key={"dashboard-options-clear-accordian"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
              key={"dashboard-options-clear-accordian-summary"}
            >
              <Typography key={"dashboard-options-clear-title"}>
                Clear Region
              </Typography>
            </AccordionSummary>
            <AccordionDetails key={"dashboard-options-clear-accordian-details"}>
              <TextField
                label="Clear Region Score"
                value={clearRegionScore ?? ""}
                onChange={(event) =>
                  setClearRegionScore(
                    event.target.value === ""
                      ? undefined
                      : parseInt(event.target.value)
                  )
                }
                type="number"
                className={classes.textField}
                key={"dashboard-options-clear-score"}
              />
              <TextField
                label="Clear Region Score Text"
                value={clearRegionScoreText ?? ""}
                onChange={(event) =>
                  setClearRegionScoreText(
                    event.target.value === "" ? undefined : event.target.value
                  )
                }
                multiline
                rows={2}
                className={classes.textField}
                key={"dashboard-options-clear-text"}
              />
              <TextField
                label="Clear Region Info Text"
                value={clearRegionInfoText ?? ""}
                onChange={(event) =>
                  setClearRegionInfoText(
                    event.target.value === "" ? undefined : event.target.value
                  )
                }
                multiline
                rows={2}
                className={classes.textField}
                key={"dashboard-options-clear-info"}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.textField}
            key={"dashboard-options-stable-accordian"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
              key={"dashboard-options-stable-accordian-summary"}
            >
              <Typography key={"dashboard-options-stable-title"}>
                Stable Region
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              key={"dashboard-options-stable-accordian-details"}
            >
              <TextField
                label="Stable Region Score"
                value={stableRegionScore ?? ""}
                onChange={(event) =>
                  setStableRegionScore(
                    event.target.value === ""
                      ? undefined
                      : parseInt(event.target.value)
                  )
                }
                type="number"
                className={classes.textField}
                key={"dashboard-options-stable-score"}
              />
              <TextField
                label="Stable Region Score Text"
                value={stableRegionScoreText ?? ""}
                onChange={(event) =>
                  setStableRegionScoreText(
                    event.target.value === "" ? undefined : event.target.value
                  )
                }
                multiline
                rows={2}
                className={classes.textField}
                key={"dashboard-options-stable-text"}
              />
              <TextField
                label="Stable Region Info Text"
                value={stableRegionInfoText ?? ""}
                onChange={(event) =>
                  setStableRegionInfoText(
                    event.target.value === "" ? undefined : event.target.value
                  )
                }
                multiline
                rows={2}
                className={classes.textField}
                key={"dashboard-options-stable-info"}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.textField}
            key={"dashboard-options-borderline-accordian"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
              key={"dashboard-options-borderline-accordian-summary"}
            >
              <Typography key={"dashboard-options-borderline-title"}>
                Borderline Region
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              key={"dashboard-options-borderline-accordian-details"}
            >
              <TextField
                label="Borderline Region Score"
                value={borderlineRegionScore ?? ""}
                onChange={(event) =>
                  setBorderlineRegionScore(
                    event.target.value === ""
                      ? undefined
                      : parseInt(event.target.value)
                  )
                }
                type="number"
                className={classes.textField}
                key={"dashboard-options-borderline-score"}
              />
              <TextField
                label="Borderline Region Score Text"
                value={borderlineRegionScoreText ?? ""}
                onChange={(event) =>
                  setBorderlineRegionScoreText(
                    event.target.value === "" ? undefined : event.target.value
                  )
                }
                multiline
                rows={2}
                className={classes.textField}
                key={"dashboard-options-borderline-text"}
              />
              <TextField
                label="Borderline Region Info Text"
                value={borderlineRegionInfoText ?? ""}
                onChange={(event) =>
                  setBorderlineRegionInfoText(
                    event.target.value === "" ? undefined : event.target.value
                  )
                }
                multiline
                rows={2}
                className={classes.textField}
                key={"dashboard-options-borderline-info"}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.textField}
            key={"clinical-region-accordian"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
              key={"clinical-region-accordian-summary"}
            >
              <Typography key={"dashboard-options-clinical-title"}>
                Clinical Region
              </Typography>
            </AccordionSummary>
            <AccordionDetails key={"clinical-region-accordian-details"}>
              <TextField
                label="Clinical Region Score"
                value={clinicalRegionScore ?? ""}
                onChange={(event) =>
                  setClinicalRegionScore(
                    event.target.value === ""
                      ? undefined
                      : parseInt(event.target.value)
                  )
                }
                type="number"
                className={classes.textField}
                key={"dashboard-options-clinical-score"}
              />
              <TextField
                label="Clinical Region Score Text"
                value={clinicalRegionScoreText ?? ""}
                onChange={(event) =>
                  setClinicalRegionScoreText(
                    event.target.value === "" ? undefined : event.target.value
                  )
                }
                multiline
                rows={2}
                className={classes.textField}
                key={"dashboard-options-clinical"}
              />
              <TextField
                label="Clinical Region Info Text"
                value={clinicalRegionInfoText ?? ""}
                onChange={(event) =>
                  setClinicalRegionInfoText(
                    event.target.value === "" ? undefined : event.target.value
                  )
                }
                multiline
                rows={2}
                className={classes.textField}
                key={"dashboard-options-clinical-info"}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </DialogContent>
      {errorText.length > 0 && (
        <Alert severity="error" key={"dashboard-options-alert"}>
          {errorText}
        </Alert>
      )}
      <DialogActions key={"dashboard-options-dialog-actions"}>
        <Button
          onClick={() => {
            saveSettings();
          }}
          variant="contained"
          key={"dashboard-options-save-button"}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            props.handleClose();
          }}
          variant="text"
          key={"dashboard-options-cancel-button"}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QuestionnaireDashboardOptionsModal;
