import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import QuestionForm from "./QuestionForm";
import {
  AnswerDataModel,
  QuestionDataModel,
  QuestionnaireBuilderDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import AnswerForm from "./AnswerForm";
import {
  RequiredAnswerFields,
  RequiredQuestionFields,
  ValidatorError,
} from "./SectionBuilderQuestionAnswerHelper";
import nameof from "../../../utils/NameOf";
import {
  GoalCategory,
  QuestionType,
  TableQuestionType,
} from "../../../../types/auto/types";
import AuthenticatedWarningModal from "./AuthenticatedWarningModal";

interface Props {
  open: boolean;
  handleCancel: () => void;
  questionWIP: QuestionDataModel;
  answerWIP: AnswerDataModel;
  isQuestion: boolean;
  requiredQuestionFields: RequiredQuestionFields[];
  requiredAnswerFields: RequiredAnswerFields[];
  handleSubmit: (newQ: QuestionDataModel) => void;
  handleAnswerUpdate: (newQ: QuestionDataModel) => void;
  edit: boolean;
  handleEdit: (editedQ: QuestionDataModel) => void;
  questionnaire?: QuestionnaireBuilderDataModel;
}

const SectionBuilderQuestionModal = (props: Props): JSX.Element => {
  const [questionWIP, setQuestionWIP] = useState<QuestionDataModel>(
    props.questionWIP
  );
  const [answerWIP, setAnswerWIP] = useState<AnswerDataModel>(props.answerWIP);
  const [rangeMinError, setRangeMinError] = useState<ValidatorError>(
    ValidatorError.NoError
  );
  const [rangeMaxError, setRangeMaxError] = useState<ValidatorError>(
    ValidatorError.NoError
  );
  const [rangeDefaultError, setRangeDefaultError] = useState<ValidatorError>(
    ValidatorError.NoError
  );
  const [tableTypeError, setTableTypeError] = useState<ValidatorError>(
    ValidatorError.NoError
  );
  const [tableError, setTableError] = useState<ValidatorError>(
    ValidatorError.NoError
  );
  const [errorColumn, setErrorColumn] = useState<number>();
  const rowHeaderField: string = "rowHeaderField";

  // keep a local variable for the form field, which will then be submitted to the next level on submission, then into the main object.
  function handleFormChange(
    field: string,
    value: string | boolean | number | GoalCategory | undefined,
    labelIndex?: number,
    rowHeader?: boolean
  ) {
    if (props.isQuestion) {
      if (field === nameof<QuestionDataModel>("labels")) {
        var newLabels = questionWIP.labels?.map((label, index) => {
          if (index === labelIndex) {
            return value;
          } else {
            return label;
          }
        });
        setQuestionWIP({
          ...questionWIP,
          [field]: newLabels,
        });
      } else if (field === nameof<QuestionDataModel>("maxAnswers")) {
        setQuestionWIP({
          ...questionWIP,
          [field]: value === "" ? undefined : Number(value),
        });
      } else if (field === rowHeaderField && rowHeader) {
        const answersField: string = "answers";
        const textField: string = "text";
        var newAnswers = questionWIP.answers.map((answer, i) => {
          if (i === 0) {
            return { number: 1, [textField]: String(value) };
          } else {
            return answer;
          }
        });
        setQuestionWIP({
          ...questionWIP,
          [answersField]: newAnswers,
        });
      } else if (field === nameof<QuestionDataModel>("rows")) {
        const rowNunmberField: string = "rowNumber";
        var arr = String(value).split("\n");
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].substring(0, 5).toLowerCase() === "other") {
            arr[i] = "Other";
          }
        }
        setQuestionWIP({
          ...questionWIP,
          [field]: arr,
          [rowNunmberField]: arr.length,
        });
        setTableError(ValidatorError.NoError);
      } else if (field === nameof<QuestionDataModel>("headers")) {
        var arrHeaders = String(value).split("\n");
        setQuestionWIP({
          ...questionWIP,
          [field]: arrHeaders,
        });
        setTableError(ValidatorError.NoError);
      } else {
        setQuestionWIP({
          ...questionWIP,
          [field]: value,
        });
      }
    } else {
      if (field === nameof<AnswerDataModel>("score")) {
        setAnswerWIP({
          ...answerWIP,
          [field]: Number(value),
        });
      } else if (field === nameof<AnswerDataModel>("rangeMin")) {
        if (answerWIP.rangeMax != null && answerWIP.rangeMax < Number(value)) {
          setRangeMinError(ValidatorError.RangeMinOutOfScope);
        } else {
          setRangeMinError(ValidatorError.NoError);
          setRangeMaxError(ValidatorError.NoError);
        }
        setAnswerWIP({
          ...answerWIP,
          [field]: value === "" ? null : Number(value) < 0 ? 0 : Number(value),
        });
      } else if (field === nameof<AnswerDataModel>("rangeMax")) {
        if (answerWIP.rangeMin != null && answerWIP.rangeMin > Number(value)) {
          setRangeMaxError(ValidatorError.RangeMaxOutOfScope);
        } else {
          setRangeMaxError(ValidatorError.NoError);
          setRangeMinError(ValidatorError.NoError);
        }
        setAnswerWIP({
          ...answerWIP,
          [field]: value === "" ? null : Number(value) < 0 ? 0 : Number(value),
        });
      } else if (field === nameof<AnswerDataModel>("rangeDefault")) {
        if (value === "") {
          setRangeDefaultError(ValidatorError.NoError);
        } else if (
          answerWIP.rangeMax != null &&
          Number(value) > answerWIP.rangeMax
        ) {
          setRangeDefaultError(ValidatorError.RangeDefaultOutOfScopeHigh);
        } else if (
          answerWIP.rangeMin != null &&
          Number(value) >= 0 &&
          Number(value) < answerWIP.rangeMin
        ) {
          setRangeDefaultError(ValidatorError.RangeDefaultOutOfScopeLow);
        } else {
          field === nameof<AnswerDataModel>("rangeDefault") &&
            setRangeDefaultError(ValidatorError.NoError);
        }
        setAnswerWIP({
          ...answerWIP,
          [field]: value === "" ? null : Number(value) < 0 ? 0 : Number(value),
        });
      } else if (field === nameof<AnswerDataModel>("tableDropdownChoices")) {
        var arrTable = String(value).split("\n");
        setAnswerWIP({
          ...answerWIP,
          [field]: arrTable,
        });
      } else if (field === nameof<AnswerDataModel>("tableType")) {
        setAnswerWIP({
          ...answerWIP,
          [field]: value,
        });
        setTableTypeError(ValidatorError.NoError);
      } else if (
        field === nameof<AnswerDataModel>("text") &&
        props.questionWIP.questionType === QuestionType.MultiSelectorTable
      ) {
        setAnswerWIP({
          ...answerWIP,
          [field]: value,
        });
        setTableError(ValidatorError.NoError);
      } else {
        setAnswerWIP({
          ...answerWIP,
          [field]: value,
        });
      }
    }
  }

  function validateFields() {
    var valid = true;

    if (!props.isQuestion) {
      if (
        props.requiredAnswerFields.includes(RequiredAnswerFields.RangeMin) ||
        props.requiredAnswerFields.includes(RequiredAnswerFields.RangeMax) ||
        (props.requiredAnswerFields.includes(RequiredAnswerFields.TableType) &&
          answerWIP.tableType === TableQuestionType.Number)
      ) {
        if (answerWIP.rangeMin == null) {
          setRangeMinError(ValidatorError.NoRangeMinValue);
          valid = false;
        } else if (
          answerWIP.rangeMax != null &&
          answerWIP.rangeMin >= answerWIP.rangeMax
        ) {
          setRangeMinError(ValidatorError.RangeMinOutOfScope);
          setRangeMaxError(ValidatorError.RangeMaxOutOfScope);
          valid = false;
        }
        if (answerWIP.rangeMax == null) {
          setRangeMaxError(ValidatorError.NoRangeMaxValue);
          valid = false;
        }
      }
      if (
        props.requiredAnswerFields.includes(RequiredAnswerFields.RangeDefault)
      ) {
        if (
          answerWIP.rangeDefault != null &&
          answerWIP.rangeMax != null &&
          answerWIP.rangeDefault > answerWIP.rangeMax
        ) {
          setRangeDefaultError(ValidatorError.RangeDefaultOutOfScopeHigh);
          valid = false;
        } else if (
          answerWIP.rangeDefault != null &&
          answerWIP.rangeMin != null &&
          answerWIP.rangeDefault < answerWIP.rangeMin
        ) {
          setRangeDefaultError(ValidatorError.RangeDefaultOutOfScopeLow);
          valid = false;
        }
      }
      if (props.requiredAnswerFields.includes(RequiredAnswerFields.TableType)) {
        if (answerWIP.tableType == null) {
          setTableTypeError(ValidatorError.NoTableTypeSelected);
          valid = false;
        }
      }
      if (
        props.requiredAnswerFields.includes(RequiredAnswerFields.TableType) &&
        props.questionWIP.questionType === QuestionType.MultiSelectorTable
      ) {
        if (props.questionWIP.answers.length > 0) {
          var index: number;
          for (var i = 0; i < props.questionWIP.answers.length; i++) {
            if (answerWIP.number != null) {
              if (
                props.questionWIP.answers[i].text === answerWIP.text &&
                i !== answerWIP.number - 1
              ) {
                setTableError(ValidatorError.TextAlreadyExists);
                valid = false;
                index = i + 1;
                setErrorColumn(index);
              }
            } else {
              setTableError(ValidatorError.NoAnswerNumber);
              valid = false;
            }
          }
        }

        if (answerWIP.text == null || answerWIP.text === "") {
          setTableError(ValidatorError.NoText);
          valid = false;
        }
      }
    } else {
      if (props.requiredQuestionFields.includes(RequiredQuestionFields.Rows)) {
        if (questionWIP.rows?.length === 0) {
          setTableError(ValidatorError.NoRows);
          valid = false;
        }
      }
      if (
        props.requiredQuestionFields.includes(RequiredQuestionFields.Headers)
      ) {
        if (questionWIP.headers?.length === 0) {
          setTableError(ValidatorError.NoHeaders);
          valid = false;
        }
      }
    }
    return valid;
  }
  const [authenticatedModalOpen, setAuthenticatedModalOpen] = useState(false);

  return (
    <>
      <Dialog
        open={props.open}
        key={
          props.isQuestion
            ? "question-details-modal-" + props.questionWIP.number
            : "answer-details-modal-" + props.answerWIP.number
        }
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {props.isQuestion
            ? "Question " + questionWIP.number?.toString()
            : props.questionWIP.questionType === QuestionType.Table
            ? "Column " + (answerWIP.number! - 1)
            : props.questionWIP.questionType === QuestionType.MultiSelectorTable
            ? "Column " + answerWIP.number!
            : "Answer " + answerWIP.number?.toString()}
        </DialogTitle>
        <DialogContent
          key={
            props.isQuestion
              ? "question-modal-dialog-content-" + props.questionWIP.number
              : "answer-modal-dialog-content-" + props.answerWIP.number
          }
        >
          <Box
            sx={{ padding: 1 }}
            key={
              props.isQuestion
                ? "question-details-modal-dialog-title-" +
                  props.questionWIP.number
                : "answer-details-modal-dialog-title-" + props.answerWIP.number
            }
          >
            {props.isQuestion ? (
              <QuestionForm
                questionWIP={questionWIP}
                handleChange={(f, v, i, rH) => handleFormChange(f, v, i, rH)}
                requiredQuestionFields={props.requiredQuestionFields}
                rowHeaderField={rowHeaderField}
                tableError={tableError}
                key={"question-form-" + props.questionWIP.number}
              />
            ) : (
              <AnswerForm
                answerWIP={answerWIP}
                handleChange={(f, v) => handleFormChange(f, v)}
                requiredAnswerFields={props.requiredAnswerFields}
                rangeMinError={rangeMinError}
                rangeMaxError={rangeMaxError}
                rangeDefaultError={rangeDefaultError}
                tableTypeError={tableTypeError}
                questionType={questionWIP.questionType}
                tableError={tableError}
                errorColumn={errorColumn}
                key={"answer-form-" + props.answerWIP.number}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions
          key={
            props.isQuestion
              ? "question-dialog-actions-container-" + props.questionWIP.number
              : "answer-dialog-actions-container-" + props.answerWIP.number
          }
        >
          <Button
            onClick={() => {
              props.handleCancel();
            }}
            key={
              props.isQuestion
                ? "question-dialog-actions-cancel-" + props.questionWIP.number
                : "answer-dialog-actions-cancel-" + props.answerWIP.number
            }
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            onClick={() => {
              if (!validateFields()) {
                return;
              }
              if (props.isQuestion) {
                if (props.edit) {
                  props.handleEdit(questionWIP);
                } else {
                  if (props.questionWIP.questionType === QuestionType.Goals) {
                    if (
                      props.questionnaire!.sections.filter(
                        (x) =>
                          x.questions.filter(
                            (x) => x.questionType === QuestionType.Goals
                          ).length > 0
                      ).length > 0
                    ) {
                      props.handleSubmit(questionWIP);
                    } else {
                      setAuthenticatedModalOpen(true);
                    }
                  } else {
                    props.handleSubmit(questionWIP);
                  }
                }
              } else {
                var newAnswers: AnswerDataModel[] = [];
                if (props.edit) {
                  newAnswers = questionWIP.answers?.map((answer) => {
                    if (answer.number === answerWIP.number) {
                      return answerWIP;
                    } else {
                      return answer;
                    }
                  });
                } else {
                  newAnswers = [...questionWIP.answers, answerWIP];
                }
                var updatedQ: QuestionDataModel = {
                  ...questionWIP,
                  answers: newAnswers,
                };
                props.handleAnswerUpdate(updatedQ);
              }
            }}
            key={
              props.isQuestion
                ? "question-dialog-actions-submit-" + props.questionWIP.number
                : "answer-dialog-actions-submit-" + props.answerWIP.number
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <AuthenticatedWarningModal
        open={authenticatedModalOpen}
        handleCancel={() => setAuthenticatedModalOpen(false)}
        handleAuthenticated={() => {
          setAuthenticatedModalOpen(false);
          props.handleSubmit(questionWIP);
        }}
      />
    </>
  );
};

export default SectionBuilderQuestionModal;
