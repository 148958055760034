import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import { useState } from "react";

interface Props {
  open: boolean;
  handleContinue: () => void;
  handleDiscard: () => void;
  error: boolean;
}

function EditDraftPrompt(props: Props) {
  const [warningOpen, setWarningOpen] = useState(false);
  return (
    <>
      <Dialog
        open={props.error ? false : props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Continue Draft?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to continue with your previous draft answers (including
            goals) or discard them and start again?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setWarningOpen(true);
            }}
            variant="text"
            startIcon={<DeleteIcon />}
            color="error"
          >
            Discard
          </Button>
          <Button
            onClick={props.handleContinue}
            variant="contained"
            startIcon={<Edit />}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={props.error ? false : warningOpen}
        aria-labelledby="discard-warning-dialog-title"
        aria-describedby="discard-warning-dialog-description"
      >
        <DialogTitle id="discard-warning-dialog-title">
          {"Discard draft?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="discard-warning-dialog-description">
            Are you sure you want to discard your previous answers (including
            goals)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setWarningOpen(false);
            }}
            variant="contained"
          >
            Back
          </Button>
          <Button onClick={props.handleDiscard} variant="text">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditDraftPrompt;
