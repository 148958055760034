export enum MeasurementError {
  NoError,
  MinMeasurement,
  MaxMeasurement,
  Both,
}

export const MeasurementCheck = (
  minMeasurement: number,
  maxMeasurement: number
): MeasurementError => {
  if (minMeasurement > maxMeasurement) {
    return MeasurementError.Both;
  } else if (minMeasurement < 0) {
    return MeasurementError.MinMeasurement;
  } else if (maxMeasurement < 0 || maxMeasurement > 1000000) {
    return MeasurementError.MaxMeasurement;
  } else {
    return MeasurementError.NoError;
  }
};

export const IsNameValid = (name: string | undefined): boolean =>
  name !== undefined && name !== "" && name.length < 20;
