import { Button, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { theme as customTheme } from "../../../../Theme";

interface Props {
  errorText: string;
}
const useStyle = makeStyles((th: Theme) => ({
  errorContainer: {
    width: "100%",
    textAlign: "center",
  },
  errorText: {
    color: "red",
  },
}));
export const ErrorComponent = (props: Props): JSX.Element => {
  const classes = useStyle(customTheme);
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }

  return (
    <Container className={classes.errorContainer}>
      <Typography className={classes.errorText}>{props.errorText}</Typography>
      <Button onClick={goBack}>Go back</Button>
    </Container>
  );
};

export default ErrorComponent;
