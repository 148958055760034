import {
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Theme,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import ReplayIcon from "@mui/icons-material/Replay";
import { AllowListPatientSearchRegex, HelpText } from "../../Constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers/";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { theme as customTheme } from "../../../Theme";
import FormErrorText from "../elements/errors/FormErrorText";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { useDebounce } from "../../utils/UseDebounce";
import {
  CaseloadType,
  CaseloadViewModel,
  Role,
  SortDirectionType,
  SortType,
} from "../../../types/auto/types";
import { useNavigate } from "react-router-dom";
import { useMountedState } from "react-use";
import { UserContext } from "../elements/stores/UserStore";

interface Props {
  filteringOfPatients: (
    searchTerm: string,
    searchDate: string,
    caseload: number | null,
    role: Role,
    page: number | null,
    sort: SortType | null,
    direction: SortDirectionType | null
  ) => void;
  caseloads: Array<CaseloadViewModel>;
  criteriaValue: string;
  dateValue: string;
  caseloadValue: Number | null;
  roleFilterValue: Role | null;
  clearSearch: () => void;
}

const UserSearchFilters = (props: Props): JSX.Element => {
  const [searchText, setSearchText] = useState(props.criteriaValue || "");
  const [searchText2, setSearchText2] = useState("");
  const [invalidCharacterUsed, setInvalidCharacterUsed] = useState(false);
  const [selectedDate, setSelectedDate] = useState(props.dateValue || null);
  const isMobile = CheckIsMobile();
  const debouncedSearchFilter = useDebounce(searchText, 1000);
  const [caseloadFilter, setCaseloadFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState<Role>(
    props.roleFilterValue || Role.Patient
  );
  const [newSelection, setNewSelection] = useState(false);
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const useStyle = makeStyles((th: Theme) => ({
    textSearchContainer: {
      display: "flex",
      padding: 0,
      paddingBottom: isMobile ? 10 : 0,
      margin: 0,
    },
    dateSearchContainer: {
      display: "flex",
      padding: 0,
      paddingBottom: isMobile ? 10 : 0,
      margin: 0,
      marginRight: isMobile ? 0 : 20,
    },

    caseloadSearchContainer: {
      display: "flex",
      padding: 0,
      paddingBottom: isMobile ? 10 : 0,
      margin: 0,
      marginRight: isMobile ? 0 : 20,
    },

    innerContainer: {
      display: isMobile ? "block" : "flex",
      alignItems: "center",
    },
    outerPaper: {
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      paddingTop: "10px",
      paddingBottom: "10px",
      boxShadow: "none",
    },
    inputText: {
      width: isMobile ? "100%" : "80%",
    },
    inputLabel: {
      marginLeft: "-15px",
    },
    clearSearchContainer: {
      paddingLeft: isMobile ? "0" : "20",
      padding: 0,
      textAlign: "center",
      width: "50%",
    },
    caseloadFormControl: {
      width: "100%",
    },
    tooltip: {
      display: "block",
    },
  }));

  const classes = useStyle(customTheme);

  const isMountedState = useMountedState();

  useEffect(() => {
    if (isMountedState() && props.caseloads.length > 0) {
      setCaseloadFilter(props.caseloadValue?.toString() || "");
    }
  }, [props.caseloadValue, props.caseloads.length, isMountedState]);

  useEffect(() => {
    if (isMountedState()) {
      var date =
        selectedDate == null ? "" : new Date(selectedDate).toDateString();
      var caseloadId = isNaN(Number(caseloadFilter))
        ? null
        : Number(caseloadFilter);

      if (
        (searchText2 !== debouncedSearchFilter &&
          debouncedSearchFilter.length > 2) ||
        newSelection
      ) {
        setSearchText(debouncedSearchFilter);
        props.filteringOfPatients(
          debouncedSearchFilter,
          date,
          caseloadId,
          roleFilter,
          null,
          null,
          null
        );
        setNewSelection(false);
        setSearchText2(debouncedSearchFilter);
      }

      setSearchText2(debouncedSearchFilter);
    }
  }, [
    props,
    debouncedSearchFilter,
    searchText2,
    selectedDate,
    caseloadFilter,
    newSelection,
    isMountedState,
    roleFilter,
  ]);

  return (
    <Paper component="form" className={classes.outerPaper}>
      <Container className={classes.innerContainer}>
        <Container className={classes.textSearchContainer}>
          <TextField
            variant="standard"
            value={searchText}
            label="Search"
            onChange={(e: any) => {
              var searchText = e.target.value;
              var regexp = new RegExp(AllowListPatientSearchRegex);
              if (regexp.test(searchText)) {
                setInvalidCharacterUsed(true);
                return;
              } else {
                setInvalidCharacterUsed(false);
              }
              setSearchText(searchText);
            }}
            className={classes.inputText}
            fullWidth
          />
          <Tooltip
            title={HelpText.PATIENT_TABLE.SEARCH_PATIENT}
            enterTouchDelay={0}
            className={classes.tooltip}
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Container>
        <Container className={classes.dateSearchContainer}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disableFuture
              inputFormat="dd/MM/yyyy"
              label="Date of birth"
              value={selectedDate}
              maxDate={new Date()}
              onChange={(e: any) => {
                setSelectedDate(e);
                if (!isNaN(Date.parse(e))) {
                  if (new Date(e).getFullYear() > 1900) {
                    setNewSelection(true);
                  }
                }
              }}
              renderInput={(params) => (
                <TextField variant="standard" {...params} fullWidth />
              )}
              disabled={roleFilter !== Role.Patient}
            />
          </LocalizationProvider>
          <Tooltip
            title={HelpText.PATIENT_TABLE.DATE_OF_BIRTH}
            enterTouchDelay={0}
            className={classes.tooltip}
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Container>
        <Container className={classes.caseloadSearchContainer}>
          <FormControl className={classes.caseloadFormControl}>
            <InputLabel id="caseloads" className={classes.inputLabel}>
              Caseload
            </InputLabel>
            <Select
              variant="standard"
              disabled={
                roleFilter === Role.Admin || roleFilter === Role.SuperAdmin
              }
              value={caseloadFilter}
              labelId="caseloads"
              label="caseloads"
              onChange={(e: any) => {
                setNewSelection(true);
                setCaseloadFilter(e.target.value);
              }}
              displayEmpty={true}
            >
              <MenuItem key="NoneCaseload" value="0" role="menuitem">
                <em>None</em>
              </MenuItem>
              {props.caseloads.map((caseload, index) => {
                if (caseload.type === CaseloadType.Personal) {
                  return (
                    <MenuItem key={-1} value={caseload.id} role="menuitem">
                      My Personal Caseload
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem key={index} value={caseload.id} role="menuitem">
                    {caseload.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Tooltip
            title={HelpText.PATIENT_TABLE.CASELOAD}
            enterTouchDelay={0}
            className={classes.tooltip}
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Container>
        {(user.role === Role.Admin || user.role === Role.SuperAdmin) && (
          <Container className={classes.caseloadSearchContainer}>
            <FormControl className={classes.caseloadFormControl}>
              <InputLabel id="role" className={classes.inputLabel}>
                Role
              </InputLabel>
              <Select
                variant="standard"
                value={roleFilter}
                labelId="role"
                label="role"
                onChange={(e) => {
                  setNewSelection(true);
                  const role = e.target.value as Role;
                  setRoleFilter(role);
                  if (role !== Role.Patient) {
                    setSelectedDate(null);
                  }
                  if (role === Role.Admin || role === Role.SuperAdmin) {
                    setCaseloadFilter("");
                  }
                }}
              >
                <MenuItem
                  key={Role.Patient}
                  value={Role.Patient}
                  role="menuitem"
                >
                  Patient
                </MenuItem>
                <MenuItem
                  key={Role.Clinician}
                  value={Role.Clinician}
                  role="menuitem"
                >
                  Clinician
                </MenuItem>
                {user.role === Role.SuperAdmin && (
                  <MenuItem key={Role.Admin} value={Role.Admin} role="menuitem">
                    Administrator
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <Tooltip
              title={HelpText.PATIENT_TABLE.ROLE}
              enterTouchDelay={0}
              className={classes.tooltip}
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Container>
        )}
        <Container className={classes.clearSearchContainer}>
          <Button
            onClick={() => {
              setSearchText("");
              setCaseloadFilter("");
              setRoleFilter(Role.Patient);
              setSelectedDate(null);
              navigate("/search");
              props.clearSearch();
            }}
          >
            <ReplayIcon />
            Clear fields
          </Button>
        </Container>
      </Container>
      <FormErrorText
        errorText="Do not use invalid characters in search i.e. #,!,?"
        isInvalid={invalidCharacterUsed}
      />
    </Paper>
  );
};

export default UserSearchFilters;
