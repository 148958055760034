import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState, useEffect } from "react";
import HeaderBuilder from "./HeaderBuilder";
import BodyBuilder from "./BodyBuilder";
import FooterBuilder from "./FooterBuilder";
import {
  QuestionnaireClient,
  QuestionnaireViewModel,
} from "../../../../types/auto/types";
import CancelWarningModal from "./CancelWarningModal";
import parse from "html-react-parser";
import {
  ChangeType,
  convertDataModelToViewModel,
  generateQuestionnaireBuilderDataModel,
  QuestionnaireBuilderDataModel,
  ScoringDataModel,
  SectionDataModel,
  PatientDashboardOptionsModel,
  updateAnswerNumbersHelper,
  updateQuestionNumbersHelper,
} from "./QuestionnaireBuilderDataModelHelper";
import QuestionnaireSettings from "./QuestionnaireSettings";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { useUnmountPromise } from "react-use";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import nameof from "../../../utils/NameOf";

interface Props {
  questionnaire: QuestionnaireViewModel;
  allQuestionnaires: QuestionnaireViewModel[];
  cancelBuilder: () => void;
  enableDashboardOptions: boolean;
}

const QuestionnaireBuilder = (props: Props): JSX.Element => {
  const [loading] = useState<boolean>(false);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [section, setSection] = useState<number>(0);
  const [openCancelWarningModal, setOpenCancelWarningModal] =
    useState<boolean>(false);
  const [questionnaireWIP, setQuestionnaireWIP] =
    useState<QuestionnaireBuilderDataModel>(generateQuestionnaireWIP);
  const [questionNumberChange, setQuestionNumberChange] = useState<number>(0);
  const [questionNumberChangeType, setQuestionNumberChangeType] =
    useState<ChangeType>(ChangeType.Undefined);
  const [questionNumberRecalc, setQuestionNumberRecalc] =
    useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [sectionNumberChange, setSectionNumberChange] = useState<number>(0);
  const [sectionQuestionNumbersChange, setSectionQuestionNumbersChange] =
    useState<number[]>([]);
  const [sectionNumberChangeType, setSectionNumberChangeType] =
    useState<ChangeType>(ChangeType.Undefined);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [saveError, setSaveError] = useState<boolean>(false);
  const [saveAndCloseLoading, setSaveAndCloseLoading] = useState(false);
  const [previewCoverPage, setPreviewCoverPage] = useState<boolean>(false);

  function generateQuestionnaireWIP() {
    var dmq = generateQuestionnaireBuilderDataModel(props.questionnaire);
    return dmq;
  }

  const isMobile = CheckIsMobile();

  const useStyle = makeStyles({
    titles: {
      marginTop: "10px",
    },
    mainContainer: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      minHeight: "100%",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
      marginTop: 10,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: isMobile ? "wrap" : "nowrap",
    },
    footer: {
      minHeight: "60px",
    },
    body: {
      flex: 1,
    },
  });
  const classes = useStyle();

  function handleNavigate(s: number) {
    if (s < 0) {
      setCancelLoading(true);
      setOpenCancelWarningModal(true);
    } else if (
      (questionnaireWIP.sections?.length != null &&
        s === questionnaireWIP.sections.length) ||
      questionnaireWIP.sections?.length === 0
    ) {
      handleAddSection(s);
    } else {
      setSection(s);
    }
  }

  function handleAddSection(s: number) {
    var localSections: SectionDataModel[] = [];
    var newQ: boolean = false;
    var newS: SectionDataModel = {
      heading: undefined,
      dependsOn: undefined,
      questions: [],
      sectionTitle: undefined,
    };
    if (questionnaireWIP.sections.length !== 0) {
      localSections = [...questionnaireWIP.sections, newS];
    } else {
      localSections = [...localSections, newS];
      newQ = true;
    }
    var localQuestionnaire = { ...questionnaireWIP, sections: localSections };
    setQuestionnaireWIP(localQuestionnaire);
    setSection(newQ ? 0 : s);
    setSaveDisabled(false);
  }

  useEffect(
    () => {
      var updatedQuestions = updateQuestionNumbersHelper(
        questionnaireWIP,
        questionNumberChange,
        questionNumberChangeType,
        sectionNumberChange,
        sectionQuestionNumbersChange,
        sectionNumberChangeType
      );
      setQuestionNumberChangeType(ChangeType.Undefined);
      setSectionNumberChangeType(ChangeType.Undefined);
      if (firstLoad) {
        updatedQuestions = updateAnswerNumbersHelper(updatedQuestions);
        setFirstLoad(false);
      }
      setQuestionnaireWIP(updatedQuestions);
    },
    // eslint-disable-next-line
    [questionNumberChange, questionNumberRecalc]
  );

  function handleChange(
    field: string,
    value:
      | string
      | number
      | boolean
      | null
      | SectionDataModel[]
      | PatientDashboardOptionsModel
      | undefined
      | ScoringDataModel[]
  ) {
    setQuestionnaireWIP((questionnaireWIP) => ({
      ...questionnaireWIP,
      [field]: value,
    }));
    setSaveDisabled(false);
  }

  const resolveWhileMounted = useUnmountPromise();
  const questionnaireClient = new QuestionnaireClient(
    Configuration.SERVER_ROOT,
    FetchOverride
  );

  async function handleSave(close: boolean) {
    var VM = convertDataModelToViewModel(questionnaireWIP);
    var JSONpayload: string = JSON.stringify(VM);
    await resolveWhileMounted(
      questionnaireClient.saveQuestionnaireVersion(JSONpayload)
    )
      .then((id) => {
        handleChange(nameof<QuestionnaireBuilderDataModel>("id"), id);
        setSaveLoading(false);
        setSaveDisabled(true);
        setSaveError(false);
        if (close) {
          props.cancelBuilder();
        }
      })
      .catch(() => {
        setSaveLoading(false);
        setSaveError(true);
      });
  }

  return (
    <Container className={classes.mainContainer} disableGutters>
      <Typography variant="h5" component="h2" className={classes.titles}>
        Questionnaire Builder
      </Typography>
      {saveError && (
        <Alert severity="error">
          There was an internal error and the questionnaire could not be saved.
          Please try again or contact support.
        </Alert>
      )}

      <Box className={classes.header}>
        <HeaderBuilder
          questionnaire={questionnaireWIP}
          setQuestionnaireWIP={(f, v) => {
            handleChange(f, v);
          }}
        />
        <QuestionnaireSettings
          questionnaire={questionnaireWIP}
          allQuestionnaires={props.allQuestionnaires}
          setQuestionnaireWIP={(f, v) => {
            handleChange(f, v);
          }}
          previewCoverPage={previewCoverPage}
          setPreviewCoverPage={setPreviewCoverPage}
          enableDashboardOptions={props.enableDashboardOptions}
          handleSave={() => handleSave(false)}
          handleSaveAndClose={() => handleSave(true)}
          saveLoading={saveLoading}
          saveAndCloseLoading={saveAndCloseLoading}
          saveDisabled={saveDisabled}
          setSaveLoading={() => setSaveLoading(true)}
          setSaveAndCloseLoading={() => setSaveAndCloseLoading(true)}
        />
      </Box>

      {previewCoverPage && (
        <Box>
          <Divider />
          {parse(questionnaireWIP.coverPage ?? "")}
        </Box>
      )}

      <Divider />

      <Box className={classes.body}>
        <BodyBuilder
          allQuestionnaires={props.allQuestionnaires}
          questionnaire={questionnaireWIP}
          section={section}
          setQuestionnaireWIP={(f, v) => {
            handleChange(f, v);
          }}
          setQuestionNumberChange={(qNum, type) => {
            setQuestionNumberChange(qNum);
            setQuestionNumberChangeType(type);
          }}
          setSectionNumberChange={(sn, sQNums, type) => {
            setSectionNumberChange(sn);
            setSectionQuestionNumbersChange(sQNums);
            setSectionNumberChangeType(type);
          }}
          setQuestionNumberRecalc={() => setQuestionNumberRecalc((x) => !x)}
          setSectionNumber={(sn) => {
            setSection(
              sn === questionnaireWIP.sections.length - 1
                ? sn - 1
                : sn - 1 === -1
                ? 0
                : sn
            );
          }}
        />
      </Box>

      <Divider></Divider>

      <Box className={classes.footer}>
        <FooterBuilder
          section={section}
          totalSections={questionnaireWIP.sections?.length ?? 0}
          navigateSection={(s) => {
            handleNavigate(s);
          }}
          cancelLoading={cancelLoading}
        />
      </Box>

      <Container>
        {loading && (
          <CircularProgress
            className={classes.loadingSpinner}
            aria-label="Loading"
          />
        )}
      </Container>
      <CancelWarningModal
        open={openCancelWarningModal}
        handleCancel={() => {
          props.cancelBuilder();
        }}
        handleStay={() => {
          setCancelLoading(false);
          setOpenCancelWarningModal(false);
          setSection(0);
        }}
      />
    </Container>
  );
};

export default QuestionnaireBuilder;
