import { Alert, Box, Button, Card, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import saveAs from "file-saver";
import { useContext, useEffect, useState } from "react";
import { Role, TemporaryAccessLogClient } from "../../types/auto/types";
import { UserContext } from "../components/elements/stores/UserStore";
import { Configuration } from "../Constants";
import { CheckIsMobile } from "../utils/MobileStatus";
import { FetchOverride } from "../utils/Request";

const Reports = (): JSX.Element => {
  const isMobile = CheckIsMobile();
  const user = useContext(UserContext);
  const today = new Date();
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  const [startDate, setStartDate] = useState<Date>(yesterday);
  const [endDate, setEndDate] = useState<Date>(today);
  const [dateRangeException, setDateRangeException] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const useStyle = makeStyles(() => ({
    card: {
      marginTop: "10px",
      width: isMobile ? "100%" : "fit-content",
      padding: 10,
    },
    dateRangeContainer: {
      display: isMobile ? "grid" : "flex",
      width: "100%",
      paddingLeft: 5,
      paddingRight: 5,
    },
    toDate: {
      marginLeft: isMobile ? 0 : "10px",
      marginTop: isMobile ? "10px" : 0,
    },
    button: {
      marginLeft: isMobile ? 0 : "20px",
      marginTop: isMobile ? "10px" : 0,
    },
    error: {
      marginTop: "10px",
    },
  }));
  const classes = useStyle();

  useEffect(() => {
    if (user.role !== Role.Admin && user.role !== Role.SuperAdmin) {
      setInvalidUser(true);
    }
  }, [user, setInvalidUser]);
  return (
    <>
      {!invalidUser && (
        <>
          <Box key="header-box" sx={{ width: 1, m: 2, marginLeft: 0 }}>
            <Typography
              variant={isMobile ? "h5" : "h4"}
              component="h1"
              gutterBottom
            >
              Download Patient Access Records
            </Typography>
          </Box>
          <Card className={classes.card}>
            <Container className={classes.dateRangeContainer} disableGutters>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  label="From Date"
                  value={startDate}
                  onChange={(e: any) => {
                    setStartDate(e);
                    if (!isNaN(Date.parse(e))) {
                      if (new Date(e).getFullYear() > 1900) {
                        if (e > endDate) {
                          setDateRangeException(true);
                        } else {
                          setDateRangeException(false);
                        }
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  label="To Date"
                  value={endDate}
                  onChange={(e: any) => {
                    setEndDate(e);
                    if (!isNaN(Date.parse(e))) {
                      if (new Date(e).getFullYear() > 1900) {
                        if (e < startDate) {
                          setDateRangeException(true);
                        } else {
                          setDateRangeException(false);
                        }
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      className={classes.toDate}
                    />
                  )}
                />
              </LocalizationProvider>
              <Button
                variant="contained"
                className={classes.button}
                disabled={dateRangeException}
                onClick={() => {
                  new TemporaryAccessLogClient(
                    Configuration.SERVER_ROOT,
                    FetchOverride
                  )
                    .downloadTemporaryAccessCSVBetweenDates(
                      startDate?.toISOString(),
                      endDate?.toISOString()
                    )
                    .then((csvStream) => {
                      var fileName =
                        "patient_access_records_" +
                        startDate.toLocaleDateString().replaceAll("/", "_") +
                        "-" +
                        endDate.toLocaleDateString().replaceAll("/", "_");
                      saveAs(csvStream.data, fileName);
                    });
                }}
              >
                Download CSV file
              </Button>
            </Container>
            {dateRangeException && (
              <Alert severity="error" className={classes.error}>
                Please provide a valid date range.
              </Alert>
            )}
          </Card>
        </>
      )}
      {invalidUser && (
        <Alert severity="error" className={classes.error}>
          You cannot view this page with your current role.
        </Alert>
      )}
    </>
  );
};

export default Reports;
