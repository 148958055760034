export const PrintChart = (graphContainerId: string) => {
  var graphs = document.querySelectorAll('[id^="graphContainer"]');
  graphs.forEach((x) => {
    if (x.id !== graphContainerId) {
      document.getElementById(x.id)?.classList.add("noPrint");
    }
  });
  window.print();
  graphs.forEach((x) => {
    if (x.id !== graphContainerId) {
      document.getElementById(x.id)?.classList.remove("noPrint");
    }
  });
};
