import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { Add, Delete, Edit } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  MedicationCategoryViewModel,
  MedicationClient,
} from "../../../../types/auto/types";
import { makeStyles } from "@mui/styles";
import CreateEditMedicationCategoryModal from "./CreateEditMedicationCategoryModal";
import CreateEditMedicationModal from "../CreateEditMedicationModal";
import WarningDialog from "../../elements/dialog/WarningDialog";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";

interface Props {
  medicationCategory: MedicationCategoryViewModel;
  linkedMedications: number;
  refresh: () => void;
  resetMedications: () => void;
}
const MedicationCategoryActions = (props: Props): JSX.Element => {
  const [anchorActionList, setAnchorActionList] =
    React.useState<null | HTMLElement>(null);
  const [openAddMedication, setOpenAddMedication] = React.useState(false);
  const [openEditMedicationCategory, setOpenEditMedicationCategory] =
    React.useState(false);
  const [openDeleteMedicationCategory, setOpenDeleteMedicationCategory] =
    React.useState(false);

  const useStyle = makeStyles(() => ({
    vert: {
      marginLeft: "auto",
      padding: "0",
      minWidth: "24px",
    },
  }));
  const classes = useStyle();
  const handleClose = () => {
    setAnchorActionList(null);
  };
  return (
    <>
      <Button
        onClick={(event) =>
          setAnchorActionList((val) =>
            val === null ? event.currentTarget : null
          )
        }
        aria-label="Open Medication Category Action List"
        className={classes.vert}
      >
        <MoreVertIcon />
        <Menu
          open={anchorActionList !== null}
          anchorEl={anchorActionList}
          aria-label="Medication Category Action List"
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => setOpenAddMedication(true)}
            aria-label="Add medication"
          >
            <Add />
            Add medication
          </MenuItem>
          <MenuItem
            onClick={() => setOpenEditMedicationCategory(true)}
            aria-label="Edit category"
          >
            <Edit />
            Edit category
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenDeleteMedicationCategory(true);
            }}
            aria-label="Delete category"
          >
            <Delete />
            Delete category
          </MenuItem>
        </Menu>
      </Button>
      <WarningDialog
        title="Delete medication category"
        errorText="Error deleting medication category."
        body={`This will delete the selected medication category: ${
          props.medicationCategory!.name ? props.medicationCategory!.name : ""
        }. Are you sure this is intended?`}
        continueButtonText="Delete medication category"
        open={openDeleteMedicationCategory}
        onCancel={() => setOpenDeleteMedicationCategory(false)}
        setOpen={() => setOpenDeleteMedicationCategory(true)}
        onContinue={() =>
          new MedicationClient(Configuration.SERVER_ROOT, FetchOverride)
            .deleteMedicationCategory(props.medicationCategory!)
            .then(() => {
              setOpenDeleteMedicationCategory(false);
              props.refresh();
            })
        }
        linkedObjects={props.linkedMedications}
        linkedErrorText={
          "Delete linked medications before deleting medication category."
        }
      />
      <CreateEditMedicationCategoryModal
        open={openEditMedicationCategory}
        closeModal={() => setOpenEditMedicationCategory(false)}
        medicationCategory={props.medicationCategory}
        refresh={props.refresh}
      />
      <CreateEditMedicationModal
        open={openAddMedication}
        closeModal={() => setOpenAddMedication(false)}
        refresh={props.refresh}
        medicationCategoryId={props.medicationCategory.id}
        resetMedications={props.resetMedications}
      />
    </>
  );
};

export default MedicationCategoryActions;
