import { Box, Button, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import {
  CaseloadClient,
  CaseloadType,
  CaseloadViewModel,
  PatientClient,
} from "../../../../types/auto/types";
import { useEffect, useState } from "react";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { LoadingButton } from "@mui/lab";
import { Error400 } from "../../elements/errors/Error400";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import MultiSelectChipSubset from "../../elements/form-elements/MultiSelectChipSubset";
import { useMountedState, useUnmountPromise } from "react-use";

interface Props {
  open: boolean;
  closeModal: () => void;
  userId: number;
  patientId: number;
}
const AssignCaseloadsToPatientModal = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    caseloadSelector: {
      width: "100%",
      marginTop: 20,
    },
    subHeader: {
      fontSize: 20,
      marginBottom: 0,
      marginTop: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
    },
  });
  const classes = useStyle(theme);
  const isMobile = CheckIsMobile();

  const [rerender, setRerender] = useState<boolean>(true);
  const [caseloadOptions, setCaseloadOptions] = useState<CaseloadViewModel[]>(
    []
  );
  const [selectedCaseloads, setSelectedCaseloads] = useState<
    CaseloadViewModel[]
  >([]);

  const [loading, setLoading] = useState(false);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "60%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    border: "none",
  };

  const isMountedState = useMountedState();
  const resolveWhileMounted = useUnmountPromise();

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        if (rerender) {
          await resolveWhileMounted(
            new CaseloadClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getCaseloads()
          ).then((x) => setCaseloadOptions(x));
          await resolveWhileMounted(
            new CaseloadClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getCaseloadsForPatient(props.patientId, CaseloadType.Both, false)
          ).then((x) => {
            setSelectedCaseloads(x);
          });
          setRerender(false);
        }
      }
    };
    effect();
  }, [props.patientId, rerender, resolveWhileMounted, isMountedState]);

  const [errorOnAssigning, setErrorOnAssigning] = useState(false);
  function onClose() {
    setErrorOnAssigning(false);
    props.closeModal();
  }
  async function searchCaseloads(filterText: string) {
    await resolveWhileMounted(
      new CaseloadClient(
        Configuration.SERVER_ROOT,
        FetchOverride
      ).getCaseloadsByName(filterText)
    ).then((x) => setCaseloadOptions(x));
  }

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h5" component="h5" className={classes.subHeader}>
          Caseloads
        </Typography>
        <MultiSelectChipSubset
          label={"User Caseloads"}
          options={caseloadOptions}
          selectedOptions={selectedCaseloads}
          setSelectedOptions={setSelectedCaseloads}
          className={classes.caseloadSelector}
          onFilter={searchCaseloads}
          noOptionsText={"No caseloads"}
        />
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              setRerender(true);
              props.closeModal();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await resolveWhileMounted(
                new PatientClient(
                  Configuration.SERVER_ROOT,
                  FetchOverride
                ).assignCaseloadsToPatient(
                  props.patientId,
                  selectedCaseloads.map((x) => x.id)
                )
              )
                .then(() => {
                  setLoading(false);
                  onClose();
                })
                .catch(() => {
                  setLoading(false);
                  setErrorOnAssigning(true);
                });
            }}
          >
            Save changes
          </LoadingButton>
        </div>
        {errorOnAssigning && <Error400 />}
      </Box>
    </Modal>
  );
};

export default AssignCaseloadsToPatientModal;
