import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { QuestionnaireBuilderDataModel } from "./QuestionnaireBuilderDataModelHelper";
import nameof from "../../../utils/NameOf";

interface Props {
  open: boolean;
  questionnaire: QuestionnaireBuilderDataModel;
  handleCancel: () => void;
  setQuestionnaireWIP: (
    field: string,
    value: string | boolean | number | null | undefined
  ) => void;
}

function QuestionnaireSettingsModal(props: Props) {
  const [copyright, setCopyright] = useState(props.questionnaire.copyright);
  const [coverPage, setCoverPage] = useState(props.questionnaire.coverPage);
  const [isSectionSelector, setIsSectionSelector] = useState<
    boolean | undefined
  >(props.questionnaire.sectionsSelector);
  const [isDisableGraphs, setIsDisableGraphs] = useState<boolean | undefined>(
    props.questionnaire.disableGraphs
  );
  const [consentCheck, setConsentCheck] = useState<boolean | undefined>(
    props.questionnaire.consentCheck
  );
  const [displaySectionComplete, setDisplaySectionComplete] = useState<
    boolean | undefined
  >(props.questionnaire.displaySectionComplete);

  const [plotSubscales, setPlotSubscales] = useState<boolean | undefined>(
    props.questionnaire.plotSubscales
  );
  const [plotTscores, setPlotTscores] = useState<boolean | undefined>(
    props.questionnaire.plotTscores
  );

  const useStyle = makeStyles({
    textField: {
      margin: "16px",
      width: "100%",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
    checkBox: {
      marginLeft: "5px",
    },
  });
  const classes = useStyle();

  const saveSettings = () => {
    if (copyright === "") {
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("copyright"),
        undefined
      );
    } else {
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("copyright"),
        copyright
      );
    }
    if (coverPage === "" && consentCheck === true) {
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("coverPage"),
        ""
      );
    } else if (coverPage === "" && consentCheck === false) {
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("coverPage"),
        undefined
      );
    } else {
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("coverPage"),
        coverPage
      );
    }
    props.setQuestionnaireWIP(
      nameof<QuestionnaireBuilderDataModel>("sectionsSelector"),
      isSectionSelector ?? false
    );
    props.setQuestionnaireWIP(
      nameof<QuestionnaireBuilderDataModel>("disableGraphs"),
      isDisableGraphs ?? false
    );
    props.setQuestionnaireWIP(
      nameof<QuestionnaireBuilderDataModel>("consentCheck"),
      consentCheck ?? false
    );
    props.setQuestionnaireWIP(
      nameof<QuestionnaireBuilderDataModel>("displaySectionComplete"),
      displaySectionComplete ?? false
    );
    props.setQuestionnaireWIP(
      nameof<QuestionnaireBuilderDataModel>("plotSubscales"),
      plotSubscales ?? false
    );
    props.setQuestionnaireWIP(
      nameof<QuestionnaireBuilderDataModel>("plotTscores"),
      plotTscores ?? false
    );
  };

  return (
    <Dialog
      open={props.open}
      aria-labelledby="questionnaire-settings-title"
      key={"questionnaire-settings-modal"}
    >
      <DialogTitle
        id="questionnaire-settings-title"
        key={"questionnaire-settings-title"}
      >
        {"Questionnaire settings"}
      </DialogTitle>
      <DialogContent key={"questionnaire-settings-dialog-content"}>
        <Box
          className={classes.container}
          key={"questionnaire-settings-container"}
        >
          <TextField
            label="Cover Page"
            className={classes.textField}
            value={coverPage ?? ""}
            onChange={(event) => setCoverPage(event.target.value)}
            multiline
            rows={4}
            key={"coverpage-field"}
          />
          <TextField
            label="Copyright"
            className={classes.textField}
            value={copyright ?? ""}
            onChange={(event) => setCopyright(event.target.value)}
            multiline
            rows={4}
            key={"copyright-field"}
          />
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSectionSelector ?? false}
                  onChange={(event) =>
                    setIsSectionSelector(event.target.checked)
                  }
                  key={"sections-selector-checkbox"}
                />
              }
              label="Show Results By Section"
              key={"sections-selector-formcontrol"}
              className={classes.checkBox}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDisableGraphs ?? false}
                  onChange={(event) => setIsDisableGraphs(event.target.checked)}
                  key={"disable-graphs-checkbox"}
                />
              }
              label="Disable Questionnaire Graphs"
              key={"disable-graphs-formcontrol"}
              className={classes.checkBox}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={consentCheck ?? false}
                  onChange={(event) => setConsentCheck(event.target.checked)}
                  key={"consent-check-checkbox"}
                />
              }
              label="Add Consent Check"
              key={"consent-check-formcontrol"}
              className={classes.checkBox}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={plotSubscales ?? false}
                  onChange={(event) => setPlotSubscales(event.target.checked)}
                  key={"plot-subscales-checkbox"}
                />
              }
              label="Plot Subscales"
              key={"plot-subscales-formcontrol"}
              className={classes.checkBox}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={plotTscores ?? false}
                  onChange={(event) => setPlotTscores(event.target.checked)}
                  key={"plot-tscores-checkbox"}
                />
              }
              label="Plot T-Scores"
              key={"plot-tscores-formcontrol"}
              className={classes.checkBox}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={displaySectionComplete ?? false}
                  onChange={(event) =>
                    setDisplaySectionComplete(event.target.checked)
                  }
                  key={"display-section-complete-checkbox"}
                />
              }
              label="Display Section Complete"
              key={"display-section-complete-formcontrol"}
              className={classes.checkBox}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions key={"questionnaire-settings-dialog-actions"}>
        <Button
          onClick={() => {
            saveSettings();
            props.handleCancel();
          }}
          variant="contained"
          key={"save-settings-button"}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            props.handleCancel();
          }}
          variant="text"
          key={"cancel-settings-button"}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QuestionnaireSettingsModal;
