import { Alert, CircularProgress, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useMountedState, useUnmountPromise } from "react-use";
import {
  TenancyOptionErrors,
  PatientViewModel,
  DatasetClient,
  PatientDatasetClient,
  PatientDatasetViewModel,
  DatasetViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import CreateEditPatientDataset from "./CreateEditPatientDataset";
import PatientDataset from "./PatientDataset";

interface Props {
  patient: PatientViewModel;
}
const ViewPatientDataset = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    titles: {
      marginTop: "10px",
    },
    patientDataset: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  }));
  const classes = useStyle();

  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<TenancyOptionErrors | undefined>(
    undefined
  );
  const [datasets, setDatasets] = React.useState<DatasetViewModel[]>([]);
  const [patientDataset, setPatientDataset] = React.useState<
    PatientDatasetViewModel[]
  >([]);

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        setLoading(true);
        await resolveWhileMounted(
          new DatasetClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getTenantDatasets()
        )
          .then((datasets) => setDatasets(datasets))
          .catch((e) => setError(e))
          .finally(() => {
            if (isMountedState()) setLoading(false);
          });
        await resolveWhileMounted(
          new PatientDatasetClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getPatientDatasetLinkedToPatient(props.patient.id)
        )
          .then((patientExternalData) =>
            setPatientDataset(
              patientExternalData !== undefined && patientExternalData !== null
                ? patientExternalData
                : []
            )
          )
          .catch((e) => setError(e))
          .finally(() => {
            if (isMountedState()) setLoading(false);
          });
      }
    };
    effect();
  }, [refresh, isMountedState, props.patient, resolveWhileMounted]);
  return (
    <>
      {error === undefined && (
        <Container disableGutters>
          <Typography variant="h5" component="h2" className={classes.titles}>
            Patient datasets
          </Typography>
          <Container disableGutters>
            <CreateEditPatientDataset
              refresh={() => setRefresh((x) => !x)}
              patientId={props.patient.id}
              datasets={datasets}
            />
            {patientDataset.length === 0 && (
              <Typography>
                There are no active patient datasets. Click the above button to
                add patient dataset.
              </Typography>
            )}
          </Container>
          <Container disableGutters>
            {loading && (
              <CircularProgress
                className={classes.loadingSpinner}
                aria-label="Loading"
              />
            )}
          </Container>
          <Container className={classes.patientDataset} disableGutters>
            {patientDataset.map((patientDataset, key) => (
              <PatientDataset
                key={key}
                patientDataset={patientDataset}
                patientId={props.patient.id}
                reload={() => setRefresh((x) => !x)}
                datasets={datasets}
              />
            ))}
          </Container>
        </Container>
      )}
      {error !== undefined && (
        <Alert severity="error">
          {error === TenancyOptionErrors.TenancyNotSupported
            ? "Tenancy does not support external datasets."
            : ""}
        </Alert>
      )}
    </>
  );
};

export default ViewPatientDataset;
