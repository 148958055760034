import { Container, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { MeasurementViewModel } from "../../../../types/auto/types";
import {
  IsNameValid,
  MeasurementCheck,
  MeasurementError,
} from "./CreateEditMeasurementModalHelper";

interface Props {
  measurement: MeasurementViewModel;
  setMeasurement: React.Dispatch<React.SetStateAction<MeasurementViewModel>>;
  setReRender: React.Dispatch<React.SetStateAction<boolean>>;
  measurementError: MeasurementError;
  nameError: boolean;
  setNameError: React.Dispatch<React.SetStateAction<boolean>>;
  setMeasurementError: React.Dispatch<React.SetStateAction<MeasurementError>>;
}
const MeasurementsDetailsForm = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    textFieldContainer: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      gap: 10,
      width: "100%",
      marginBottom: 15,
    },
    textField: {
      width: "100%",
    },
  });
  const classes = useStyle();

  return (
    <Container className={classes.textFieldContainer}>
      <TextField
        label="Name"
        className={classes.textField}
        required
        value={
          props.measurement.name === undefined ? "" : props.measurement.name
        }
        onChange={(e) => {
          props.setMeasurement((x) => {
            x.name = e.target.value;
            props.setReRender((x) => !x);
            return x;
          });
          props.setNameError(!IsNameValid(e.target.value));
        }}
        error={props.nameError}
        helperText={
          props.nameError && "Name must be between 1 and 20 characters long"
        }
      />
      <TextField
        label="Min measurement"
        className={classes.textField}
        value={
          props.measurement.minMeasurement
            ? props.measurement.minMeasurement
            : ""
        }
        type="number"
        onChange={(e) => {
          var minMeasurement = e.target.value === "" ? "0" : e.target.value;
          props.setMeasurement((x) => {
            x.minMeasurement = Number.parseInt(minMeasurement);
            props.setReRender((x) => !x);
            return x;
          });
          props.setMeasurementError(
            MeasurementCheck(
              Number.parseInt(minMeasurement),
              props.measurement.maxMeasurement ?? 0
            )
          );
        }}
        error={
          props.measurementError === MeasurementError.MinMeasurement ||
          props.measurementError === MeasurementError.Both
        }
        helperText={
          (props.measurementError === MeasurementError.MinMeasurement ||
            props.measurementError === MeasurementError.Both) &&
          "Min measurement must be less than max measurement and a positive number"
        }
      />
      <TextField
        label="Max measurement"
        className={classes.textField}
        type="number"
        value={
          props.measurement.maxMeasurement
            ? props.measurement.maxMeasurement
            : ""
        }
        onChange={(e) => {
          var maxMeasurement = e.target.value === "" ? "0" : e.target.value;
          props.setMeasurement((x) => {
            x.maxMeasurement = Number.parseInt(maxMeasurement);
            props.setReRender((x) => !x);
            return x;
          });
          props.setMeasurementError(
            MeasurementCheck(
              props.measurement.minMeasurement ?? 0,
              Number.parseInt(maxMeasurement)
            )
          );
        }}
        error={
          props.measurementError === MeasurementError.MaxMeasurement ||
          props.measurementError === MeasurementError.Both
        }
        helperText={
          (props.measurementError === MeasurementError.MaxMeasurement ||
            props.measurementError === MeasurementError.Both) &&
          "Max measurement must be greater than min measurement and between 1 and 1000000"
        }
      />
    </Container>
  );
};

export default MeasurementsDetailsForm;
