import { LoadingButton } from "@mui/lab";
import { Modal, Box, Typography, Button, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import MeasurementsDetailsForm from "./MeasurementsDetailsForm";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import {
  DatasetClient,
  MeasurementViewModel,
  TenancyOptionErrors,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import {
  MeasurementCheck,
  MeasurementError,
} from "./CreateEditMeasurementModalHelper";

interface Props {
  open: boolean;
  closeModal: () => void;
  measurement?: MeasurementViewModel;
  resetMeasurements: () => void;
  datasetId: number;
}
const CreateEditMeasurementModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "80%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
    },
  });
  const classes = useStyle();
  const [, setReRender] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [measurement, setMeasurement] = React.useState<MeasurementViewModel>(
    props.measurement ?? ({} as MeasurementViewModel)
  );
  const [measurementError, setMeasurementError] =
    React.useState<MeasurementError>(MeasurementError.NoError);
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<TenancyOptionErrors | undefined>(
    undefined
  );

  function resetErrors() {
    setMeasurementError(MeasurementError.NoError);
    setNameError(false);
    setError(undefined);
    setLoading(false);
  }
  function confirmInput(
    measurement: MeasurementViewModel | undefined
  ): boolean {
    var correctDetails = true;
    if (measurement === undefined) {
      setNameError(true);
      setMeasurementError(MeasurementError.Both);
      return false;
    }
    if (measurement.name === undefined) {
      setNameError(true);
      correctDetails = false;
    }
    if (
      measurement.minMeasurement !== undefined &&
      measurement.maxMeasurement !== undefined
    ) {
      if (
        MeasurementCheck(
          measurement.minMeasurement,
          measurement.maxMeasurement
        ) !== MeasurementError.NoError
      ) {
        setMeasurementError(
          MeasurementCheck(
            measurement.minMeasurement,
            measurement.maxMeasurement
          )
        );
        correctDetails = false;
      }
    }

    return correctDetails;
  }

  useEffect(() => {
    if (props.measurement) {
      // Deep copy patient prop to fix state update problems
      setMeasurement(
        JSON.parse(JSON.stringify(props.measurement), function (key, value) {
          return value;
        })
      );
    } else {
      setMeasurement({
        datasetId: props.datasetId,
      } as MeasurementViewModel);
    }

    setReRender((x) => !x);
  }, [props]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          {props.measurement ? "Edit Measurement" : "Create Measurement"}
        </Typography>
        <MeasurementsDetailsForm
          measurement={measurement}
          setMeasurement={setMeasurement}
          setReRender={setReRender}
          setMeasurementError={setMeasurementError}
          setNameError={setNameError}
          measurementError={measurementError}
          nameError={nameError}
        />
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              props.closeModal();
              setReRender((x) => !x);
              resetErrors();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={
              measurementError !== MeasurementError.NoError || nameError
            }
            onClick={async () => {
              setLoading(true);
              if (measurement.maxMeasurement === undefined) {
                measurement.minMeasurement = 0;
              }
              if (!confirmInput(measurement)) {
                setLoading(false);
                return;
              }
              if (
                props.measurement === undefined ||
                props.measurement === null
              ) {
                new DatasetClient(Configuration.SERVER_ROOT, FetchOverride)
                  .createMeasurement(measurement)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                    props.resetMeasurements();
                  })
                  .catch((e) => {
                    setError(e);
                  });
              } else {
                new DatasetClient(Configuration.SERVER_ROOT, FetchOverride)
                  .updateMeasurement(measurement)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                    props.resetMeasurements();
                  })
                  .catch((e) => {
                    setError(e);
                  });
              }
              if (error !== undefined) {
                resetErrors();
              }
              props.resetMeasurements();
            }}
          >
            {props.measurement ? "Save Changes" : "Create Measurement"}
          </LoadingButton>
        </div>
        {error !== undefined && (
          <Alert severity="error">
            {error === TenancyOptionErrors.InvalidModel
              ? "Details for measurement are invalid"
              : "Tenancy does not support external datasets."}
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default CreateEditMeasurementModal;
