import {
  Button,
  CircularProgress,
  Container,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useMountedState, useUnmountPromise } from "react-use";
import { theme } from "../../../../Theme";
import {
  MonitoringStatus,
  PatientViewModel,
  Role,
  ScheduleClient,
  ScheduleViewModel,
  UserViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { FetchOverride } from "../../../utils/Request";
import CustomDialog from "../../elements/dialog/CustomDialog";
import { Error400 } from "../../elements/errors/Error400";
import OneOffQuestionnaireAllocation from "../dialog/OneOffQuestionnaireAllocation";
import QuestionnaireCard from "./QuestionnaireCard";
import ScheduledQuestionnaireCard from "./ScheduledQuestionnaireCard";

interface Props {
  patient: PatientViewModel;
  user: UserViewModel;
}
const ViewPatientQuestionnaire = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles((th: Theme) => ({
    questionnaireContainer: {
      display: isMobile ? "default" : "flex",
      flexWrap: "wrap",
      padding: 0,
    },
    questionnaireButton: {
      margin: 20,
      marginBottom: 5,
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  }));
  const classes = useStyle(theme);
  const [openOneOffQuestionnaireDialog, setOpenOneOffQuestionnaireDialog] =
    useState(false);
  const [scheduledQuestionnaires, setScheduledQuestionnaires] = useState<
    ScheduleViewModel[]
  >([]);
  const [validRequest, setValidRequest] = useState(true);
  const [load, setLoad] = useState(false);
  const [componentLoaded, setComponentLoaded] = useState(false);
  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  const isStaff =
    props.user.role === Role.SuperAdmin ||
    props.user.role === Role.Admin ||
    props.user.role === Role.Clinician;

  useEffect(() => {
    if (isMountedState()) {
      const effect = async () => {
        if (props.patient.id !== undefined && !componentLoaded) {
          setComponentLoaded(true);
          setLoad(true);
          await resolveWhileMounted(
            new ScheduleClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getSchedulesForPatient(props.patient.id)
          )
            .then((schedules) => {
              setLoad(false);
              setScheduledQuestionnaires(
                schedules !== undefined && schedules !== null
                  ? schedules.filter(
                      (x) => x.monitoringStatus === MonitoringStatus.Active
                    )
                  : []
              );
            })
            .catch(() => {
              setValidRequest(false);
            });
        }
      };
      effect();
    }
  }, [
    props,
    setScheduledQuestionnaires,
    resolveWhileMounted,
    isMountedState,
    componentLoaded,
  ]);

  return (
    <>
      {load ? (
        <CircularProgress
          className={classes.loadingSpinner}
          aria-label="Loading"
        />
      ) : (
        <Container className={classes.questionnaireContainer}>
          {isStaff && (
            <QuestionnaireCard
              title="Ad-hoc Questionnaires"
              body="Allows a patient to complete some one-off questionnaires without scheduling."
              buttons={
                <Button
                  className={classes.questionnaireButton}
                  aria-label="Select Questionnaires"
                  onClick={() => setOpenOneOffQuestionnaireDialog(true)}
                  variant="contained"
                >
                  Select Questionnaires
                </Button>
              }
            />
          )}
          {scheduledQuestionnaires.map((scheduledQuestionnaire, index) => (
            <ScheduledQuestionnaireCard
              key={scheduledQuestionnaire.id}
              scheduledQuestionnaire={scheduledQuestionnaire}
              patient={props.patient}
              setValidRequest={setValidRequest}
              isStaff={isStaff}
            />
          ))}
          {!isStaff && scheduledQuestionnaires.length === 0 && (
            <Container>
              <Typography>You have no scheduled questionnaires</Typography>
            </Container>
          )}
          {!validRequest && <Error400 />}
          <CustomDialog
            open={openOneOffQuestionnaireDialog}
            onClose={() => setOpenOneOffQuestionnaireDialog(false)}
            title={"Ad-hoc Questionnaires"}
            aria-label="Ad-hoc Questionnaires"
          >
            <OneOffQuestionnaireAllocation patient={props.patient} />
          </CustomDialog>
        </Container>
      )}
    </>
  );
};

export default ViewPatientQuestionnaire;
