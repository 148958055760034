import { Container, Typography } from "@mui/material";
import {
  QuestionType,
  QuestionViewModel,
  PatientTimelineDataViewModel,
  AnswerViewModel,
  GoalViewModel,
  GoalClient,
  GoalCategory,
} from "../../../../types/auto/types";
import parse from "html-react-parser";
import { makeStyles } from "@mui/styles";
import GenericTable from "../../elements/table/GenericTable";
import MultiSelectorTableResponse from "../../elements/table/MultiSelectorTableResponse";
import { useEffect, useState } from "react";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";

interface Props {
  question: QuestionViewModel;
  index: number;
  timelineEntree: PatientTimelineDataViewModel;
}
export const ResponseContent = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    scoringHeading: {
      fontWeight: "bold",
      fontSize: "18px",
    },
    questionAnswerSection: {
      margin: 0,
      padding: 0,
    },
    question: {
      fontWeight: "bolder",
    },
    answer: {
      textIndent: "10px",
    },
  }));
  const classes = useStyle();
  const [questionnaireGoals, setQuestionnaireGoals] = useState<GoalViewModel[]>(
    []
  );

  const loadMultiAnswer = (props: Props) => {
    var output = props.timelineEntree.questionnaireResponse
      ?.find((response) => response.number === props.question.number)
      ?.answer?.split(",")
      .map((x) => {
        return props.question.answers!.find((y) => y.number === Number(x))
          ?.text;
      });
    return output?.toString();
  };

  type TableData = { [key: string]: string | number };

  useEffect(() => {
    if (
      props.question.questionType === QuestionType.Goals &&
      props.timelineEntree.questionnaire != null
    ) {
      new GoalClient(Configuration.SERVER_ROOT, FetchOverride)
        .getQuestionnaireGoals(
          props.timelineEntree.patientId,
          props.timelineEntree.questionnaire.id,
          props.question.goalCategory ?? GoalCategory.General,
          props.timelineEntree.responseId
        )
        .then((goals) => setQuestionnaireGoals(goals));
    }
  });

  return (
    <Container
      className={classes.questionAnswerSection}
      key={"questionAnswer" + props.index + props.timelineEntree.responseId}
    >
      <Typography
        key={"question" + props.index + props.timelineEntree.responseId}
        className={classes.question}
      >
        {!props.question.hideNumber &&
          (props.question.displayName ?? props.question.number) + " "}
        {props.question.text &&
        props.question.text.trim().length > 0 &&
        !props.question.text.trim().endsWith(":")
          ? parse(props.question.text + ":")
          : parse(props.question.text ?? "")}
      </Typography>
      {props.question.disableResponses ? null : (
        <Typography
          key={"answer" + props.index + props.timelineEntree.responseId}
          className={classes.answer}
        >
          {props.question.questionType === QuestionType.Buttons ? (
            props.question.answers!.find((answer) => {
              return (
                answer.number?.toString() ===
                props.timelineEntree.questionnaireResponse?.find(
                  (response) => response.number === props.question.number
                )?.answer
              );
            })?.text
          ) : props.question.questionType === QuestionType.ButtonsMulti ? (
            loadMultiAnswer(props)
          ) : props.question.questionType === QuestionType.Sketch ? (
            <>
              <img
                src={
                  props.timelineEntree.questionnaireResponse?.find(
                    (response) => response.number === props.question.number
                  )?.answer
                }
                alt="Sketched user input"
              ></img>
            </>
          ) : props.question.questionType === QuestionType.Table ? (
            <GenericTable
              center={true}
              id={"answer" + props.index + props.timelineEntree.responseId}
              head={
                props.question.answers?.map(
                  (a: AnswerViewModel) => a.text ?? ""
                ) ?? []
              }
              rows={JSON.parse(
                props.timelineEntree.questionnaireResponse?.find(
                  (response) => response.number === props.question.number
                )?.answer ?? "[]"
              ).map((x: TableData, indexX: number) =>
                Object.keys(x)
                  .filter((m) => m !== "row")
                  .map((y, indexY) =>
                    props.question.rows && indexY === 0 ? (
                      props.question.rows?.at(indexX) === "Other" ? (
                        <>{JSON.stringify(x[y])}</>
                      ) : (
                        props.question.rows?.at(indexX)
                      )
                    ) : (
                      <>{JSON.stringify(x[y])}</>
                    )
                  )
              )}
            />
          ) : props.question.questionType ===
            QuestionType.MultiSelectorTable ? (
            <MultiSelectorTableResponse
              question={props.question}
              answers={props.question.answers}
              response={
                props.timelineEntree.questionnaireResponse?.find(
                  (response) => response.number === props.question.number
                )?.answer ?? "[]"
              }
            ></MultiSelectorTableResponse>
          ) : props.question.questionType === QuestionType.SkippableRange ? (
            props.timelineEntree.questionnaireResponse?.find(
              (response) => response.number === props.question.number
            )?.answer === "-1" ? (
              "Skipped"
            ) : (
              props.timelineEntree.questionnaireResponse?.find(
                (response) => response.number === props.question.number
              )?.answer
            )
          ) : props.question.questionType === QuestionType.Goals ? (
            questionnaireGoals.map((goal) => (
              <Typography>{goal.question}</Typography>
            ))
          ) : (
            props.timelineEntree.questionnaireResponse?.find(
              (response) => response.number === props.question.number
            )?.answer
          )}
        </Typography>
      )}
    </Container>
  );
};
