import { FormControlLabel, Switch, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ArrowForward } from "@mui/icons-material";
import { theme } from "../../../../Theme";
import { useEffect } from "react";
import { CheckIsMobile } from "../../../utils/MobileStatus";

interface Props {
  editing: boolean;
  startDate: Date | undefined;
  setStartDate: (startDate: Date) => void;
  endDate: Date | undefined;
  setEndDate: (endDate: Date) => void;
  indefiniteEndDate: boolean;
  setIndefiniteEndDate: (indefiniteEndDate: boolean) => void;
}

const DateSelect = (props: Props) => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    datePicker: {
      width: "100%",
    },
    container: {
      width: "100%",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
    },
    dateContainer: {},
    arrow: {
      fontSize: isMobile ? 20 : 30,
      margin: 10,
      color: "gray",
      transform: isMobile ? "rotate(90deg)" : "0",
    },
    continueIndefinitely: {
      marginLeft: isMobile ? 0 : 10,
    },
  });
  const classes = useStyle(theme);

  useEffect(() => {
    if (props.startDate === undefined || props.startDate === null) {
      props.setStartDate(new Date());
    }
    if (
      (props.endDate === undefined || props.endDate === null) &&
      !props.indefiniteEndDate
    ) {
      props.setEndDate(new Date());
    }
  }, [props]);

  return (
    <div className={classes.container}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className={classes.dateContainer}>
          <DateTimePicker
            label="Start Date"
            value={props.startDate}
            onChange={(newValue: Date | null) =>
              newValue != null && props.setStartDate(newValue)
            }
            renderInput={(params) => <TextField {...params} />}
            className={classes.datePicker}
            disablePast={!props.editing} // Dates can be in the past if we're editing
            inputFormat="dd/MM/yyyy h:mm a"
            disableMaskedInput
          />
        </div>
        {!props.indefiniteEndDate && (
          <>
            <ArrowForward className={classes.arrow} />

            <div className={classes.dateContainer}>
              <DatePicker
                label="End Date"
                value={props.endDate}
                onChange={(newValue: Date | null) =>
                  newValue != null && props.setEndDate(newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      props.startDate !== undefined &&
                      props.endDate !== undefined &&
                      props.startDate > props.endDate &&
                      "End date must be after start date"
                    }
                  />
                )}
                className={classes.datePicker}
                minDate={props.startDate}
                inputFormat="dd/MM/yyyy"
              />
            </div>
          </>
        )}
        <FormControlLabel
          control={
            <Switch
              checked={props.indefiniteEndDate}
              onChange={() =>
                props.setIndefiniteEndDate(!props.indefiniteEndDate)
              }
              color="secondary"
            />
          }
          label="Continue Indefinitely"
          className={classes.continueIndefinitely}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DateSelect;
