import {
  Card,
  CardContent,
  Typography,
  Container,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { QuestionnaireVersionViewModel } from "../../../types/auto/types";
import { Publish, Delete, Edit, Preview, Print } from "@mui/icons-material";
import { CheckIsMobile } from "../../utils/MobileStatus";
import moment from "moment";
import { Configuration } from "../../Constants";
import QuestionnaireVersionDeleteModal from "./QuestionnaireVersionDeleteModal";
import QuestionnaireVersionPublishModal from "./QuestionnaireVersionPublishModal";
import React from "react";

interface Props {
  questionnaireVersion: QuestionnaireVersionViewModel;
  resetQuestionnaireVersions: () => void;
  editQuestionnaire: (qId: number, v: number) => void;
  printingTemplateEnabled: boolean;
}

const QuestionnaireVersionCard = (props: Props): JSX.Element => {
  function previewQuestionnaire() {
    window.location.href =
      Configuration.SITEBASE +
      "/previewQuestionnaire?qId=" +
      props.questionnaireVersion.questionnaireId +
      "&v=" +
      props.questionnaireVersion.version;
  }

  function customPrintingTemplate() {
    window.location.href =
      Configuration.SITEBASE +
      "/printingTemplate?qId=" +
      props.questionnaireVersion.questionnaireId +
      "&v=" +
      props.questionnaireVersion.version;
  }

  const [versionDeleteModalOpen, setVersionDeleteModalOpen] =
    React.useState(false);
  const [versionPublishModalOpen, setVersionPublishModalOpen] =
    React.useState(false);

  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    cardContent: {
      display: isMobile ? "block" : "flex",
      padding: "6px",
      paddingLeft: "12px",
      "&:last-child": {
        paddingBottom: 5,
      },
    },
    publishedContainer: {
      textAlign: "right",
      width: "100%",
      paddingTop: "5px",
    },
    buttonContainer: {
      display: "flex",
      padding: 0,
      width: isMobile ? "" : "fit-content",
      marginRight: isMobile ? "" : 0,
    },
    publishButton: {
      marginLeft: isMobile ? "" : "auto",
      width: isMobile ? "33%" : "",
    },
    editButton: {
      marginLeft: "10px",
      width: isMobile ? "33%" : "",
    },
    deleteButton: {
      backgroundColor: "red",
      "&:hover": {
        backgroundColor: "crimson",
      },
      marginLeft: "10px",
      width: isMobile ? "33%" : "",
    },
    previewButton: {
      marginLeft: "10px",
      width: isMobile ? "33%" : "",
    },
    printingTemplateButton: {
      marginLeft: "10px",
      width: isMobile ? "33%" : "",
    },
  });
  const classes = useStyle();
  return (
    <Card variant="outlined" elevation={0}>
      <CardContent className={classes.cardContent}>
        <Typography paddingTop={"5px"} fontSize={"20px"}>
          {props.questionnaireVersion.version}
        </Typography>
        <Button
          className={classes.previewButton}
          aria-label="Preview questionnaire version"
          variant="contained"
          onClick={previewQuestionnaire}
        >
          <Preview />
        </Button>
        {props.printingTemplateEnabled && (
          <Button
            className={classes.printingTemplateButton}
            aria-label="View printing template"
            variant="contained"
            onClick={() => customPrintingTemplate()}
          >
            <Print />
          </Button>
        )}
        {props.questionnaireVersion.published != null && (
          <Typography className={classes.publishedContainer}>
            {`Published on ${moment(
              props.questionnaireVersion.published
            ).format("DD/MM/YYYY hh:mm A")}`}
          </Typography>
        )}
        {props.questionnaireVersion.published == null && (
          <Container className={classes.buttonContainer} disableGutters>
            <Button
              className={classes.publishButton}
              aria-label="Publish questionnaire version"
              variant="contained"
              onClick={() => {
                setVersionPublishModalOpen(true);
              }}
            >
              <Publish />
            </Button>
            <Button
              className={classes.editButton}
              aria-label="Edit questionnaire version"
              variant="contained"
              onClick={() => {
                props.editQuestionnaire(
                  props.questionnaireVersion.questionnaireId,
                  props.questionnaireVersion.version
                );
              }}
            >
              <Edit />
            </Button>
            <Button
              className={classes.deleteButton}
              aria-label="Delete questionnaire version"
              variant="contained"
              onClick={() => {
                setVersionDeleteModalOpen(true);
              }}
            >
              <Delete />
            </Button>
          </Container>
        )}
      </CardContent>
      <QuestionnaireVersionDeleteModal
        questionnaireVersionToDelete={props.questionnaireVersion}
        open={versionDeleteModalOpen}
        closeModal={() => setVersionDeleteModalOpen(false)}
        refresh={() => props.resetQuestionnaireVersions()}
      />
      <QuestionnaireVersionPublishModal
        questionnaireVersionToEdit={props.questionnaireVersion}
        open={versionPublishModalOpen}
        closeModal={() => setVersionPublishModalOpen(false)}
        refresh={() => props.resetQuestionnaireVersions()}
      />
    </Card>
  );
};

export default QuestionnaireVersionCard;
