import React, { useEffect, useState } from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryChart,
  VictoryZoomContainer,
} from "victory";
import GraphLoading from "../../../elements/graph/GraphLoading";
import GraphError from "../../../elements/graph/GraphError";
import { useMountedState } from "react-use";
import { GetXAxisDomain } from "../ChartHelper";
import GraphTickLabel from "../GraphTickLabel";
import { DataScatter, GroupedLine } from "./MedicationLineGraphCoreHelper";
import MobileZoomable from "./MobileZoomable";
import { CheckIsTablet } from "../../../../utils/MobileStatus";
import GraphMedicationLegend, {
  ExtendedMedicationViewModel,
} from "../../../elements/graph/GraphMedicationLegend";

interface Props {
  data: ExtendedMedicationViewModel[];
  setData?: React.Dispatch<React.SetStateAction<ExtendedMedicationViewModel[]>>;
  loading: boolean;
  error: boolean;
  width: number;
  height: number;
  fromDate: Date | undefined;
  toDate: Date | undefined;
  yDomain?: DomainTuple;
}

const MedicationLineGraphCore = (props: Props): JSX.Element => {
  const isMobile = CheckIsTablet();
  const [xAxisValues, setXAxisValues] = useState<number[]>([]);
  const isMountedState = useMountedState();
  const [zoomDomain, setZoomDomain] = useState<{
    x?: DomainTuple;
    y?: DomainTuple;
  }>();
  const [mobileSelectedDomain, setMobileSelectedDomain] = useState<{
    x?: DomainTuple;
    y?: DomainTuple;
  }>();
  useEffect(() => {
    if (props.data && isMountedState()) {
      setXAxisValues(GetXAxisDomain(props.fromDate, props.toDate, 1));
    }
  }, [
    props.data,
    props.data.length,
    isMountedState,
    props.fromDate,
    props.toDate,
  ]);
  return (
    <>
      {props.loading ? (
        <GraphLoading
          width={props.width}
          height={props.height}
          type="medication"
        />
      ) : props.error ? (
        <GraphError width={props.width} height={props.height} />
      ) : (
        <>
          <VictoryChart
            domainPadding={{ x: 40, y: 40 }}
            padding={{ left: 120, top: 50, right: 50, bottom: 55 }}
            domain={{
              x:
                xAxisValues.length > 1
                  ? [xAxisValues[0], xAxisValues[xAxisValues.length - 1]]
                  : undefined,
            }}
            width={props.width}
            height={props.height}
            aria-label={"Medication Graph"}
            containerComponent={
              <VictoryZoomContainer
                zoomDimension="x"
                containerId="chart"
                height={props.height}
                onZoomDomainChange={(s) => {
                  setMobileSelectedDomain(s);
                }}
                zoomDomain={zoomDomain}
                allowZoom={!isMobile}
              />
            }
            animate={
              props.data.filter((x) => !x.hidden).length > 1 // Don't show animations for single lines, as it breaks the graph when hiding / showing the line
                ? { duration: 1000, easing: "linear" }
                : undefined
            }
          >
            <VictoryAxis
              dependentAxis
              style={{
                grid: { stroke: "lightgray", strokeWidth: 0.5 },
                tickLabels: { padding: 5 },
              }}
              crossAxis={false}
              tickLabelComponent={
                <GraphTickLabel
                  chartTitle={
                    props.data && props.data.length > 0
                      ? props.data[0].lineName || ""
                      : ""
                  }
                  style={{
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                />
              }
            />
            <VictoryAxis
              style={{
                grid: {
                  stroke: "lightgray",
                  strokeWidth: 0.5,
                },
                tickLabels: { angle: -5, padding: 20 },
                axisLabel: { padding: 35 },
              }}
              label="Date"
              tickValues={xAxisValues}
              tickFormat={(t: number) => {
                return new Date(t).toLocaleDateString("en-GB");
              }}
              fixLabelOverlap
            />
            {GroupedLine(props.data, "")}
            {DataScatter(props.data, "")}
          </VictoryChart>
          {/* Add a second chart on mobile to enable zooming, as the zoom container is bugged on mobile */}
          {isMobile && (
            <MobileZoomable
              width={props.width}
              mobileSelectedDomain={mobileSelectedDomain}
              setZoomDomain={setZoomDomain}
              xAxisValues={xAxisValues}
              data={props.data}
            />
          )}
          {props.setData && (
            <GraphMedicationLegend data={props.data} setData={props.setData} />
          )}
        </>
      )}
    </>
  );
};

export default MedicationLineGraphCore;
