import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useMountedState, useUnmountPromise } from "react-use";
import {
  PatientNoteClient,
  PatientNoteViewModel,
  PatientViewModel,
  Role,
  UserViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import CreateEditPatientNote from "./CreateEditPatientNote";
import PatientNote from "./PatientNote";

interface Props {
  patient: PatientViewModel;
  user: UserViewModel;
}
const ViewPatientNotes = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    cardContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: 0,
      padding: 0,
    },
    mainContainer: {
      padding: 0,
      marginTop: "10px",
    },
  }));
  const classes = useStyle();
  const [patientNotes, setPatientNotes] = useState<PatientNoteViewModel[]>([]);
  const [rerender, setRerender] = useState(false);
  const isMountedState = useMountedState();
  const resolveWhileMounted = useUnmountPromise();

  var isStaff =
    props.user.role === Role.SuperAdmin ||
    props.user.role === Role.Admin ||
    props.user.role === Role.Clinician;

  useEffect(() => {
    if (isMountedState()) {
      const effect = async () => {
        if (isStaff) {
          await resolveWhileMounted(
            new PatientNoteClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getPatientNotes(props.patient.id)
          ).then((x) => {
            setPatientNotes(x);
          });
        } else {
          await resolveWhileMounted(
            new PatientNoteClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getCurrentPatientNotes()
          ).then((x) => {
            setPatientNotes(x);
          });
        }
      };
      effect();
    }
  }, [props.patient, rerender, isMountedState, isStaff, resolveWhileMounted]);
  return (
    <Container className={classes.mainContainer}>
      <Typography variant="h5" component="h2">
        Notes
      </Typography>
      <CreateEditPatientNote
        patient={props.patient}
        onSave={() => setRerender((x) => !x)}
      />
      <Container className={classes.cardContainer}>
        {" "}
        {patientNotes.map((x, key) => (
          <PatientNote
            patientNote={x}
            key={key}
            patient={props.patient}
            reload={() => setRerender((x) => !x)}
          />
        ))}
        {patientNotes.length === 0 && (
          <Typography>There are no patient notes.</Typography>
        )}
      </Container>
    </Container>
  );
};

export default ViewPatientNotes;
