import React, { createContext, useReducer } from "react";
import { UserViewModel } from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { StorageAccess } from "../../../utils/LocalStorage";

interface IProps {
  children?: JSX.Element;
}

const UserStore = ({ children }: IProps): React.ReactElement => {
  const CURRENTUSER_KEY = Configuration.CURRENTUSERKEY;
  const getCurrentUser = (): UserViewModel => {
    const store = StorageAccess.userStorage;
    const user: string | undefined = store?.fetch(CURRENTUSER_KEY) ?? undefined;

    if (user) {
      return JSON.parse(user) as UserViewModel;
    }

    return new UserViewModel();
  };

  const reducer = (
    currentUser: UserViewModel,
    newUser: UserViewModel
  ): UserViewModel => {
    const store = StorageAccess.userStorage;
    store?.set(CURRENTUSER_KEY, JSON.stringify(newUser));

    return newUser;
  };

  const [user, dispatchUser] = useReducer(reducer, getCurrentUser());

  return (
    <UserDispatchContext.Provider value={dispatchUser}>
      <UserContext.Provider value={user}>{children}</UserContext.Provider>
    </UserDispatchContext.Provider>
  );
};

/** Allows the User state to be updated. */
export const UserDispatchContext = createContext(
  (() => 0) as React.Dispatch<UserViewModel>
);

/** Returns the current user state. */
export const UserContext = createContext(new UserViewModel());

export default UserStore;
