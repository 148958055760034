import { MenuList, MenuItem, Theme, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { theme as customTheme } from "../../../../Theme";
import PatientNavDrawer from "./PatientNavDrawer";
import {
  CheckIsMobile,
  CheckIsTablet,
  CheckIsSmallDevice,
} from "../../../utils/MobileStatus";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Role, UserViewModel, PatientTab } from "../../../../types/auto/types";

export interface PatientTabOption {
  tab: PatientTab;
  enabled: boolean;
}

interface Props {
  tab: PatientTab;
  setTab: React.Dispatch<React.SetStateAction<PatientTab>>;
  enabledTabs?: PatientTab[];
  medicationSupported: boolean;
  notesSupported: boolean;
  user: UserViewModel;
  externalDatasetSupported: boolean;
  dashboardSupported: boolean;
  getContainerWidth: () => number;
  getNameWidth: () => number;
}

const PatientNavBar = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isTablet = CheckIsTablet();
  const isSmallDevice = CheckIsSmallDevice();
  const menuRef = useRef<HTMLUListElement>(null);
  const compressedMenuRef = useRef<HTMLUListElement>(null);
  const uncompressedWidth = useRef<number>();
  const [compress, setCompress] = useState(false);
  const useStyle = makeStyles((th: Theme) => ({
    buttonToolbar: {
      display: "flex",
      flexDirection: "row",
      padding: 0,
      boxShadow: "none",
      borderLeft:
        isMobile || isTablet || compress ? "none" : "1px solid #c5c5c5",
      "& .Mui-selected": {
        backgroundColor: "white",
        fontWeight: "bold",
        borderRadius: "4px",
        borderBottom: "2px solid " + th.palette.secondary.main,
        "&:hover": {
          backgroundColor: "white",
        },
      },
    },
    menuIcon: {
      color: th.palette.primary.main,
    },

    menuItemWithBorder: {
      border: "2px solid transparent",
      "&:hover": {
        borderBottom: "2px solid " + th.palette.secondary.main,
        backgroundColor: "transparent",
      },
      borderRight: "1px solid #c5c5c5",
    },
    menuItem: {
      border: "2px solid transparent",
      "&:hover": {
        borderBottom: "2px solid " + th.palette.secondary.main,
        backgroundColor: "transparent",
      },
    },
    menuMobileButton: {
      width: isSmallDevice ? "100px" : "fit-content",
    },
    menuButtonSpan: {
      overflow: isSmallDevice ? "hidden" : "auto",
      textOverflow: isSmallDevice ? "ellipsis" : "initial",
      whiteSpace: "nowrap",
    },
  }));
  const classes = useStyle(customTheme);
  const [navDrawAnchor, setNavDrawAnchor] = useState<null | HTMLElement>(null);
  const isStaff =
    props.user.role === Role.SuperAdmin ||
    props.user.role === Role.Admin ||
    props.user.role === Role.Clinician;

  const handleClickNavDrawer = (event: React.MouseEvent<HTMLElement>) => {
    if (navDrawAnchor != null) {
      setNavDrawAnchor(null);
    } else {
      setNavDrawAnchor(event.currentTarget);
    }
  };

  const checkMedicationTab = (x: string): boolean =>
    PatientTab[x as keyof typeof PatientTab] !== PatientTab.Medication ||
    props.medicationSupported;

  const checkNotesTab = (x: string): boolean =>
    PatientTab[x as keyof typeof PatientTab] !== PatientTab.Notes ||
    props.notesSupported;

  const checkDatasetTab = (x: string): boolean =>
    PatientTab[x as keyof typeof PatientTab] !== PatientTab.Datasets ||
    (props.externalDatasetSupported && isStaff);

  const detailsTab = (x: string): boolean =>
    PatientTab[x as keyof typeof PatientTab] !== PatientTab.Details || isStaff;

  const dashboardTab = (x: string): boolean =>
    PatientTab[x as keyof typeof PatientTab] !== PatientTab.Dashboard ||
    (!isStaff && props.dashboardSupported);

  const scheduleTab = (x: string): boolean =>
    PatientTab[x as keyof typeof PatientTab] !== PatientTab.Schedule || isStaff;

  const checkTab = (x: string): boolean =>
    props.enabledTabs !== undefined
      ? props.enabledTabs.find(
          (y: PatientTab) => y === PatientTab[x as keyof typeof PatientTab]
        ) !== undefined
      : true;

  var navItems = Object.keys(PatientTab)
    .filter(
      (x) =>
        isNaN(Number(x)) &&
        checkMedicationTab(x) &&
        checkTab(x) &&
        checkNotesTab(x) &&
        detailsTab(x) &&
        dashboardTab(x) &&
        scheduleTab(x) &&
        checkDatasetTab(x)
    )

    .map((x) => {
      const enumVal = PatientTab[x as keyof typeof PatientTab];
      return (
        <MenuItem
          key={x}
          onClick={() => {
            props.setTab(enumVal);
            setNavDrawAnchor(null);
          }}
          role="menuitem"
          aria-label={x}
          tabIndex={0}
          selected={props.tab === enumVal}
          className={
            isMobile || enumVal + 1 === Object.keys(PatientTab).length / 2
              ? classes.menuItem
              : classes.menuItemWithBorder
          }
        >
          {x}
        </MenuItem>
      );
    });

  useEffect(() => {
    const compressMenu = () => {
      var menuWidth = menuRef.current?.clientWidth ?? 0;
      var nameWidth = props.getNameWidth();
      var containerWidth = props.getContainerWidth();

      if (menuRef.current && menuWidth + nameWidth > containerWidth) {
        // only compresses if the menu plus name is larger than the container - it won't fit
        uncompressedWidth.current = menuWidth + nameWidth;
        setCompress(true);
      } else if (
        uncompressedWidth.current &&
        uncompressedWidth.current < containerWidth
      ) {
        // uncompress if the original menu plus name is less than the container - it will fit
        setCompress(false);
      }
    };

    compressMenu();
    window.addEventListener("resize", compressMenu);
    return () => {
      window.removeEventListener("resize", compressMenu);
    };
  }, [props]);

  return (
    <>
      {!compress && !isMobile && !isTablet && (
        <MenuList className={classes.buttonToolbar} role="menu" ref={menuRef}>
          {navItems}
        </MenuList>
      )}
      {(isMobile || isTablet || compress) && (
        <>
          <MenuList
            className={classes.buttonToolbar}
            role="menu"
            ref={compressedMenuRef}
          >
            <Button
              onClick={handleClickNavDrawer}
              aria-label="patient nav bar"
              sx={{ paddding: 0 }}
              className={classes.menuMobileButton}
              role="menuitem"
            >
              {navDrawAnchor !== null ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
              <span className={classes.menuButtonSpan}>
                {PatientTab[props.tab] + " "}
              </span>
            </Button>
          </MenuList>
          <PatientNavDrawer
            anchor={navDrawAnchor}
            onClose={() => setNavDrawAnchor(null)}
            navItems={navItems}
          />
        </>
      )}
    </>
  );
};

export default PatientNavBar;
