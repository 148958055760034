import { Card, CardContent, Container, Theme, Typography } from "@mui/material";
import {
  PatientViewModel,
  PatientTimelineDataViewModel,
  UserViewModel,
  Frequency,
} from "../../../../../types/auto/types";
import { makeStyles } from "@mui/styles";
import { CheckIsMobile } from "../../../../utils/MobileStatus";
import { theme } from "../../../../../Theme";
import moment from "moment";
import MedicationIcon from "@mui/icons-material/Medication";

interface Props {
  timelineEntree: PatientTimelineDataViewModel;
  index: number;
  patient: PatientViewModel;
  user: UserViewModel;
  currentTimelineLength: number;
  totalTimelineLength: number;
  totalTimelineLengthFiltered: number;
  setHiddenArrayIndex: () => void;
  isLastItem: boolean;
}
export const MedicationTimelineCard = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles((th: Theme) => ({
    headerAndActions: {
      paddingRight: "0",
      paddingLeft: "0",
    },
    headingText: {
      fontWeight: "bold",
    },
    cardContent: {
      padding: 8,
    },
    card: {
      marginBottom: "10px",
      marginTop: props.index === 0 && isMobile ? 0 : "10px",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
    },
    patientMedicationNote: {
      whiteSpace: "pre-wrap",
      paddingLeft: "4px",
    },
    dates: {
      paddingLeft: "4px",
    },
  }));
  const classes = useStyle(theme);

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Container className={classes.headerAndActions}>
          <Typography className={classes.headingText}>
            <MedicationIcon sx={{ padding: 0 }} />
            {props.timelineEntree.medicationName}{" "}
            {props.timelineEntree.dose !== null
              ? (props.timelineEntree.dose ?? "") +
                (props.timelineEntree.units ?? "")
              : ""}
            {props.timelineEntree.frequency !== null
              ? " - every " +
                props.timelineEntree.frequencyAmount +
                " " +
                Frequency[props.timelineEntree.frequency ?? 0]
              : ""}
          </Typography>
          <Typography className={classes.dates}>
            From {moment(props.timelineEntree.startDate).format("DD-MM-YYYY")}
            {props.timelineEntree.endDate
              ? " to " +
                moment(props.timelineEntree.endDate).format("DD-MM-YYYY")
              : " - Ongoing"}
          </Typography>
          <Typography className={classes.patientMedicationNote}>
            {props.timelineEntree.notes ? <b>Notes: </b> : null}
            {props.timelineEntree.notes}
          </Typography>
        </Container>
      </CardContent>
    </Card>
  );
};
