import React from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryScatter,
} from "victory";
import { SymptomsDataPointViewModel } from "../../../../../types/auto/types";
import { ExtendedBubbleViewModel } from "./SymptomsChart";

interface Props {
  width: number;
  mobileSelectedDomain:
    | {
        x?: DomainTuple | undefined;
        y?: DomainTuple | undefined;
      }
    | undefined;
  setZoomDomain: React.Dispatch<
    React.SetStateAction<
      | {
          x?: DomainTuple | undefined;
          y?: DomainTuple | undefined;
        }
      | undefined
    >
  >;
  xAxisValues: number[];
  data: ExtendedBubbleViewModel;
}

const MobileZoomable = (props: Props): JSX.Element => (
  <VictoryChart
    padding={{ top: 0, bottom: 30, left: 100, right: 50 }}
    width={props.width}
    height={90}
    containerComponent={
      <VictoryBrushContainer
        brushDimension="x"
        brushDomain={props.mobileSelectedDomain}
        onBrushDomainChange={(b) => props.setZoomDomain(b)}
        style={{ margin: "auto" }}
        height={90}
      />
    }
    aria-label={"Symptoms Graph For " + props.data.chartName + " Mobile Zoom"}
  >
    <VictoryAxis
      style={{
        grid: { stroke: "lightgray", strokeWidth: 0.5 },
      }}
      tickValues={props.xAxisValues}
      tickFormat={(t: number) => {
        return new Date(t).toLocaleDateString("en-GB");
      }}
      fixLabelOverlap
    />
    <VictoryScatter
      style={{
        data: {
          fill: (d) =>
            d.datum.score === 0
              ? "rgba(0, 0, 0, 0)"
              : d.datum.score < 0
              ? "gray"
              : props.data.colour,
          opacity: "80%",
        },
      }}
      data={props.data.data?.map((x) => {
        return {
          x: x.x,
          y: x.y?.replace(" ", " \n"),
          size: x.size / 100.0,
          score: x.score,
          totalScore: x.totalScore,
        } as SymptomsDataPointViewModel;
      })}
    />
  </VictoryChart>
);

export default MobileZoomable;
