import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  ScoringRuleDataModel,
  ScoringTransformationDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import nameof from "../../../utils/NameOf";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import { ScoringRuleOperator } from "../../../../types/auto/types";
import RulesArrayBuilder from "./RulesArrayBuilder";

interface Props {
  open: boolean;
  handleCancel: () => void;
  handleUpdate: (
    transformation: ScoringTransformationDataModel,
    arrayString: string
  ) => void;
  transformationRule: ScoringTransformationDataModel;
}

const TransformationAddModal = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    scoringContainer: {
      margin: 0,
      paddingTop: 10,
      paddingRight: 0,
      paddingLeft: 0,
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      flexDirection: "column",
      gap: 15,
    },
    checkbox: {
      width: "100%",
      justifyContent: "flex-start",
    },
    textField: {
      width: "100%",
    },
    textFieldContainer: {
      display: "flex",
      flexDirection: "row",
      gap: 10,
      width: "100%",
      marginBottom: 15,
      justifyContent: "space-between",
    },
    checkboxes: {
      display: "flex",
      flexDirection: "row",
      gap: 10,
      width: "100%",
      marginBottom: 15,
      justifyContent: "flex-start",
    },
    infoContainer: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: 10,
    },
  });
  const classes = useStyle(theme);

  const [transformationRule, setTransformationRule] =
    useState<ScoringTransformationDataModel>(props.transformationRule);
  const [arrayString, setArrayString] = useState<string>(
    props.transformationRule?.transformation
      ? props.transformationRule?.transformation.join(",")
      : ""
  );
  const [rows, setRows] = useState<JSX.Element[]>([]);
  const [inputError, setInputError] = useState<boolean>(false);

  useEffect(() => {
    setTransformationRule(props.transformationRule);
    setArrayString(
      props.transformationRule?.transformation
        ? props.transformationRule?.transformation.join(",")
        : ""
    );
  }, [props.transformationRule]);

  function handleLocalChange(field: string, value: any, index?: number) {
    if (index != null) {
      const newArr = transformationRule.rules!.map((v, i) => {
        if (i === index) {
          return { ...v, [field]: value };
        } else {
          return v;
        }
      });
      setTransformationRule({
        ...transformationRule,
        [nameof<ScoringTransformationDataModel>("rules")]: newArr,
      });
    } else if (Array.isArray(value)) {
      setTransformationRule({
        ...transformationRule,
        [field]: value,
      });
    } else {
      setTransformationRule({
        ...transformationRule,
        [field]: value,
      });
    }
  }

  function loadRows() {
    const newRows = transformationRule.rules
      ? transformationRule.rules.map((rule, index) => {
          return (
            <Box
              key={"container-" + index}
              display={"flex"}
              flexWrap={"nowrap"}
              width={"100%"}
              paddingTop={1}
              gap={1}
            >
              <TextField
                key={"question-" + index}
                value={rule.question ?? ""}
                onChange={(e) =>
                  handleLocalChange(
                    nameof<ScoringRuleDataModel>("question"),
                    Number(e.target.value),
                    index
                  )
                }
                fullWidth
                type="number"
                label="Question"
              ></TextField>
              <TextField
                key={"answer-" + index}
                value={rule.answer ?? ""}
                onChange={(e) =>
                  handleLocalChange(
                    nameof<ScoringRuleDataModel>("answer"),
                    e.target.value,
                    index
                  )
                }
                fullWidth
                type="number"
                label="Answer"
              ></TextField>
            </Box>
          );
        })
      : [];
    setRows(newRows);
  }

  function handleCountChange(c: number) {
    if (c > rows.length) {
      var newArr = transformationRule.rules
        ? [...transformationRule.rules]
        : [];
      for (let i = rows.length; i < c; i++) {
        newArr = [
          ...newArr,
          {
            question: undefined,
            answer: undefined,
            operator: undefined,
          },
        ];
      }
      setTransformationRule({
        ...transformationRule,
        [nameof<ScoringTransformationDataModel>("rules")]: newArr,
      });
    } else {
      if (c > 0) {
        const newArr = transformationRule.rules!.slice(0, c);
        setTransformationRule({
          ...transformationRule,
          [nameof<ScoringTransformationDataModel>("rules")]: newArr,
        });
      } else {
        setTransformationRule({
          ...transformationRule,
          [nameof<ScoringTransformationDataModel>("rules")]: undefined,
        });
      }
    }
  }

  function validateInputs() {
    const regex = new RegExp("^[,0-9]+$");
    if (!regex.test(arrayString)) {
      setInputError(true);
      return false;
    }
    return true;
  }

  useEffect(
    () => {
      loadRows();
    },
    // eslint-disable-next-line
    [transformationRule.rules]
  );

  return (
    <Dialog
      open={props.open}
      maxWidth="lg"
      fullWidth
      key={"transformation-add-modal"}
    >
      <DialogTitle key={"transformation-add-modal-title"}>
        Transformation Rules
      </DialogTitle>
      <DialogContent key={"transformation-add-modal-content"}>
        <Box
          className={classes.scoringContainer}
          key={"transformation-add-modal-container"}
        >
          <Box
            className={classes.infoContainer}
            key={"transformation-container"}
          >
            <TextField
              label="Transformation Array"
              className={classes.textField}
              onChange={(e) => {
                setArrayString(e.target.value);
                setInputError(false);
              }}
              value={arrayString ?? ""}
              sx={{ width: "100%" }}
              error={inputError}
              helperText={
                inputError ? "Please follow the described format." : ""
              }
              key={"transformation-array-field"}
              multiline
            />
            <Alert severity="info" key={"transformation-array-info"}>
              Please enter the transformation array as comma separated numbers,
              e.g. 1,2,3,4
            </Alert>
          </Box>
          <Box
            className={classes.textFieldContainer}
            key={"override-container"}
          >
            <TextField
              label="Borderline Override"
              className={classes.textField}
              onChange={(e) =>
                handleLocalChange(
                  nameof<ScoringTransformationDataModel>("borderlineOverride"),
                  e.target.value === "" ? undefined : Number(e.target.value)
                )
              }
              value={transformationRule.borderlineOverride ?? ""}
              sx={{ width: "100%" }}
              key={"borderline-override-field"}
              type="number"
            />

            <TextField
              label="Clinical Override"
              className={classes.textField}
              onChange={(e) =>
                handleLocalChange(
                  nameof<ScoringTransformationDataModel>("clinicalOverride"),
                  e.target.value === "" ? undefined : Number(e.target.value)
                )
              }
              value={transformationRule.clinicalOverride ?? ""}
              sx={{ width: "100%" }}
              key={"clinical-override-field"}
              type="number"
            />
          </Box>
          <Box
            className={classes.textFieldContainer}
            key={"tickbox-dropdown-container"}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) =>
                    handleLocalChange(
                      nameof<ScoringTransformationDataModel>("hideGraph"),
                      e.target.checked
                    )
                  }
                  checked={transformationRule.hideGraph ?? false}
                  key={"hide-graph"}
                />
              }
              label="Hide Timeline Graph?"
              className={classes.checkbox}
              key={"hide-graph-label"}
            />

            <FormControl
              sx={{ width: "100%" }}
              key={"main-rule-operator-select-fromcontrol"}
            >
              <InputLabel
                id="main-rule-operator-label"
                key={"main-rule-operator-select-inputlabel"}
              >
                Operator
              </InputLabel>
              <Select
                labelId="main-rule-operator-label"
                id="main-rule-operator-select"
                key={"main-rule-operator-select"}
                label="Operator"
                onChange={(e) =>
                  handleLocalChange(
                    nameof<ScoringTransformationDataModel>("operator"),
                    e.target.value
                  )
                }
                value={transformationRule.operator ?? ""}
              >
                <MenuItem
                  key={"main-rule-operator-and"}
                  value={ScoringRuleOperator.And}
                >
                  And
                </MenuItem>
                <MenuItem
                  key={"main-rule-operator-or"}
                  value={ScoringRuleOperator.Or}
                >
                  Or
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <RulesArrayBuilder
            rules={transformationRule.rules}
            rows={rows}
            setRowCount={(c) => handleCountChange(c)}
          />
        </Box>
      </DialogContent>
      <DialogActions key={"transformation-add-modal-actions"}>
        <Button
          onClick={() => {
            props.handleCancel();
          }}
          key={"transformation-add-modal-close"}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            if (validateInputs()) {
              props.handleUpdate(transformationRule, arrayString);
            } else {
              setInputError(true);
            }
          }}
          key={"transformation-add-modal-submit"}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransformationAddModal;
