import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { TableQuestionType } from "../../../../types/auto/types";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MultiSelectorTableEditModal from "../questions/MultiSelectorTableEditModal";
import {
  AnswerDataModel,
  QuestionDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import BuilderMultiSelectorTableRow from "./BuilderMultiSelectorTableRow";

interface Props {
  question: QuestionDataModel;
}

interface DataModel {
  [key: string]: any;
}

const BuilderMultiSelectorTable = (props: Props): JSX.Element => {
  const [headers, setHeaders] = useState<JSX.Element[]>([]);
  const [rows, setRows] = useState<JSX.Element[]>([]);
  const [formFields, setFormFields] = useState<JSX.Element[]>([]);
  const [addDataModalOpen, setAddDataModalOpen] = useState<boolean>(false);
  const [dataModel, setDataModel] = useState<DataModel>({});
  const [reloadRows, setReloadRows] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [responseString, setResponseString] = useState<string>("[]");

  // create the table headers based on the questionnaire headers
  function createHeaders(headers: string[] | undefined) {
    const localHeaders: JSX.Element[] =
      headers != null
        ? headers?.map((header) => (
            <TableCell style={{ fontWeight: "bold" }} key={"header-" + header}>
              {header}
            </TableCell>
          ))
        : [];
    localHeaders.push(<TableCell key={"space-for-edit-button"} />);
    localHeaders.push(<TableCell key={"space-for-delete-button"} />);
    setHeaders(localHeaders);
    setReloadRows((x) => !x);
  }

  function getNewRowKey() {
    var responseString = getResponseString();
    var currResponses: DataModel[] = JSON.parse(responseString);
    var latest: DataModel | undefined = currResponses.pop();
    if (latest == null) {
      return 0;
    } else {
      return (latest["RowId"] as number) + 1;
    }
  }

  // dataModel will be an empty object, so need to add each prop. This is called before the add modal is opened so the order of the props is the same each time.
  async function createDataModel(answers: AnswerDataModel[] | undefined) {
    setDataModel((existingProps) => ({
      ...existingProps,
      // eslint-disable-next-line
      ["RowId"]: getNewRowKey(),
    }));
    answers?.forEach((answer) => {
      if (answer.text != null) {
        const key = answer.text;
        setDataModel((existingProps) => ({
          ...existingProps,
          [key]: "",
        }));
      }
    });
  }

  const handleFormDateChange = (value: string, label: string | undefined) => {
    if (label != null) {
      var dateVal = new Date(value).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      if (isNaN(new Date(value).getDate())) {
        dateVal = "";
      }
      setDataModel((prev) => ({
        ...prev,
        [label]: dateVal,
      }));
    }
  };

  // update each prop depending on the form field.
  const handleFormChange = (
    event?: any | null,
    type?: TableQuestionType | null,
    label?: string | null
  ) => {
    if (label != null && TableQuestionType.Dropdown) {
      setDataModel((prev) => ({
        ...prev,
        [label]: event.target.value,
      }));
    } else if (event != null && type === TableQuestionType.Checkbox) {
      setDataModel((prev) => ({
        ...prev,
        [event.target.id]: event.target.checked,
      }));
    } else if (event != null && type === TableQuestionType.Textbox) {
      setDataModel((prev) => ({
        ...prev,
        [event.target.id]: event.target.value,
      }));
    } else if (event != null && type === TableQuestionType.Number) {
      setDataModel((prev) => ({
        ...prev,
        [event.target.id]: event.target.value as number,
      }));
    }
  };

  // take the dataModel created from the modal form and generate the row.
  function createRow(model: DataModel) {
    return (
      <BuilderMultiSelectorTableRow
        key={"row" + model["RowId"]}
        model={model}
        answers={props.question.answers}
        editResponseFromKey={(k) => {
          editResponseFromKey(k);
        }}
        removeResponseFromKey={(k) => {
          removeResponseFromKey(k);
        }}
      />
    );
  }

  function addRow(model: DataModel | undefined) {
    var localRowsData: DataModel[] = [];
    var responseString = getResponseString();

    localRowsData = JSON.parse(responseString ?? "[]");

    if (model != null) {
      localRowsData.push(model);
      createAnswerString(localRowsData);
      setDataModel({});
    }
  }

  // Load all rows based on answer string
  function loadRows(responses: string) {
    var localRowsData: DataModel[] = JSON.parse(responses);
    var localRows: JSX.Element[] = [];
    localRowsData.forEach((model) => {
      const row = createRow(model);
      localRows.push(row);
    });
    setRows(localRows);
    setTotalRows(localRows.length);
  }

  function createFormFields(answers: AnswerDataModel[] | undefined) {
    const localFields: JSX.Element[] = [];
    answers?.forEach((answer) => {
      switch (answer.tableType) {
        case TableQuestionType.Textbox: {
          localFields.push(
            <TextField
              sx={{
                fieldset: {
                  legend: {
                    width: "unset",
                  },
                },
              }}
              label={answer.text}
              id={answer.text}
              variant="outlined"
              placeholder={answer.text}
              value={getValue(answer.text, TableQuestionType.Textbox) ?? ""}
              multiline
              fullWidth
              type={"text"}
              key={"form-text-input-" + answer.text}
              onChange={(e) => {
                handleFormChange(e, TableQuestionType.Textbox);
              }}
              InputProps={{
                "aria-label": answer.text,
              }}
            />
          );
          break;
        }
        case TableQuestionType.Number: {
          localFields.push(
            <TextField
              type="number"
              sx={{
                fieldset: {
                  legend: {
                    width: "unset",
                  },
                },
              }}
              label={answer.text}
              id={answer.text}
              variant="outlined"
              placeholder={answer.text}
              fullWidth
              key={"form-number-input-" + answer.text}
              value={getValue(answer.text, TableQuestionType.Number) ?? ""}
              onChange={(e) => {
                handleFormChange(e, TableQuestionType.Number);
              }}
              InputProps={{
                "aria-label": answer.text,
                inputProps: {
                  min: answer.rangeMin,
                  max: answer.rangeMax,
                },
              }}
            />
          );
          break;
        }
        case TableQuestionType.Dropdown: {
          localFields.push(
            <FormControl key={"formcontrol-dropdown-" + answer.text}>
              <InputLabel
                id={"form-label-dropdown-" + answer.text}
                key={"form-label-dropdown-" + answer.text}
              >
                {answer.text}
              </InputLabel>
              <Select
                id={"form-dropdown-" + answer.text}
                variant="outlined"
                key={"form-dropdown-" + answer.text}
                label={answer.text}
                value={
                  getValue(answer.text, TableQuestionType.Dropdown) ?? "None"
                }
                onChange={(e) =>
                  handleFormChange(e, TableQuestionType.Dropdown, answer.text)
                }
                aria-label={answer.text + " dropdown"}
              >
                <MenuItem
                  key={"select-" + 0}
                  value={"None"}
                  aria-label={"select-None"}
                >
                  <em>None</em>
                </MenuItem>
                {answer.tableDropdownChoices?.map((val, index) => (
                  <MenuItem
                    key={"select-" + (index + 1)}
                    value={val}
                    role="menuitem"
                    aria-label={"select-" + val}
                  >
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
          break;
        }
        case TableQuestionType.Checkbox: {
          localFields.push(
            <FormControlLabel
              key={"form-control-label-checkbox-" + answer.text}
              control={
                <Checkbox
                  onChange={(e) =>
                    handleFormChange(e, TableQuestionType.Checkbox)
                  }
                  checked={
                    getValue(answer.text, TableQuestionType.Checkbox) ?? false
                  }
                  id={answer.text}
                  key={"form-checkbox-" + answer.text}
                />
              }
              label={answer.text}
              labelPlacement="start"
            />
          );
          break;
        }
        case TableQuestionType.Date: {
          localFields.push(
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              key={"form-localisation-provider-" + answer.text}
            >
              <DatePicker
                key={"form-date-picker-" + answer.text}
                label={answer.text}
                disableFuture={true}
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                onChange={(value) => {
                  handleFormDateChange(value, answer.text);
                }}
                value={getValue(answer.text, TableQuestionType.Date) ?? ""}
                PopperProps={{
                  placement: "bottom",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={false}
                    sx={{
                      fieldset: {
                        legend: {
                          width: "unset",
                        },
                      },
                    }}
                    key={"form-date-textfield-" + answer.text}
                  />
                )}
              />
            </LocalizationProvider>
          );
          break;
        }
        default:
          break;
      }
    });
    setFormFields(localFields);
  }

  function getResponseString() {
    return responseString;
  }

  // edit the responses inside the answer string using a key
  function createAnswerString(models: DataModel[]) {
    var jsonObjectString = JSON.stringify(models);

    setResponseString(jsonObjectString);

    setReloadRows((x) => !x);
  }

  function editAnswerString(model: DataModel | undefined) {
    if (model != null) {
      var responseString = getResponseString();
      var currResponses: DataModel[] = JSON.parse(responseString);
      currResponses[currResponses.findIndex((x) => x.RowId === model.RowId)] =
        model;
      var updatedString = JSON.stringify(currResponses);
      setResponseString(updatedString);
      setReloadRows((x) => !x);
      setEdit(false);
    }
  }

  // remove a specific dataModel instance from the answer string
  function removeResponseFromKey(key: string) {
    var responseString = getResponseString();
    var currResponses: DataModel[] = JSON.parse(responseString);
    var removeModel =
      currResponses[currResponses.findIndex((x) => x.RowId === key)];
    if (removeModel != null) {
      currResponses.splice(
        currResponses.findIndex((x) => x.RowId === removeModel.RowId),
        1
      );
      var updatedString = JSON.stringify(currResponses);
      setResponseString(updatedString);
      setReloadRows((x) => !x);
    }
  }

  // get all properties of a specific dataModel instance via the RowId
  function editResponseFromKey(key: string) {
    var responseString = getResponseString();
    var currResponses: DataModel[] = JSON.parse(responseString);
    var editModel =
      currResponses[currResponses.findIndex((x) => x.RowId === key)];
    setDataModel(editModel);
    createFormFields(props.question.answers);
    setEdit(true);
    setAddDataModalOpen(true);
  }

  function getValue(key: string | undefined, type: TableQuestionType) {
    if (key != null && dataModel[key] != null) {
      switch (type) {
        case TableQuestionType.Textbox: {
          return dataModel[key];
        }
        case TableQuestionType.Number: {
          return dataModel[key] as number;
        }
        case TableQuestionType.Dropdown: {
          if (dataModel[key] === "") {
            return "None";
          } else {
            return dataModel[key];
          }
        }
        case TableQuestionType.Checkbox: {
          if (dataModel[key] === "") {
            return false;
          } else {
            return dataModel[key] as boolean;
          }
        }
        case TableQuestionType.Date: {
          return dataModel[key];
        }
      }
    }
  }

  useEffect(
    () => {
      if (props.question.answers != null) {
        createHeaders(props.question.headers);
      }
    },
    // eslint-disable-next-line
    [props.question.headers]
  );

  useEffect(
    () => {
      const effect = () => {
        createFormFields(props.question.answers);
      };
      effect();
    }, // eslint-disable-next-line
    [dataModel]
  );

  useEffect(
    () => {
      const effect = () => {
        var responseString = getResponseString();
        if (responseString !== "" && responseString != null)
          loadRows(responseString);
      };
      effect();
    }, // eslint-disable-next-line
    [reloadRows, setReloadRows]
  );

  useEffect(() => {
    setResponseString("[]");

    setReloadRows((x) => !x);
  }, [props.question.answers]);

  return (
    <>
      <Box key={"multi-selector-table-box-" + props.question.number}>
        <TableContainer
          component={Paper}
          key={"multi-selector-table-container-" + props.question.number}
        >
          <Table
            sx={{ minWidth: 1000 }}
            aria-label="simple table"
            key={"multi-selector-table-" + props.question.number}
          >
            <TableHead
              key={"multi-selector-table-head-" + props.question.number}
            >
              <TableRow
                key={"multi-selector-table-head-row-" + props.question.number}
              >
                {headers}
              </TableRow>
            </TableHead>
            <TableBody
              key={"multi-selector-table-body-" + props.question.number}
            >
              {rows}
            </TableBody>
          </Table>
        </TableContainer>
        {totalRows < 10 && (
          <Button
            onClick={async () => {
              await createDataModel(props.question.answers);
              createFormFields(props.question.answers);
              setAddDataModalOpen(true);
            }}
            variant="contained"
            sx={{ marginTop: 2, marginBottom: 2 }}
            key={"multi-selector-table-add-button-" + props.question.number}
          >
            {"Add " +
              (props.question.rowObjectName == null ||
              props.question.rowObjectName === ""
                ? "Row"
                : props.question.rowObjectName)}
          </Button>
        )}
      </Box>
      <MultiSelectorTableEditModal
        open={addDataModalOpen}
        edit={edit}
        formFields={formFields}
        setModalOpen={(val) => {
          setAddDataModalOpen(val);
        }}
        handleCancel={() => {
          setAddDataModalOpen(false);
          setDataModel({});
          setEdit(false);
        }}
        handleAddRow={() => {
          addRow(dataModel);
        }}
        handleEditAnswerString={() => {
          editAnswerString(dataModel);
        }}
        dataModel={dataModel}
        objectName={
          props.question.rowObjectName == null ||
          props.question.rowObjectName === ""
            ? "Row"
            : props.question.rowObjectName
        }
        key={"multi-selector-table-modal-" + props.question.number}
      />
    </>
  );
};

export default BuilderMultiSelectorTable;
