import React from "react";
import { DoneAll, Flag } from "@mui/icons-material";

const CompletedGoalIcon = () => (
  <>
    <Flag />
    <DoneAll
      style={{
        fontSize: 15,
        marginTop: 20,
        marginLeft: -12,
      }}
    />
  </>
);

export default CompletedGoalIcon;
