import { useMediaQuery, useTheme } from "@mui/material";

export const CheckIsMobile = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm"));
};

export const CheckIsTablet = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("md"));
};

export const CheckIsSmallDevice = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(300));
};

export const CheckIsLargeTablet = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(
    theme.breakpoints.up("md") && theme.breakpoints.down(1000)
  );
};

export const CheckIsLargeDevice = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("lg"));
};

export const CheckIsSmallerThan = (width: number) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(width));
};

export function calculateGap(
  isMobile: boolean,
  isLargeDevice: boolean
): string {
  const windowWidth = window.screen.width;
  if (isMobile) {
    return "0px";
  } else if (isLargeDevice) {
    return "18px";
  } else {
    var gapWidth = ((windowWidth - 600) / 600) * 11 + 8;
    return gapWidth.toString() + "px";
  }
}
