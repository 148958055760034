import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { CaseloadViewModel } from "../../../types/auto/types";
import { CheckIsMobile } from "../../utils/MobileStatus";
import CreateEditCaseloadModal from "./CreateEditCaseloadModal";
import { Add, Edit } from "@mui/icons-material";

interface Props {
  caseload?: CaseloadViewModel;
  setRefresh: () => void;
}
const CreateEditCaseload = (props: Props) => {
  const [openCreateCaseloadModal, setOpenCreateCaseloadModal] =
    React.useState(false);
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles(() => ({
    button: {
      width: isMobile ? "100%" : "default",
      marginTop: isMobile && props.caseload !== undefined ? "10px" : 0,
    },
  }));
  const classes = useStyle();
  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpenCreateCaseloadModal(true)}
        className={classes.button}
        startIcon={props.caseload === undefined ? <Add /> : <Edit />}
      >
        {props.caseload === undefined ? "Create" : "Edit"} Caseload
      </Button>
      <CreateEditCaseloadModal
        open={openCreateCaseloadModal}
        setOpen={setOpenCreateCaseloadModal}
        caseload={props.caseload}
        refresh={props.setRefresh}
      />
    </>
  );
};

export default CreateEditCaseload;
