import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DependsOn } from "./DependsOnBuilder";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import { useEffect, useState } from "react";

interface Props {
  open: boolean;
  formFields: JSX.Element[];
  handleClose: (dO?: DependsOn) => void;
  handleUpdate: () => void;
  noQuestionError: boolean;
  noAnswerError: boolean;
}

const DependsOnAddModal = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    dependsOnContainer: {
      margin: 0,
      paddingTop: 10,
      paddingRight: 0,
      paddingLeft: 0,
      display: "grid",
      width: "100%",
      gridTemplateColumns: "1fr 3fr",
      gridTemplateAreas: '"question answers"',
      alignContent: "start",
    },
  });
  const classes = useStyle(theme);

  const [noQuestionError, setNoQuestionError] = useState<boolean>(false);
  const [noAnswerError, setNoAnswerError] = useState<boolean>(false);

  useEffect(() => {
    setNoQuestionError(false);
    setNoAnswerError(false);
  }, [props.formFields]);

  return (
    <Dialog open={props.open} maxWidth="lg" fullWidth>
      <DialogTitle>Add Dependancy</DialogTitle>
      <DialogContent>
        <Container className={classes.dependsOnContainer}>
          {props.formFields}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.handleClose();
          }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            if (props.noQuestionError) {
              setNoQuestionError(true);
            } else if (props.noAnswerError) {
              setNoAnswerError(true);
            } else {
              props.handleUpdate();
            }
          }}
        >
          Submit
        </Button>
      </DialogActions>
      {(noQuestionError || noAnswerError) && (
        <Alert severity="error">
          {"Please select " + (noQuestionError ? "a Question." : "Answer(s).")}
        </Alert>
      )}
    </Dialog>
  );
};

export default DependsOnAddModal;
