/**
 * Implements window storage for storing non-sensitive data.
 */
export class LocalStorage {
  fetchAsync = (key: string): Promise<string | null> => {
    return Promise.resolve<string | null>(window.localStorage.getItem(key));
  };

  fetch = (key: string): string | null => {
    return window.localStorage.getItem(key);
  };

  set = (key: string, value: string): Promise<void> => {
    return Promise.resolve<void>(window.localStorage.setItem(key, value));
  };
}

/**
 * Allows the different types of storage to be configured for each app, e.g. web, mobile.
 */
export const StorageAccess = {
  userStorage: {
    fetchAsync: (key: string): Promise<string | null> => {
      return Promise.resolve<string | null>(window.localStorage.getItem(key));
    },

    fetch: (key: string): string | null => {
      return window.localStorage.getItem(key);
    },

    set: (key: string, value: string): Promise<void> => {
      return Promise.resolve<void>(window.localStorage.setItem(key, value));
    },
  },
  secureStorage: {} as LocalStorage,
};
