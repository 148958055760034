import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMountedState, useUnmountPromise } from "react-use";
import { TenantClient, TenantViewModel } from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import Footer from "../page/Footer";
import PageTitle from "../page/PageTitle";

import LogoBar from "./LogoBar";

interface IProps {
  children?: JSX.Element;
  title: string;
}

const styles = makeStyles({
  gridContainer: {
    display: "grid",
    gridTemplateAreas: `
      "header"
      "main_content"
      "footer"
    `,
    gridTemplateRows: "auto 1fr auto",
    gridTemplateColumns: "100%",
    position: "relative",
    minHeight: "100vh",
  },
  header: {
    gridArea: "header",
  },
  mainContent: {
    gridArea: "main_content",
    paddingBottom: "2.5rem",
  },
  titleHeader: {
    fontSize: 0, // Add a header with no font size to improve readability for screen readers without interfering with the styling of the page
  },
  footer: {
    gridArea: "footer",
    textAlign: "center",
    position: "absolute",
    bottom: "0",
    width: "100%",
    height: "2.5rem",
  },
});

const UnauthedRoute = ({ children, title }: IProps): React.ReactElement => {
  const classes = styles();
  const [searchParams] = useSearchParams();
  const [tenant, setTenant] = useState<TenantViewModel>();
  const tenantId = searchParams.get("tId");
  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        await resolveWhileMounted(
          new TenantClient(Configuration.SERVER_ROOT, FetchOverride).getTenant(
            parseInt(tenantId ?? "0")
          )
        ).then((tenancy) => {
          setTenant(tenancy);
        });
      }
    };
    effect();
  }, [setTenant, tenantId, resolveWhileMounted, isMountedState]);

  return (
    <PageTitle title={title}>
      <Container className={classes.gridContainer}>
        <div className={classes.header}>
          <LogoBar tenant={tenant ?? new TenantViewModel()} />
        </div>
        <h1 className={classes.titleHeader}>{title}</h1>
        <div className={classes.mainContent}>{children}</div>
        <Footer
          className={classes.footer}
          footer={tenant?.tenantOptions?.footer ?? ""}
        />
      </Container>
    </PageTitle>
  );
};

export default UnauthedRoute;
