import { LoadingButton } from "@mui/lab";
import { Box, MobileStepper, Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../Theme";

interface Props {
  totalSections: number;
  section: number;
  navigateSection: (value: number, override?: boolean) => void;
  submitButtonLoad: boolean;
  preview?: boolean;
  isNewResponse: boolean;
}

const useStyle = makeStyles((th: Theme) => ({
  stepper: {
    backgroundColor: "#EAE8E1",
    marginBottom: "10px",
  },
}));

export const QuestionnaireFooter = (props: Props): JSX.Element => {
  const classes = useStyle(customTheme);
  var nextButton =
    props.section === props.totalSections - 1
      ? props.preview
        ? "Calculate Scores"
        : "Submit"
      : "Next";
  var previousButton = props.section < 1 ? "Cancel" : "Back";

  return (
    <Box key="footer-box">
      <MobileStepper
        className={classes.stepper}
        variant="dots"
        steps={props.totalSections}
        position="static"
        activeStep={props.section}
        nextButton={
          <LoadingButton
            variant="contained"
            onClick={(e) => {
              props.navigateSection(props.section + 1, true);
            }}
            loading={props.submitButtonLoad}
          >
            {nextButton}
          </LoadingButton>
        }
        backButton={
          <Button
            variant="contained"
            onClick={(e) => {
              props.navigateSection(props.section - 1, !props.isNewResponse);
            }}
          >
            {previousButton}
          </Button>
        }
      />
    </Box>
  );
};

export default QuestionnaireFooter;
