import { Box, MobileStepper, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../../Theme";
import { LoadingButton } from "@mui/lab";

interface Props {
  section: number;
  totalSections: number;
  navigateSection: (s: number) => void;
  cancelLoading: boolean;
}

const FooterBuilder = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    stepper: {
      backgroundColor: "#EAE8E1",
      marginBottom: "10px",
    },
  }));

  const classes = useStyle(customTheme);
  var nextButton =
    props.section === props.totalSections - 1 || props.totalSections === 0
      ? "Add Section"
      : "Next";
  var previousButton = props.section < 1 ? "Cancel" : "Back";

  return (
    <Box key="footer-box">
      <MobileStepper
        className={classes.stepper}
        variant="dots"
        steps={props.totalSections}
        position="static"
        activeStep={props.section}
        nextButton={
          <LoadingButton
            variant="contained"
            onClick={() => {
              props.navigateSection(props.section + 1);
            }}
            sx={{ minWidth: "120px" }}
          >
            {nextButton}
          </LoadingButton>
        }
        backButton={
          <LoadingButton
            variant="contained"
            onClick={() => {
              props.navigateSection(props.section - 1);
            }}
            loading={props.cancelLoading}
            sx={{ minWidth: "120px" }}
          >
            {previousButton}
          </LoadingButton>
        }
      />
    </Box>
  );
};

export default FooterBuilder;
