import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import axe from "@axe-core/react";

if (process.env.NODE_ENV !== "production") {
  // Configure the Axe WCAG linter
  axe(React, ReactDOM, 1000, {
    rules: [
      { id: "page-has-heading-one", enabled: false }, // Heading is set in `AuthedRoute.tsx`, however this is too deep for the linter to detect
      { id: "landmark-one-main", enabled: false }, // Main region is contained within index.html file, but isn't detected by the linter
      { id: "region", enabled: false }, // Main region is contained within index.html file, but isn't detected by the linter
      { id: "aria-dialog-name", enabled: false }, // This is incompatible with MUI's implementation of alert dialogs
      { id: "color-contrast", enabled: false }, // MUI's text colour implementation is not detected by the linters
      { id: "landmark-unique", enabled: false }, // MUI's Accordian conflicts with this rule and have tried forcing roles for accordian but MUI does background stuff to avoid it
    ],
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
