import { useEffect, useState } from "react";
import { QuestionnaireBuilderDataModel } from "./QuestionnaireBuilderDataModelHelper";
import { useUnmountPromise } from "react-use";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { AdditionalPlots } from "./AdditionalPlotsBuilder";
import AdditionalPlotsAddModal from "./AdditionalPlotsAddModal";
import {
  QuestionnaireClient,
  QuestionnaireViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";

interface Props {
  questionnaire: QuestionnaireBuilderDataModel;
  additionalPlots: AdditionalPlots;
  singleAdditionalPlots: AdditionalPlots;
  allQuestionnaires: QuestionnaireViewModel[];
  setSingleAdditionalPlots: (dO: AdditionalPlots) => void;
  handleChange: () => void;
  handleLocalChange: (e: any, isQ: boolean) => void;
  handleDelete: () => void;
  handleClose: () => void;
  noQuestionnaireError: boolean;
  noCategoryError: boolean;
  resetErrors: (to: boolean) => void;
}

const AdditionalPlotsSelector = (props: Props): JSX.Element => {
  const headers: string[] = ["Questionniare", "Category", "Edit", "Delete"];

  const [row, setRow] = useState<JSX.Element | null>(null);
  const [additionalPlotsAddModalOpen, setAdditionalPlotsAddModalOpen] =
    useState<boolean>(false);
  const [formFields, setFormFields] = useState<JSX.Element[]>([]);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [questionnaireDropdown] = useState<JSX.Element[]>(
    buildQuestionnaireDropdown
  );

  async function loadRows() {
    var localRow: JSX.Element | null = null;
    const qId = props.additionalPlots?.questionnaireId;
    const v = props.additionalPlots?.questionnaireVersion;
    if (qId === -1 || qId == null) {
      setRow(null);
    } else {
      var questionnaire = await questionnaireClient.getQuestionnaireViaVersion(
        qId,
        v
      );
      const subscaleIndex = props.additionalPlots?.subscaleIndex ?? -1;
      if (subscaleIndex === -1) {
        return;
      }

      var subscaleName =
        questionnaire?.scoring == null
          ? "Undefined"
          : questionnaire?.scoring[subscaleIndex].name;

      localRow = (
        <TableRow key={"additionalPlots-row-"}>
          <TableCell key={"questionniare"} width={250}>
            <TextField
              value={questionnaire?.name}
              key={"additionalPlots-questionnaire-"}
              variant="outlined"
              multiline
              maxRows={4}
              fullWidth
            />
          </TableCell>
          <TableCell key={"Category"}>
            <TextField
              value={subscaleName}
              key={"additionalPlots-categorys-"}
              variant="outlined"
              multiline
              maxRows={4}
              fullWidth
            />
          </TableCell>
          <TableCell key={"EditButton-cell-"} width={150}>
            <Button
              id={"edit-row-"}
              variant="contained"
              key={"EditButton-"}
              onClick={() => {
                editResponseFromIndex();
              }}
              fullWidth
            >
              Edit
            </Button>
          </TableCell>
          <TableCell key={"DeleteButton-cell-"} width={150}>
            <Button
              id={"delete-row-"}
              variant="contained"
              key={"DeleteButton-"}
              onClick={() => {
                props.handleDelete();
              }}
              fullWidth
            >
              Delete
            </Button>
          </TableCell>
        </TableRow>
      );
    }
    setRow(localRow);
  }

  function buildQuestionnaireDropdown() {
    var qDrop: JSX.Element[] = [
      <MenuItem value={""} key={"questionnaire-placeholder"}>
        <em>Questionnaire</em>
      </MenuItem>,
    ];
    for (let i = 0; i < props.allQuestionnaires.length; i++) {
      if (props.allQuestionnaires[i].name !== props.questionnaire.name) {
        qDrop.push(
          <MenuItem
            value={props.allQuestionnaires[i].id}
            key={"questionnaire-" + props.allQuestionnaires[i].id}
          >
            {props.allQuestionnaires[i].name}
          </MenuItem>
        );
      }
    }
    return qDrop;
  }

  async function buildCategoryDropdown(questionnaireId: number) {
    var cDrop: JSX.Element[] = [];
    var questionnaire = await getQuestionnaire(questionnaireId);
    if (questionnaire == null || questionnaire.scoring == null) {
      return cDrop;
    }
    for (let i = 0; i < questionnaire.scoring.length; i++) {
      cDrop.push(
        <MenuItem
          value={JSON.stringify({
            subscaleIndex: i,
            questionnaireVersion: questionnaire.version,
          })}
          key={"category-index-" + i}
        >
          {questionnaire.scoring[i].name}
        </MenuItem>
      );
    }

    return cDrop;
  }

  async function loadFormFields() {
    var questionnaireId: number = -1;
    var questionnaireVersion: number = -1;
    var subscaleIndex: number = -1;
    var categorysDropdown: JSX.Element[] = [];
    var formFields: JSX.Element[] = [];

    if (props.singleAdditionalPlots === undefined) {
      questionnaireId = -1;
      questionnaireVersion = -1;
      subscaleIndex = -1;
    } else {
      questionnaireId = props.singleAdditionalPlots.questionnaireId ?? -1;
      questionnaireVersion =
        props.singleAdditionalPlots.questionnaireVersion ?? -1;
      subscaleIndex = props.singleAdditionalPlots.subscaleIndex ?? -1;
    }
    if (questionnaireId !== -1) {
      categorysDropdown = await buildCategoryDropdown(questionnaireId);
    }

    formFields.push(
      <Box
        width={"100%"}
        gridArea={"questionnaire"}
        margin={0}
        paddingRight={2}
        paddingLeft={0}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignContent={"flex-start"}
        flexWrap={"wrap"}
        key={"questionnaire-container-"}
      >
        <FormControl sx={{ minWidth: "100%" }}>
          <InputLabel id="questionnaire-label">Questionnaire</InputLabel>
          <Select
            labelId="questionnaire-label"
            id="questionnaire-select"
            key={"questionnaire-select"}
            label="Questionnaire"
            onChange={(e) => {
              props.handleLocalChange(e, true);
            }}
            value={questionnaireId === -1 ? "" : questionnaireId}
          >
            {questionnaireDropdown}
          </Select>
        </FormControl>
      </Box>
    );
    if (questionnaireId !== -1) {
      var defaultValue = JSON.stringify({
        subscaleIndex: subscaleIndex,
        questionnaireVersion: questionnaireVersion,
      });
      if (defaultValue === "{}") {
        defaultValue = "";
      }
      formFields.push(
        <Box
          margin={0}
          display={"flex"}
          flexDirection={"row"}
          width={"100%"}
          justifyContent={"flex-start"}
          flexWrap={"wrap"}
          gridArea={"category"}
          padding={0}
          key={"category-container-"}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="category-select"
              key={"category-select"}
              label="category"
              onChange={(e) => {
                props.handleLocalChange(e, false);
              }}
              value={defaultValue}
            >
              {categorysDropdown}
            </Select>
          </FormControl>
        </Box>
      );
    }

    setFormFields(formFields);
  }

  const questionnaireClient = new QuestionnaireClient(
    Configuration.SERVER_ROOT,
    FetchOverride
  );
  const resolveWhileMounted = useUnmountPromise();

  async function getQuestionnaire(
    qId: number
  ): Promise<QuestionnaireViewModel | null> {
    return new Promise((resolve) => {
      resolveWhileMounted(
        questionnaireClient.getLatestQuestionnaireVersion(qId, true)
      )
        .then((questionnaire) => {
          resolve(questionnaire);
        })
        .catch(() => resolve(null));
    });
  }

  function addDependancy() {
    props.setSingleAdditionalPlots(undefined);
    setAdditionalPlotsAddModalOpen(true);
  }

  function editResponseFromIndex() {
    props.setSingleAdditionalPlots(props.additionalPlots);
    props.resetErrors(false);
    setAdditionalPlotsAddModalOpen(true);
  }

  useEffect(
    () => {
      loadRows();
    },
    // eslint-disable-next-line
    [props.additionalPlots]
  );

  useEffect(
    () => {
      loadFormFields();
    },
    // eslint-disable-next-line
    [props.singleAdditionalPlots]
  );

  return (
    <>
      <Box key={"additionalPlots-selector-table-box-"}>
        <TableContainer
          component={Paper}
          key={"additionalPlots-selector-table-container-"}
        >
          <Table
            sx={{ minWidth: 1000 }}
            aria-label="simple table"
            key={"additionalPlots-selector-table-"}
          >
            <TableHead key={"additionalPlots-selector-table-head-"}>
              <TableRow key={"additionalPlots-selector-table-head-row-"}>
                {headers?.map((header) => (
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    key={"header-" + header}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody key={"additionalPlots-selector-table-body-"}>
              {row}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display={"flex"} justifyContent={"space-between"}>
          {!row && (
            <Button
              onClick={async () => {
                addDependancy();
              }}
              variant="contained"
              sx={{ marginTop: 2, marginBottom: 2 }}
              key={"additionalPlots-selector-table-add-button-"}
            >
              Add Dependancy
            </Button>
          )}
          <Button
            onClick={() => {
              props.handleClose();
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
      <AdditionalPlotsAddModal
        open={additionalPlotsAddModalOpen}
        formFields={formFields}
        handleClose={() => {
          if (isAdd) {
            setIsAdd(false);
          }
          props.resetErrors(true);
          setAdditionalPlotsAddModalOpen(false);
        }}
        handleUpdate={() => {
          props.handleChange();
          setAdditionalPlotsAddModalOpen(false);
        }}
        noQuestionnaireError={props.noQuestionnaireError}
        noCategoryError={props.noCategoryError}
        key={"additionalPlots-selector-table-modal-"}
      />
    </>
  );
};

export default AdditionalPlotsSelector;
