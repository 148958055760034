import { SearchOff } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Container,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../Theme";
import UserSearchBody from "./UserSearchBody";
import UserSort from "./UserSort";
import { AbstractUserCardType, UserDescription } from "./SearchTypes";
import UserPagination from "./UserPagination";
import { SortDirectionType, SortType } from "../../../types/auto/types";

interface Props {
  loading: boolean;
  allowCaseloadDeletion: boolean;
  sortableValues: UserDescription[];
  users: AbstractUserCardType[] | undefined;
  onPageChange: (page: number) => void;
  page: number;
  totalPages: number;
  onSortChange: (sortBy?: SortType, direction?: SortDirectionType) => void;
  sortBy: SortType;
  sortDirection: SortDirectionType;
}

const UserSearchResults = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    noResults: {
      textAlign: "center",
      marginTop: "10%",
    },
    noResultsIcon: {
      fontSize: "4em",
    },
    noResultsText: {
      fontSize: "1.8em",
      textAlign: "center",
    },
    loadContainer: {
      display: "block",
      textAlign: "center",
      marginTop: "10%",
    },
  }));
  const classes = useStyle(theme);

  if (props.users === undefined) {
    return <></>;
  }

  return (
    <Box>
      {props.loading && (
        <Container className={classes.loadContainer}>
          <CircularProgress aria-label="Loading" />
        </Container>
      )}
      {props.users && props.users.length > 0 && !props.loading && (
        <>
          <UserSort
            sortBy={props.sortBy}
            sortDirection={props.sortDirection}
            setSortBy={(x) => props.onSortChange(x, undefined)}
            setSortDirection={(x) => props.onSortChange(undefined, x)}
            floatRight={true}
            sortableValues={props.sortableValues}
          />
          <UserSearchBody
            allowCaseloadDeletion={props.allowCaseloadDeletion}
            users={props.users}
          />
          {props.totalPages > 1 && (
            <UserPagination
              totalPages={props.totalPages}
              page={props.page}
              onPageChange={props.onPageChange}
            />
          )}
        </>
      )}
      {props.users && props.users.length === 0 && !props.loading && (
        <Container className={classes.noResults}>
          <SearchOff className={classes.noResultsIcon} />
          <Typography className={classes.noResultsText}>
            No matching records found
          </Typography>
        </Container>
      )}
    </Box>
  );
};

export default UserSearchResults;
