import { Checkbox, TableCell, TableRow, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  AnswerViewModel,
  TableQuestionType,
} from "../../../../types/auto/types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface DataModel {
  [key: string]: any;
}

interface Props {
  model: DataModel;
  answers: AnswerViewModel[] | undefined;
}

const MultiSelectorTableRowResponse = (props: Props): JSX.Element => {
  const responseValues: JSX.Element[] = [];
  var answerIndex = 0;

  for (const [key, value] of Object.entries(props.model)) {
    if (key !== "RowId" && props.answers != null) {
      switch (props.answers[answerIndex].tableType) {
        case TableQuestionType.Textbox:
        case TableQuestionType.Number:
        case TableQuestionType.Dropdown: {
          responseValues.push(
            <TableCell
              key={"table-cell-response-" + key + props.model["RowId"]}
            >
              <TextField
                value={value}
                key={"response-text-input-" + key + props.model["RowId"]}
                variant="outlined"
                multiline
                maxRows={4}
              ></TextField>
            </TableCell>
          );
          break;
        }
        case TableQuestionType.Date: {
          responseValues.push(
            <TableCell
              key={"table-cell-response-" + key + props.model["RowId"]}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                key={"response-date-localisation-" + key + props.model["RowId"]}
              >
                <DatePicker
                  key={"response-date-" + key + props.model["RowId"]}
                  onChange={() => {}}
                  disableFuture={true}
                  readOnly={true}
                  views={["year", "month", "day"]}
                  inputFormat="DD/MM/YYYY"
                  value={value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={false}
                      sx={{
                        fieldset: {
                          legend: {
                            width: "unset",
                          },
                        },
                      }}
                      key={
                        "response-date-textfield-" + key + props.model["RowId"]
                      }
                      multiline
                      maxRows={2}
                    />
                  )}
                ></DatePicker>
              </LocalizationProvider>
            </TableCell>
          );
          break;
        }
        case TableQuestionType.Checkbox: {
          responseValues.push(
            <TableCell
              key={"table-cell-response-" + key + props.model["RowId"]}
            >
              <Checkbox
                checked={value as boolean}
                key={"response-checkbox-" + key + props.model["RowId"]}
              ></Checkbox>
            </TableCell>
          );
          break;
        }
      }
      answerIndex++;
    }
  }
  return (
    <TableRow key={"response-row-" + props.model["RowId"]} sx={{ gap: 2 }}>
      {responseValues}
    </TableRow>
  );
};

export default MultiSelectorTableRowResponse;
