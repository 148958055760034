import React, { useEffect, useState } from "react";
import {
  CustomSummaryGraph,
  GraphClient,
  PatientViewModel,
  Role,
  TenantOptionsViewModel,
  UserViewModel,
} from "../../../../../types/auto/types";
import { graphColours } from "../../../../../Theme";
import { ExtendedLineViewModel } from "../../../elements/graph/GraphLegend";
import { Configuration } from "../../../../Constants";
import { FetchOverride } from "../../../../utils/Request";
import LineGraphCore from "./LineGraphCore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import GraphHeader from "../header/GraphHeader";
import { Grading } from "@mui/icons-material";
import { useUnmountPromise } from "react-use";
import GraphSectionTitle from "../header/GraphSectionTitle";
import { CheckIsMobile } from "../../../../utils/MobileStatus";

interface Props {
  patient: PatientViewModel;
  user: UserViewModel;
  fromDate: Date | undefined;
  toDate: Date | undefined;
  separateGraph: boolean;
  separateCustomGraphsIds: number[];
  setNoData: (noData: boolean) => void;
  hideGraph?: boolean;
  tenancyOptions: TenantOptionsViewModel | undefined;
  firstSummaryLoad: boolean;
  setFirstSummaryLoad: React.Dispatch<React.SetStateAction<boolean>>;
  collapsedGraph?: boolean;
  filterChange?: boolean;
}

const graphWidth = 1000;
const graphHeight = 500;
const initialSummaryData: { [summaryId: number]: ExtendedLineViewModel[] } = {};

const SummaryChart = (props: Props): JSX.Element => {
  const [data, setData] = useState<ExtendedLineViewModel[]>([]);
  const [seperateGraphsData, setSeperateGraphsData] = useState<
    ExtendedLineViewModel[][]
  >([]);
  const [customSeperateGraphsData, setCustomSeperateGraphsData] = useState<
    ExtendedLineViewModel[][]
  >([]);
  const [customSummaryData, setCustomSummaryData] =
    useState(initialSummaryData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const resolveWhileMounted = useUnmountPromise();
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const isMobile = CheckIsMobile();

  const useStyle = makeStyles(() => ({
    splitSummaryContainer: { marginTop: 30, "@media print": { marginTop: 0 } },
    splitSummaryTitle: { marginLeft: 10, marginBottom: 0 },
    accordion: {
      marginLeft: isMobile ? 0 : 60,
      marginRight: isMobile ? 0 : 60,
      marginTop: 20,
      marginBottom: 10,
    },
  }));
  const classes = useStyle();

  function groupByLineId(
    items: ExtendedLineViewModel[]
  ): ExtendedLineViewModel[][] {
    const groupedItems: { [lineId: number]: ExtendedLineViewModel[] } = {};
    items.forEach((item) => {
      // Group items by lineId
      if (item.lineId !== undefined) {
        if (!groupedItems[item.lineId]) {
          groupedItems[item.lineId] = [];
        }
        groupedItems[item.lineId].push(item);
      }
    });

    const result: ExtendedLineViewModel[][] = Object.values(groupedItems);

    return result;
  }

  function groupByCustomSummary(
    items: ExtendedLineViewModel[],
    summaryGraphData: CustomSummaryGraph[]
  ): {
    [summaryId: number]: ExtendedLineViewModel[];
  } {
    const mainSummaryId = -1;
    let summaryData: { [summaryId: number]: ExtendedLineViewModel[] } = {
      [mainSummaryId]: items,
    };
    items.forEach((item) => {
      summaryGraphData.forEach((summary) => {
        summary.questionnaireIds?.forEach((questionnaireId) => {
          if (
            (item.plotSubscalesLineId != null
              ? item.plotSubscalesLineId
              : item.lineId) === questionnaireId
          ) {
            if (summaryData[summary.id]) {
              summaryData[summary.id].push(item);
            } else {
              summaryData[summary.id] = [item];
            }
            summaryData[-1] = summaryData[-1].filter((data) => data !== item);
          }
        });
      });
    });
    return summaryData;
  }
  var isStaff =
    props.user.role === Role.Clinician ||
    props.user.role === Role.Admin ||
    props.user.role === Role.SuperAdmin;

  useEffect(
    () => {
      if (dataLoaded) {
        if (props.tenancyOptions?.customSummaryGraphs !== undefined) {
          let customSplitGraphs: Array<ExtendedLineViewModel> = [];
          props.separateCustomGraphsIds.forEach(
            (x) =>
              (customSplitGraphs = customSplitGraphs.concat(
                customSummaryData[x]
              ))
          );
          var customSplit = groupByLineId(customSplitGraphs);
          var separate = groupByLineId(customSummaryData[-1]);
          setCustomSeperateGraphsData(customSplit);
          setSeperateGraphsData(separate);
        } else {
          var separateSummaryData = groupByLineId(data);
          setSeperateGraphsData(separateSummaryData);
        }
      }
    },
    // eslint-disable-next-line
    [props.filterChange]
  );

  function populateAdditionalPlots(data: ExtendedLineViewModel[]) {
    data.forEach((item) => {
      // populate the additionalPlots
      if (item.additionalPlots) {
        data
          .filter(
            ({ lineId, subscaleIndex }) =>
              lineId ===
                (item.additionalPlots?.questionnaireId != null &&
                item.additionalPlots?.questionnaireVersion != null
                  ? item.additionalPlots?.questionnaireId +
                    item.additionalPlots?.questionnaireVersion
                  : -1) && subscaleIndex === item.additionalPlots?.subscaleIndex
          )
          .forEach((plotItem) => {
            item.additionalPlotsData = [
              ...(item.additionalPlotsData || []),
              ...(plotItem.data || []),
            ];
            item.data = [...(item.data || []), ...(plotItem.data || [])];
            item.maxScore = Math.max(item.maxScore, plotItem.maxScore);
          });
      }
    });
  }

  useEffect(() => {
    setCustomSummaryData(
      groupByCustomSummary(
        data,
        props.tenancyOptions?.customSummaryGraphs ?? []
      )
    );
  }, [data, props.tenancyOptions?.customSummaryGraphs]);
  useEffect(() => {
    const effect = async () => {
      if (props.tenancyOptions === undefined) {
        setLoading(true);
        return;
      }
      if (props.firstSummaryLoad) {
        props.setFirstSummaryLoad(false);
        setLoading(true);
        if (isStaff) {
          await resolveWhileMounted(
            new GraphClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getSummaryGraph(props.fromDate, props.toDate, props.patient.id)
          )
            .then((x) => {
              if (x.lines === undefined || x.lines.length === 0) {
                props.setNoData(true);
                setLoading(false);
                setError(false);
                return;
              }
              var data =
                x.lines?.map(
                  (x, i) =>
                    ({
                      ...x,
                      hidden: false,
                      colour: graphColours[i % graphColours.length],
                    } as ExtendedLineViewModel)
                ) || [];
              populateAdditionalPlots(data);
              setData(data);
              var customSummaryDataLocal = groupByCustomSummary(
                data,
                props.tenancyOptions?.customSummaryGraphs ?? []
              );
              if (props.tenancyOptions?.customSummaryGraphs !== undefined) {
                let customSplitGraphs: Array<ExtendedLineViewModel> = [];
                props.separateCustomGraphsIds.forEach(
                  (x) =>
                    (customSplitGraphs = customSplitGraphs.concat(
                      customSummaryDataLocal[x]
                    ))
                );
                var separateCustomSummaryData =
                  groupByLineId(customSplitGraphs);
                var separateRemainingSummaryData = groupByLineId(
                  customSummaryDataLocal[-1]
                );
                setCustomSeperateGraphsData(separateCustomSummaryData);
                setSeperateGraphsData(separateRemainingSummaryData);
                setCustomSummaryData(customSummaryDataLocal);
              } else {
                var separateSummaryData = groupByLineId(data);
                setSeperateGraphsData(separateSummaryData);
              }
              setDataLoaded(true);
              props.setNoData(false);
              setError(false);
              setLoading(false);
            })
            .catch(() => {
              setError(true);
              setLoading(false);
              props.setNoData(false);
            });
        } else {
          await resolveWhileMounted(
            new GraphClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getCurrentSummaryGraph(props.fromDate, props.toDate)
          )
            .then((x) => {
              if (x.lines === undefined || x.lines.length === 0) {
                props.setNoData(true);
                setLoading(false);
                setError(false);
                return;
              }
              var data =
                x.lines?.map(
                  (x, i) =>
                    ({
                      ...x,
                      hidden: false,
                      colour: graphColours[i % graphColours.length],
                    } as ExtendedLineViewModel)
                ) || [];
              populateAdditionalPlots(data);
              setData(data);
              var customSummaryDataLocal = groupByCustomSummary(
                data,
                props.tenancyOptions?.customSummaryGraphs ?? []
              );
              if (props.tenancyOptions?.customSummaryGraphs !== undefined) {
                let customSplitGraphs: Array<ExtendedLineViewModel> = [];
                props.separateCustomGraphsIds.forEach(
                  (x) =>
                    (customSplitGraphs = customSplitGraphs.concat(
                      customSummaryDataLocal[x]
                    ))
                );
                var separateCustomSummaryData =
                  groupByLineId(customSplitGraphs);
                var separateRemainingSummaryData = groupByLineId(
                  customSummaryDataLocal[-1]
                );
                setCustomSeperateGraphsData(separateCustomSummaryData);
                setSeperateGraphsData(separateRemainingSummaryData);
                setCustomSummaryData(customSummaryDataLocal);
              } else {
                var separateSummaryData = groupByLineId(data);
                setSeperateGraphsData(separateSummaryData);
              }
              setDataLoaded(true);
              props.setNoData(false);
              setError(false);
              setLoading(false);
            })
            .catch(() => {
              setError(true);
              setLoading(false);
              props.setNoData(false);
            });
        }
      }
    };
    effect();
  }, [
    setData,
    props,
    resolveWhileMounted,
    isStaff,
    props.firstSummaryLoad,
    customSummaryData,
  ]);

  if (props.hideGraph) {
    return <></>;
  }

  const splitGraphSection = (
    <>
      {seperateGraphsData.map((x) => {
        // Ensure that the graph is not hidden
        var name = x[0].lineName ?? "";
        var colour = x[0].colour;
        if (x.length > 1) {
          const versionRegex = /Version \d+ \(/i; // Regex to match "Version" followed by a number and then " ("
          const match = name.match(versionRegex);

          if (match) {
            const versionIndex = match.index;
            name = name.substring(0, versionIndex).trim();
          }
        }
        x.forEach((line) => {
          line.hidden = false;
        });

        return (
          <Container
            id={name}
            key={name + colour}
            className={classes.splitSummaryContainer}
          >
            <GraphHeader
              title={name ?? ""}
              icon={
                <Tooltip title="Questionnaire">
                  <Grading />
                </Tooltip>
              }
              hidePrint={error || loading}
            >
              <LineGraphCore
                data={x}
                loading={loading}
                error={error}
                width={graphWidth}
                height={graphHeight}
                fromDate={props.fromDate}
                toDate={props.toDate}
                tenancyOptions={props.tenancyOptions}
              />
            </GraphHeader>
          </Container>
        );
      })}
    </>
  );

  return (
    <>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        className={classes.accordion}
        disableGutters
        defaultExpanded={!props.collapsedGraph}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <GraphSectionTitle title="Summary" />
        </AccordionSummary>
        <AccordionDetails>
          {props.tenancyOptions?.customSummaryGraphs !== undefined ? (
            <>
              {!props.separateGraph && (
                <Container>
                  <GraphHeader title={""} hidePrint={error || loading}>
                    <LineGraphCore
                      data={customSummaryData[-1]}
                      loading={loading}
                      error={error}
                      width={graphWidth}
                      height={graphHeight}
                      fromDate={props.fromDate}
                      toDate={props.toDate}
                      tenancyOptions={props.tenancyOptions}
                    />
                  </GraphHeader>
                </Container>
              )}
              {props.separateGraph && splitGraphSection}
              {props.tenancyOptions?.customSummaryGraphs.map(
                (x) =>
                  customSummaryData[x.id] &&
                  !props.separateCustomGraphsIds.includes(x.id) && (
                    <Container key={x.id}>
                      <GraphHeader
                        title={x.title + " Summary" ?? ""}
                        hidePrint={error || loading}
                      >
                        <LineGraphCore
                          data={customSummaryData[x.id]}
                          loading={loading}
                          error={error}
                          width={graphWidth}
                          height={graphHeight}
                          fromDate={props.fromDate}
                          toDate={props.toDate}
                          tenancyOptions={props.tenancyOptions}
                        />
                      </GraphHeader>
                    </Container>
                  )
              )}
            </>
          ) : (
            !props.separateGraph && (
              <Container>
                <GraphHeader title="" hidePrint={error || loading}>
                  <LineGraphCore
                    data={data}
                    loading={loading}
                    error={error}
                    width={graphWidth}
                    height={graphHeight}
                    fromDate={props.fromDate}
                    toDate={props.toDate}
                    tenancyOptions={props.tenancyOptions}
                  />
                </GraphHeader>
              </Container>
            )
          )}
          {props.separateGraph &&
            props.tenancyOptions?.customSummaryGraphs == null &&
            splitGraphSection}
          {props.separateCustomGraphsIds.length > 0 &&
            customSeperateGraphsData.map((x) => {
              // Ensure that the graph is not hidden
              var name = x[0].lineName ?? "";
              var colour = x[0].colour;
              if (x.length > 1) {
                const versionRegex = /Version \d+ \(/i; // Regex to match "Version" followed by a number and then " ("
                const match = name.match(versionRegex);

                if (match) {
                  const versionIndex = match.index;
                  name = name.substring(0, versionIndex).trim();
                }
              }
              x.forEach((line) => {
                line.hidden = false;
              });

              return (
                <Container
                  id={name}
                  key={name + colour}
                  className={classes.splitSummaryContainer}
                >
                  <GraphHeader
                    title={name ?? ""}
                    icon={
                      <Tooltip title="Questionnaire">
                        <Grading />
                      </Tooltip>
                    }
                    hidePrint={error || loading}
                  >
                    <LineGraphCore
                      data={x}
                      loading={loading}
                      error={error}
                      width={graphWidth}
                      height={graphHeight}
                      fromDate={props.fromDate}
                      toDate={props.toDate}
                      tenancyOptions={props.tenancyOptions}
                    />
                  </GraphHeader>
                </Container>
              );
            })}{" "}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SummaryChart;
