import { Button, Container, Dialog, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../../Theme";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}
const CustomDialog = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    popupTitleText: {
      fontSize: "21px",
      fontWeight: "bolder",
    },
    closeButton: {
      marginLeft: "auto",
      width: "1%",
      paddingRight: 0,
    },
    mainContainer: {
      padding: "20px 10px",
    },
    titleContainer: {
      display: "flex",
      paddingRight: 0,
    },
  }));
  const classes = useStyle(customTheme);

  return (
    <Dialog open={props.open} aria-label={props.title}>
      <Container className={classes.mainContainer} aria-label={props.title}>
        <Container className={classes.titleContainer}>
          <Typography className={classes.popupTitleText}>
            {props.title}
          </Typography>
          <Button
            onClick={props.onClose}
            className={classes.closeButton}
            aria-label="Close Dialog"
          >
            <CloseIcon />
          </Button>
        </Container>
        <hr />
        {props.children}
      </Container>
    </Dialog>
  );
};

export default CustomDialog;
