import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { TenantViewModel } from "../../../../types/auto/types";
import { CheckIsMobile, CheckIsSmallDevice } from "../../../utils/MobileStatus";

interface Props {
  tenant: TenantViewModel;
}
const LogoBar = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const location = useLocation();
  const navigate = useNavigate();
  const elementStyles = makeStyles({
    userManagementNavigation: {
      display: "flex",
      flexDirection: "row",
    },
    trueColoursContainer: {
      marginRight: "auto",
      display: "flex",
    },
    nhsLogo: {
      margin: 10,
    },
    trueColoursLogo: {
      alignSelf: "center",
      cursor: "pointer",
    },
  });
  const classes = elementStyles();

  return (
    <nav className={classes.userManagementNavigation}>
      <Box className={classes.trueColoursContainer}>
        <img
          src={"/TrueColoursLogo.png"}
          alt="True Colours Logo"
          height={isMobile ? (isSmallDevice ? "17px" : "26px") : "35px"}
          width={isMobile ? (isSmallDevice ? "104px" : "157px") : "210px"}
          className={classes.trueColoursLogo}
          aria-hidden="true"
          onClick={() => {
            if (location.pathname !== "/search" && location.pathname !== "/")
              navigate("/");
          }}
        />
      </Box>
      {props.tenant.tenantOptions?.logoPath !== undefined &&
        props.tenant.tenantOptions?.logoPath !== null &&
        props.tenant.tenantOptions?.logoPath !== "" && (
          <Box className={classes.nhsLogo}>
            <img
              src={props.tenant.tenantOptions?.logoPath}
              alt={
                props.tenant.tenantOptions.logoName === undefined ||
                props.tenant.tenantOptions.logoName === ""
                  ? "Oxford Health Logo"
                  : props.tenant.tenantOptions.logoName
              }
              height={isSmallDevice ? "44px" : "64px"}
            />
          </Box>
        )}
    </nav>
  );
};

export default LogoBar;
