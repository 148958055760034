import { Alert, CircularProgress, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useEffect } from "react";
import { useMountedState, useUnmountPromise } from "react-use";
import {
  MedicationCategoryViewModel,
  MedicationClient,
  TenancyOptionErrors,
} from "../../types/auto/types";
import CreateEditMedicationCategory from "../components/medication/medicationCategory/CreateEditMedicationCategory";
import MedicationCategoryCard from "../components/medication/medicationCategory/MedicationCategoryCard";
import { Configuration, HelpText } from "../Constants";
import { FetchOverride } from "../utils/Request";
import PaginatedView from "../components/search/PaginatedView";

const Medications = (): JSX.Element => {
  const useStyle = makeStyles(() => ({
    medicationContainer: {
      marginTop: "10px",
    },
    medicationCategories: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    titles: {
      marginTop: "10px",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  }));
  const classes = useStyle();
  const [medicationCategories, setMedicationCategories] = React.useState<
    MedicationCategoryViewModel[]
  >([]);
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const isMountedState = useMountedState();
  const resolveWhileMounted = useUnmountPromise();
  const [error, setError] = React.useState<TenancyOptionErrors | undefined>(
    undefined
  );

  const [termFilter, setTermFilter] = React.useState<string>("");

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        setLoading(true);
        setMedicationCategories([]);
        const medicationClient = new MedicationClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        );
        await resolveWhileMounted(
          medicationClient.getTenantMedicationCategories()
        )
          .then((medCategories) => {
            setLoading(false);
            setMedicationCategories(medCategories);
          })
          .catch((e: TenancyOptionErrors) => {
            setLoading(false);
            if (e === TenancyOptionErrors.TenancyNotSupported) {
              window.history.back();
            }
            setError(e);
          });
      }
    };
    effect();
  }, [setMedicationCategories, refresh, isMountedState, resolveWhileMounted]);

  return (
    <Container className={classes.medicationContainer} disableGutters>
      <Typography variant="h5" component="h2" className={classes.titles}>
        Medication Categories
      </Typography>

      <PaginatedView
        controlElements={
          <Container disableGutters>
            <CreateEditMedicationCategory
              refresh={() => setRefresh((x) => !x)}
            />
            {medicationCategories.length === 0 && !loading && (
              <Typography>
                There are no active medication categories. Click the above
                button to button to add a new category.
              </Typography>
            )}
          </Container>
        }
        elements={medicationCategories
          .filter(
            (x) =>
              x.name
                ?.toLowerCase()
                ?.startsWith(termFilter?.toLowerCase() ?? "") ||
              termFilter?.trimEnd() === ""
          )
          .map((medicationCategory, key) => (
            <MedicationCategoryCard
              medicationCategory={medicationCategory}
              key={key}
              refresh={() => setRefresh((x) => !x)}
              medicationCategories={medicationCategories}
            />
          ))}
        filterCallback={(term, _) => {
          setTermFilter(term);
        }}
        searchTerm={termFilter}
        helpText={HelpText.MEDICATION_TABLE.SEARCH_MEDICATION}
      />

      <Container>
        {error !== undefined && (
          <Alert severity="error">
            {error === TenancyOptionErrors.TenancyNotSupported
              ? "Tenancy does not support medication."
              : ""}
          </Alert>
        )}

        {loading && (
          <CircularProgress
            className={classes.loadingSpinner}
            aria-label="Loading"
          />
        )}
      </Container>
    </Container>
  );
};

export default Medications;
