import {
  Alert,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import { AnswerDataModel } from "./QuestionnaireBuilderDataModelHelper";
import { QuestionType, TableQuestionType } from "../../../../types/auto/types";
import {
  RequiredAnswerFields,
  ValidatorError,
} from "./SectionBuilderQuestionAnswerHelper";
import nameof from "../../../utils/NameOf";

interface Props {
  handleChange: (field: string, value: string | boolean | number) => void;
  answerWIP: AnswerDataModel;
  requiredAnswerFields: RequiredAnswerFields[];
  rangeMinError: ValidatorError;
  rangeMaxError: ValidatorError;
  rangeDefaultError: ValidatorError;
  tableTypeError: ValidatorError;
  questionType: QuestionType | undefined;
  tableError: ValidatorError;
  errorColumn: number | undefined;
}

const AnswerForm = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    textFieldContainer: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "row",
      gap: 10,
      width: "100%",
      marginBottom: 5,
      justifyContent: "flex-end",
    },
    singleContainer: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "row",
      gap: 10,
      width: "50%",
      marginBottom: 15,
      justifyContent: "flex-end",
    },
    textField: {
      width: "100%",
    },
    checkbox: {
      width: "100%",
      justifyContent: "flex-end",
    },
    infoContainer: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: 10,
    },
  });
  const classes = useStyle(theme);

  return (
    <>
      {props.requiredAnswerFields.includes(RequiredAnswerFields.Base) && (
        <Container
          className={
            props.requiredAnswerFields.includes(
              RequiredAnswerFields.PlaceholderNote
            ) ||
            props.requiredAnswerFields.includes(
              RequiredAnswerFields.ScreenReaderNote
            )
              ? classes.infoContainer
              : classes.textFieldContainer
          }
          key={"answer-form-text-score-container"}
        >
          <TextField
            label="Text"
            className={classes.textField}
            onChange={(e) =>
              props.handleChange(
                nameof<AnswerDataModel>("text"),
                e.target.value
              )
            }
            value={props.answerWIP.text ?? ""}
            key={"answer-form-text-field"}
          />
          {props.requiredAnswerFields.includes(
            RequiredAnswerFields.PlaceholderNote
          ) && (
            <Alert
              key={"alert-" + props.answerWIP.number}
              severity="info"
              className={classes.textField}
            >
              {"This input will set the placeholder text for your textbox."}
            </Alert>
          )}
          {props.requiredAnswerFields.includes(
            RequiredAnswerFields.ScreenReaderNote
          ) && (
            <Alert
              key={"alert-screenreader-" + props.answerWIP.number}
              severity="info"
              className={classes.textField}
            >
              {"This input will label the slider for screen readers."}
            </Alert>
          )}
          {props.requiredAnswerFields.includes(RequiredAnswerFields.Score) && (
            <TextField
              label="Score"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<AnswerDataModel>("score"),
                  e.target.value
                )
              }
              value={props.answerWIP.score ?? ""}
              type="number"
              key={"answer-form-score-field"}
            />
          )}
        </Container>
      )}
      {props.requiredAnswerFields.includes(RequiredAnswerFields.Regex) && (
        <Container
          className={classes.textFieldContainer}
          key={"answer-form-regex-container"}
        >
          <TextField
            label="Validation Regex"
            className={classes.textField}
            onChange={(e) =>
              props.handleChange(
                nameof<AnswerDataModel>("validationRegex"),
                e.target.value
              )
            }
            value={props.answerWIP.validationRegex ?? ""}
            key={"answer-form-text-field"}
          />
        </Container>
      )}
      {props.requiredAnswerFields.includes(RequiredAnswerFields.RangeMin) &&
        props.requiredAnswerFields.includes(RequiredAnswerFields.RangeMax) && (
          <Container
            className={classes.textFieldContainer}
            key={"answer-form-rangemin-rangemax-container"}
          >
            <TextField
              label="Range Min"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<AnswerDataModel>("rangeMin"),
                  e.target.value
                )
              }
              value={props.answerWIP.rangeMin ?? ""}
              type="number"
              key={"answer-form-rangemin-field"}
              required
              error={
                props.rangeMinError === ValidatorError.NoError ? false : true
              }
              helperText={
                props.rangeMinError !== ValidatorError.NoError &&
                (props.rangeMinError === ValidatorError.NoRangeMinValue
                  ? "Please enter a minimum range value."
                  : "The minimum value must be smaller than the maximum value")
              }
            />
            <TextField
              label="Range Max"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<AnswerDataModel>("rangeMax"),
                  e.target.value
                )
              }
              value={props.answerWIP.rangeMax ?? ""}
              type="number"
              key={"answer-form-rangemax-field"}
              required
              error={
                props.rangeMaxError === ValidatorError.NoError ? false : true
              }
              helperText={
                props.rangeMaxError !== ValidatorError.NoError &&
                (props.rangeMaxError === ValidatorError.NoRangeMaxValue
                  ? "Please enter a maximum range value."
                  : "The maximum value must be larger than the minimum value")
              }
            />
          </Container>
        )}
      {props.requiredAnswerFields.includes(RequiredAnswerFields.RangeDefault) &&
        props.requiredAnswerFields.includes(
          RequiredAnswerFields.RangeDecimal
        ) && (
          <Container
            className={classes.textFieldContainer}
            key={"answer-form-rangedef-rangedec-container"}
          >
            <TextField
              label="Range Default"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<AnswerDataModel>("rangeDefault"),
                  e.target.value
                )
              }
              value={props.answerWIP.rangeDefault ?? ""}
              type="number"
              key={"answer-form-rangedef-field"}
              error={
                props.rangeDefaultError === ValidatorError.NoError
                  ? false
                  : true
              }
              helperText={
                props.rangeDefaultError !== ValidatorError.NoError &&
                (props.rangeDefaultError ===
                ValidatorError.RangeDefaultOutOfScopeHigh
                  ? "The default value must be less than or equal to the max value."
                  : "The default value must be greater than or equal to the min value.")
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) =>
                    props.handleChange(
                      nameof<AnswerDataModel>("rangeDecimal"),
                      e.target.checked
                    )
                  }
                  checked={props.answerWIP.rangeDecimal ?? false}
                  key={"answer-form-rangedec-checkbox"}
                />
              }
              label="Range Decimal?"
              labelPlacement="start"
              className={classes.checkbox}
              key={"answer-form-rangedec-label"}
            />
          </Container>
        )}
      {props.requiredAnswerFields.includes(RequiredAnswerFields.TableType) && (
        <Container
          className={classes.textFieldContainer}
          key={"answer-form-tabletype-header-dropdown-container"}
        >
          {props.questionType === QuestionType.Table && (
            <TextField
              label="Column Header"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<AnswerDataModel>("text"),
                  e.target.value
                )
              }
              value={props.answerWIP.text ?? ""}
              key={"answer-form-table-header"}
            />
          )}
          <FormControl
            className={classes.textField}
            error={props.tableTypeError !== ValidatorError.NoError}
          >
            <InputLabel
              id={"label-for-table-type-dropdown" + props.answerWIP.number}
            >
              Table Input Type
            </InputLabel>
            <Select
              onChange={(e) =>
                props.handleChange(
                  nameof<AnswerDataModel>("tableType"),
                  e.target.value
                )
              }
              value={props.answerWIP.tableType ?? ""}
              labelId={"label-for-table-type-dropdown" + props.answerWIP.number}
              label="Table Input Type"
              key={"answer-form-tabletype-field"}
            >
              <MenuItem
                key={"answer-form-tabletype-textbox" + props.answerWIP.number}
                value={TableQuestionType.Textbox}
              >
                {TableQuestionType[TableQuestionType.Textbox].toString()}
              </MenuItem>
              <MenuItem
                key={"answer-form-tabletype-number" + props.answerWIP.number}
                value={TableQuestionType.Number}
              >
                {TableQuestionType[TableQuestionType.Number].toString()}
              </MenuItem>
              <MenuItem
                key={"answer-form-tabletype-dropdown" + props.answerWIP.number}
                value={TableQuestionType.Dropdown}
              >
                {TableQuestionType[TableQuestionType.Dropdown].toString()}
              </MenuItem>
              <MenuItem
                key={"answer-form-tabletype-checkbox" + props.answerWIP.number}
                value={TableQuestionType.Checkbox}
              >
                {TableQuestionType[TableQuestionType.Checkbox].toString()}
              </MenuItem>
              <MenuItem
                key={"answer-form-tabletype-date" + props.answerWIP.number}
                value={TableQuestionType.Date}
              >
                {TableQuestionType[TableQuestionType.Date].toString()}
              </MenuItem>
            </Select>
            <FormHelperText>
              {props.tableTypeError === ValidatorError.NoTableTypeSelected
                ? "Please select a table input type."
                : undefined}
            </FormHelperText>
          </FormControl>
          {props.questionType === QuestionType.MultiSelectorTable && (
            <Container
              className={classes.infoContainer}
              key={"answer-form-multi-selector-table-text-container"}
            >
              <TextField
                label="Text"
                className={classes.textField}
                onChange={(e) =>
                  props.handleChange(
                    nameof<AnswerDataModel>("text"),
                    e.target.value
                  )
                }
                value={props.answerWIP.text ?? ""}
                key={"answer-form-mulit-selector-table-text"}
                error={props.tableError !== ValidatorError.NoError}
                required
                helperText={
                  props.tableError !== ValidatorError.NoError &&
                  (props.tableError === ValidatorError.NoText
                    ? "Please enter text."
                    : ValidatorError.TextAlreadyExists
                    ? "This field must be unique for this question. " +
                      "Column " +
                      props.errorColumn +
                      " already has this name."
                    : "An error has occured. No answer number found, please press cancel and try again.")
                }
              />
              {props.answerWIP.tableType === TableQuestionType.Textbox ||
              props.answerWIP.tableType === TableQuestionType.Number ? (
                <Alert
                  key={"alert-multi-" + props.answerWIP.number}
                  severity="info"
                  className={classes.textField}
                >
                  {"This input will set the placeholder text for your " +
                    (props.answerWIP.tableType === TableQuestionType.Textbox
                      ? "text"
                      : "number") +
                    " box in the modal."}
                </Alert>
              ) : props.answerWIP.tableType === TableQuestionType.Checkbox ||
                props.answerWIP.tableType === TableQuestionType.Date ? (
                <Alert
                  key={"alert-multi-check-" + props.answerWIP.number}
                  severity="info"
                  className={classes.textField}
                >
                  {"This input will set the label for your " +
                    (props.answerWIP.tableType === TableQuestionType.Checkbox
                      ? "checkbox in the modal."
                      : "date field in the modal.")}
                </Alert>
              ) : props.answerWIP.tableType === TableQuestionType.Dropdown ? (
                <Alert
                  key={"alert-multi-drop-" + props.answerWIP.number}
                  severity="info"
                  className={classes.textField}
                >
                  {"This input will set the label for your dropdown list."}
                </Alert>
              ) : (
                <></>
              )}
            </Container>
          )}
        </Container>
      )}
      {props.requiredAnswerFields.includes(RequiredAnswerFields.TableType) &&
      props.answerWIP.tableType === TableQuestionType.Number ? (
        <Container
          className={classes.textFieldContainer}
          key={"answer-form-rangemin-rangemax-container"}
        >
          <TextField
            label="Range Min"
            className={classes.textField}
            onChange={(e) =>
              props.handleChange(
                nameof<AnswerDataModel>("rangeMin"),
                e.target.value
              )
            }
            value={props.answerWIP.rangeMin ?? ""}
            type="number"
            key={"answer-form-rangemin-field"}
            required
            error={
              props.rangeMinError === ValidatorError.NoError ? false : true
            }
            helperText={
              props.rangeMinError !== ValidatorError.NoError &&
              (props.rangeMinError === ValidatorError.NoRangeMinValue
                ? "Please enter a minimum range value."
                : "The minimum value must be smaller than the maximum value")
            }
          />
          <TextField
            label="Range Max"
            className={classes.textField}
            onChange={(e) =>
              props.handleChange(
                nameof<AnswerDataModel>("rangeMax"),
                e.target.value
              )
            }
            value={props.answerWIP.rangeMax ?? ""}
            type="number"
            key={"answer-form-rangemax-field"}
            required
            error={
              props.rangeMaxError === ValidatorError.NoError ? false : true
            }
            helperText={
              props.rangeMaxError !== ValidatorError.NoError &&
              (props.rangeMaxError === ValidatorError.NoRangeMaxValue
                ? "Please enter a maximum range value."
                : "The minimum value must be smaller than the maximum value")
            }
          />
        </Container>
      ) : props.answerWIP.tableType === TableQuestionType.Dropdown ? (
        <>
          <Container className={classes.infoContainer}>
            <TextField
              label="Rows"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<AnswerDataModel>("tableDropdownChoices"),
                  e.target.value
                )
              }
              value={
                props.answerWIP.tableDropdownChoices != null
                  ? props.answerWIP.tableDropdownChoices.join("\n")
                  : ""
              }
              multiline
              maxRows={6}
            ></TextField>
            <Alert severity="info">
              Each row in this field will be a row in the dropdown box.
            </Alert>
          </Container>
        </>
      ) : (
        <></>
      )}
      {props.requiredAnswerFields.includes(RequiredAnswerFields.ScoreCap) && (
        <Container
          className={classes.singleContainer}
          key={"answer-form-scorecap-container"}
        >
          <TextField
            label="Score Cap"
            className={classes.textField}
            onChange={(e) =>
              props.handleChange(
                nameof<AnswerDataModel>("scoreCap"),
                e.target.value
              )
            }
            value={props.answerWIP.scoreCap ?? ""}
            type="number"
            key={"answer-form-scorecap-field"}
          />
        </Container>
      )}
    </>
  );
};

export default AnswerForm;
