import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { QuestionType } from "../../../../types/auto/types";

interface Props {
  open: boolean;
  handleCancel: () => void;
  handleAddQuestion: (qT: QuestionType) => void;
}

const SectionBuilderQuestionTypeModal = (props: Props): JSX.Element => {
  return (
    <Dialog
      open={props.open}
      key={"question-type-modal"}
      scroll="paper"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle key={"question-type-modal-dialog-title"}>
        Select Question Type
      </DialogTitle>
      <DialogContent key={"question-type-modal-dialog-content"}>
        <Box
          display="flex"
          flexWrap={"wrap"}
          width={"100%"}
          key={"question-type-modal-dialog-content-container"}
        >
          <Accordion
            sx={{ width: "100%" }}
            TransitionProps={{ unmountOnExit: true }}
            key={"question-type-modal-dialog-buttons-accordian"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="buttons-content"
              id="buttons-header"
              key={"accordian-summary-buttons"}
            >
              <Typography key={"accordian-summary-buttons-title"}>
                Buttons
              </Typography>
            </AccordionSummary>
            <AccordionDetails key={"accordian-details-buttons"}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={"accordian-details-buttons-container"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  key={"accordian-details-buttons-desc-container"}
                >
                  <Typography key={"accordian-details-buttons-desc"}>
                    A set of answers that can be selected from (single selection
                    or multi selection).
                  </Typography>
                </Box>
                <Box key={"accordian-details-buttons-button-container"}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddQuestion(QuestionType.Buttons);
                    }}
                    key={"accordian-details-buttons-button"}
                  >
                    Add Buttons
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ width: "100%" }}
            TransitionProps={{ unmountOnExit: true }}
            key={"checkbox-accoridan"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
              key={"checkbox-acc-summary"}
            >
              <Typography key={"checkbox-title"}>Checkbox</Typography>
            </AccordionSummary>
            <AccordionDetails key={"checkbox-acc-details"}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={"checkbox-accordian-details-container"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  key={"checkbox-desc-container"}
                >
                  <Typography key={"checkbox-desc"}>
                    A question with a single checkbox to tick true or false
                  </Typography>
                </Box>
                <Box
                  sx={{ whiteSpace: "nowrap" }}
                  key={"checkbox-add-button-container"}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddQuestion(QuestionType.CheckBoxSingle);
                    }}
                    key={"add-checkbox-button"}
                  >
                    Add Checkbox
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ width: "100%" }}
            TransitionProps={{ unmountOnExit: true }}
            key={"checkbox-list-accoridan"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
              key={"checkbox-acc-summary"}
            >
              <Typography key={"checkbox-title"}> Checkbox List </Typography>
            </AccordionSummary>
            <AccordionDetails key={"checkbox-acc-details"}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={"checkbox-accordian-details-container"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  key={"checkbox-desc-container"}
                >
                  <Typography key={"checkbox-desc"}>
                    A question with a list of possible answers with checkboxes
                    to tick the chosen answer(s)
                  </Typography>
                </Box>
                <Box
                  sx={{ whiteSpace: "nowrap" }}
                  key={"checkbox-add-button-container"}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddQuestion(QuestionType.CheckBoxList);
                    }}
                    key={"add-checkbox-button"}
                  >
                    Add Checkbox List
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ width: "100%" }}
            TransitionProps={{ unmountOnExit: true }}
            key={"date-accoridan"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="Date-content"
              id="Date-header"
              key={"date-acc-summary"}
            >
              <Typography key={"date-title"}>Date</Typography>
            </AccordionSummary>
            <AccordionDetails key={"date-acc-details"}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={"date-accordian-details-container"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  key={"date-desc-container"}
                >
                  <Typography key={"date-desc"}>
                    A date picker for selecting a date (toggle between month and
                    day).
                  </Typography>
                </Box>
                <Box key={"date-add-button-container"}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddQuestion(QuestionType.Date);
                    }}
                    key={"add-date-button"}
                  >
                    Add Date
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ width: "100%" }}
            TransitionProps={{ unmountOnExit: true }}
            key={"dropdown-accoridan"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
              key={"dropdown-acc-summary"}
            >
              <Typography key={"dropdown-title"}>Dropdown</Typography>
            </AccordionSummary>
            <AccordionDetails key={"dropdown-acc-details"}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={"dropdown-accordian-details-container"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  key={"dropdown-desc-container"}
                >
                  <Typography key={"dropdown-desc"}>
                    A question with a dropdown that contains a list of possible
                    answers to choose one answer from
                  </Typography>
                </Box>
                <Box
                  sx={{ whiteSpace: "nowrap" }}
                  key={"dropdown-add-button-container"}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddQuestion(QuestionType.DropDown);
                    }}
                    key={"add-dropdown-button"}
                  >
                    Add Dropdown
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ width: "100%" }}
            TransitionProps={{ unmountOnExit: true }}
            key={"goals-accoridan"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
              key={"goals-acc-summary"}
            >
              <Typography key={"goals-title"}>Goals</Typography>
            </AccordionSummary>
            <AccordionDetails key={"goals-acc-details"}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={"goals-accordian-details-container"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  key={"goals-desc-container"}
                >
                  <Typography key={"goals-desc"}>
                    A question that has goals for a patient related to the
                    questionnaire. Note this question type cannot be previewed
                    as the goals are linked to the patient.
                  </Typography>
                </Box>
                <Box
                  sx={{ whiteSpace: "nowrap" }}
                  key={"goals-add-button-container"}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddQuestion(QuestionType.Goals);
                    }}
                    key={"add-goals-button"}
                  >
                    Add Goals
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ width: "100%" }}
            TransitionProps={{ unmountOnExit: true }}
            key={"input-table-accoridan"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
              key={"input-table-acc-summary"}
            >
              <Typography key={"input-table-title"}> Input Table </Typography>
            </AccordionSummary>
            <AccordionDetails key={"input-table-acc-details"}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={"input-table-accordian-details-container"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  key={"input-table-desc-container"}
                >
                  <Typography key={"input-table-desc"}>
                    A table that allows rows of information to be submitted via
                    a modal.
                  </Typography>
                </Box>
                <Box key={"input-table-add-button-container"}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddQuestion(QuestionType.MultiSelectorTable);
                    }}
                    key={"add-input-table-button"}
                  >
                    Add Input Table
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ width: "100%" }}
            TransitionProps={{ unmountOnExit: true }}
            key={"number-accoridan"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="number-content"
              id="number-header"
              key={"number-acc-summary"}
            >
              <Typography key={"number-title"}>Number</Typography>
            </AccordionSummary>
            <AccordionDetails key={"number-acc-details"}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={"number-accordian-details-container"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  key={"number-desc-container"}
                >
                  <Typography key={"number-desc"}>A number input.</Typography>
                </Box>
                <Box key={"number-add-button-container"}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddQuestion(QuestionType.Number);
                    }}
                    key={"add-number-button"}
                  >
                    Add Number
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ width: "100%" }}
            TransitionProps={{ unmountOnExit: true }}
            key={"range-accoridan"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="range-content"
              id="range-header"
              key={"range-acc-summary"}
            >
              <Typography key={"range-title"}>Range</Typography>
            </AccordionSummary>
            <AccordionDetails key={"range-acc-details"}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={"range-accordian-details-container"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  key={"range-desc-container"}
                >
                  <Typography key={"range-desc"}>
                    A range answer slider with an option to make it skippable.
                  </Typography>
                </Box>
                <Box key={"range-add-button-container"}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddQuestion(QuestionType.Range);
                    }}
                    key={"add-range-button"}
                  >
                    Add Range
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ width: "100%" }}
            TransitionProps={{ unmountOnExit: true }}
            key={"sketch-accoridan"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
              key={"sketch-acc-summary"}
            >
              <Typography key={"sketch-title"}>Sketch</Typography>
            </AccordionSummary>
            <AccordionDetails key={"sketch-acc-details"}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={"sketch-accordian-details-container"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  key={"sketch-desc-container"}
                >
                  <Typography key={"sketch-desc"}>
                    An answer where the result can be drawn.
                  </Typography>
                </Box>
                <Box key={"sketch-add-button-container"}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddQuestion(QuestionType.Sketch);
                    }}
                    key={"add-sketch-button"}
                  >
                    Add Sketch
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ width: "100%" }}
            TransitionProps={{ unmountOnExit: true }}
            key={"table-accoridan"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
              key={"table-acc-summary"}
            >
              <Typography key={"table-title"}>Table</Typography>
            </AccordionSummary>
            <AccordionDetails key={"table-acc-details"}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={"table-accordian-details-container"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  key={"table-desc-container"}
                >
                  <Typography key={"table-desc"}>
                    A table with multiple different types of fields.
                  </Typography>
                </Box>
                <Box key={"table-add-button-container"}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddQuestion(QuestionType.Table);
                    }}
                    key={"add-table-button"}
                  >
                    Add Table
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ width: "100%" }}
            TransitionProps={{ unmountOnExit: true }}
            key={"textbox-accordian"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="textbox-content"
              id="textbox-header"
              key={"textbox-acc-summary"}
            >
              <Typography key={"textbox-title"}>Textbox</Typography>
            </AccordionSummary>
            <AccordionDetails key={"textbox-acc-details"}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={"textbox-accordian-details-container"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  key={"textbox-desc-container"}
                >
                  <Typography key={"textbox-desc"}>
                    A free text field.
                  </Typography>
                </Box>
                <Box key={"textbox-add-button-container"}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddQuestion(QuestionType.Textbox);
                    }}
                    key={"add-textbox-button"}
                  >
                    Add Textbox
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.handleCancel();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SectionBuilderQuestionTypeModal;
