import { ExtendedLineViewModel } from "../../elements/graph/GraphLegend";

export const GetXAxisDomain = (
  fromDate: Date | undefined,
  toDate: Date | undefined,
  maxXPadding?: number
): number[] => {
  const minDate = fromDate!.getTime();
  const maxDate = toDate!.getTime() + (maxXPadding || 0);

  // Calculate the number of days different the two dates are
  const daysDifferent = Math.ceil((maxDate - minDate) / (1000 * 3600 * 24));

  // The maximum number of values that can be included in the x domain (i.e. the max number of labels)
  const maxXDomainLength = 20;

  // Get the steps needed to reach the maxDate from the minDate in maxXDomainLength steps
  const step = Math.ceil(daysDifferent / (maxXDomainLength - 1));
  let value = new Date(minDate);
  const xDomainArray = [minDate] as number[];

  // The maxDate that we want to go to, this must be higher than the current max date, but less than the next step
  const maxDateIncremented = new Date(maxDate);

  // Until we reach the max date, add the stepped time to the array
  while (value < maxDateIncremented) {
    value.setDate(value.getDate() + step);
    xDomainArray.push(value.getTime());
  }

  return xDomainArray;
};

export const GetMaxYValueForSummaryGraph = (
  lines: ExtendedLineViewModel[]
): number =>
  lines
    ? Math.max(...lines.map((x) => (x.data && !x.hidden ? x.maxScore : 0))) + 1
    : 0;
