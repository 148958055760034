import React, { useEffect, useState } from "react";
import {
  GraphClient,
  PatientViewModel,
  Role,
  TenantOptionsViewModel,
  UserViewModel,
} from "../../../../../types/auto/types";
import { graphColours } from "../../../../../Theme";
import { ExtendedLineViewModel } from "../../../elements/graph/GraphLegend";
import { Configuration } from "../../../../Constants";
import { FetchOverride } from "../../../../utils/Request";
import LineGraphCore from "../summary/LineGraphCore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import GraphHeader from "../header/GraphHeader";
import { Flag } from "@mui/icons-material";
import { useUnmountPromise } from "react-use";
import GraphSectionTitle from "../header/GraphSectionTitle";
import CompletedGoalIcon from "../../../elements/completed-goal-icon/CompletedGoalIcon";
import { CheckIsMobile } from "../../../../utils/MobileStatus";

interface Props {
  patient: PatientViewModel;
  user: UserViewModel;
  fromDate: Date | undefined;
  toDate: Date | undefined;
  showCompletedGoals: boolean;
  setNoData: (noData: boolean) => void;
  hideGraph?: boolean;
  tenancyOptions: TenantOptionsViewModel | undefined;
  firstGoalResponsesLoad: boolean;
  setFirstGoalResponsesLoad: React.Dispatch<React.SetStateAction<boolean>>;
  collapsedGraph?: boolean;
}

const graphWidth = 1000;
const graphHeight = 500;

const GoalResponsesChart = (props: Props): JSX.Element => {
  const [data, setData] = useState<ExtendedLineViewModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const resolveWhileMounted = useUnmountPromise();

  const isMobile = CheckIsMobile();

  const useStyle = makeStyles(() => ({
    splitSummaryContainer: { marginTop: 30, "@media print": { marginTop: 0 } },
    splitSummaryTitle: { marginLeft: 20, marginBottom: 0 },
    accordion: {
      marginLeft: isMobile ? 0 : 60,
      marginRight: isMobile ? 0 : 60,
      marginTop: 20,
      marginBottom: 10,
    },
  }));
  const classes = useStyle();

  var isStaff =
    props.user.role === Role.Clinician ||
    props.user.role === Role.Admin ||
    props.user.role === Role.SuperAdmin;

  useEffect(() => {
    const effect = async () => {
      if (props.firstGoalResponsesLoad) {
        props.setFirstGoalResponsesLoad(false);
        setLoading(true);
        if (isStaff) {
          await resolveWhileMounted(
            new GraphClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getGoalResponsesGraph(
              props.showCompletedGoals,
              props.fromDate,
              props.toDate,
              props.patient.id
            )
          )
            .then((x) => {
              if (x.lines === undefined || x.lines.length === 0) {
                props.setNoData(true);
                setLoading(false);
                setError(false);
                return;
              }

              setData(
                x.lines?.map(
                  (x, i) =>
                    ({
                      ...x,
                      hidden: false,
                      colour: graphColours[i % graphColours.length],
                    } as ExtendedLineViewModel)
                ) || []
              );
              props.setNoData(false);
              setError(false);
              setLoading(false);
            })
            .catch(() => {
              setError(true);
              setLoading(false);
              props.setNoData(false);
            });
        } else {
          await resolveWhileMounted(
            new GraphClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getCurrentGoalResponsesGraph(
              props.showCompletedGoals,
              props.fromDate,
              props.toDate
            )
          )
            .then((x) => {
              if (x.lines === undefined || x.lines.length === 0) {
                props.setNoData(true);
                setLoading(false);
                setError(false);
                return;
              }

              setData(
                x.lines?.map(
                  (x, i) =>
                    ({
                      ...x,
                      hidden: false,
                      colour: graphColours[i % graphColours.length],
                    } as ExtendedLineViewModel)
                ) || []
              );
              props.setNoData(false);
              setError(false);
              setLoading(false);
            })
            .catch(() => {
              setError(true);
              setLoading(false);
              props.setNoData(false);
            });
        }
      }
    };
    effect();
  }, [
    setData,
    props,
    resolveWhileMounted,
    isStaff,
    props.firstGoalResponsesLoad,
  ]);

  if (
    data === undefined ||
    data === null ||
    data.length === 0 ||
    props.hideGraph
  ) {
    return <></>;
  }

  return (
    <>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        className={classes.accordion}
        disableGutters
        defaultExpanded={!props.collapsedGraph}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <GraphSectionTitle title="Personalised Goals" />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {data.map((x) => (
            <Container
              id={x.lineName}
              key={x.lineName + x.colour}
              className={classes.splitSummaryContainer}
              sx={{
                paddingLeft: isMobile ? 0 : 1,
                paddingRight: isMobile ? 0 : 1,
                paddingBottom: 2,
              }}
            >
              <GraphHeader
                title={x.lineName ?? ""}
                icon={
                  <Tooltip title={(x.completed ? "Completed " : "") + "Goal"}>
                    <>{x.completed ? <CompletedGoalIcon /> : <Flag />}</>
                  </Tooltip>
                }
                hidePrint={error || loading}
              >
                <LineGraphCore
                  data={[x]}
                  loading={loading}
                  error={error}
                  width={graphWidth}
                  height={graphHeight}
                  fromDate={props.fromDate}
                  toDate={props.toDate}
                  yDomain={[0, 10]}
                  fromGoal={true}
                  tenancyOptions={props.tenancyOptions}
                />
              </GraphHeader>
            </Container>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default GoalResponsesChart;
