import React, { forwardRef } from "react";
import { InputBaseComponentProps } from "@mui/material";
import { PatternFormat } from "react-number-format";

interface Props {}

const NHSNumberInput = forwardRef<InputBaseComponentProps, Props>(
  function NumberFormatCustom(props, ref) {
    return (
      <PatternFormat
        {...props}
        getInputRef={ref}
        format="### ### ####"
        mask="*"
        onChange={(e) => e.preventDefault()}
        onValueChange={(value) => {
          // When the value changes from the pattern format, update the input component's values with value and formattedValue
          (props as any).onChange({
            target: {
              value: value.value,
              formattedValue: value.formattedValue,
            },
          });
        }}
      />
    );
  }
);

export default NHSNumberInput;
