import {
  VictoryArea,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from "victory";
import { ExtendedLineViewModel } from "../../../elements/graph/GraphLegend";
import React from "react";
import { cloneDeep } from "lodash";

export const GroupedLine = (
  data: ExtendedLineViewModel[],
  keySuffix: string
): JSX.Element[] =>
  data.map((group, index) => (
    // Data lines
    <VictoryLine
      key={"line-" + index + keySuffix}
      interpolation="linear"
      style={{
        data: {
          stroke: group.hidden ? "rgba(0, 0, 0, 0)" : group.colour,
        },
      }}
      data={group.data}
    />
  ));

export const DataScatter = (
  data: ExtendedLineViewModel[],
  keySuffix: string
): JSX.Element[] =>
  data
    .filter((x) => !x.hidden)
    .map((group, index) => (
      // Data point scatter
      <VictoryScatter
        key={"scatter-" + index + keySuffix}
        style={{
          data: {
            stroke: group.hidden ? "rgba(0, 0, 0, 0)" : group.colour,
            strokeWidth: "2",
            fill: group.hidden ? "rgba(0, 0, 0, 0)" : "rgb(255, 255, 255)",
          },
        }}
        size={4}
        data={group.data}
        labels={(data) => [
          group.lineName || "",
          data.datum.x === undefined || isNaN(data.datum.x)
            ? ""
            : (data.datum.x as Date).toLocaleDateString("en-GB"),

          "Score: " +
            (data.datum.y === undefined || isNaN(data.datum.y)
              ? ""
              : data.datum.y.toString()),
        ]}
        labelComponent={
          <VictoryTooltip
            pointerLength={20}
            flyoutPadding={{
              left: 20,
              right: 20,
              top: 20,
              bottom: 20,
            }}
            flyoutStyle={{
              fill: "rgba(65, 65, 65, 0.8)",
              strokeWidth: 1,
              overflowWrap: "break-word",
            }}
            style={{
              fill: "white",
              overflowWrap: "break-word",
            }}
            renderInPortal
          />
        }
      />
    ));

export const VersionChangeScatter = (
  data: ExtendedLineViewModel[],
  keySuffix: string
): JSX.Element[] =>
  data
    .filter(
      (x) => x.versions !== undefined && x.versions.length > 0 && !x.hidden
    )
    .map(
      // Version change scatter
      (group, index) => (
        <VictoryScatter
          key={"scatter-version-" + index + keySuffix}
          style={{
            data: {
              stroke: group.hidden ? "rgba(0, 0, 0, 0)" : group.colour,
              strokeWidth: "2",
              fill: group.hidden ? "rgba(0, 0, 0, 0)" : "rgba(255, 255, 255)",
            },
          }}
          size={6}
          symbol="plus"
          data={group.versions}
          labels={(data) => [
            group.lineName || "",
            data.datum.x === undefined || isNaN(data.datum.x)
              ? ""
              : (data.datum.x as Date).toLocaleDateString("en-GB"),
            "Questionnaire Version Changed",
          ]}
          labelComponent={
            <VictoryTooltip
              pointerLength={20}
              flyoutPadding={{
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
              }}
              flyoutStyle={{
                fill: "rgba(65, 65, 65, 0.8)",
                strokeWidth: 1,
                overflowWrap: "break-word",
              }}
              style={{
                fill: "white",
                overflowWrap: "break-word",
              }}
              renderInPortal
            />
          }
        />
      )
    );

export const AdditionalPlotsScatter = (
  data: ExtendedLineViewModel[],
  keySuffix: string
): JSX.Element[] =>
  data
    .filter(
      (x) =>
        x.additionalPlotsData !== undefined &&
        x.additionalPlotsData.length > 0 &&
        !x.hidden
    )
    .map(
      // Additional plots scatter
      (group, index) => (
        <VictoryScatter
          key={"scatter-additional-" + index + keySuffix}
          style={{
            data: {
              stroke: group.hidden ? "rgba(0, 0, 0, 0)" : group.colour,
              strokeWidth: "2",
              fill: group.hidden ? "rgba(0, 0, 0, 0)" : "rgba(255, 255, 255)",
            },
          }}
          size={6}
          symbol="square"
          data={group.additionalPlotsData}
          labels={(data) => [
            group.lineName || "",
            data.datum.x === undefined || isNaN(data.datum.x)
              ? ""
              : (data.datum.x as Date).toLocaleDateString("en-GB"),
            "Additional Data imported",
          ]}
          labelComponent={
            <VictoryTooltip
              pointerLength={20}
              flyoutPadding={{
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
              }}
              flyoutStyle={{
                fill: "rgba(65, 65, 65, 0.8)",
                strokeWidth: 1,
                overflowWrap: "break-word",
              }}
              style={{
                fill: "white",
                overflowWrap: "break-word",
              }}
              renderInPortal
            />
          }
        />
      )
    );

export const updateNoResponseYValues = (
  dataPoints: ExtendedLineViewModel[],
  MaxY: number
): ExtendedLineViewModel[] => {
  // Deep clone the array of dataPoints
  const clonedDataPoints = cloneDeep(dataPoints);
  // Apply changes to the cloned array
  clonedDataPoints.forEach((Questionnaire) => {
    if (Questionnaire.noResponseData) {
      Questionnaire.noResponseData.forEach((point) => {
        point.y = (MaxY - 1) / 40;
      });
    }
  });

  return clonedDataPoints;
};

export const NoResponseScatter = (
  data: ExtendedLineViewModel[],
  keySuffix: string
): JSX.Element[] =>
  data
    .filter(
      (x) =>
        x.noResponseData !== undefined &&
        x.noResponseData.length > 0 &&
        !x.hidden
    )
    .map(
      // No response scatter
      (group, index) => (
        <VictoryScatter
          key={"scatter-noresponse-" + index + keySuffix}
          style={{
            data: {
              stroke: group.hidden ? "rgba(0, 0, 0, 0)" : group.colour,
              strokeWidth: "2",
              fill: group.hidden ? "rgba(0, 0, 0, 0)" : "rgba(255, 255, 255)",
            },
          }}
          size={6}
          symbol={"triangleDown"}
          data={group.noResponseData}
          labels={(data) => [
            group.lineName || "",
            data.datum.x === undefined || isNaN(data.datum.x)
              ? ""
              : (data.datum.x as Date).toLocaleDateString("en-GB"),
            "No Response",
          ]}
          labelComponent={
            <VictoryTooltip
              pointerLength={20}
              flyoutPadding={{
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
              }}
              flyoutStyle={{
                fill: "rgba(65, 65, 65, 0.8)",
                strokeWidth: 1,
                overflowWrap: "break-word",
              }}
              style={{
                fill: "white",
                overflowWrap: "break-word",
              }}
              renderInPortal
            />
          }
        />
      )
    );

export const CreateBackgroundVictory = (
  stable: number,
  borderline: number,
  clinical: number,
  maxYValue: number
): JSX.Element[] =>
  [0, stable, borderline, clinical]
    .filter((x) => x <= maxYValue)
    .map((x, index) => {
      if (x === 0) {
        return (
          <VictoryArea
            style={{
              data: {
                fill: stable > 0 ? "darkgreen" : "green",
                opacity: 0.2,
              },
            }}
            data={[
              {
                x: new Date(1, 1, 1),
                y: stable > 0 ? stable : borderline,
                y0: 0,
              },
              { x: new Date(), y: stable > 0 ? stable : borderline, y0: 0 },
            ]}
            key={"clear-" + index}
          />
        );
      } else if (x === stable && stable > 0) {
        return (
          <VictoryArea
            style={{ data: { fill: "green", opacity: 0.2 } }}
            data={[
              { x: new Date(1, 1, 1), y: borderline, y0: stable },
              { x: new Date(), y: borderline, y0: stable },
            ]}
            key={"stable-" + index}
          />
        );
      } else if (x === borderline) {
        return (
          <VictoryArea
            style={{ data: { fill: "orange", opacity: 0.2 } }}
            data={[
              { x: new Date(1, 1, 1), y: clinical, y0: borderline },
              { x: new Date(), y: clinical, y0: borderline },
            ]}
            key={"borderline-" + index}
          />
        );
      } else {
        return (
          <VictoryArea
            style={{ data: { fill: "red", opacity: 0.2 } }}
            data={[
              { x: new Date(1, 1, 1), y: maxYValue, y0: clinical },
              { x: new Date(), y: maxYValue, y0: clinical },
            ]}
            key={"clinical-" + index}
          />
        );
      }
    });

export const CreateReverseBackgroundVictory = (
  stable: number,
  borderline: number,
  clinical: number,
  maxYValue: number
): JSX.Element[] =>
  [0, stable, borderline, clinical]
    .filter((x) => x <= maxYValue)
    .map((x, index) => {
      if (x === 0) {
        return (
          <VictoryArea
            style={{ data: { fill: "red", opacity: 0.2 } }}
            data={[
              { x: new Date(1, 1, 1), y: clinical, y0: 0 },
              { x: new Date(), y: clinical, y0: 0 },
            ]}
            key={"clinical-" + index}
          />
        );
      } else if (x === borderline) {
        return (
          <VictoryArea
            style={{ data: { fill: "orange", opacity: 0.2 } }}
            data={[
              { x: new Date(1, 1, 1), y: borderline, y0: clinical },
              { x: new Date(), y: borderline, y0: clinical },
            ]}
            key={"borderline-" + index}
          />
        );
      } else if (x === stable && stable > 0) {
        return (
          <VictoryArea
            style={{ data: { fill: "green", opacity: 0.2 } }}
            data={[
              { x: new Date(1, 1, 1), y: borderline, y0: stable },
              { x: new Date(), y: borderline, y0: stable },
            ]}
            key={"stable-" + index}
          />
        );
      } else {
        return (
          <VictoryArea
            style={{
              data: {
                fill: stable > 0 ? "darkgreen" : "green",
                opacity: 0.2,
              },
            }}
            data={[
              {
                x: new Date(1, 1, 1),
                y: maxYValue,
                y0: stable > 0 ? stable : borderline,
              },
              {
                x: new Date(),
                y: maxYValue,
                y0: stable > 0 ? stable : borderline,
              },
            ]}
            key={"clear-" + index}
          />
        );
      }
    });
