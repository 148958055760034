import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

const useStyle = makeStyles({
  pageNotFound: {
    paddingTop: "25vh",
  },
  bold: {
    fontWeight: "bold",
  },
  p: {
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
  },
});

const PageNotFound = () => {
  const classes = useStyle();
  return (
    <Container maxWidth="sm">
      <div className={classes.pageNotFound}>
        <Typography
          variant="h1"
          align="center"
          color="primary"
          className={classes.bold}
        >
          404
        </Typography>
        <Typography
          variant="h2"
          align="center"
          color="primary"
          className={classes.bold}
        >
          Page Not Found
        </Typography>
        <Typography align="center">
          We're sorry, we couldn't find the page you requested.
        </Typography>
        <ul>
          <li>
            <Link to="/" className={classes.link}>
              Go to home page
            </Link>
          </li>
        </ul>
      </div>
    </Container>
  );
};

export default PageNotFound;
