import {
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Theme,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import ReplayIcon from "@mui/icons-material/Replay";
import MedicationIcon from "@mui/icons-material/Medication";
import {
  AllowListPatientSearchRegex,
  Configuration,
  HelpText,
  MaxDate,
} from "../../../Constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers/";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { theme as customTheme } from "../../../../Theme";
import FormErrorText from "../../elements/errors/FormErrorText";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { useDebounce } from "../../../utils/UseDebounce";
import { TenantClient, TimelineCardType } from "../../../../types/auto/types";
import GradingIcon from "@mui/icons-material/Grading";
import FlagIcon from "@mui/icons-material/Flag";
import { useMountedState } from "react-use";
import ArticleIcon from "@mui/icons-material/Article";
import { FetchOverride } from "../../../utils/Request";

interface Props {
  filteringOfTimeline: (
    searchTerm: string,
    dateRangeStart: string,
    dateRangeEnd: string,
    responseType: TimelineCardType | null
  ) => void;
  getInitialTimeline: () => void;
}

export const FilteringTimelineBar = (props: Props): JSX.Element => {
  const [invalidCharacterUsed, setInvalidCharacterUsed] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchText2, setSearchText2] = useState("");
  const [dateRangeStart, setDateRangeStart] = useState<Date | null>(null);
  const [dateRangeEnd, setDateRangeEnd] = useState<Date | null>(null);
  const [responseTypeFilter, setResponseTypeFilter] = useState("");
  const isMobile = CheckIsMobile();
  const debouncedSearchFilter = useDebounce(searchText, 1000);
  const [newSelection, setNewSelection] = useState(false);
  const [dateRangeException, setDateRangeException] = useState(false);
  const [medicationEnabled, setMedicationEnabled] = useState(false);
  const [datasetEnabled, setDatasetEnabled] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const useStyle = makeStyles((th: Theme) => ({
    textSearchContainer: {
      display: "flex",
      padding: 0,
      margin: 0,
      width: isMobile ? "100%" : "90%",
    },
    dateRangeContainer: {
      padding: 0,
      paddingBottom: isMobile ? 10 : 0,
      margin: 0,
      display: isMobile ? "block" : "flex",
      width: "100%",
    },

    responseTypeContainer: {
      display: "flex",
      padding: 0,
      paddingBottom: isMobile ? 10 : 0,
      margin: 0,
      width: isMobile ? "100%" : "80%",
    },

    innerContainer: {
      display: isMobile ? "block" : "flex",
      alignItems: "center",
    },
    outerPaper: {
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      paddingTop: "10px",
      paddingBottom: "10px",
      boxShadow: "none",
    },
    inputText: {
      width: "100%",
    },
    inputLabel: {
      marginLeft: "-15px",
    },
    clearSearchContainer: {
      paddingLeft: isMobile ? "0" : "20",
      padding: 0,
      textAlign: "center",
      width: "40%",
    },
    responseTypeFormControl: {
      width: "100%",
    },
    tooltip: {
      display: "block",
    },
    daterangeAndTooltip: {
      display: "flex",
      padding: 0,
    },
    fromDate: {
      width: isMobile ? "100%" : "48%",
      marginRight: isMobile ? 0 : "10px",
    },
    toDate: {
      width: isMobile ? "100%" : "48%",
    },
  }));

  const classes = useStyle(customTheme);

  const isMountedState = useMountedState();

  useEffect(() => {
    if (isMountedState()) {
      var date1 =
        dateRangeStart == null ? "" : new Date(dateRangeStart).toDateString();
      var date2 =
        dateRangeEnd == null ? "" : new Date(dateRangeEnd).toDateString();
      var timelineType = isNaN(Number(responseTypeFilter))
        ? null
        : Number(responseTypeFilter);

      if (searchText2 !== debouncedSearchFilter || newSelection) {
        setSearchText(debouncedSearchFilter);
        props.filteringOfTimeline(
          debouncedSearchFilter,
          date1,
          date2,
          timelineType
        );
      }
      setSearchText2(debouncedSearchFilter);
      setNewSelection(false);

      if (firstLoad) {
        new TenantClient(Configuration.SERVER_ROOT, FetchOverride)
          .getMedicationEnabled()
          .then((medEnabled) => {
            setMedicationEnabled(medEnabled);
          });

        new TenantClient(Configuration.SERVER_ROOT, FetchOverride)
          .getExternalDataEnabled()
          .then((externalDataEnabled) => {
            setDatasetEnabled(externalDataEnabled);
          });
        setFirstLoad(false);
      }
    }
  }, [
    props,
    debouncedSearchFilter,
    searchText2,
    dateRangeStart,
    dateRangeEnd,
    responseTypeFilter,
    newSelection,
    isMountedState,
    firstLoad,
  ]);

  return (
    <Paper component="form" className={classes.outerPaper}>
      <Container className={classes.innerContainer}>
        <Container className={classes.textSearchContainer}>
          <TextField
            variant="standard"
            value={searchText}
            label="Search"
            onChange={(e: any) => {
              var searchText = e.target.value;
              var regexp = new RegExp(AllowListPatientSearchRegex);
              if (regexp.test(searchText)) {
                setInvalidCharacterUsed(true);
                return;
              } else {
                setInvalidCharacterUsed(false);
              }
              setSearchText(searchText);
            }}
            className={classes.inputText}
          />
          <Tooltip
            title={HelpText.PATIENT_TIMELINE.SEARCH_TIMELINE}
            enterTouchDelay={0}
            className={classes.tooltip}
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Container>
        <Container className={classes.daterangeAndTooltip}>
          <Container className={classes.dateRangeContainer}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                label="From Date"
                value={dateRangeStart}
                onChange={(e: any) => {
                  setDateRangeStart(e);
                  if (!isNaN(Date.parse(e))) {
                    if (new Date(e).getFullYear() > 1900) {
                      if (e > (dateRangeEnd ?? MaxDate)) {
                        setDateRangeException(true);
                      } else {
                        if (dateRangeEnd === null) {
                          setDateRangeEnd(new Date());
                          setNewSelection(true);
                        } else {
                          setDateRangeException(false);
                          setNewSelection(true);
                        }
                      }
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    className={classes.fromDate}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                label="To Date"
                value={dateRangeEnd}
                onChange={(e: any) => {
                  setDateRangeEnd(e);
                  if (!isNaN(Date.parse(e))) {
                    if (new Date(e).getFullYear() > 1900) {
                      if (
                        dateRangeStart !== undefined &&
                        dateRangeStart !== null &&
                        e < dateRangeStart
                      ) {
                        setDateRangeException(true);
                      } else {
                        if (dateRangeStart === null) {
                          setDateRangeStart(new Date("01-01-2000"));
                          setNewSelection(true);
                        } else {
                          setDateRangeException(false);
                          setNewSelection(true);
                        }
                      }
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    className={classes.toDate}
                  />
                )}
              />
            </LocalizationProvider>
          </Container>
          <Tooltip
            title={HelpText.PATIENT_TIMELINE.DATE_RANGE}
            enterTouchDelay={0}
            className={classes.tooltip}
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Container>
        <Container className={classes.responseTypeContainer}>
          <FormControl className={classes.responseTypeFormControl}>
            <InputLabel id="responseTypes" className={classes.inputLabel}>
              Response Type
            </InputLabel>
            <Select
              variant="standard"
              value={responseTypeFilter}
              labelId="responseTypes"
              label="responseTypes"
              onChange={(e: any) => {
                setNewSelection(true);
                setResponseTypeFilter(e.target.value);
              }}
              displayEmpty={true}
            >
              <MenuItem
                key="timelineTypeBoth"
                value={TimelineCardType.All}
                role="menuitem"
              >
                {TimelineCardType[TimelineCardType.All]}
              </MenuItem>
              <MenuItem
                key="timelineTypeQuestionnaire"
                value={TimelineCardType.Questionnaire}
                role="menuitem"
              >
                <GradingIcon />
                {TimelineCardType[TimelineCardType.Questionnaire]}
              </MenuItem>
              <MenuItem
                key="timelineTypeGoal"
                value={TimelineCardType.Goal}
                role="menuitem"
              >
                <FlagIcon />
                {TimelineCardType[TimelineCardType.Goal]}
              </MenuItem>
              {medicationEnabled && (
                <MenuItem
                  key="timelineTypeMedication"
                  value={TimelineCardType.Medication}
                  role="menuitem"
                >
                  <MedicationIcon />
                  {TimelineCardType[TimelineCardType.Medication]}
                </MenuItem>
              )}
              {datasetEnabled && (
                <MenuItem
                  key="timelineTypeDataset"
                  value={TimelineCardType.Dataset}
                  role="menuitem"
                >
                  <ArticleIcon />
                  {TimelineCardType[TimelineCardType.Dataset]}
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <Tooltip
            title={HelpText.PATIENT_TIMELINE.TIMELINE_TYPE}
            enterTouchDelay={0}
            className={classes.tooltip}
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Container>
        <Container className={classes.clearSearchContainer}>
          <Button
            onClick={() => {
              setDateRangeStart(null);
              setDateRangeEnd(null);
              setSearchText("");
              setResponseTypeFilter("");
              setDateRangeException(false);
              props.getInitialTimeline();
            }}
          >
            <ReplayIcon />
            Clear fields
          </Button>
        </Container>
      </Container>
      <FormErrorText
        errorText="Do not use invalid characters in search i.e. #,!,?"
        isInvalid={invalidCharacterUsed}
      />
      <FormErrorText
        errorText="Please select a valid date range"
        isInvalid={dateRangeException}
      />
    </Paper>
  );
};
