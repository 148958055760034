import React from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
} from "victory";
import { ExtendedMedicationViewModel } from "../../../elements/graph/GraphMedicationLegend";
import { DataScatter, GroupedLine } from "./MedicationLineGraphCoreHelper";

interface Props {
  width: number;
  mobileSelectedDomain:
    | {
        x?: DomainTuple | undefined;
        y?: DomainTuple | undefined;
      }
    | undefined;
  setZoomDomain: React.Dispatch<
    React.SetStateAction<
      | {
          x?: DomainTuple | undefined;
          y?: DomainTuple | undefined;
        }
      | undefined
    >
  >;
  xAxisValues: number[];
  data: ExtendedMedicationViewModel[];
}

const MobileZoomable = (props: Props): JSX.Element => (
  <VictoryChart
    padding={{ top: 0, bottom: 30, left: 50, right: 50 }}
    width={props.width}
    height={90}
    domainPadding={{ x: 20, y: 20 }}
    containerComponent={
      <VictoryBrushContainer
        brushDimension="x"
        brushDomain={props.mobileSelectedDomain}
        onBrushDomainChange={(b) => props.setZoomDomain(b)}
        style={{ margin: "auto" }}
        height={90}
      />
    }
    aria-label={
      "GraphMobileZoom" + props.data && props.data.length === 1
        ? props.data[0].lineName
        : "all"
    }
  >
    <VictoryAxis
      style={{
        grid: { stroke: "lightgray", strokeWidth: 0.5 },
      }}
      tickValues={props.xAxisValues}
      tickFormat={(t: number) => {
        return new Date(t).toLocaleDateString("en-GB");
      }}
      fixLabelOverlap
    />
    {GroupedLine(props.data, "mobile-zoom")}
    {DataScatter(props.data, "mobile-zoom")}
  </VictoryChart>
);

export default MobileZoomable;
