import { useEffect, useState } from "react";
import { useIsMounted } from "./UseIsMounted";

export function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const isMounted = useIsMounted();

  useEffect(() => {
    const handler = setTimeout(
      () => isMounted.current && setDebouncedValue(value),
      delay
    );
    return () => clearTimeout(handler);
  }, [value, delay, isMounted]);

  return debouncedValue;
}
