import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Dispatch, SetStateAction, useState } from "react";
import { theme } from "../../../../../Theme";
import {
  Day,
  Frequency,
  PromptFrequency,
  PromptScheduleViewModel,
} from "../../../../../types/auto/types";
import { CheckIsMobile } from "../../../../utils/MobileStatus";
import DailyPromptScheduling from "./AtTimeScheduling";
import MonthlyPromptScheduling from "./MonthlyPromptScheduling";
import WeeklyPromptScheduling from "./WeeklyPromptScheduling";
import AtTimeScheduling from "./AtTimeScheduling";

interface Props {
  schedulingDetails: PromptScheduleViewModel;
  setSchedulingDetails: (schedulingDetails: PromptScheduleViewModel) => void;
  setRerender: Dispatch<SetStateAction<boolean>>;
}

const PromptScheduling = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    container: {
      display: isMobile ? "block" : "flex",
      width: "100%",
    },
    frequencyAmountContainer: {
      flex: 1,
      marginRight: isMobile ? 0 : "10px",
      marginBottom: isMobile ? "15px" : 0,
    },
    timePeriodContainer: {
      flex: 2,
    },
    timeSetContainer: {
      flex: 3,
    },
    formControl: {
      width: "100%",
    },
    frequencyInput: {
      width: "fit-content",
    },
  });
  const classes = useStyle(theme);
  const [frequencyAmountError, setFrequencyAmountError] = useState(false);

  const getDefaultFrequency = (frequency: PromptFrequency | undefined) =>
    frequency === undefined || frequency == null
      ? PromptFrequency.Daily
      : frequency;

  function setFrequencyAmount(frequencyAmount: number) {
    props.schedulingDetails.frequencyAmount = frequencyAmount;
    props.setSchedulingDetails(props.schedulingDetails);
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.frequencyAmountContainer}>
          <TextField
            className={classes.frequencyInput}
            label="Every"
            type="number"
            value={props.schedulingDetails.frequencyAmount ?? 1}
            onChange={(event) => {
              if (Number(event.target.value) < 1) {
                setFrequencyAmountError(true);
                return;
              } else {
                setFrequencyAmountError(false);
              }
              setFrequencyAmount(Number(event.target.value) ?? 1);
            }}
            error={frequencyAmountError}
            helperText={frequencyAmountError && "Value must be larger than 0"}
          />
        </div>
        <div className={classes.timePeriodContainer}>
          <FormControl className={classes.formControl}>
            <InputLabel id="frequencyLabel">Prompt Frequency</InputLabel>
            <Select
              value={getDefaultFrequency(props.schedulingDetails.frequency)}
              labelId="frequencyLabel"
              onChange={(event) => {
                props.schedulingDetails.frequency = event.target
                  .value as PromptFrequency;

                // Set default values if there are no values provided
                switch (props.schedulingDetails.frequency) {
                  case PromptFrequency.Daily:
                    break;
                  case PromptFrequency.Weekly:
                    if (
                      props.schedulingDetails.daysOfWeek === undefined ||
                      props.schedulingDetails.daysOfWeek === null ||
                      props.schedulingDetails.daysOfWeek?.length === 0
                    ) {
                      props.schedulingDetails.daysOfWeek = [Day.Monday];
                    }
                    break;
                  case PromptFrequency.Monthly:
                    if (
                      (props.schedulingDetails.dayOfMonth === undefined ||
                        props.schedulingDetails.dayOfMonth === null) &&
                      (props.schedulingDetails.daysOfWeek === undefined ||
                        props.schedulingDetails.daysOfWeek.length !== 1) &&
                      (props.schedulingDetails.monthPlace === undefined ||
                        props.schedulingDetails.monthPlace === null)
                    ) {
                      props.schedulingDetails.dayOfMonth = 1;
                      props.schedulingDetails.daysOfWeek = undefined;
                    }
                    break;
                }

                props.setSchedulingDetails(props.schedulingDetails);
              }}
            >
              <MenuItem
                key="Daily"
                value={PromptFrequency.Daily}
                role="menuitem"
              >
                Daily
              </MenuItem>
              <MenuItem
                key="Weekly"
                value={PromptFrequency.Weekly}
                role="menuitem"
              >
                Weekly
              </MenuItem>
              <MenuItem
                key="Monthly"
                value={PromptFrequency.Monthly}
                role="menuitem"
              >
                Monthly
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <Divider flexItem orientation="vertical" />
        <div className={classes.timeSetContainer}>
          {(props.schedulingDetails.frequency === PromptFrequency.Daily ||
            props.schedulingDetails.frequency === undefined ||
            props.schedulingDetails.frequency === null) && (
            <DailyPromptScheduling
              timeOfDay={props.schedulingDetails.timeOfDay}
              setTimeOfDay={(timeOfDay) => {
                props.schedulingDetails.timeOfDay = timeOfDay;
                props.setSchedulingDetails(props.schedulingDetails);
              }}
              frequencyType={Frequency.Days}
              frequencyAmount={props.schedulingDetails.frequencyAmount}
              setFrequencyAmount={(frequencyAmount) => {
                props.schedulingDetails.frequencyAmount = frequencyAmount;
                props.setSchedulingDetails(props.schedulingDetails);
              }}
            />
          )}
          {props.schedulingDetails.frequency === PromptFrequency.Weekly && (
            <AtTimeScheduling
              timeOfDay={props.schedulingDetails.timeOfDay}
              setTimeOfDay={(timeOfDay) => {
                props.schedulingDetails.timeOfDay = timeOfDay;
                props.setSchedulingDetails(props.schedulingDetails);
              }}
              frequencyType={Frequency.Months}
              frequencyAmount={props.schedulingDetails.frequencyAmount}
              setFrequencyAmount={(frequencyAmount) => {
                props.schedulingDetails.frequencyAmount = frequencyAmount;
                props.setSchedulingDetails(props.schedulingDetails);
              }}
            />
          )}
          {props.schedulingDetails.frequency === PromptFrequency.Monthly && (
            <div>
              <AtTimeScheduling
                timeOfDay={props.schedulingDetails.timeOfDay}
                setTimeOfDay={(timeOfDay) => {
                  props.schedulingDetails.timeOfDay = timeOfDay;
                  props.setSchedulingDetails(props.schedulingDetails);
                }}
                frequencyType={Frequency.Months}
                frequencyAmount={props.schedulingDetails.frequencyAmount}
                setFrequencyAmount={(frequencyAmount) => {
                  props.schedulingDetails.frequencyAmount = frequencyAmount;
                  props.setSchedulingDetails(props.schedulingDetails);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        {props.schedulingDetails.frequency === PromptFrequency.Monthly && (
          <MonthlyPromptScheduling
            timeOfDay={props.schedulingDetails.timeOfDay}
            setTimeOfDay={(timeOfDay) => {
              props.schedulingDetails.timeOfDay = timeOfDay;
              props.setSchedulingDetails(props.schedulingDetails);
            }}
            dayOfMonth={props.schedulingDetails.dayOfMonth}
            setDayOfMonth={(dayOfMonth) => {
              props.schedulingDetails.dayOfMonth = dayOfMonth;
              props.setSchedulingDetails(props.schedulingDetails);
            }}
            monthPlace={props.schedulingDetails.monthPlace}
            setMonthPlace={(monthPlace) => {
              props.schedulingDetails.monthPlace = monthPlace;
              props.setSchedulingDetails(props.schedulingDetails);
              props.setRerender((x) => !x);
            }}
            dayOfWeek={
              props.schedulingDetails.daysOfWeek &&
              props.schedulingDetails.daysOfWeek.length > 0
                ? props.schedulingDetails.daysOfWeek[0]
                : undefined
            }
            setDayOfWeek={(dayOfWeek) => {
              props.schedulingDetails.daysOfWeek =
                dayOfWeek !== undefined ? [dayOfWeek] : undefined;
              props.setSchedulingDetails(props.schedulingDetails);
              props.setRerender((x) => !x);
            }}
            frequencyAmount={props.schedulingDetails.frequencyAmount}
            setFrequencyAmount={(frequencyAmount) => {
              props.schedulingDetails.frequencyAmount = frequencyAmount;
              props.setSchedulingDetails(props.schedulingDetails);
            }}
          />
        )}
        {props.schedulingDetails.frequency === PromptFrequency.Weekly && (
          <WeeklyPromptScheduling
            timeOfDay={props.schedulingDetails.timeOfDay}
            setTimeOfDay={(timeOfDay) => {
              props.schedulingDetails.timeOfDay = timeOfDay;
              props.setSchedulingDetails(props.schedulingDetails);
            }}
            daysOfWeek={props.schedulingDetails.daysOfWeek || [Day.Monday]}
            setDaysOfWeek={(daysOfWeek) => {
              props.schedulingDetails.daysOfWeek = daysOfWeek;
              props.setSchedulingDetails(props.schedulingDetails);
            }}
            frequencyAmount={props.schedulingDetails.frequencyAmount}
            setFrequencyAmount={(frequencyAmount) => {
              props.schedulingDetails.frequencyAmount = frequencyAmount;
              props.setSchedulingDetails(props.schedulingDetails);
            }}
          />
        )}
      </div>
    </>
  );
};

export default PromptScheduling;
