import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  Theme,
  Typography,
} from "@mui/material";
import { CheckIsMobile, CheckIsSmallDevice } from "../../../utils/MobileStatus";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import {
  DatasetClient,
  DatasetViewModel,
  MeasurementViewModel,
  PatientDatasetViewModel,
} from "../../../../types/auto/types";
import moment from "moment";
import { Container } from "@mui/system";
import CreateEditPatientDataset from "./CreateEditPatientDataset";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";

interface Props {
  patientDataset: PatientDatasetViewModel;
  patientId: number;
  reload: () => void;
  datasets: DatasetViewModel[];
}

const PatientDataset = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const useStyle = makeStyles((th: Theme) => ({
    headerAndActions: {
      display: "flex",
      paddingRight: "0",
      paddingLeft: "0",
    },
    card: {
      marginTop: "10px",
      width: isMobile ? "100%" : "49.5%",
      marginRight: isMobile ? "auto" : "0.5%",
      minWidth: isSmallDevice ? "180px" : "250px",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      height: "fit-content",
      "&:nth-child(even)": {
        marginRight: isMobile ? "auto" : "0",
        marginLeft: isMobile ? "auto" : "0.5%",
      },
    },
    patientDataset: {
      display: "flex",
    },
    cardActions: {
      cursor: "pointer",
      paddingLeft: 0,
    },
    collapse: {
      width: "100%",
    },
    noMedications: {
      padding: 10,
      paddingTop: 0,
    },
    expandButton: {
      marginLeft: "auto",
    },
    subTitle: {
      fontWeight: "bold",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  }));
  const classes = useStyle(theme);
  const [expanded, setExpanded] = useState(false);
  const [measurements, setMeasurements] = useState<MeasurementViewModel[]>([]);
  const [loading, setLoading] = useState(false);

  return (
    <Card className={classes.card} elevation={0}>
      <CardContent>
        <Container className={classes.headerAndActions}>
          <Typography
            className={classes.patientDataset}
            variant="h5"
            component="h2"
          >
            {props.patientDataset.dataset?.name}{" "}
          </Typography>
          <CreateEditPatientDataset
            patientDataset={props.patientDataset}
            patientId={props.patientId}
            refresh={props.reload}
            datasets={props.datasets}
          />
        </Container>
        <Typography>
          {" "}
          {moment(props.patientDataset.testDate).format("DD-MM-YYYY")}
        </Typography>
        <CardActions
          className={classes.cardActions}
          onClick={() => {
            setExpanded((x) => !x);
            if (!expanded && measurements.length === 0) {
              new DatasetClient(Configuration.SERVER_ROOT, FetchOverride)
                .getMeasurementsLinkedToDataset(props.patientDataset.datasetId)
                .then((measurements) => {
                  setMeasurements(measurements);
                  setLoading(false);
                });
            }
          }}
        >
          <Typography className={classes.subTitle}>Measurements</Typography>
          <div className={classes.expandButton}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </div>
        </CardActions>
        <Collapse
          className={classes.collapse}
          in={expanded}
          timeout="auto"
          unmountOnExit
        >
          <Container disableGutters>
            {loading && (
              <CircularProgress
                className={classes.loadingSpinner}
                aria-label="Loading"
              />
            )}
          </Container>
          {!loading &&
            measurements.map((measurement, key) => (
              <Typography key={key}>
                <b>{measurement.name}</b>:{" "}
                {props.patientDataset.measurements?.find(
                  (x) => x.measurementId === measurement.id
                ) !== undefined
                  ? props.patientDataset.measurements?.find(
                      (x) => x.measurementId === measurement.id
                    )?.value
                  : "No value supplied"}
              </Typography>
            ))}
        </Collapse>
      </CardContent>
    </Card>
  );
};
export default PatientDataset;
