import { Button, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useContext } from "react";
import { useUnmountPromise } from "react-use";
import { theme } from "../../../../Theme";
import {
  PatientViewModel,
  ScheduleViewModel,
} from "../../../../types/auto/types";
import { UserDispatchContext } from "../../elements/stores/UserStore";
import { GetReportingFrequencyString } from "../../schedule/PromptFrequencyReporting";
import QuestionnaireCard from "./QuestionnaireCard";
import { GetLastScheduledPromptDate } from "./ScheduledQuestionnaireCardHelper";
import { handleContinue } from "./ScheduledQuestionnaireRedirectHelper";

interface Props {
  scheduledQuestionnaire: ScheduleViewModel;
  patient: PatientViewModel;
  setValidRequest: (validRequest: boolean) => void;
  isStaff: boolean;
}

const ScheduledQuestionnaireCard = (props: Props): JSX.Element => {
  const setUser = useContext(UserDispatchContext);
  const resolveWhileMounted = useUnmountPromise();
  const useStyle = makeStyles((th: Theme) => ({
    questionnaireName: {
      fontWeight: "bold",
      display: "inline",
      marginRight: 2,
    },
    completeQuestionnaireNow: {
      color: "red",
      display: "inline",
    },
    questionnaireCompleteStatus: {
      display: "inline",
    },
    lastResponseTime: { fontStyle: "italic", marginTop: 15 },
    promptSchedule: { marginTop: 15 },
    questionnaireButton: {
      margin: 10,
      marginBottom: 5,
      padding: "default",
      width: "fit-content",
      whiteSpace: "nowrap",
    },
  }));
  const classes = useStyle(theme);

  const GetQuestionnaireCompleteStatusElement = (): JSX.Element => {
    if (props.scheduledQuestionnaire.isSubmitted) {
      return <></>;
    }
    if (props.scheduledQuestionnaire.startDate < new Date()) {
      if (props.scheduledQuestionnaire.promptSchedule === undefined) {
        return (
          <Typography className={classes.questionnaireCompleteStatus}>
            You do not receive prompts for this questionnaire so you may
            complete it at any time.
          </Typography>
        );
      } else if (
        (props.scheduledQuestionnaire.lastResponseDate == null &&
          GetLastScheduledPromptDate(
            props.scheduledQuestionnaire.promptSchedule
          ) < new Date()) ||
        (props.scheduledQuestionnaire.lastResponseDate != null &&
          GetLastScheduledPromptDate(
            props.scheduledQuestionnaire.promptSchedule
          ) > props.scheduledQuestionnaire.lastResponseDate)
      ) {
        return (
          <Typography className={classes.completeQuestionnaireNow}>
            Please complete this questionnaire now.
          </Typography>
        );
      } else {
        return (
          <Typography className={classes.questionnaireCompleteStatus}>
            You are not due to complete this questionnaire at the moment.
          </Typography>
        );
      }
    } else {
      return (
        <Typography className={classes.questionnaireCompleteStatus}>
          You are not due to complete this questionnaire at the moment.
        </Typography>
      );
    }
  };
  return (
    <QuestionnaireCard
      title={props.scheduledQuestionnaire.caseload?.name || ""}
      body={
        <>
          <Typography className={classes.questionnaireName}>
            {props.scheduledQuestionnaire.questionnaire?.name}:
          </Typography>
          {GetQuestionnaireCompleteStatusElement()}
          <Typography className={classes.lastResponseTime}>
            {props.scheduledQuestionnaire.lastResponseDate == null
              ? ""
              : "You last completed this questionnaire on: " +
                moment(props.scheduledQuestionnaire.lastResponseDate).format(
                  "DD-MM-YYYY [at] HH:mm"
                ) +
                "."}
          </Typography>
          {props.scheduledQuestionnaire.promptSchedule && (
            <Typography className={classes.promptSchedule}>
              You receive prompts{" "}
              {GetReportingFrequencyString(
                props.scheduledQuestionnaire.promptSchedule,
                false
              )}
            </Typography>
          )}
        </>
      }
      buttons={
        <>
          <Button
            className={classes.questionnaireButton}
            aria-label={
              props.isStaff ? "Complete As Clinician" : "Complete questionnaire"
            }
            onClick={() =>
              handleContinue(
                false,
                props.scheduledQuestionnaire,
                props.patient,
                setUser,
                props.setValidRequest,
                resolveWhileMounted
              )
            }
            variant="contained"
            disabled={
              props.scheduledQuestionnaire.startDate > new Date() ||
              props.scheduledQuestionnaire.isSubmitted ||
              (props.scheduledQuestionnaire.startDate > new Date() &&
                props.scheduledQuestionnaire.promptSchedule != null &&
                ((props.scheduledQuestionnaire.lastResponseDate == null &&
                  (GetLastScheduledPromptDate(
                    props.scheduledQuestionnaire.promptSchedule
                  ) < props.scheduledQuestionnaire.promptSchedule.created ||
                    GetLastScheduledPromptDate(
                      props.scheduledQuestionnaire.promptSchedule
                    ) > new Date())) ||
                  (props.scheduledQuestionnaire.lastResponseDate != null &&
                    GetLastScheduledPromptDate(
                      props.scheduledQuestionnaire.promptSchedule
                    ) < props.scheduledQuestionnaire.lastResponseDate)))
            }
          >
            {props.isStaff ? "Complete As Clinician" : "Complete questionnaire"}
          </Button>
          {props.isStaff && (
            <Button
              className={classes.questionnaireButton}
              aria-label="Complete As Patient"
              onClick={() =>
                handleContinue(
                  true,
                  props.scheduledQuestionnaire,
                  props.patient,
                  setUser,
                  props.setValidRequest,
                  resolveWhileMounted
                )
              }
              variant="contained"
              disabled={
                (props.scheduledQuestionnaire.promptSchedule != null &&
                  GetLastScheduledPromptDate(
                    props.scheduledQuestionnaire.promptSchedule
                  ) <
                    (props.scheduledQuestionnaire.lastResponseDate ??
                      new Date())) ||
                props.scheduledQuestionnaire.questionnaire?.authenticatedOnly
              }
            >
              Complete as Patient
            </Button>
          )}
        </>
      }
    />
  );
};

export default ScheduledQuestionnaireCard;
