import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import {
  Gender,
  PatientClient,
  PreferredContact,
  PatientViewModel,
  UserViewModel,
} from "../../../../types/auto/types";
import { theme as customTheme, theme } from "../../../../Theme";
import moment from "moment";
import {
  CheckIsLargeDevice,
  CheckIsMobile,
  CheckIsSmallDevice,
} from "../../../utils/MobileStatus";
import React, { useCallback, useEffect, useState } from "react";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import AvatarLogo from "../../elements/nav/AvatarLogo";
import ContactPreferences from "../../elements/contact-preferences/ContactPreferences";
import CreateEditPatient from "../create-patient/CreateEditPatient";
import { useCommonStyle } from "../../../utils/CommonStyles";

interface Props {
  patient: PatientViewModel;
  integration: boolean;
  reloadPatient: () => void;
  dashboardSupported: boolean;
  nhsNumberRequired: boolean;
  setPatient: React.Dispatch<
    React.SetStateAction<PatientViewModel | undefined>
  >;
  user: UserViewModel;
  customPatientId: boolean;
  customPatientIdDisplayName: string;
  customPatientIdRegex: string;
  customPatientIdMandatory: boolean;
}

const ViewPatientDetails = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const isLargeDevice = CheckIsLargeDevice();

  const useCommonStyles = useCommonStyle(
    customTheme,
    isSmallDevice,
    isMobile,
    isLargeDevice
  );
  const commonStyles = useCommonStyles(customTheme);

  const birthDateFormatted = moment(props.patient.dateOfBirth).format(
    "DD-MMM-YYYY"
  );
  const genderIndex =
    props.patient.gender !== undefined ? props.patient.gender : -1;

  const getPreference = useCallback(() => {
    return props.patient.preferredContact === PreferredContact.Email
      ? [PreferredContact.Email]
      : props.patient.preferredContact === PreferredContact.Mobile
      ? [PreferredContact.Mobile]
      : [PreferredContact.Email, PreferredContact.Mobile];
  }, [props.patient.preferredContact]);

  const [messagingPreferences, setMessagingPreferences] = useState<
    PreferredContact[]
  >(getPreference());

  const handlePreferenceChange = (
    event: React.MouseEvent<HTMLElement>,
    newMessagingPreferences: PreferredContact[]
  ) => {
    if (newMessagingPreferences.length) {
      const patientPreference =
        newMessagingPreferences.length === 2
          ? PreferredContact.Both
          : newMessagingPreferences[0];

      new PatientClient(Configuration.SERVER_ROOT, FetchOverride)
        .updateMessagingPreferences(props.patient.id, patientPreference)
        .then(() => {
          setMessagingPreferences(newMessagingPreferences);
          props.setPatient((x) => {
            if (x === undefined) {
              return x;
            }
            x.preferredContact = patientPreference;
            return x;
          });
        });
    }
  };

  const patientUsername =
    props.patient.firstName + " " + props.patient.lastName;

  useEffect(() => {
    setMessagingPreferences(getPreference());
  }, [
    props.patient.id,
    props.user,
    props.patient.preferredContact,
    getPreference,
  ]);

  const withCustomId = [
    { name: "First Name", value: props.patient.firstName },
    { name: "Last Name", value: props.patient.lastName },
    { name: "True Colours ID", value: props.patient.id },
    { name: "NHS Number", value: props.patient.nhsNumber || "Not Provided" },
    { name: " Date of Birth", value: birthDateFormatted },
    { name: "Gender", value: Gender[genderIndex] },
    {
      name: props.customPatientIdDisplayName,
      value: props.patient.customPatientId,
    },
  ] as {
    name: string;
    value: string;
  }[];

  const withoutCustomId = [
    { name: "First Name", value: props.patient.firstName },
    { name: "Last Name", value: props.patient.lastName },
    { name: "True Colours ID", value: props.patient.id },
    { name: "NHS Number", value: props.patient.nhsNumber || "Not Provided" },
    { name: " Date of Birth", value: birthDateFormatted },
    { name: "Gender", value: Gender[genderIndex] },
  ] as {
    name: string;
    value: string;
  }[];

  return (
    <Grid container className={commonStyles.cardContainer}>
      {!props.integration && (
        <CreateEditPatient
          patient={props.patient}
          onSave={() => {
            props.reloadPatient();
            setMessagingPreferences(getPreference());
          }}
          user={props.user}
          dashboardSupported={props.dashboardSupported}
          nhsNumberRequired={props.nhsNumberRequired}
          customPatientId={props.customPatientId}
          customPatientIdDisplayName={props.customPatientIdDisplayName}
          customPatientIdRegex={props.customPatientIdRegex}
          customPatientIdMandatory={props.customPatientIdMandatory}
        />
      )}
      <Grid item xs={12} sm={5.9}>
        <Card className={commonStyles.card} elevation={0}>
          <CardContent
            className={commonStyles.cardContent}
            sx={{ ":last-child": { paddingBottom: 1 } }}
          >
            <Container className={commonStyles.avatarContainer}>
              <AvatarLogo
                username={patientUsername}
                backgroundColour={theme.palette.secondary.main}
                colour="white"
                size={56}
              />
            </Container>
            {(props.customPatientId ? withCustomId : withoutCustomId).map(
              (x, key) => (
                <Container className={commonStyles.textContainer} key={key}>
                  <Typography className={commonStyles.textField}>
                    {x.name}:
                  </Typography>
                  <Typography className={commonStyles.textValue}>
                    {x.value}
                  </Typography>
                </Container>
              )
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={5.9}>
        <Card className={commonStyles.card} elevation={0}>
          <CardContent
            className={commonStyles.cardContent}
            sx={{ ":last-child": { paddingBottom: 1 } }}
          >
            <Typography className={commonStyles.contactDetails}>
              Contact details
            </Typography>
            {(
              [
                {
                  name: "Mobile Number",
                  value: props.patient.mobileNumber || "Not Provided",
                },
                {
                  name: "Email",
                  value: props.patient.contactEmail || "Not Provided",
                },
              ] as {
                name: string;
                value: string;
              }[]
            ).map((x, key) => (
              <Container className={commonStyles.textContainer2} key={key}>
                <Typography className={commonStyles.textField2}>
                  {x.name}:
                </Typography>
                <Typography className={commonStyles.textValue2}>
                  {x.value}
                </Typography>
              </Container>
            ))}
          </CardContent>
          <Typography className={commonStyles.cardTitle}>
            Contact preference
          </Typography>
          <ContactPreferences
            messagingPreferences={messagingPreferences}
            onChange={handlePreferenceChange}
            fromPatientForm={false}
            hasMobile={
              props.patient.mobileNumber !== undefined &&
              props.patient.mobileNumber !== null
            }
          />
        </Card>
      </Grid>
    </Grid>
  );
};
export default ViewPatientDetails;
