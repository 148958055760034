import {
  DataModelType,
  QuestionDataModel,
  QuestionnaireBuilderDataModel,
  SectionDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import DependsOnEditModal from "./DependsOnEditModal";
import { useState } from "react";
import { Mediation } from "@mui/icons-material";

interface Props {
  questionnaire: QuestionnaireBuilderDataModel;
  section: SectionDataModel;
  question?: QuestionDataModel;
  index: number;
  type: DataModelType;
  setSectionWIP: (
    index: number,
    field: string,
    value: DependsOn | null
  ) => void;
}

export interface DependsOn {
  [key: string]: number[];
}

const DependsOnBuilder = (props: Props): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  function handleSubmit(
    index: number,
    field: string,
    newDependsOn: DependsOn | null
  ) {
    props.setSectionWIP(index, field, newDependsOn);
  }

  return (
    <Box display={"flex"} gap={1} alignItems={"center"}>
      <Button variant="contained" onClick={() => setOpenModal(true)}>
        Edit Depends On
      </Button>
      {props.section.dependsOn && (
        <Tooltip
          title={"This section depends on certain questions to be visible."}
          enterTouchDelay={0}
          key={"dependsOn-tooltip-container"}
        >
          <IconButton key={"dependsOn-icon-button"}>
            <Mediation key={"dependsOn-icon"} sx={{ color: "red" }} />
          </IconButton>
        </Tooltip>
      )}
      <DependsOnEditModal
        open={openModal}
        index={props.index}
        type={props.type}
        questionnaire={props.questionnaire}
        question={props.question}
        dependsOn={
          props.type === DataModelType.Section
            ? props.section.dependsOn ?? {}
            : props.question == null
            ? {}
            : props.question.dependsOn ?? {}
        }
        handleClose={() => setOpenModal(false)}
        handleSubmit={(i, f, dO) => handleSubmit(i, f, dO)}
      />
    </Box>
  );
};

export default DependsOnBuilder;
