import { useEffect, useState } from "react";
import { useUnmountPromise } from "react-use";
import {
  QuestionnaireBuilderDataModel,
  QuestionDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import {
  Box,
  Button,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { AutofillFrom } from "./AutofillFromBuilder";
import AutofillFromAddModal from "./AutofillFromAddModal";
import {
  QuestionnaireViewModel,
  QuestionnaireClient,
} from "../../../../types/auto/types";
import { Configuration } from "./../../../Constants";
import { FetchOverride } from "./../../../utils/Request";

interface Props {
  questionnaire: QuestionnaireBuilderDataModel;
  autofillFrom: AutofillFrom;
  question?: QuestionDataModel;
  allQuestionnaires: QuestionnaireViewModel[];
  singleAutofillFrom: AutofillFrom;
  setSingleAutofillFrom: (dO: AutofillFrom) => void;
  handleChange: (index: number) => void;
  handleLocalChange: (e: any, isQ: boolean) => void;
  handleDelete: (index: number) => void;
  handleClose: () => void;
  noQuestionnaireError: boolean;
  noQuestionError: boolean;
  resetErrors: (to: boolean) => void;
}

const AutofillFromSelector = (props: Props): JSX.Element => {
  const headers: string[] = ["Questionnaire", "Question", "Edit", "Delete"];

  const [row, setRow] = useState<JSX.Element | null>(null);
  const [autofillFromAddModalOpen, setAutofillFromAddModalOpen] =
    useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [formFields, setFormFields] = useState<JSX.Element[]>([]);
  const [isAdd, setIsAdd] = useState<boolean>(false);

  function loadRows() {
    var localRows: JSX.Element | null = null;
    var Qid = props.autofillFrom?.questionnaireId ?? -1;
    if (Qid === -1) {
      setRow(null);
    } else {
      var Questionnaire = props.allQuestionnaires.find(
        // eslint-disable-next-line
        (questionnaire) => questionnaire.id == Qid
      );
      localRows = (
        <TableRow key={"autofillFrom-row-"}>
          <TableCell key={"questionnaire"} width={250}>
            <TextField
              value={Questionnaire?.name}
              key={"autofillFrom-questionnaire-" + Questionnaire?.name}
              variant="outlined"
              multiline
              maxRows={4}
              fullWidth
            />
          </TableCell>
          <TableCell key={"question"}>
            <TextField
              value={"Question " + props.autofillFrom?.questionNumber}
              key={"autofillFrom-question-"}
              variant="outlined"
              multiline
              maxRows={4}
              fullWidth
            />
          </TableCell>
          <TableCell key={"EditButton-cell-"} width={150}>
            <Button
              id={"edit-row-"}
              variant="contained"
              key={"EditButton-"}
              onClick={() => {
                editResponseFromKey(0);
              }}
              fullWidth
            >
              Edit
            </Button>
          </TableCell>
          <TableCell key={"DeleteButton-cell-"} width={150}>
            <Button
              id={"delete-row-"}
              variant="contained"
              key={"DeleteButton-"}
              onClick={() => {
                props.handleDelete(0);
              }}
              fullWidth
            >
              Delete
            </Button>
          </TableCell>
        </TableRow>
      );
    }
    setRow(localRows);
  }

  async function buildQuestionnaireDropdown() {
    var qDrop: JSX.Element[] = [
      <MenuItem value={""} key={"questionnaire-placeholder"}>
        <em>Questionnaire</em>
      </MenuItem>,
    ];
    var allQuestionnaires = await getAllQuestionnaires();
    for (let i = 0; i < allQuestionnaires.length; i++) {
      //if there is not a published version for that questionnaire we ignore
      if (allQuestionnaires[i].name !== props.questionnaire.name) {
        qDrop.push(
          <MenuItem
            value={allQuestionnaires[i].id}
            key={"questionnaire-" + allQuestionnaires[i].id}
          >
            {allQuestionnaires[i].name}
          </MenuItem>
        );
      }
    }
    return qDrop;
  }

  const questionnaireClient = new QuestionnaireClient(
    Configuration.SERVER_ROOT,
    FetchOverride
  );

  const resolveWhileMounted = useUnmountPromise();

  async function getQuestionnaire(qId: number): Promise<any> {
    return new Promise((resolve) => {
      resolveWhileMounted(
        questionnaireClient.getLatestQuestionnaireVersion(qId, true)
      )
        .then((questionnaire) => {
          resolve(questionnaire);
        })
        .catch(() => resolve(null));
    });
  }
  async function getAllQuestionnaires(): Promise<any> {
    return new Promise((resolve) => {
      resolveWhileMounted(questionnaireClient.getAllQuestionnaires(true))
        .then((questionnaire) => {
          resolve(questionnaire);
        })
        .catch(() => resolve(null));
    });
  }

  async function buildQuestionDropdown(questionnaireNumber: number) {
    var aDrop: JSX.Element[] = [];
    var questionnaire = await getQuestionnaire(questionnaireNumber);
    if (questionnaire == null) {
      return aDrop;
    }
    if (questionnaire.sections !== undefined) {
      for (let s = 0; s < questionnaire.sections.length; s++) {
        var sectionLength = questionnaire.sections[s].questions?.length;
        for (let q = 0; q < (sectionLength ?? 0); q++) {
          var section = questionnaire.sections[s];
          if (section.questions !== undefined) {
            var question = section.questions[q];
            if (question.questionType === props.question?.questionType) {
              aDrop.push(
                <MenuItem
                  value={JSON.stringify({
                    questionNumber: question.number,
                    questionnaireVersion: questionnaire.version,
                  })}
                  key={"question" + question.number}
                >
                  {"Question " + question.number}
                </MenuItem>
              );
            }
          }
        }
      }
    }
    return aDrop;
  }

  async function loadFormFields() {
    var questionnaireid: number = -1;
    var question: number = -1;
    var version: number = -1;
    var questionnairesDropdown: JSX.Element[] = [];
    var questionDropdown: JSX.Element[] = [];
    var formFields: JSX.Element[] = [];

    if (props.singleAutofillFrom === undefined) {
      questionnaireid = -1;
      question = -1;
      version = -1;
    } else {
      questionnaireid = props.singleAutofillFrom.questionnaireId;
      question = props.singleAutofillFrom.questionNumber;
      version = props.singleAutofillFrom.questionnaireVersion;
    }
    questionnairesDropdown = await buildQuestionnaireDropdown();
    if (questionnaireid !== -1) {
      questionDropdown = await buildQuestionDropdown(questionnaireid);
    }

    formFields.push(
      <Box
        width={"100%"}
        gridArea={"questionnaire"}
        margin={0}
        paddingRight={2}
        paddingLeft={0}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignContent={"flex-start"}
        flexWrap={"wrap"}
        key={"questionnaire-container-"}
      >
        <FormControl sx={{ minWidth: "100%" }}>
          <InputLabel id="questionnaire-label">Questionnaire</InputLabel>
          <Select
            labelId="questionnaire-label"
            id="questionnaire-select"
            key={"questionnaire-select"}
            label="Questionnaire"
            onChange={(e) => {
              props.handleLocalChange(e, true);
            }}
            value={questionnaireid === -1 ? "" : questionnaireid}
          >
            {questionnairesDropdown}
          </Select>
        </FormControl>
      </Box>
    );
    if (questionnaireid !== -1) {
      var DefaultValue = JSON.stringify({
        questionNumber: question,
        questionnaireVersion: version,
      });
      if (DefaultValue === "{}") {
        DefaultValue = "";
      }
      formFields.push(
        <Box
          margin={0}
          display={"flex"}
          flexDirection={"row"}
          width={"100%"}
          justifyContent={"flex-start"}
          flexWrap={"wrap"}
          gridArea={"question"}
          padding={0}
          key={"question-container-"}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel id="question-label">Question</InputLabel>
            <Select
              labelId="question-label"
              id="question-select"
              key={"question-select"}
              label="Question"
              onChange={(e) => {
                props.handleLocalChange(e, false);
              }}
              value={DefaultValue}
              disabled={questionDropdown.length === 0}
            >
              {questionDropdown}
            </Select>
            {questionDropdown.length === 0 && (
              <Alert severity="error">
                No valid questions matching question type
              </Alert>
            )}
          </FormControl>
        </Box>
      );
    }

    setFormFields(formFields);
  }

  function addTarget() {
    var newAutofill: AutofillFrom;
    props.setSingleAutofillFrom(newAutofill);
    setIndex(0);
    setAutofillFromAddModalOpen(true);
  }

  function editResponseFromKey(index: number) {
    props.setSingleAutofillFrom(props.autofillFrom);
    setIndex(index);
    props.resetErrors(false);
    setAutofillFromAddModalOpen(true);
  }

  useEffect(
    () => {
      loadRows();
    },
    // eslint-disable-next-line
    [props.autofillFrom]
  );

  useEffect(
    () => {
      loadFormFields();
    },
    // eslint-disable-next-line
    [index, props.singleAutofillFrom]
  );

  return (
    <>
      <Box key={"autofillFrom-selector-table-box-"}>
        <TableContainer
          component={Paper}
          key={"autofillFrom-selector-table-container-"}
        >
          <Table
            sx={{ minWidth: 1000 }}
            aria-label="simple table"
            key={"autofillFrom-selector-table-"}
          >
            <TableHead key={"autofillFrom-selector-table-head-"}>
              <TableRow key={"autofillFrom-selector-table-head-row-"}>
                {headers?.map((header) => (
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    key={"header-" + header}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody key={"autofillFrom-selector-table-body-"}>
              {row}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display={"flex"} justifyContent={"space-between"}>
          {row == null && (
            <Button
              onClick={async () => {
                addTarget();
              }}
              variant="contained"
              sx={{ marginTop: 2, marginBottom: 2 }}
              key={"autofillFrom-selector-table-add-button-"}
            >
              Add Target
            </Button>
          )}
          <Button
            onClick={() => {
              props.handleClose();
            }}
            variant="contained"
            sx={{ marginTop: 2, marginBottom: 2 }}
          >
            Close
          </Button>
        </Box>
      </Box>
      <AutofillFromAddModal
        open={autofillFromAddModalOpen}
        formFields={formFields}
        allQuestionnaires={props.allQuestionnaires}
        handleClose={() => {
          if (isAdd) {
            setIsAdd(false);
          }
          props.resetErrors(true);
          setAutofillFromAddModalOpen(false);
        }}
        handleUpdate={() => {
          props.handleChange(index);
          setAutofillFromAddModalOpen(false);
        }}
        noQuestionnaireError={props.noQuestionnaireError}
        noQuestionError={props.noQuestionError}
        key={"autofillFrom-selector-table-modal-"}
      />
    </>
  );
};

export default AutofillFromSelector;
