import React, { useEffect, useState } from "react";
import {
  GraphClient,
  MedicationLineGraphDataViewModel,
  PatientViewModel,
  TenantClient,
} from "../../../../../types/auto/types";
import { Configuration } from "../../../../Constants";
import { FetchOverride } from "../../../../utils/Request";
import MedicationLineGraphCore from "./MedicationLineGraphCore";
import { useMountedState, useUnmountPromise } from "react-use";
import MedicationIcon from "@mui/icons-material/Medication";
import { ExtendedMedicationViewModel } from "../../../elements/graph/GraphMedicationLegend";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GraphSectionTitle from "../header/GraphSectionTitle";
import GraphHeader from "../header/GraphHeader";
import { CheckIsMobile } from "../../../../utils/MobileStatus";

interface Props {
  patient: PatientViewModel;
  fromDate: Date | undefined;
  toDate: Date | undefined;
  setNoData: (noData: boolean) => void;
  hideGraph?: boolean;
  isStaff?: boolean;
  firstMedicationLoad: boolean;
  setFirstMedicationLoad: React.Dispatch<React.SetStateAction<boolean>>;
  collapsedGraph?: boolean;
}

const graphWidth = 1000;
const graphHeight = 500;

const MedicationChart = (props: Props): JSX.Element => {
  const [data, setData] = useState<ExtendedMedicationViewModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [usingMedication, setUsingMedication] = useState(false);
  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  const isMobile = CheckIsMobile();

  const useStyle = makeStyles(() => ({
    accordion: {
      marginLeft: isMobile ? 0 : 60,
      marginRight: isMobile ? 0 : 60,
      marginTop: 20,
      marginBottom: 10,
    },
  }));
  const classes = useStyle();

  useEffect(() => {
    const applyGraph = (x: MedicationLineGraphDataViewModel) => {
      if (x.lines === undefined || x.lines.length === 0) {
        props.setNoData(true);
        setLoading(false);
        setError(false);
        return;
      }

      setData(
        x.lines?.map(
          (x, i) =>
            ({
              ...x,
              hidden: false,
            } as ExtendedMedicationViewModel)
        ) || []
      );
      props.setNoData(false);
      setError(false);
      setLoading(false);
    };
    const effect = async () => {
      if (props.firstMedicationLoad) {
        props.setFirstMedicationLoad(false);
        if (isMountedState()) {
          setLoading(true);
          await resolveWhileMounted(
            new TenantClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getMedicationEnabled()
          ).then((medEnabled) => setUsingMedication(medEnabled));
          if (props.isStaff) {
            await resolveWhileMounted(
              new GraphClient(
                Configuration.SERVER_ROOT,
                FetchOverride
              ).getMedicationGraph(
                props.fromDate,
                props.toDate,
                props.patient.id
              )
            )
              .then((x) => {
                applyGraph(x);
              })
              .catch(() => {
                if (isMountedState()) {
                  setError(true);
                  setLoading(false);
                  props.setNoData(false);
                }
              });
          } else {
            await resolveWhileMounted(
              new GraphClient(
                Configuration.SERVER_ROOT,
                FetchOverride
              ).getCurrentPatientMedicationGraph(props.fromDate, props.toDate)
            )
              .then((x) => {
                applyGraph(x);
              })
              .catch(() => {
                if (isMountedState()) {
                  setError(true);
                  setLoading(false);
                  props.setNoData(false);
                }
              });
          }
        }
      }
    };
    effect();
  }, [
    setData,
    props,
    resolveWhileMounted,
    isMountedState,
    props.firstMedicationLoad,
  ]);

  if (props.hideGraph || !usingMedication) {
    return <></>;
  }

  return (
    <>
      {
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          className={classes.accordion}
          disableGutters
          defaultExpanded={!props.collapsedGraph}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <GraphSectionTitle title="Medications" />
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <GraphHeader
              title="Medications Graph"
              icon={
                <Tooltip title="Medication">
                  <MedicationIcon />
                </Tooltip>
              }
              hidePrint={error || loading}
            >
              <MedicationLineGraphCore
                data={data}
                setData={setData}
                loading={loading}
                error={error}
                width={graphWidth}
                height={graphHeight}
                fromDate={props.fromDate}
                toDate={props.toDate}
              />
            </GraphHeader>
          </AccordionDetails>
        </Accordion>
      }
    </>
  );
};

export default MedicationChart;
