import { FormControl, FormControlLabel, Switch } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { theme } from "../../../../Theme";
import { ScheduleFormSetupArchived } from "../../../HelpText";
import InfoHint from "../../elements/form-elements/InfoHint";
import { makeStyles } from "@mui/styles";
import PromptScheduling from "./PromptScheduling/PromptScheduling";
import ArchivePromptScheduling from "./PromptScheduling/ArchivePromptScheduling";
import {
  PromptScheduleViewModel,
  ArchiveCountType,
} from "../../../../types/auto/types";

interface Props {
  promptSchedule: PromptScheduleViewModel;
  setPromptSchedule: Dispatch<
    SetStateAction<PromptScheduleViewModel | undefined>
  >;
  setRerender: Dispatch<SetStateAction<boolean>>;
}

const PromptSchedulingForm = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    formControl: { marginBottom: 20 },
    receivePrompts: { display: "flex" },
    hint: { marginTop: 10 },
  });
  const classes = useStyle(theme);

  return (
    <>
      <PromptScheduling
        schedulingDetails={props.promptSchedule}
        setSchedulingDetails={(details) => {
          props.setRerender((x) => !x);
          props.setPromptSchedule(details);
        }}
        setRerender={props.setRerender}
      />
      <FormControl className={classes.formControl}>
        <div className={classes.receivePrompts}>
          <FormControlLabel
            control={
              <Switch
                checked={
                  props.promptSchedule.archivesAfter !== undefined &&
                  props.promptSchedule.archivesAfter !== null
                }
                color="secondary"
                onChange={() =>
                  props.setPromptSchedule((x) => {
                    if (x === undefined) {
                      x = new PromptScheduleViewModel();
                    }
                    x.archiveCountType =
                      x.archiveCountType !== undefined
                        ? undefined
                        : ArchiveCountType.TotalMissedResponses;
                    x.archivesAfter =
                      x.archivesAfter !== undefined ? undefined : 1;

                    props.setRerender((y) => !y);
                    return x;
                  })
                }
              />
            }
            label="Setup Prompt Archiving"
          />
          <InfoHint
            helpText={ScheduleFormSetupArchived}
            className={classes.hint}
          />
        </div>
        {props.promptSchedule.archivesAfter !== undefined &&
          props.promptSchedule.archivesAfter !== null && (
            <ArchivePromptScheduling
              archivesAfter={props.promptSchedule.archivesAfter}
              setArchivesAfter={(archivesAfter) =>
                props.setPromptSchedule((x) => {
                  if (x === undefined) {
                    x = new PromptScheduleViewModel();
                  }
                  x.archivesAfter = archivesAfter;
                  props.setRerender((x) => !x);
                  return x;
                })
              }
              archiveCountType={props.promptSchedule.archiveCountType}
              setArchiveCountType={(archiveCountType) =>
                props.setPromptSchedule((x) => {
                  if (x === undefined) {
                    x = new PromptScheduleViewModel();
                  }
                  x.archiveCountType = archiveCountType;
                  props.setRerender((x) => !x);
                  return x;
                })
              }
            />
          )}
      </FormControl>
    </>
  );
};

export default PromptSchedulingForm;
