import FormErrorText from "./FormErrorText";

export const Error400 = (): JSX.Element => {
  return (
    <FormErrorText
      errorText="The request is invalid. Please try again."
      isInvalid
    />
  );
};
