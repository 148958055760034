import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./ts/views/Login";
import AuthedRoute from "./ts/components/elements/nav/AuthedRoute";
import UnauthedRoute from "./ts/components/elements/nav/UnauthedRoute";
import PageNotFound from "./ts/views/PageNotFound";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Theme";
import UserStore from "./ts/components/elements/stores/UserStore";
import "bootstrap/dist/css/bootstrap.min.css";
import AdministratorSearch from "./ts/views/Administrators";
import UserSearchPage from "./ts/views/UserHomePage";
import Questionnaire from "./ts/views/Questionnaire";
import Settings from "./ts/views/Settings";
import ViewPatient from "./ts/views/ViewPatient";
import SharedCaseloads from "./ts/views/SharedCaseloads";
import { Role } from "./types/auto/types";
import ViewClinician from "./ts/views/ViewStaff";
import UserHomePage from "./ts/views/UserHomePage";
import Reports from "./ts/views/Reports";
import Goal from "./ts/views/Goals";
import Medications from "./ts/views/Medications";
import ExternalData from "./ts/views/ExternalData";
import Questionnaires from "./ts/views/Questionnaires";
import PreviewQuestionnaire from "./ts/views/PreviewQuestionnaire";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import PrintingTemplate from "./ts/views/PrintingTemplate";

const cache = createCache({
  key: "tc",
  nonce:
    document
      .querySelector('meta[property="csp-nonce"]')
      ?.getAttribute("content") ?? "",
  prepend: true,
});

const App = (): React.ReactElement => (
  <ThemeProvider theme={theme}>
    <CacheProvider value={cache}>
      <div className="App">
        <UserStore>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />}>
                <Route path="/login/:redirectUrl" element={<Login />} />
              </Route>
              <Route
                path="/"
                element={
                  <AuthedRoute
                    title="Home "
                    requiredRoles={[
                      Role.Clinician,
                      Role.Admin,
                      Role.SuperAdmin,
                      Role.Patient,
                    ]}
                  >
                    <UserHomePage />
                  </AuthedRoute>
                }
              />
              <Route
                path="/questionnaire"
                element={
                  <UnauthedRoute title="Questionnaire ">
                    <Questionnaire />
                  </UnauthedRoute>
                }
              />
              <Route
                path="/search"
                element={
                  <AuthedRoute title="Search ">
                    <UserSearchPage />
                  </AuthedRoute>
                }
              />
              <Route
                path="/goals"
                element={
                  <UnauthedRoute title="Goals ">
                    <Goal />
                  </UnauthedRoute>
                }
              />
              <Route
                path="/administrator"
                element={
                  <AuthedRoute title="Administrators ">
                    <AdministratorSearch />
                  </AuthedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <AuthedRoute title="Settings ">
                    <Settings />
                  </AuthedRoute>
                }
              />
              <Route
                path="/viewPatient"
                element={
                  <AuthedRoute title="ViewPatient ">
                    <ViewPatient />
                  </AuthedRoute>
                }
              />
              <Route
                path="/sharedCaseloads"
                element={
                  <AuthedRoute title="Shared Caseloads ">
                    <SharedCaseloads />
                  </AuthedRoute>
                }
              />
              <Route
                path="/viewStaff/:staffId"
                element={
                  <AuthedRoute title="ViewStaff ">
                    <ViewClinician />
                  </AuthedRoute>
                }
              />
              <Route
                path="/reports"
                element={
                  <AuthedRoute title="Reports ">
                    <Reports />
                  </AuthedRoute>
                }
              />
              <Route
                path="/medications"
                element={
                  <AuthedRoute title="Medications ">
                    <Medications />
                  </AuthedRoute>
                }
              />
              <Route
                path="/externalDatasets"
                element={
                  <AuthedRoute title="External Datasets ">
                    <ExternalData />
                  </AuthedRoute>
                }
              />
              <Route
                path="/questionnaires"
                element={
                  <AuthedRoute
                    title="Questionnaires "
                    requiredRoles={[Role.Admin, Role.SuperAdmin]}
                  >
                    <Questionnaires />
                  </AuthedRoute>
                }
              />
              <Route
                path="/previewQuestionnaire"
                element={
                  <AuthedRoute title="Preview Questionnaire">
                    <PreviewQuestionnaire />
                  </AuthedRoute>
                }
              />
              <Route
                path="/printingTemplate"
                element={
                  <AuthedRoute title="Printing Template">
                    <PrintingTemplate />
                  </AuthedRoute>
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </UserStore>
      </div>
    </CacheProvider>
  </ThemeProvider>
);

export default App;
