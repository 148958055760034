import { useState } from "react";
import { Button } from "@mui/material";
import AssignPatientToCaseloadModal from "./AssignCaseloadsToPatientModal";
import { makeStyles } from "@mui/styles";
import { CheckIsMobile } from "../../../utils/MobileStatus";

interface Props {
  userId: number;
  patientId: number;
}
const AssignCaseloadsToPatient = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles(() => ({
    button: {
      width: isMobile ? "100%" : "default",
      marginTop: isMobile ? "10px" : 0,
    },
  }));
  const classes = useStyle();
  const [
    assignPatientToCaseloadModalOpen,
    setAssignPatientToCaseloadModalOpen,
  ] = useState(false);

  return (
    <>
      <AssignPatientToCaseloadModal
        open={assignPatientToCaseloadModalOpen}
        closeModal={() => {
          setAssignPatientToCaseloadModalOpen(false);
        }}
        userId={props.userId}
        patientId={props.patientId}
      />
      <Button
        variant="contained"
        onClick={() => setAssignPatientToCaseloadModalOpen(true)}
        className={classes.button}
      >
        Edit patient caseloads
      </Button>
    </>
  );
};

export default AssignCaseloadsToPatient;
