import FormErrorText from "./FormErrorText";

export const Error500 = (): JSX.Element => {
  return (
    <FormErrorText
      errorText="A server error has occurred. Please contact support."
      isInvalid
    />
  );
};
