import { Alert, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  GoalViewModel,
  QuestionnaireResponseDataViewModel,
  QuestionnaireResponseViewModel,
  QuestionnaireViewModel,
  Role,
} from "../../../types/auto/types";
import { CheckIsMobile } from "../../utils/MobileStatus";
import QuestionBox from "./questions/QuestionBox";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import "./PrintingTemplateCss.css";

interface Props {
  patientId?: string;
  questionnaire: QuestionnaireViewModel;
  questionnaireResponseData?: QuestionnaireResponseDataViewModel;
  role?: Role;
  caseloadId?: number;
  questionnaireResponseId?: number;
}

const PrintingTemplateForm = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    mainContainer: {
      "@media print": {
        margin: 0,
        padding: 0,
      },
    },
    section: {
      "@media print": {
        padding: 15,
        breakInside: "avoid",
        breakAfter: "page",
      },
    },
    copyright: {
      display: "flex",
      justifyContent: "center",
      "@media print": {
        breakBefore: "page",
        padding: 15,
      },
    },
    fillerFooter: {
      "@media print": {
        flex: "1 1 auto",
        breakAfter: "avoid-page",
      },
    },
  }));
  const classes = useStyle();

  const isMobile = CheckIsMobile();
  const [goalsFromQuestionnaire, setGoalsFromQuestionnaire] = useState<
    GoalViewModel[]
  >([]);
  const [responses, setResponses] = useState<QuestionnaireResponseViewModel[]>(
    props.questionnaireResponseData?.responses ??
      new Array<QuestionnaireResponseViewModel>()
  );
  const [template, setTemplate] = useState<JSX.Element[]>([]);
  const [heights, setHeights] = useState<number[]>(
    Array(props.questionnaire.sections?.length ?? 0)
  );

  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(
    () => {
      if (template.length === 0) {
        setTemplate(BuildPrintingTemplate());
      }
    },
    // eslint-disable-next-line
    [refresh]
  );

  useEffect(
    () => {
      if (template.length !== 0) {
        calculateHeights();
      }
    },
    // eslint-disable-next-line
    [refresh]
  );

  function calculateHeights() {
    var localHeights: number[] = Array(heights.length);
    var reRender = false;
    for (var i = 0; i < heights.length; i++) {
      if (
        document.getElementById(i.toString()) != null &&
        document.getElementById(i.toString())!.offsetHeight != null
      ) {
        localHeights[i] = document.getElementById(i.toString())!.offsetHeight;
      }
      if (localHeights[i] !== heights[i]) {
        reRender = true;
      }
    }
    if (reRender) {
      setTemplate(BuildPrintingTemplate());
    }
    setHeights(localHeights);
  }

  function addExtraPadding(
    j: number,
    heading: string | undefined,
    subheading: string | undefined
  ) {
    // Always skip padding on first component.
    if (j === 0) return [false, false];
    // Larger paddingTop on second element to compensate for missing 1 paddingBottom from above element
    if (j === 1) return [true, true];
    // Always add padding on non-first component
    if (j > 1) return [true, false];

    // If both heading and section title are empty, do not add padding
    return [Boolean(heading || subheading), false];
  }

  useEffect(() => {
    if (props.questionnaireResponseData?.responses) {
      setResponses(props.questionnaireResponseData?.responses);
    }
  }, [setResponses, props.questionnaireResponseData]);

  function BuildPrintingTemplate() {
    var singlePageQuestionnaire: JSX.Element[] = [];

    if (
      props.questionnaire.sections != null &&
      props.questionnaire.sections.length > 0
    ) {
      for (var i = 0; i < props.questionnaire.sections?.length; i++) {
        var childComponents: JSX.Element[] = [];
        var currentSection = props.questionnaire.sections[i];

        // HEADER
        if (currentSection.heading) {
          childComponents.push(
            <Typography
              variant={isMobile ? "h6" : "h5"}
              component="h1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                m: 2,
                ml: isMobile ? 0 : 2,
                "@media print": {
                  paddingLeft: 1.5,
                },
              }}
              key={"section-heading-" + i}
            >
              {currentSection.heading ?? null}
            </Typography>
          );
        }

        // SECTION TITlE
        if (currentSection.sectionTitle) {
          childComponents.push(
            <Alert
              key={"sectionTitle-" + currentSection.sectionTitle + i}
              severity="info"
            >
              {currentSection.sectionTitle}
            </Alert>
          );
        }

        // QUESTIONS
        const questions = currentSection.questions;
        if (questions != null) {
          for (var j = 0; j < questions.length; j++) {
            childComponents.push(
              <div
                key={
                  "questionContainer-" + questions[j].number + "-" + j + "-" + i
                }
              >
                <QuestionBox
                  key={"question-" + questions[j].number}
                  question={questions[j]}
                  setResponses={setResponses}
                  responses={
                    responses || new Array<QuestionnaireResponseViewModel>()
                  }
                  setRerender={() => {}}
                  patientId={props.patientId}
                  questionnaireId={props.questionnaire.id}
                  role={
                    props.questionnaire?.authenticatedOnly
                      ? props.role
                      : undefined
                  }
                  goalsFromQuestionnaire={goalsFromQuestionnaire}
                  setGoalsFromQuestionnaire={setGoalsFromQuestionnaire}
                  preview={false}
                  questions={questions}
                  caseloadId={props.caseloadId}
                  readOnly={true}
                  extraPrintPadding={addExtraPadding(
                    j,
                    currentSection.heading,
                    currentSection.sectionTitle
                  )}
                  setHasUnsubmittedGoals={() => {}}
                  questionnaireResponseId={props.questionnaireResponseId}
                />
              </div>
            );
          }
        }
        singlePageQuestionnaire.push(
          <Box
            className={classes.section}
            key={"section-box-" + i}
            id={i.toString()}
            sx={{
              "@media print": {
                backgroundImage:
                  "url(" +
                  props.questionnaire?.sections?.[i].backgroundImage +
                  ")",
                minHeight:
                  heights[i] > 800
                    ? "200vh"
                    : heights[i] > 1600
                    ? "300vh"
                    : heights[i] > 2400
                    ? "400vh"
                    : "100vh",
                // do not remove this border!!
                border: "1px solid transparent",
                breakInside: "avoid",
                breakAfter: "page",
                backgroundSize: "100vw 100vh",
                backgroundRepeat: heights[i] > 800 ? "repeat-y" : "no-repeat",
                backgroundPosition: "top",
              },
            }}
          >
            {childComponents}
          </Box>
        );
      }
      if (props.questionnaire.copyright != null) {
        singlePageQuestionnaire.push(
          <div className={classes.copyright} key={"copyright-box-" + i}>
            <Typography variant="body2" component="h1">
              {parse(props.questionnaire.copyright ?? "")}
            </Typography>
          </div>
        );
      }
      singlePageQuestionnaire.push(
        <div className={classes.fillerFooter} key={"filler"}></div>
      );
    }
    setRefresh((x) => !x);
    return singlePageQuestionnaire;
  }

  return (
    <div
      className={classes.mainContainer}
      key={"main-container-printing-template"}
    >
      {template}
    </div>
  );
};

export default PrintingTemplateForm;
