import { Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { orderBy } from "lodash";
import { useState } from "react";
import {
  GoalClient,
  GoalOwner,
  GoalViewModel,
  MonitoringStatus,
  PatientViewModel,
  Role,
} from "../../../types/auto/types";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import PromptableCard from "../elements/promptable-card/PromptableCard";
import PromptWarningDialog, {
  PromptActionWarningState,
} from "../schedule/dialog/PromptWarningDialog";

interface Props {
  name: string;
  goals: GoalViewModel[];
  patient: PatientViewModel;
  refreshGoals: () => void;
  role?: Role;
  goalOwnerDescriptions: {
    [key in keyof typeof GoalOwner]?: string;
  };
  monitoringStatusDescriptions: {
    [key in keyof typeof MonitoringStatus]?: string;
  };
}

const StaticGoalsList = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    cardContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: 0,
      padding: 0,
    },
    title: { marginTop: 20 },
  }));
  const classes = useStyle();
  const goalClient = new GoalClient(Configuration.SERVER_ROOT, FetchOverride);

  const isStaff =
    props.role === Role.SuperAdmin ||
    props.role === Role.Admin ||
    props.role === Role.Clinician;

  const [warningModalState, setWarningModalState] =
    useState<PromptActionWarningState>();

  const [goalToModify, setGoalToModify] = useState<GoalViewModel>();
  // Don't show anything if there are no archived goals
  if (props.goals.length === 0) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h5" component="h2" className={classes.title}>
        {props.name}
      </Typography>

      <Container className={classes.cardContainer}>
        <PromptWarningDialog
          open={warningModalState !== undefined}
          onClose={() => setWarningModalState(undefined)}
          patient={props.patient}
          state={warningModalState}
          refreshList={props.refreshGoals}
          name={goalToModify?.question || "goal"}
          promptName="goal"
          onResumeSingle={() => goalClient.resumeGoal(goalToModify?.id)}
          onResumeAll={() =>
            goalClient.resumeAllGoalsForUser(props.patient?.id)
          }
          onSuspendSingle={() => goalClient.suspendGoal(goalToModify?.id)}
          onSuspendAll={() =>
            goalClient.suspendAllGoalsForUser(props.patient?.id)
          }
          onArchiveSingle={() => goalClient.archiveGoal(goalToModify?.id)}
          onArchiveAll={() =>
            goalClient.archiveAllGoalsForUser(props.patient?.id)
          }
          onUnarchive={() => goalClient.unarchiveGoal(goalToModify?.id)}
        />
        {orderBy(props.goals, "created", "desc").map((x) => (
          <PromptableCard
            key={"goals" + x.id}
            title={x.question || ""}
            monitoringStatus={x.monitoringStatus}
            showPrompt
            createdDate={x.created}
            lastResponse={x.lastResponse}
            lastResponseValue={x.lastResponseValue}
            prompt={x.promptSchedule}
            owner={x.owner}
            onUnarchiving={
              isStaff
                ? () => {
                    setWarningModalState(PromptActionWarningState.Unarchive);
                    setGoalToModify(x);
                  }
                : undefined
            }
            role={props.role}
            goalOwnerDescriptions={props.goalOwnerDescriptions}
            monitoringStatusDescriptions={props.monitoringStatusDescriptions}
            readOnly={false}
          />
        ))}
      </Container>
    </>
  );
};
export default StaticGoalsList;
