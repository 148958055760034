import {
  ContactDetailsViewModel,
  ErrorType,
  PreferredContact,
  UserClient,
} from "../../../../types/auto/types";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import { Alert, Box, Button, Modal } from "@mui/material";
import ContactPreferencesForm from "../../patient/create-patient/ContactPreferencesForm";
import { LoadingButton } from "@mui/lab";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";

interface Props {
  open: boolean;
  closeModal: () => void;
  details: ContactDetailsViewModel;
}

const ContactPreferenceModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "80%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    subHeader: {
      fontSize: 20,
      marginBottom: 0,
      marginTop: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
    },
    caseloadSelector: {
      width: "100%",
      marginTop: 20,
    },
    error: {
      marginTop: "10px",
    },
  });
  const [, setReRender] = useState(false);
  const classes = useStyle(theme);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [hasMobile, setHasMobile] = useState(false);
  const [details, setDetails] = useState(
    new ContactDetailsViewModel({
      preferredContact: PreferredContact.Email,
    })
  );
  const [error, setError] = useState<ErrorType | undefined>(undefined);
  const errorText =
    error !== undefined
      ? error === ErrorType.OIDCInvite
        ? "Failed to send invite, please contact support team"
        : error === ErrorType.InvalidModel
        ? "Invalid patient details provided."
        : error === ErrorType.ExisitingEmail
        ? "A user with this email address already exists"
        : "System error please contact support team"
      : "";
  useEffect(() => {
    setDetails(
      JSON.parse(JSON.stringify(props.details), function (key, value) {
        return value;
      })
    );
    setHasMobile(props.details.mobileNumber !== undefined);
  }, [props.details, refresh]);
  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <ContactPreferencesForm
          details={details}
          setDetails={setDetails}
          emailError={emailError}
          setEmailError={setEmailError}
          mobileNumberError={mobileNumberError}
          setMobileNumberError={setMobileNumberError}
          setReRender={setReRender}
          hasMobile={hasMobile}
        />
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              setRefresh(!refresh);
              setEmailError(false);
              setMobileNumberError(false);
              props.closeModal();
              setError(undefined);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={emailError || mobileNumberError}
            onClick={() => {
              setLoading(true);
              new UserClient(Configuration.SERVER_ROOT, FetchOverride)
                .updateContactDetails(details)
                .then(() => {
                  setLoading(false);
                  props.closeModal();
                })
                .catch((e) => {
                  setError(e);
                  setLoading(false);
                });
            }}
          >
            {"Update Preferences"}
          </LoadingButton>
        </div>
        {error !== undefined && (
          <Alert severity="error" className={classes.error}>
            {errorText}
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default ContactPreferenceModal;
