import {
  Theme,
  CardContent,
  Typography,
  Button,
  Container,
  Card,
} from "@mui/material";
import { PersonRemove } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../Theme";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { AbstractUserCardType } from "./SearchTypes";

interface Props {
  user: AbstractUserCardType;
  allowCaseloadDeletion?: boolean;
}

export const UserCard = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles((th: Theme) => ({
    userCard: {
      display: isMobile ? "default" : "flex",
      flexWrap: "wrap",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      marginTop: "5px",
      padding: "2px",
      boxShadow: "none",
    },
    cardContent: {
      width: "100%",
      padding: "2px !important",
    },
    NameContainer: {
      display: isMobile ? "grid" : "flex",
      width: "80%",
      float: "left",
    },
    NameText: {
      fontSize: isMobile ? "1.3em" : "1.8em",
      paddingLeft: "1%",
    },
    ButtonContainer: {
      width: "100%",
      display: isMobile ? "grid" : "flex",
      justifyContent: isMobile ? undefined : "center",
      alignItems: isMobile ? undefined : "center",
      padding: isMobile ? "2%" : "0%",
      margin: 0,
    },
    textMainContainer: {
      display: isMobile ? "block" : "flex",
      width: isMobile ? "100%" : "85%",
      paddingLeft: "1%",
      paddingRight: "1%",
      float: "left",
    },
    textContainer: {
      display: "flex",
      float: "left",
      paddingRight: isMobile ? undefined : "0",
      marginRight: isMobile ? undefined : "0",
    },
    textField: {
      fontWeight: "Bold",
      marginRight: "10px",
    },
    textFieldValue: {
      fontWeight: "normal",
    },
    button: {
      padding: "5px",
      marginLeft: isMobile ? undefined : "5px",
      marginTop: isMobile ? "5px" : undefined,
    },
    actionContainer: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      width: isMobile ? "100%" : "15%",
      height: isMobile ? undefined : "100%",
      alignItems: "center",
      justifyContent: "center",
      margin: 0,
      padding: 0,
    },
    deleteButton: {
      backgroundColor: "red",
      "&:hover": {
        backgroundColor: "crimson",
      },
      padding: "5px",
      marginLeft: isMobile ? undefined : "5px",
      marginTop: isMobile ? "5px" : undefined,
    },
  }));
  const classes = useStyle({ theme: customTheme });

  return (
    <>
      <Card className={classes.userCard}>
        <CardContent className={classes.cardContent}>
          <Container className={classes.NameContainer}>
            <Typography className={classes.NameText}>
              {props.user.firstName + " " + props.user.lastName}
              {!isMobile && " - " + props.user.id}
            </Typography>
          </Container>
          {props.user.descriptions.length > 0 && (
            <Container className={classes.textMainContainer}>
              {props.user.descriptions.map((x, key) => {
                if (!x.mobileOnly || isMobile) {
                  return (
                    <Container className={classes.textContainer} key={key}>
                      <Typography className={classes.textField}>
                        {x.name}:
                      </Typography>
                      <Typography className={classes.textFieldValue}>
                        {x.value}
                      </Typography>
                    </Container>
                  );
                } else {
                  return null;
                }
              })}
            </Container>
          )}
          <Container className={classes.actionContainer}>
            {props.user.handleClick && (
              <Container className={classes.ButtonContainer}>
                <Button
                  variant="contained"
                  onClick={props.user.handleClick}
                  className={classes.button}
                  aria-label={"View User details"}
                >
                  View Details
                </Button>
                {props.allowCaseloadDeletion && (
                  <Button
                    variant="contained"
                    onClick={props.user.handleRemoveFromCaseload}
                    className={classes.deleteButton}
                    aria-label={"Remove User from caseload"}
                  >
                    <PersonRemove />
                  </Button>
                )}
              </Container>
            )}
          </Container>
        </CardContent>
      </Card>
    </>
  );
};
