import { Alert, CircularProgress, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useMountedState, useUnmountPromise } from "react-use";
import {
  MedicationClient,
  TenancyOptionErrors,
  MedicationViewModel,
  PatientMedicationClient,
  PatientMedicationViewModel,
  PatientViewModel,
  UserViewModel,
  Role,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import CreateEditPatientMedication from "./CreateEditPatientMedication";
import PatientMedication from "./PatientMedication";

interface Props {
  patient: PatientViewModel;
  user: UserViewModel;
}
const ViewPatientMedications = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    titles: {
      marginTop: "10px",
    },
    patientMedications: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  }));
  const classes = useStyle();

  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<TenancyOptionErrors | undefined>(
    undefined
  );
  const [medications, setMedications] = React.useState<MedicationViewModel[]>(
    []
  );
  const [patientMedications, setPatientMedications] = React.useState<
    PatientMedicationViewModel[]
  >([]);

  var isStaff =
    props.user.role === Role.SuperAdmin ||
    props.user.role === Role.Admin ||
    props.user.role === Role.Clinician;

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        setLoading(true);
        await resolveWhileMounted(
          new MedicationClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getTenantMedications()
        ).then((meds) => setMedications(meds));

        if (isStaff) {
          await resolveWhileMounted(
            new PatientMedicationClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getPatientMedicationLinkedToPatient(props.patient.id)
          )
            .then((patientMeds) => {
              setPatientMedications(
                patientMeds !== undefined && patientMeds !== null
                  ? patientMeds
                  : []
              );
            })
            .catch((e) => setError(e))
            .finally(() => {
              if (isMountedState()) setLoading(false);
            });
        } else {
          await resolveWhileMounted(
            new PatientMedicationClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getPatientMedicationLinkedToCurrentPatient()
          )
            .then((patientMeds) =>
              setPatientMedications(
                patientMeds !== undefined && patientMeds !== null
                  ? patientMeds
                  : []
              )
            )
            .catch((e) => setError(e))
            .finally(() => {
              if (isMountedState()) setLoading(false);
            });
        }
      }
    };
    effect();
  }, [refresh, isMountedState, props.patient, resolveWhileMounted, isStaff]);
  return (
    <Container disableGutters>
      <Typography variant="h5" component="h2" className={classes.titles}>
        Medications
      </Typography>
      <Container disableGutters>
        <CreateEditPatientMedication
          refresh={() => setRefresh((x) => !x)}
          patientId={props.patient.id}
          medications={medications}
        />
        {patientMedications.length === 0 && (
          <Typography>
            There are no active patient medications. Click the above button to
            add patient medication.
          </Typography>
        )}
      </Container>
      <Container disableGutters>
        {loading && (
          <CircularProgress
            className={classes.loadingSpinner}
            aria-label="Loading"
          />
        )}
      </Container>
      <Container className={classes.patientMedications} disableGutters>
        {patientMedications.map((patientMedication, key) => (
          <PatientMedication
            key={key}
            patientMedication={patientMedication}
            patientId={props.patient.id}
            reload={() => setRefresh((x) => !x)}
            medications={medications}
          />
        ))}
        {error !== undefined && (
          <Alert severity="error">
            {error === TenancyOptionErrors.TenancyNotSupported
              ? "Tenancy does not support medication."
              : ""}
          </Alert>
        )}
      </Container>
    </Container>
  );
};

export default ViewPatientMedications;
