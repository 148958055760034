import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Container,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { MuiColorInput } from "mui-color-input";
import {
  MedicationCategoryViewModel,
  MedicationClient,
  TenancyOptionErrors,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { FetchOverride } from "../../../utils/Request";
import { IsNameValid } from "../CreateEditMedicationModalHelper";

interface Props {
  medicationCategory?: MedicationCategoryViewModel;
  open: boolean;
  closeModal: () => void;
  refresh: () => void;
}
const CreateEditMedicationCategoryModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "100%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
    },
    textField: {
      width: "100%",
      marginBottom: "10px",
    },
  });
  const classes = useStyle();
  const [medicationCategory, setMedicationCategory] =
    React.useState<MedicationCategoryViewModel>(
      props.medicationCategory ?? ({} as MedicationCategoryViewModel)
    );
  const [colour, setColour] = React.useState(
    medicationCategory.colour ? medicationCategory.colour : "#000"
  );
  const [, setReRender] = React.useState<boolean>(false);
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<TenancyOptionErrors | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    if (props.medicationCategory) {
      // Deep copy patient prop to fix state update problems
      setMedicationCategory(
        JSON.parse(
          JSON.stringify(props.medicationCategory),
          function (key, value) {
            return value;
          }
        )
      );
    } else {
      setMedicationCategory({} as MedicationCategoryViewModel);
    }
    setReRender((x) => !x);
  }, [props.open, props.medicationCategory]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "80%" : "40%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          {props.medicationCategory ? "Edit Category" : "Create Category"}
        </Typography>
        <Container>
          <TextField
            label="Category Name"
            className={classes.textField}
            required
            value={medicationCategory.name ? medicationCategory.name : ""}
            onChange={(e) => {
              setMedicationCategory((x) => {
                x.name = e.target.value;
                setReRender((x) => !x);
                return x;
              });
              setNameError(!IsNameValid(e.target.value));
            }}
            error={nameError}
            helperText={
              nameError && "Name must be between 1 and 20 characters long"
            }
          />
          <MuiColorInput
            format="hex"
            value={colour}
            label="Category Colour"
            isAlphaHidden={true}
            onChange={(e) => {
              setMedicationCategory((x) => {
                x.colour = e;
                setColour(e);
                setReRender((x) => !x);
                return x;
              });
            }}
          />
        </Container>
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              props.closeModal();
              setNameError(false);
              setReRender((x) => !x);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            disabled={nameError}
            loading={loading}
            onClick={async () => {
              setLoading(true);
              if (medicationCategory.name === undefined) {
                setLoading(false);
                setNameError(true);
                return;
              }
              if (
                props.medicationCategory === undefined ||
                props.medicationCategory === null
              ) {
                new MedicationClient(Configuration.SERVER_ROOT, FetchOverride)
                  .createMedicationCategory(medicationCategory)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                    props.refresh();
                  })
                  .catch((error) => {
                    setLoading(false);
                    setError(error);
                  });
              } else {
                new MedicationClient(Configuration.SERVER_ROOT, FetchOverride)
                  .updateMedicationCategory(medicationCategory)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                    props.refresh();
                  })
                  .catch((error) => {
                    setLoading(false);
                    setError(error);
                  });
              }
              setNameError(false);
            }}
          >
            {props.medicationCategory ? "Save Changes" : "Create Category"}
          </LoadingButton>
        </div>
        {error !== undefined && (
          <Alert severity="error">
            {error === TenancyOptionErrors.InvalidModel
              ? "Details for medication category are invalid"
              : "Tenancy does not support medication."}
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default CreateEditMedicationCategoryModal;
