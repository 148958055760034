import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

interface Props {
  open: boolean;
  handleCancel: () => void;
  handleAuthenticated: () => void;
}

const AuthenticatedWarningModal = (props: Props): JSX.Element => {
  return (
    <Dialog
      open={props.open}
      aria-labelledby="authenticate-warning-dialog-title"
      aria-describedby="authenticate-warning-dialog-description"
    >
      <DialogTitle id="authenticate-warning-dialog-title">
        Authenticate Questionnaire?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="authenticate-warning-dialog-description">
          This action will make the questionnaire authenticated. These means it
          can only be accessed if the user is logged in. If patient log in
          tenant option is not selected then only clinicians will be able to
          access the questionnaire. Are you sure you want to make this
          questionnaire authenticated?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.handleCancel();
          }}
          variant="contained"
          aria-label="Back button"
        >
          Back
        </Button>
        <Button
          onClick={() => props.handleAuthenticated()}
          variant="text"
          aria-label="Yes button"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthenticatedWarningModal;
