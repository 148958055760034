import { useEffect, useState } from "react";
import { QuestionnaireBuilderDataModel } from "./QuestionnaireBuilderDataModelHelper";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DependsOn } from "./DependsOnBuilder";
import DependsOnAddModal from "./DependsOnAddModal";
import { QuestionType } from "../../../../types/auto/types";

interface Props {
  questionnaire: QuestionnaireBuilderDataModel;
  dependsOn: DependsOn;
  singleDependsOn: DependsOn;
  setSingleDependsOn: (dO: DependsOn) => void;
  handleChange: (index: number) => void;
  handleLocalChange: (e: any, isQ: boolean) => void;
  handleDelete: (index: number) => void;
  handleClose: () => void;
  noQuestionError: boolean;
  noAnswerError: boolean;
  resetErrors: (to: boolean) => void;
}

const DependsOnSelector = (props: Props): JSX.Element => {
  const headers: string[] = ["Question", "Answers", "Edit", "Delete"];

  const [rows, setRows] = useState<JSX.Element[]>([]);
  const [totalRows] = useState<number>(rows.length);
  const [dependsOnAddModalOpen, setDependsOnAddModalOpen] =
    useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [formFields, setFormFields] = useState<JSX.Element[]>([]);
  const [isAdd, setIsAdd] = useState<boolean>(false);

  function loadRows() {
    var localRows: JSX.Element[] = [];
    var keys = Object.keys(props.dependsOn);
    var values = Object.values(props.dependsOn);
    if (keys.length === 0) {
      setRows([]);
    } else {
      for (let i = 0; i < keys.length; i++) {
        const qType = getQuestion(Number(keys[i]))?.questionType;
        localRows.push(
          <TableRow key={"dependsOn-row-" + i}>
            <TableCell key={"question" + i} width={250}>
              <TextField
                value={"Question " + Number(keys[i])}
                key={"dependsOn-question-" + Number(keys[i]) + i}
                variant="outlined"
                multiline
                maxRows={4}
                fullWidth
              />
            </TableCell>
            <TableCell key={"answers" + i}>
              {qType === 1 ? (
                <TextField
                  value={
                    values[i][0] === 0
                      ? "Display when textbox is empty"
                      : "Display when textbox is filled"
                  }
                  key={"dependsOn-answers-" + i}
                  variant="outlined"
                  multiline
                  maxRows={4}
                  fullWidth
                />
              ) : qType === 14 ? (
                <TextField
                  value={
                    values[i][0] === 0
                      ? "Display when checkbox is unicked"
                      : "Display when checkbox is ticked"
                  }
                  key={"dependsOn-answers-" + i}
                  variant="outlined"
                  multiline
                  maxRows={4}
                  fullWidth
                />
              ) : (
                <TextField
                  value={"Answers " + values[i].join(", ")}
                  key={"dependsOn-answers-" + i}
                  variant="outlined"
                  multiline
                  maxRows={4}
                  fullWidth
                />
              )}
            </TableCell>
            <TableCell key={"EditButton-cell-" + i} width={150}>
              <Button
                id={"edit-row-" + i}
                variant="contained"
                key={"EditButton-" + i}
                onClick={() => {
                  editResponseFromKey(i);
                }}
                fullWidth
              >
                Edit
              </Button>
            </TableCell>
            <TableCell key={"DeleteButton-cell-" + i} width={150}>
              <Button
                id={"delete-row-" + i}
                variant="contained"
                key={"DeleteButton-" + i}
                onClick={() => {
                  props.handleDelete(i);
                }}
                fullWidth
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    }
    setRows(localRows);
  }

  function buildQuestionDropdown() {
    var qDrop: JSX.Element[] = [
      <MenuItem value={-1} key={"question-placeholder"}>
        <em>Question</em>
      </MenuItem>,
    ];
    for (let i = 0; i < props.questionnaire.sections.length; i++) {
      if (props.questionnaire.sections[i].questions.length === 0) {
        continue;
      } else {
        for (
          let x = 0;
          x < props.questionnaire.sections[i].questions.length;
          x++
        ) {
          const index = props.questionnaire.sections[i].questions[x].number;
          const text = "Question " + index;
          qDrop.push(
            <MenuItem value={index} key={"question-" + index}>
              {text}
            </MenuItem>
          );
        }
      }
    }
    return qDrop;
  }

  function buildAnswersDropdown(questionNumber: number) {
    var aDrop: JSX.Element[] = [];
    if (questionNumber != null) {
      const selectedQuestion = getQuestion(questionNumber);
      if (selectedQuestion != null) {
        if (selectedQuestion.questionType === QuestionType.Textbox) {
          aDrop.push(
            <MenuItem value={0} key={"answer-empty"}>
              Display when textbox is empty
            </MenuItem>
          );
          aDrop.push(
            <MenuItem value={1} key={"answer-filled"}>
              Display when textbox is filled
            </MenuItem>
          );
        } else if (
          selectedQuestion.questionType === QuestionType.CheckBoxSingle
        ) {
          aDrop.push(
            <MenuItem value={0} key={"answer-unticked"}>
              Display when checkbox is unticked
            </MenuItem>
          );
          aDrop.push(
            <MenuItem value={1} key={"answer-ticked"}>
              Display when checkbox is ticked
            </MenuItem>
          );
        } else if (
          selectedQuestion.questionType === QuestionType.Range &&
          selectedQuestion.answers.length !== 0
        ) {
          var incrementVal = selectedQuestion.answers[0].rangeDecimal ? 0.1 : 1;
          const min = selectedQuestion.answers[0].rangeMin!;
          const max = selectedQuestion.answers[0].rangeMax!;
          for (let i = min; i <= max; i += incrementVal) {
            const index = incrementVal === 1 ? i : i.toFixed(1);
            const text = "Answer " + index;
            aDrop.push(
              <MenuItem value={index} key={"answer-" + index}>
                {text}
              </MenuItem>
            );
          }
        } else {
          selectedQuestion.answers.forEach((a) => {
            const index = a.number;
            const text = "Answer " + a.number;
            aDrop.push(
              <MenuItem value={index} key={"answer-" + index}>
                {text}
              </MenuItem>
            );
          });
        }
      }
    }

    return aDrop;
  }

  function getQuestion(num: number) {
    for (var i = 0; i < props.questionnaire.sections.length; i++) {
      if (props.questionnaire.sections[i].questions.length === 0) {
        continue;
      } else {
        const index = props.questionnaire.sections[i].questions.findIndex(
          (ques) => ques.number === num
        );
        if (index > -1) {
          return props.questionnaire.sections[i].questions[index];
        }
      }
    }
    return undefined;
  }

  function loadFormFields() {
    var question: number = -1;
    var answers: number[] = [];
    var questionsDropdown: JSX.Element[] = [];
    var answersDropdown: JSX.Element[] = [];
    var formFields: JSX.Element[] = [];

    if (Object.keys(props.singleDependsOn).length === 0) {
      question = -1;
      answers = [];
    } else {
      for (const [key, value] of Object.entries(props.singleDependsOn)) {
        question = Number(key);
        answers = value;
      }
    }

    questionsDropdown = buildQuestionDropdown();
    if (question !== -1) {
      answersDropdown = buildAnswersDropdown(question);
    }

    formFields.push(
      <Box
        width={"100%"}
        gridArea={"question"}
        margin={0}
        paddingRight={2}
        paddingLeft={0}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignContent={"flex-start"}
        flexWrap={"wrap"}
        key={"question-container-"}
      >
        <FormControl sx={{ minWidth: "100%" }}>
          <InputLabel id="question-label">Question</InputLabel>
          <Select
            labelId="question-label"
            id="question-select"
            key={"question-select"}
            label="Question"
            onChange={(e) => {
              props.handleLocalChange(e, true);
            }}
            value={question ?? ""}
          >
            {questionsDropdown}
          </Select>
        </FormControl>
      </Box>
    );
    if (question !== -1) {
      const multiple =
        answersDropdown.length === 2 &&
        (answersDropdown[0].key === "answer-empty" ||
          answersDropdown[0].key === "answer-unticked")
          ? false
          : true;
      formFields.push(
        <Box
          margin={0}
          display={"flex"}
          flexDirection={"row"}
          width={"100%"}
          justifyContent={"flex-start"}
          flexWrap={"wrap"}
          gridArea={"answers"}
          padding={0}
          key={"answers-container-"}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel id="answer-label">Answers</InputLabel>
            <Select
              labelId="answer-label"
              id="answer-select"
              key={"answer-select"}
              label="Answers"
              onChange={(e) => {
                props.handleLocalChange(e, false);
              }}
              value={answers ?? ""}
              multiple={multiple}
            >
              {answersDropdown}
            </Select>
          </FormControl>
        </Box>
      );
    }

    setFormFields(formFields);
  }

  function addDependancy() {
    props.setSingleDependsOn({});
    setIndex(Object.keys(props.dependsOn).length);
    setDependsOnAddModalOpen(true);
  }

  function editResponseFromKey(index: number) {
    let i = 0;
    for (const [key, value] of Object.entries(props.dependsOn)) {
      if (i === index) {
        props.setSingleDependsOn({ [key]: value });
      }
      i++;
    }
    setIndex(index);
    props.resetErrors(false);
    setDependsOnAddModalOpen(true);
  }

  useEffect(
    () => {
      loadRows();
    },
    // eslint-disable-next-line
    [props.dependsOn]
  );

  useEffect(
    () => {
      loadFormFields();
    },
    // eslint-disable-next-line
    [index, props.singleDependsOn]
  );

  return (
    <>
      <Box key={"dependsOn-selector-table-box-"}>
        <TableContainer
          component={Paper}
          key={"dependsOn-selector-table-container-"}
        >
          <Table
            sx={{ minWidth: 1000 }}
            aria-label="simple table"
            key={"dependsOn-selector-table-"}
          >
            <TableHead key={"dependsOn-selector-table-head-"}>
              <TableRow key={"dependsOn-selector-table-head-row-"}>
                {headers?.map((header) => (
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    key={"header-" + header}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody key={"dependsOn-selector-table-body-"}>{rows}</TableBody>
          </Table>
        </TableContainer>
        <Box display={"flex"} justifyContent={"space-between"}>
          {totalRows < 20 && (
            <Button
              onClick={async () => {
                addDependancy();
              }}
              variant="contained"
              sx={{ marginTop: 2, marginBottom: 2 }}
              key={"dependsOn-selector-table-add-button-"}
            >
              Add Dependancy
            </Button>
          )}
          <Button
            onClick={() => {
              props.handleClose();
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
      <DependsOnAddModal
        open={dependsOnAddModalOpen}
        formFields={formFields}
        handleClose={() => {
          if (isAdd) {
            setIsAdd(false);
          }
          props.resetErrors(true);
          setDependsOnAddModalOpen(false);
        }}
        handleUpdate={() => {
          props.handleChange(index);
          setDependsOnAddModalOpen(false);
        }}
        noQuestionError={props.noQuestionError}
        noAnswerError={props.noAnswerError}
        key={"dependsOn-selector-table-modal-"}
      />
    </>
  );
};

export default DependsOnSelector;
