import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import {
  PatientDashboardOptionsModel,
  QuestionnaireBuilderDataModel,
  ScoringDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import QuestionnaireSettingsModal from "./QuestionnaireSettingsModal";
import QuestionnaireDashboardOptionsModal from "./QuestionnaireDashboardOptionsModal";
import { LoadingButton } from "@mui/lab";
import QuestionnaireScoringWarningModal from "./QuestionnaireScoringWarningModal";
import QuestionnaireScoringModal from "./QuestionnaireScoringModal";
import nameof from "../../../utils/NameOf";
import { QuestionnaireViewModel } from "../../../../types/auto/types";

interface Props {
  questionnaire: QuestionnaireBuilderDataModel;
  allQuestionnaires: QuestionnaireViewModel[];
  setQuestionnaireWIP: (
    field: string,
    value:
      | string
      | boolean
      | number
      | null
      | PatientDashboardOptionsModel
      | undefined
      | ScoringDataModel[]
  ) => void;
  previewCoverPage: boolean;
  setPreviewCoverPage: React.Dispatch<React.SetStateAction<boolean>>;
  enableDashboardOptions: boolean;
  handleSave: () => void;
  handleSaveAndClose: () => void;
  saveLoading: boolean;
  saveAndCloseLoading: boolean;
  saveDisabled: boolean;
  setSaveLoading: () => void;
  setSaveAndCloseLoading: () => void;
}

const QuestionnaireSettings = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);
  const [openDashboardModal, setOpenDashboardModal] = useState<boolean>(false);
  const [
    openQuestionnaireScoringWarningModal,
    setOpenQuestionnaireScoringWarningModal,
  ] = useState<boolean>(false);
  const [openQuestionnaireScoringModal, setOpenQuestionnaireScoringModal] =
    useState<boolean>(false);

  const useStyle = makeStyles({
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      paddingLeft: isMobile ? "0.5em" : "0",
    },
    buttonContainer2: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
    },
    buttonContainer3: {
      display: "flex",
      paddingBottom: "1em",
      paddingTop: "1em",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    buttonContainer4: {
      display: "flex",
      paddingBottom: "1em",
      justifyContent: "flex-end",
    },
  });
  const classes = useStyle();

  return (
    <Box className={classes.buttonContainer}>
      <Box className={classes.buttonContainer2}>
        <Box className={classes.buttonContainer3}>
          <LoadingButton
            variant="contained"
            fullWidth
            onClick={() => {
              props.setSaveLoading();
              props.handleSave();
            }}
            loading={props.saveLoading}
            disabled={props.saveDisabled}
          >
            Save
          </LoadingButton>
        </Box>
        <Box className={classes.buttonContainer4}>
          <LoadingButton
            variant="contained"
            fullWidth
            onClick={() => {
              props.setSaveAndCloseLoading();
              props.handleSaveAndClose();
            }}
            loading={props.saveAndCloseLoading}
          >
            Save and Close
          </LoadingButton>
        </Box>
      </Box>
      <Box className={classes.buttonContainer2}>
        <Box className={classes.buttonContainer4}>
          <Button
            onClick={() => setOpenQuestionnaireScoringWarningModal(true)}
            variant="contained"
            fullWidth
            key={"questionnaire-scoring-button"}
          >
            Questionnaire Scoring
          </Button>
          <QuestionnaireScoringWarningModal
            open={openQuestionnaireScoringWarningModal}
            handleContinue={() => {
              setOpenQuestionnaireScoringWarningModal(false);
              setOpenQuestionnaireScoringModal(true);
            }}
            handleStay={() => {
              setOpenQuestionnaireScoringWarningModal(false);
            }}
            key={"questionnaire-scoring-warning-modal"}
          />
          {openQuestionnaireScoringModal && (
            <QuestionnaireScoringModal
              open={openQuestionnaireScoringModal}
              questionnaire={props.questionnaire}
              allQuestionnaires={props.allQuestionnaires}
              handleCancel={() => setOpenQuestionnaireScoringModal(false)}
              setQuestionnaireWIP={(scoring) => {
                props.setQuestionnaireWIP(
                  nameof<QuestionnaireBuilderDataModel>("scoring"),
                  scoring
                );
              }}
              key={"questionnaire-scoring-modal"}
            />
          )}
        </Box>
        {props.questionnaire.coverPage && (
          <Box>
            <FormGroup key={"preview-coverpage"}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={() => props.setPreviewCoverPage((x) => !x)}
                    checked={props.previewCoverPage}
                  />
                }
                label="Preview Cover Page"
                key={"preview-coverpage-form-control"}
              />
            </FormGroup>
          </Box>
        )}
        <Box className={classes.buttonContainer4}>
          <Button
            onClick={() => setOpenSettingsModal(true)}
            variant="contained"
            fullWidth
          >
            Questionnaire Settings
          </Button>
          {openSettingsModal && (
            <QuestionnaireSettingsModal
              open={openSettingsModal}
              questionnaire={props.questionnaire}
              handleCancel={() => setOpenSettingsModal(false)}
              setQuestionnaireWIP={props.setQuestionnaireWIP}
              key={"question-settings-modal"}
            />
          )}
        </Box>

        {props.enableDashboardOptions && (
          <Box className={classes.buttonContainer4}>
            <Button
              onClick={() => setOpenDashboardModal(true)}
              variant="contained"
              fullWidth
            >
              Dashboard Options
            </Button>
            {openDashboardModal && (
              <QuestionnaireDashboardOptionsModal
                open={openDashboardModal}
                questionnaire={props.questionnaire}
                handleClose={() => setOpenDashboardModal(false)}
                setQuestionnaireWIP={props.setQuestionnaireWIP}
                key={"dashboard-options-modal"}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default QuestionnaireSettings;
