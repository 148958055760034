import {
  MonthPlace,
  PromptFrequency,
  PromptScheduleViewModel,
} from "../../../../types/auto/types";

const SetTime = (date: Date, time: string | undefined): Date => {
  if (time === undefined) {
    return date;
  }

  var splitTime = time.split(":");

  date.setHours(
    parseInt(splitTime[0]),
    parseInt(splitTime[1]),
    parseInt(splitTime[2])
  );

  return date;
};

const TimeAfterDate = (date: Date, time: string): boolean => {
  var splitTime = time.split(":");
  if (parseInt(splitTime[0]) > date.getHours()) {
    return true;
  } else if (parseInt(splitTime[0]) === date.getHours()) {
    if (parseInt(splitTime[1]) > date.getMinutes()) {
      return true;
    } else if (parseInt(splitTime[1]) === date.getMinutes()) {
      if (parseInt(splitTime[2]) > date.getSeconds()) {
        return true;
      }
    }
  } else {
    return false;
  }
  return false;
};

const GetDateForLastOccurrenceOfWeekDay = (
  date: Date,
  dayOfWeek: Day,
  timeOfDay: string | undefined
): Date => {
  date.setDate(date.getDate() - ((date.getDay() + (6 - dayOfWeek)) % 7));

  // Set the time of day
  date = SetTime(date, timeOfDay);
  return date;
};

const GetMostRecentScheduledWeekDay = (
  daysOfWeek: Day[] | undefined,
  timeOfDay: string | undefined
): Date => {
  if (daysOfWeek === undefined || timeOfDay === undefined) {
    return new Date();
  }

  var mostRecentDate = new Date();
  mostRecentDate.setDate(mostRecentDate.getDate() - 30);

  // Get the most recent prompt date
  for (const dayOfWeek of daysOfWeek) {
    var dayOfWeekDate = GetDateForLastOccurrenceOfWeekDay(
      new Date(),
      dayOfWeek,
      timeOfDay
    );

    if (dayOfWeekDate > mostRecentDate) {
      mostRecentDate = dayOfWeekDate;
    }
  }

  return mostRecentDate;
};

const GetMonthlyScheduledDate = (
  dayOfMonth: number | undefined,
  monthPlace: MonthPlace | undefined,
  dayOfWeek: Day[] | undefined,
  timeOfDay: string | undefined
): Date => {
  var date = new Date();
  // If on day has been set for monthly prompting
  if (dayOfMonth !== undefined && dayOfMonth !== null) {
    date.setDate(dayOfMonth);
    date = SetTime(date, timeOfDay);
  } else if (
    monthPlace !== undefined &&
    dayOfWeek !== undefined &&
    dayOfWeek.length > 0
  ) {
    // Find the first occurrence of the day of the week in the month
    date.setDate(7);

    date = GetDateForLastOccurrenceOfWeekDay(date, dayOfWeek[0], timeOfDay);

    if (monthPlace !== MonthPlace.Last) {
      date.setDate(date.getDate() + 7 * monthPlace);
    } else {
      var daysInMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();
      var lastMultiplier = Math.floor(daysInMonth / 7);
      date.setDate(date.getDate() + 7 * (lastMultiplier - 1));
    }
  }

  // If the date is larger than the current date, reduce by a month
  if (date > new Date()) {
    var weeksInMonth = Math.floor(
      new Date(date.getFullYear(), date.getMonth(), 0).getDate() / 7
    );
    date.setDate(date.getDate() - weeksInMonth * 7);
  }

  return date;
};

export const GetLastScheduledPromptDate = (
  promptSchedule: PromptScheduleViewModel
): Date => {
  switch (promptSchedule.frequency) {
    case PromptFrequency.Daily:
      var date = new Date();
      if (
        promptSchedule.timeOfDay &&
        TimeAfterDate(date, promptSchedule.timeOfDay)
      ) {
        date.setDate(date.getDate() - 1);
      }
      date.setUTCHours(0, 0, 0, 0);
      date = SetTime(date, promptSchedule.timeOfDay);
      return date;
    case PromptFrequency.Weekly:
      return GetMostRecentScheduledWeekDay(
        promptSchedule.daysOfWeek,
        promptSchedule.timeOfDay
      );
    case PromptFrequency.Monthly:
      return GetMonthlyScheduledDate(
        promptSchedule.dayOfMonth,
        promptSchedule.monthPlace,
        promptSchedule.daysOfWeek,
        promptSchedule.timeOfDay
      );
  }
  var d = new Date();

  d.setDate(d.getDate() - 5);
  return d;
};
