import { Box, Button, Modal, Slider, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import {
  GoalClient,
  GoalResponseViewModel,
  PatientViewModel,
} from "../../../types/auto/types";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import { useMountedState } from "react-use";

interface Props {
  goalQuestion: string;
  goalId: number;
  open: boolean;
  closeModal: () => void;
  patient: PatientViewModel;
  goalResponseId?: number;
  goalScore?: number;
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const RateGoalModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
    },
    formControl: { marginBottom: 20 },
    receivePrompts: { display: "flex" },
    hint: { marginTop: 10 },
    textLabelContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    textlabel: {
      fontSize: 12,
    },
  });

  const goalClient = new GoalClient(Configuration.SERVER_ROOT, FetchOverride);
  const defaultValue = 5;
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(defaultValue);

  const isMountedState = useMountedState();

  useEffect(() => {
    isMountedState() && setResponse(defaultValue);
  }, [props, isMountedState]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          {props.goalQuestion}
        </Typography>
        <div className={classes.textLabelContainer}>
          <Typography className={classes.textlabel}>Not at all met</Typography>
          <Typography className={classes.textlabel}>
            Halfway to reaching this goal
          </Typography>
          <Typography className={classes.textlabel}>Goal reached</Typography>
        </div>
        <Slider
          key="slider-goalResponse"
          defaultValue={props.goalScore ?? defaultValue}
          step={1}
          min={0}
          max={10}
          onChange={(_, value) => setResponse(value as number)}
          aria-label={props.goalQuestion}
          getAriaValueText={(num) => num.toString()}
          valueLabelDisplay="auto"
          marks={Array.from({ length: 11 }, (_, i) => ({
            value: i,
            label: i.toString(),
          }))}
          getAriaLabel={() => "slider-" + props.goalQuestion}
        />

        <div className={classes.actionButtonGroup}>
          <Button onClick={() => props.closeModal()}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              setLoading(true);
              var goalResponseModel = new GoalResponseViewModel();
              goalResponseModel.goalId = props.goalId;
              goalResponseModel.patientId = props.patient.id;
              goalResponseModel.response = response;
              goalResponseModel.active = true;

              goalClient
                .respondToGoalsQuestionnaire(
                  goalResponseModel,
                  props.goalResponseId,
                  undefined,
                  undefined
                )
                .then(() => {
                  props.closeModal();
                })
                .finally(() => setLoading(false));
            }}
          >
            Rate Goal
          </LoadingButton>
        </div>
      </Box>
    </Modal>
  );
};

export default RateGoalModal;
