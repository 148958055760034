import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import {
  DashboardClient,
  QuestionnaireClient,
  QuestionnaireViewModel,
} from "../../../../types/auto/types";
import { useEffect, useState } from "react";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { LoadingButton } from "@mui/lab";
import { Error400 } from "../../elements/errors/Error400";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { useMountedState, useUnmountPromise } from "react-use";

interface Props {
  open: boolean;
  closeModal: () => void;
  refreshQuestionnaires: () => void;
  questionnaireId: number;
  patientId: number;
  onSave?: () => void;
}

const AssignPatientsToQuestionnaireModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();

  const useStyle = makeStyles({
    subHeader: {
      fontSize: 20,
      marginBottom: 0,
      marginTop: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
    },
    formControl: {
      marginTop: 20,
      width: "100%",
    },
  });
  const classes = useStyle(theme);

  const [questionnaireId, setQuestionnaireId] = useState<number>();

  const [allQuestionnaires, setAllQuestionnaires] =
    useState<QuestionnaireViewModel[]>();

  const [loading, setLoading] = useState(false);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "60%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    border: "none",
  };

  const [errorOnAssigning, setErrorOnAssigning] = useState(false);

  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        setQuestionnaireId(props.questionnaireId);
        setErrorOnAssigning(false);

        // Get the questionnaires for dashboard questionnaire selection
        resolveWhileMounted(
          new QuestionnaireClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getDashboardConfigurableQuestionnaires()
        ).then((questionnaires: QuestionnaireViewModel[]) => {
          setAllQuestionnaires(questionnaires);
        });
      }
    };
    effect();
  }, [resolveWhileMounted, isMountedState, props.questionnaireId]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h5" component="h5" className={classes.subHeader}>
          Assigned Dashboard Questionnaire
        </Typography>
        <FormControl className={classes.formControl}>
          <InputLabel id="medication">Displayed Questionnaire</InputLabel>
          <Select
            variant="outlined"
            labelId="dashboardQuestionnaire"
            label="Dashboard Questionnaire"
            value={questionnaireId ? questionnaireId : props.questionnaireId}
            onChange={(event) =>
              setQuestionnaireId(event.target.value as number)
            }
          >
            {allQuestionnaires?.map((val) => (
              <MenuItem key={val.id} value={val.id} role="menuitem">
                {val.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              setErrorOnAssigning(false);
              props.closeModal();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              setLoading(true);

              resolveWhileMounted(
                new DashboardClient(
                  Configuration.SERVER_ROOT,
                  FetchOverride
                ).setPatientDashboardQuestionnaire(
                  props.patientId,
                  questionnaireId
                )
              ).then((success: boolean) => {
                setLoading(false);
                if (success) {
                  props.onSave && props.onSave();
                  setErrorOnAssigning(false);
                  props.closeModal();
                } else {
                  setErrorOnAssigning(true);
                }
              });
            }}
          >
            Save changes
          </LoadingButton>
        </div>
        {errorOnAssigning && <Error400 />}
      </Box>
    </Modal>
  );
};

export default AssignPatientsToQuestionnaireModal;
