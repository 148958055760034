import { ScoringTransformationDataModel } from "./QuestionnaireBuilderDataModelHelper";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import TransformationAddModal from "./TransformationAddModal";

interface Props {
  transformWIP: ScoringTransformationDataModel[];
  handleChange: (
    index: number,
    scoreTransf: ScoringTransformationDataModel,
    arrString?: string
  ) => void;
  handleDelete: (index: number) => void;
  handleCancel: () => void;
}

const TransformationSelector = (props: Props): JSX.Element => {
  const headers: string[] = ["Rules", "Transformation Array", "Edit", "Delete"];

  const [rows, setRows] = useState<JSX.Element[]>([]);
  const [totalRows] = useState<number>(rows.length);
  const [transformationAddModalOpen, setTransformationAddModalOpen] =
    useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [transformationRule, setTransformationRule] =
    useState<ScoringTransformationDataModel>({ hideGraph: false });

  function loadRows() {
    var localRows: JSX.Element[] = [];
    if (props.transformWIP.length === 0) {
      setRows([]);
    } else {
      for (let i = 0; i < props.transformWIP.length; i++) {
        const rules = props.transformWIP[i].rules;
        var rulesString: string[] = [];
        if (rules != null) {
          rulesString = rules.map((rule) => {
            return (
              "{Question: " + rule.question + ", Answer: " + rule.answer + "}"
            );
          });
        }
        const transformationArr = props.transformWIP[i].transformation;
        var transformString: string = "";
        if (transformationArr != null) {
          transformString = "[" + transformationArr.join(",") + "]";
        }
        localRows.push(
          <TableRow key={"transformWIP-row-" + i}>
            <TableCell key={"question" + i} width={250}>
              <TextField
                value={
                  rulesString.length === 0
                    ? "No Rules"
                    : rulesString?.join(", ")
                }
                key={"transformWIP-name-" + i}
                variant="outlined"
                multiline
                maxRows={4}
                fullWidth
              />
            </TableCell>
            <TableCell key={"answers" + i}>
              <TextField
                value={transformString}
                key={"transformWIP-answers-" + i}
                variant="outlined"
                multiline
                maxRows={4}
                fullWidth
              />
            </TableCell>
            <TableCell key={"transformWIP-EditButton-cell-" + i} width={150}>
              <Button
                id={"edit-row-" + i}
                variant="contained"
                key={"transformWIP-EditButton-" + i}
                onClick={() => {
                  editResponseFromKey(i);
                }}
                fullWidth
              >
                Edit
              </Button>
            </TableCell>
            <TableCell key={"transformWIP-DeleteButton-cell-" + i} width={150}>
              <Button
                id={"delete-row-" + i}
                variant="contained"
                key={"transformWIP-DeleteButton-" + i}
                onClick={() => {
                  props.handleDelete(i);
                }}
                fullWidth
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    }
    setRows(localRows);
  }

  function addTransformationRule() {
    setIndex(props.transformWIP.length);
    setTransformationRule({ hideGraph: false });
    setTransformationAddModalOpen(true);
  }

  function editResponseFromKey(index: number) {
    for (let i = 0; i < props.transformWIP.length; i++) {
      if (i === index) {
        const newRule = structuredClone(props.transformWIP[i]);
        setTransformationRule(newRule);
        continue;
      }
    }
    setIndex(index);
    setTransformationAddModalOpen(true);
  }

  useEffect(
    () => {
      loadRows();
    },
    // eslint-disable-next-line
    [props.transformWIP]
  );

  return (
    <>
      <Box key={"transformation-selector-table-box-"}>
        <TableContainer
          component={Paper}
          key={"transformation-selector-table-container-"}
        >
          <Table
            sx={{ minWidth: 1000 }}
            aria-label="simple table"
            key={"transformation-selector-table-"}
          >
            <TableHead key={"transformation-selector-table-head-"}>
              <TableRow key={"transformation-selector-table-head-row-"}>
                {headers?.map((header) => (
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    key={"header-" + header}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody key={"transformation-selector-table-body-"}>
              {rows}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          key={"transformation-selector-buttons-container"}
        >
          {totalRows < 20 && (
            <Button
              onClick={async () => {
                addTransformationRule();
              }}
              variant="contained"
              sx={{ marginTop: 2, marginBottom: 2 }}
              key={"transformation-selector-table-add-button-"}
            >
              Add Transformation Rule
            </Button>
          )}
          <Button
            onClick={() => {
              props.handleCancel();
            }}
            key={"transformation-selector-back-button"}
          >
            Back
          </Button>
        </Box>
      </Box>
      <TransformationAddModal
        open={transformationAddModalOpen}
        handleCancel={() => {
          setTransformationAddModalOpen(false);
        }}
        handleUpdate={(transformationRule, arrString) => {
          props.handleChange(index, transformationRule, arrString);
          setTransformationAddModalOpen(false);
        }}
        transformationRule={transformationRule}
        key={"transformation-selector-table-modal-"}
      />
    </>
  );
};

export default TransformationSelector;
