import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../../../Theme";
import { makeStyles } from "@mui/styles";
import { MenuItem, Theme } from "@mui/material";
import {
  IdentityClient,
  Role,
  UserViewModel,
} from "../../../../types/auto/types";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { useEffect, useState } from "react";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { useUnmountPromise } from "react-use";

interface Props {
  userRole: Role;
  usingMedication: boolean;
  usingExternalData: boolean;
  allowEditQuestionnaires: boolean;
}

const NavigationOptions = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = CheckIsMobile();
  const resolveWhileMounted = useUnmountPromise();
  const [user, setUser] = useState<UserViewModel>();
  const [isAuthed, setIsAuthed] = useState(false);
  const useStyle = makeStyles((th: Theme) => ({
    usersLinkMobile: {
      display:
        props.userRole === Role.Admin || props.userRole === Role.SuperAdmin
          ? isMobile
            ? "block"
            : "inline-block"
          : "none",
    },
    menuItem: {
      border: "2px solid transparent",
      "&:hover": {
        borderBottom: "2px solid white",
        backgroundColor: "transparent",
      },
    },
  }));
  const classes = useStyle(theme);

  useEffect(() => {
    const effect = async () => {
      if (!isAuthed) {
        await resolveWhileMounted(
          new IdentityClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).isAuthed()
        ).then((usr: UserViewModel) => {
          setIsAuthed(true);
          setUser(usr);
        });
      }
    };
    effect();
  }, [isAuthed, resolveWhileMounted]);

  return (
    <>
      {(user?.role === Role.Clinician ||
        user?.role === Role.Admin ||
        user?.role === Role.SuperAdmin) && (
        <MenuItem
          role="menuitem"
          aria-label="Search"
          tabIndex={0}
          id="Search"
          onClick={() => {
            if (location.pathname !== "/search") navigate("/search");
          }}
          className={classes.menuItem}
        >
          Search
        </MenuItem>
      )}
      {(user?.role === Role.Admin || user?.role === Role.SuperAdmin) && (
        <MenuItem
          role="menuitem"
          aria-label="Shared Caseloads"
          tabIndex={0}
          id="sharedCaseloads"
          onClick={() => navigate("/sharedCaseloads")}
          className={classes.menuItem}
        >
          Shared Caseloads
        </MenuItem>
      )}
      {(user?.role === Role.Admin || user?.role === Role.SuperAdmin) && (
        <MenuItem
          role="menuitem"
          aria-label="Reports"
          tabIndex={0}
          id="reports"
          onClick={() => navigate("/reports")}
          className={classes.menuItem}
        >
          Reports
        </MenuItem>
      )}
      {props.usingMedication &&
        (user?.role === Role.Admin || user?.role === Role.SuperAdmin) && (
          <MenuItem
            role="menuitem"
            aria-label="Medications"
            tabIndex={0}
            id="medications"
            onClick={() => navigate("/medications")}
            className={classes.menuItem}
          >
            Medications
          </MenuItem>
        )}
      {props.usingExternalData &&
        (user?.role === Role.Admin || user?.role === Role.SuperAdmin) && (
          <MenuItem
            role="menuitem"
            aria-label="External Datasets"
            tabIndex={0}
            id="externaldatasets"
            onClick={() => navigate("/externalDatasets")}
            className={classes.menuItem}
          >
            External Datasets
          </MenuItem>
        )}
      {props.allowEditQuestionnaires &&
        (user?.role === Role.Admin || user?.role === Role.SuperAdmin) && (
          <MenuItem
            role="menuitem"
            aria-label="Questionnaires"
            tabIndex={0}
            id="questionnaires"
            onClick={() => navigate("/questionnaires")}
            className={classes.menuItem}
          >
            Questionnaires
          </MenuItem>
        )}
    </>
  );
};

export default NavigationOptions;
