import { Box, Alert } from "@mui/material";
import {
  AnswerDataModel,
  QuestionDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import BuilderQuestionBox from "./BuilderQuestionBox";
import { makeStyles } from "@mui/styles";
import QuestionDetails from "./QuestionDetails";
import QuestionActions from "./QuestionActions";
import {
  QuestionType,
  QuestionnaireViewModel,
} from "../../../../types/auto/types";
import { CheckIsMobile } from "../../../utils/MobileStatus";

interface Props {
  questions: QuestionDataModel[];
  allQuestionnaires: QuestionnaireViewModel[];
  handleAddAnswer: (qNum: number) => void;
  preview: boolean;
  handleEditQuestion: (q: QuestionDataModel) => void;
  handleDuplicateQuestion: (q: QuestionDataModel) => void;
  handleDeleteQuestion: (qNum: number) => void;
  handleDeleteAnswer: (qNum: number, aNum: number) => void;
  handleEditAnswer: (answer: AnswerDataModel, qNum: number) => void;
  handleDependsOn: (q: QuestionDataModel) => void;
  handleAutofillFrom: (q: QuestionDataModel) => void;
  handleToggleMulti: (q: QuestionDataModel, qType: QuestionType) => void;
  sectionNumber: number;
  handleToggleSkippable: (q: QuestionDataModel, qType: QuestionType) => void;
  handleToggleDay: (q: QuestionDataModel, qType: QuestionType) => void;
}

const SectionQuestions = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    mainContainer: {
      display: "flex",
      backgroundColor: "#EAE8E1",
      borderRadius: 20,
      padding: 15,
      width: "100%",
      flexWrap: "wrap",
      borderStyle: "solid",
      borderColor: "lightgrey",
      borderWidth: 2,
      gap: 10,
    },
    dependsOnContainer: {
      display: "flex",
      backgroundColor: "#EAE8E1",
      borderRadius: 20,
      padding: 15,
      width: "100%",
      flexWrap: "wrap",
      borderStyle: "solid",
      borderColor: "#e88282",
      borderWidth: 2,
      gap: 10,
    },
    titleContainer: {
      display: "flex",
      backgroundColor: "#EAE8E1",
      padding: 5,
      width: "100%",
    },
    previewContainer: {
      display: "flex",
      backgroundColor: "#EAE8E1",
      width: "100%",
      gap: 10,
      flexWrap: isMobile ? "wrap" : "nowrap",
    },
  });
  const classes = useStyle();

  const returnRows: JSX.Element[] = props.questions.map((question, index) => {
    return (
      <Box
        className={
          props.preview && question.dependsOn
            ? classes.dependsOnContainer
            : classes.mainContainer
        }
        key={"question-container-" + question.number + "-index-" + index}
      >
        {!props.preview && (
          <Box
            className={classes.previewContainer}
            key={"question-details-container-" + question.number}
          >
            <QuestionDetails
              question={question}
              allQuestionnaires={props.allQuestionnaires}
              handleAddAnswer={(qNum) => props.handleAddAnswer(qNum)}
              handleEditQuestion={(q) => props.handleEditQuestion(q)}
              handleDuplicateQuestion={(q) => props.handleDuplicateQuestion(q)}
              handleDeleteQuestion={(qNum) => props.handleDeleteQuestion(qNum)}
              handleDependsOn={(q) => props.handleDependsOn(q)}
              handleAutofillFrom={(q) => props.handleAutofillFrom(q)}
              handleDeleteAnswer={props.handleDeleteAnswer}
              handleEditAnswer={props.handleEditAnswer}
              handleToggleSkippable={props.handleToggleSkippable}
              handleToggleMulti={(q, qType) =>
                props.handleToggleMulti(q, qType)
              }
              handleToggleDay={(q, qType) => props.handleToggleDay(q, qType)}
              key={"question-details-" + question.number}
            />
          </Box>
        )}
        {props.preview && (
          <Box
            className={classes.previewContainer}
            key={"question-preview-container-" + question.number}
          >
            {question.answers.length === 0 &&
            question.questionType !== QuestionType.Goals ? (
              <Alert
                key={"question-preview-alert-" + question.number}
                severity="info"
              >
                Please add answers to preview this question.
              </Alert>
            ) : (
              <BuilderQuestionBox
                question={question}
                key={"question-box-" + question.number + "-" + index}
              />
            )}
            <QuestionActions
              question={question}
              handleEditQuestion={(q) => props.handleEditQuestion(q)}
              handleDuplicateQuestion={(q) => props.handleDuplicateQuestion(q)}
              handleDeleteQuestion={(qNum) => props.handleDeleteQuestion(qNum)}
              handleAddAnswer={(qNum) => props.handleAddAnswer(qNum)}
              handleDependsOn={(q) => props.handleDependsOn(q)}
              handleAutofillFrom={(q) => props.handleAutofillFrom(q)}
              key={"preview-question-actions-" + question.number}
            />
          </Box>
        )}
      </Box>
    );
  });

  return (
    <Box
      display={"flex"}
      gap={2}
      width={"100%"}
      flexWrap={"wrap"}
      key={"section-questions-main-container-" + props.sectionNumber}
    >
      {returnRows}
    </Box>
  );
};

export default SectionQuestions;
