import { useContext } from "react";
import { Role } from "../../types/auto/types";
import { UserContext } from "../components/elements/stores/UserStore";
import PatientHomepage from "./PatientHomepage";
import UserSearchPage from "./UserSearchPage";

const UserHomepage = () => {
  const user = useContext(UserContext);
  return (
    <>
      {user.role !== Role.Patient && <UserSearchPage />}
      {user.role === Role.Patient && <PatientHomepage />}
    </>
  );
};

export default UserHomepage;
