import React from "react";
import { Container, Typography } from "@mui/material";
import { DataSaverOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

interface Props {
  width: number;
  height: number;
}

const GraphNoData = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    container: {
      marginTop: 30,
      height: props.height,
      width: props.width,
      textAlign: "center",
      paddingTop: 175,
      color: "gray",
    },
    typography: {
      fontSize: 25,
    },
    icon: { fontSize: 50 },
  }));
  const classes = useStyle();

  return (
    <Container className={classes.container}>
      <DataSaverOff className={classes.icon} />
      <Typography className={classes.typography}>No Data To Display</Typography>
    </Container>
  );
};

export default GraphNoData;
