import { Alert, CircularProgress, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useEffect } from "react";
import { useMountedState, useUnmountPromise } from "react-use";
import {
  DatasetClient,
  DatasetViewModel,
  TenancyOptionErrors,
} from "../../types/auto/types";
import CreateEditDataset from "../components/externalDatasets/datasets/CreateEditDataset";
import DatasetCard from "../components/externalDatasets/datasets/DatasetCard";
import { Configuration, HelpText } from "../Constants";
import { FetchOverride } from "../utils/Request";
import PaginatedView from "../components/search/PaginatedView";

const ExternalData = (): JSX.Element => {
  const useStyle = makeStyles(() => ({
    datasetContainer: {
      marginTop: "10px",
    },
    datasets: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    titles: {
      marginTop: "10px",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  }));
  const classes = useStyle();
  const [datasets, setDatasets] = React.useState<DatasetViewModel[]>([]);
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const isMountedState = useMountedState();
  const resolveWhileMounted = useUnmountPromise();
  const [error, setError] = React.useState<TenancyOptionErrors | undefined>(
    undefined
  );

  const [termFilter, setTermFilter] = React.useState<string>("");

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        setLoading(true);
        setDatasets([]);
        const datasetClient = new DatasetClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        );
        await resolveWhileMounted(datasetClient.getTenantDatasets())
          .then((datasets) => {
            setLoading(false);
            setDatasets(datasets);
          })
          .catch((e: TenancyOptionErrors) => {
            setLoading(false);
            if (e === TenancyOptionErrors.TenancyNotSupported) {
              window.history.back();
            }
            setError(e);
          });
      }
    };
    effect();
  }, [isMountedState, resolveWhileMounted, refresh]);
  /* a*/
  return (
    <Container className={classes.datasetContainer} disableGutters>
      <Typography variant="h5" component="h2" className={classes.titles}>
        Datasets
      </Typography>

      <PaginatedView
        controlElements={
          <Container disableGutters>
            <CreateEditDataset refresh={() => setRefresh((x) => !x)} />
            {datasets.length === 0 && !loading && (
              <Typography>
                There are no active datasets. Click the above button to add a
                new dataset.
              </Typography>
            )}
          </Container>
        }
        elements={datasets
          .filter(
            (x) =>
              x.name
                ?.toLowerCase()
                ?.startsWith(termFilter?.toLowerCase() ?? "") ||
              termFilter?.trimEnd() === ""
          )
          .map((dataset, key) => (
            <DatasetCard
              dataset={dataset}
              refresh={() => setRefresh((x) => !x)}
              key={key}
            />
          ))}
        filterCallback={(term, _) => {
          setTermFilter(term);
        }}
        searchTerm={termFilter}
        helpText={HelpText.DATASET_TABLE.SEARCH_DATASET}
      />

      <Container className={classes.datasets} disableGutters>
        {loading && (
          <CircularProgress
            className={classes.loadingSpinner}
            aria-label="Loading"
          />
        )}
        {error !== undefined && (
          <Alert severity="error">
            {error === TenancyOptionErrors.TenancyNotSupported
              ? "Tenancy does not support external datasets."
              : ""}
          </Alert>
        )}
      </Container>
    </Container>
  );
};

export default ExternalData;
