import { MedicationViewModel } from "../../../../types/auto/types";

export const CurrentDate = new Date();

export const IsDosageValid = (
  selectedMedication: MedicationViewModel,
  dose: number
): boolean =>
  !(dose > selectedMedication.maxDosage) &&
  !(dose < selectedMedication.minDosage);

export const IsValidDateRange = (startDate: Date, endDate: Date): boolean =>
  !(startDate > endDate);

export const IsValidFrequencyAmount = (frequencyAmount: number): boolean =>
  !(frequencyAmount < 1);
