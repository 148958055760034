import { Card, CardContent, Theme, Typography } from "@mui/material";
import { CheckIsMobile, CheckIsSmallDevice } from "../../../utils/MobileStatus";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import {
  Frequency,
  MedicationViewModel,
  PatientMedicationViewModel,
} from "../../../../types/auto/types";
import moment from "moment";
import { Container } from "@mui/system";
import CreateEditPatientMedication from "./CreateEditPatientMedication";

interface Props {
  patientMedication: PatientMedicationViewModel;
  patientId: number;
  reload: () => void;
  medications: MedicationViewModel[];
}

const PatientMedication = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const useStyle = makeStyles((th: Theme) => ({
    headerAndActions: {
      display: "flex",
      paddingRight: "0",
      paddingLeft: "0",
    },
    card: {
      marginTop: "10px",
      width: isMobile ? "100%" : "49.5%",
      marginRight: isMobile ? "auto" : "0.5%",
      minWidth: isSmallDevice ? "180px" : "250px",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      height: "fit-content",
      "&:nth-child(even)": {
        marginRight: isMobile ? "auto" : "0",
        marginLeft: isMobile ? "auto" : "0.5%",
      },
    },
    medication: {
      display: "flex",
    },
    patientNote: {
      whiteSpace: "pre-wrap",
    },
  }));
  const classes = useStyle(theme);
  return (
    <Card className={classes.card} elevation={0}>
      <CardContent>
        <Container className={classes.headerAndActions}>
          <Typography
            className={classes.medication}
            variant="h5"
            component="h2"
          >
            {props.patientMedication.medication?.name}{" "}
            {props.patientMedication.dose !== undefined &&
            props.patientMedication.dose !== null
              ? " (" +
                props.patientMedication.dose.toString() +
                props.patientMedication.medication?.units +
                (props.patientMedication.frequency !== undefined &&
                props.patientMedication.frequency !== null
                  ? " every " +
                    props.patientMedication.frequencyAmount +
                    " " +
                    Frequency[props.patientMedication.frequency ?? 0] +
                    ")"
                  : ")")
              : ""}
          </Typography>

          <CreateEditPatientMedication
            patientMedication={props.patientMedication}
            patientId={props.patientId}
            refresh={props.reload}
            medications={props.medications}
          />
        </Container>
        <Typography className={classes.patientNote}>
          {props.patientMedication.notes}
        </Typography>
        <Typography>
          {moment(props.patientMedication.startDate).format("DD-MM-YYYY")}{" "}
          {props.patientMedication.endDate !== undefined
            ? " - " +
              moment(props.patientMedication.endDate).format("DD-MM-YYYY")
            : ""}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default PatientMedication;
