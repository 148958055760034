import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
  Box,
  Theme,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  AnswerViewModel,
  QuestionViewModel,
} from "../../../../types/auto/types";

import MultiSelectorTableRowResponse from "./MultiSelectorTableRowResponse";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import { CheckIsMobile } from "../../../utils/MobileStatus";

interface Props {
  question: QuestionViewModel;
  answers: AnswerViewModel[] | undefined;
  response: string;
}

interface DataModel {
  [key: string]: any;
}

const MultiSelectorTableResponse = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    tableContainer: {
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      borderRadius: "4px",
    },
  }));
  const classes = useStyle(theme);
  const [headers, setHeaders] = useState<JSX.Element[]>([]);
  const [rows, setRows] = useState<JSX.Element[]>([]);
  const [reloadRows, setReloadRows] = useState<boolean>(false);
  const isMobile = CheckIsMobile();

  // create the table headers based on the questionnaire headers
  function createHeaders(headers: string[] | undefined) {
    const localHeaders: JSX.Element[] =
      headers != null
        ? headers?.map((header) => (
            <TableCell
              style={{ fontWeight: "bold" }}
              key={"table-head-cell-" + header}
            >
              {header}
            </TableCell>
          ))
        : [];
    setHeaders(localHeaders);
    setReloadRows((x) => !x);
  }

  // take the dataModel created from the modal form and generate the row.
  function createRow(model: DataModel) {
    return (
      <MultiSelectorTableRowResponse
        model={model}
        answers={props.answers}
        key={"RowId" + model["RowId"]}
      />
    );
  }

  // Load all rows based on answer string
  function loadRows(responses: string) {
    var localRowsData: DataModel[] = JSON.parse(responses);
    var localRows: JSX.Element[] = [];
    localRowsData.forEach((model) => {
      const row = createRow(model);
      localRows.push(row);
    });
    setRows(localRows);
  }

  useEffect(
    () => {
      if (props.answers != null) {
        createHeaders(props.question.headers);
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const effect = () => {
        var responseString = props.response;
        if (responseString !== "" && responseString != null)
          loadRows(responseString);
      };
      effect();
    }, // eslint-disable-next-line
    [reloadRows, setReloadRows]
  );

  return (
    <>
      <Box key={"table-box-" + props.question.number}>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          key={"table-container-" + props.question.number}
        >
          <Table
            sx={isMobile ? { minWidth: 1000 } : {}}
            aria-label="table of responses"
            key={"table-" + props.question.number}
          >
            <TableHead key={"table-head-" + props.question.number}>
              <TableRow key={"table-head-row-" + props.question.number}>
                {headers}
              </TableRow>
            </TableHead>
            <TableBody key={"table-body-" + props.question.number}>
              {rows}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default MultiSelectorTableResponse;
