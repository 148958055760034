import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  PatientTimelineDataViewModel,
  QuestionViewModel,
} from "../../../../types/auto/types";
import { makeStyles } from "@mui/styles";
import { ResponseContent } from "./ReponseContent";
import { isDependsValid } from "../../questionnaires/questions/QuestionBoxHelper";
import { useState } from "react";

interface Props {
  timelineEntree: PatientTimelineDataViewModel;
}

export const ResponseAccordian = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    accordianHeading: {
      fontWeight: "bold",
    },
    accordian: { borderTop: "solid 1px lightgray", boxShadow: "none" },
  }));
  const classes = useStyle();
  let questions: QuestionViewModel[] = [];
  props.timelineEntree.questionnaire?.sections?.forEach((element) => {
    if (element.questions !== undefined) {
      element.questions.forEach((question) => {
        questions.push(question);
      });
    }
  });
  const [sectionSelected, setSectionSelected] = useState(0);
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      key={"response accordian" + props.timelineEntree.responseId}
      aria-label={"panel-content-responses" + props.timelineEntree.responseId}
      className={classes.accordian}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={
          "panel-content-responses" + props.timelineEntree.responseId
        }
        id={"panel-header-responses" + props.timelineEntree.responseId}
      >
        <Typography className={classes.accordianHeading}>Responses</Typography>
      </AccordionSummary>
      <AccordionDetails
        key={"accordian-details-response-" + props.timelineEntree.responseId}
        id={"accordian-details-response-" + props.timelineEntree.responseId}
        aria-label={
          "accordian-details-response-" + props.timelineEntree.responseId
        }
        sx={{
          paddingTop: props.timelineEntree.sectionsSelector ? 0 : "8px",
        }}
      >
        {props.timelineEntree.sectionsSelector && (
          <Select
            id={"dropdown-section" + props.timelineEntree.responseId}
            variant="standard"
            key={"dropdown-section" + props.timelineEntree.responseId}
            value={sectionSelected}
            onChange={(e) => {
              setSectionSelected(e.target.value as number);
            }}
            aria-label={"section dropdown" + props.timelineEntree.responseId}
            sx={{ marginBottom: "8px" }}
          >
            {props.timelineEntree.questionnaire?.sections?.map((val, index) => (
              <MenuItem
                key={"select-section-" + (index + 1)}
                value={index}
                role="menuitem"
                aria-label={`select-${index}-${props.timelineEntree.responseId}`}
              >
                {val.heading}
              </MenuItem>
            ))}
          </Select>
        )}
        {(props.timelineEntree.questionnaire?.paxTaskNumber === undefined ||
          props.timelineEntree.questionnaire?.paxTaskNumber === null) &&
          props.timelineEntree.sectionsSelector &&
          props.timelineEntree.questionnaire?.sections![
            sectionSelected
          ].questions?.map(
            (question, index) =>
              isDependsValid(
                question,
                props.timelineEntree.questionnaireResponse!,
                questions,
                false
              ) && (
                <ResponseContent
                  question={question}
                  index={index}
                  timelineEntree={props.timelineEntree}
                  key={index.toString() + props.timelineEntree.responseId}
                />
              )
          )}

        {(props.timelineEntree.questionnaire?.paxTaskNumber === undefined ||
          props.timelineEntree.questionnaire?.paxTaskNumber === null) &&
          !props.timelineEntree.sectionsSelector &&
          props.timelineEntree.questionnaire?.sections?.map((section) =>
            section.questions?.map(
              (question, index) =>
                isDependsValid(
                  question,
                  props.timelineEntree.questionnaireResponse!,
                  questions,
                  false
                ) && (
                  <ResponseContent
                    question={question}
                    index={index}
                    timelineEntree={props.timelineEntree}
                    key={index.toString() + props.timelineEntree.responseId}
                  />
                )
            )
          )}

        {props.timelineEntree.questionnaire?.paxTaskNumber !== undefined &&
          props.timelineEntree.questionnaire?.paxTaskNumber !== null && (
            <Container disableGutters>
              <Typography>{props.timelineEntree.paxDResponses}</Typography>
            </Container>
          )}
      </AccordionDetails>
    </Accordion>
  );
};
