import React from "react";
import { theme } from "../../../../Theme";
import { Card, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckIsMobile } from "../../../utils/MobileStatus";

interface Props {
  children?: React.ReactNode;
}

const GraphCard = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles((th: Theme) => ({
    card: {
      height: "fit-content",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      borderBottom: isMobile
        ? "none"
        : "solid " + th.palette.grey[50] + " thin",
      borderTop: isMobile ? "none" : "solid " + th.palette.grey[50] + " thin",
    },
  }));
  const classes = useStyle(theme);

  return <Card className={classes.card}>{props.children}</Card>;
};

export default GraphCard;
