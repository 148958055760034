import { Alert, Box, Button, Modal, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { theme } from "../../../../Theme";
import {
  CaseloadViewModel,
  ErrorType,
  IPatientViewModel,
  PreferredContact,
  PatientViewModel,
  ContactDetailsViewModel,
} from "../../../../types/auto/types";
import { CreateEditPatientCall } from "./CreateEditPatientModalHelper";
import MultiSelectChip from "../../elements/form-elements/MultiSelectChip";
import ContactPreferencesForm from "./ContactPreferencesForm";
import PatientDetailsForm from "./PatientDetailsForm";
import nameof from "../../../utils/NameOf";
import { useMountedState, useUnmountPromise } from "react-use";

interface Props {
  open: boolean;
  closeModal: () => void;
  saveModal: () => void;
  patient?: PatientViewModel;
  allCaseloads?: CaseloadViewModel[];
  nhsNumberRequired: boolean;
  customPatientId: boolean;
  customPatientIdDisplayName: string;
  customPatientIdRegex: string;
  customPatientIdMandatory: boolean;
}

const CreateEditPatientModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "80%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    subHeader: {
      fontSize: 20,
      marginBottom: 0,
      marginTop: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      marginTop: 20,
    },
    caseloadSelector: {
      width: "100%",
      marginTop: 20,
    },
    error: {
      marginTop: "10px",
    },
  });
  const classes = useStyle(theme);
  const [patient, setPatient] = useState(
    new PatientViewModel({
      firstName: "",
      lastName: "",
      preferredContact: PreferredContact.Email,
    } as IPatientViewModel)
  );
  const [details, setDetails] = useState(
    new ContactDetailsViewModel({
      preferredContact: PreferredContact.Email,
    })
  );
  const [, setReRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formattedPatientNumber, setFormattedPatientNumber] = useState("");
  const [formattedCustomPatientId, setFormattedCustomPatientId] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [birthDateError, setBirthDateError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nhsNumberError, setNhsNumberError] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [customPatientIdError, setCustomPatientIdError] = useState(false);
  const [selectedCaseloads, setSelectedCaseloads] = useState<
    CaseloadViewModel[]
  >([]);
  const [hasMobile, setHasMobile] = useState(false);
  const isMountedState = useMountedState();
  const resolveWhileMounted = useUnmountPromise();
  const [error, setError] = useState<ErrorType | undefined>(undefined);
  const errorText =
    error !== undefined
      ? error === ErrorType.OIDCInvite
        ? "Failed to send invite, please contact support team"
        : error === ErrorType.InvalidModel
        ? "Invalid patient details provided."
        : error === ErrorType.ExisitingEmail
        ? "A user with this email address already exists. Please use a different email."
        : error === ErrorType.ExistingNHSNumber
        ? "A patient with that NHS number already exists. Please use a different NHS number."
        : error === ErrorType.ExistingCustomPatientId
        ? "A patient with that " +
          props.customPatientIdDisplayName +
          " already exists. Please use a different " +
          props.customPatientIdDisplayName +
          "."
        : "System error please contact support team"
      : "";

  // Clear the patient when the modal is reopened
  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        if (props.patient) {
          // Deep copy patient prop to fix state update problems
          setPatient(
            JSON.parse(JSON.stringify(props.patient), function (key, value) {
              if (typeof value === "string") {
                // Parse datetimes for the relevant fields
                if (
                  (key === nameof<PatientViewModel>("created") ||
                    key === nameof<PatientViewModel>("updated") ||
                    key === nameof<PatientViewModel>("dateOfBirth")) &&
                  !isNaN(Date.parse(value))
                ) {
                  return new Date(value);
                }
              }
              return value;
            })
          );
          setDetails(
            JSON.parse(JSON.stringify(props.patient), function (key, value) {
              return value;
            })
          );
          setFormattedPatientNumber(props.patient.nhsNumber?.toString() || "");
          setFormattedCustomPatientId(
            props.patient.customPatientId?.toString() || ""
          );
          setHasMobile(props.patient.mobileNumber !== undefined);
        } else {
          setPatient({
            firstName: "",
            lastName: "",
            preferredContact: PreferredContact.Email,
          } as PatientViewModel);
          setFormattedPatientNumber("");
          setFormattedCustomPatientId("");
          setSelectedCaseloads([]);
          setHasMobile(false);
        }
        setFirstNameError(false);
        setLastNameError(false);
        setGenderError(false);
        setBirthDateError(false);
        setEmailError(false);
        setNhsNumberError(false);
        setMobileNumberError(false);
        setCustomPatientIdError(false);

        setReRender((x) => !x);
      }
    };
    effect();
  }, [props.open, props.patient, isMountedState, resolveWhileMounted]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          {props.patient ? "Save Patient" : "Create Patient"}
        </Typography>

        <PatientDetailsForm
          patient={patient}
          setPatient={setPatient}
          firstNameError={firstNameError}
          setFirstNameError={setFirstNameError}
          lastNameError={lastNameError}
          setLastNameError={setLastNameError}
          birthDateError={birthDateError}
          setBirthDateError={setBirthDateError}
          genderError={genderError}
          setGenderError={setGenderError}
          formattedPatientNumber={formattedPatientNumber}
          setFormattedPatientNumber={setFormattedPatientNumber}
          nhsNumberError={nhsNumberError}
          setNhsNumberError={setNhsNumberError}
          setReRender={setReRender}
          nhsNumberRequired={props.nhsNumberRequired}
          customPatientId={props.customPatientId}
          formattedCustomPatientId={formattedCustomPatientId}
          setFormattedCustomPatientId={setFormattedCustomPatientId}
          customPatientIdError={customPatientIdError}
          setCustomPatientIdError={setCustomPatientIdError}
          customPatientIdDisplayName={props.customPatientIdDisplayName}
          customPatientIdRegex={props.customPatientIdRegex}
          customPatientIdMandatory={props.customPatientIdMandatory}
        />
        <ContactPreferencesForm
          details={details}
          setDetails={setDetails}
          emailError={emailError}
          setEmailError={setEmailError}
          mobileNumberError={mobileNumberError}
          setMobileNumberError={setMobileNumberError}
          setReRender={setReRender}
          hasMobile={hasMobile}
        />
        {!props.patient &&
          props.allCaseloads !== null &&
          props.allCaseloads !== undefined && (
            <>
              <Typography className={classes.subHeader}>Caseloads</Typography>
              <MultiSelectChip
                label={"User Caseloads"}
                options={props.allCaseloads}
                selectedOptions={selectedCaseloads}
                setSelectedOptions={setSelectedCaseloads}
                className={classes.caseloadSelector}
              />
            </>
          )}
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              props.closeModal();
              setError(undefined);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () =>
              CreateEditPatientCall(
                props.patient === undefined,
                patient,
                details,
                selectedCaseloads,
                setLoading,
                setFirstNameError,
                setLastNameError,
                setBirthDateError,
                setGenderError,
                setNhsNumberError,
                setEmailError,
                setMobileNumberError,
                props.saveModal,
                setError,
                props.nhsNumberRequired,
                props.customPatientId,
                props.customPatientIdRegex,
                setCustomPatientIdError,
                props.customPatientIdMandatory
              )
            }
          >
            {props.patient ? "Save Changes" : "Create Patient"}
          </LoadingButton>
        </div>
        {error !== undefined && (
          <Alert severity="error" className={classes.error}>
            {errorText}
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default CreateEditPatientModal;
