import { useEffect, ReactNode } from "react";

interface IProps {
  title: string;
  children: ReactNode;
}

export const PageTitle = ({ title, children }: IProps): JSX.Element => {
  useEffect(() => {
    document.title = title + " | True Colours";
  }, [title]);

  return <>{children}</>;
};

export default PageTitle;
