import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUnmountPromise } from "react-use";
import { StaffClient, StaffViewModel } from "../../types/auto/types";
import ViewStaffDetails from "../components/staff/ViewStaffDetails";
import ErrorComponent from "../components/elements/errors/ErrorComponent";
import { Configuration } from "../Constants";
import { FetchOverride } from "../utils/Request";

const useStyle = makeStyles(() => ({
  mainContainer: {
    paddingRight: 0,
    paddingLeft: 0,
    marginTop: 20,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
}));
const ViewStaff = () => {
  const resolveWhileMounted = useUnmountPromise();
  const [staff, setStaff] = useState<StaffViewModel>();
  const [errorNoStaff, setErrorNoStaff] = useState(false);
  const [errorUnauthorized, setErrorUnauthorized] = useState(false);
  const [serverError, setServerError] = useState(false);
  const { staffId } = useParams();

  const reloadStaff = useCallback(async () => {
    setErrorNoStaff(false);
    setErrorUnauthorized(false);
    setServerError(false);
    await resolveWhileMounted(
      new StaffClient(Configuration.SERVER_ROOT, FetchOverride).getStaff(
        parseInt(staffId ?? "0")
      )
    )
      .then((x) => {
        setStaff(x);
      })
      .catch((e) => {
        if (e.status === 400) {
          setErrorNoStaff(true);
        } else if (e.status === 401) {
          setErrorUnauthorized(true);
        } else {
          setServerError(true);
        }
      });
  }, [resolveWhileMounted, staffId]);

  useEffect(() => {
    reloadStaff();
  }, [resolveWhileMounted, staffId, reloadStaff]);

  const classes = useStyle();
  return (
    <Container className={classes.mainContainer} disableGutters>
      {!errorNoStaff && !errorUnauthorized && !serverError && (
        <ViewStaffDetails
          staff={staff ?? new StaffViewModel()}
          reloadStaff={reloadStaff}
          setStaff={setStaff}
        />
      )}
      {errorNoStaff && <ErrorComponent errorText="No staff with this id." />}
      {errorUnauthorized && (
        <ErrorComponent errorText="You are not authorized to view this staff member." />
      )}
      {serverError && (
        <ErrorComponent errorText="An unexpected server error occurred, please try again later." />
      )}
    </Container>
  );
};

export default ViewStaff;
