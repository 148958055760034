import {
  Alert,
  Container,
  Divider,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import {
  DashboardClient,
  PatientViewModel,
  TenantClient,
  TenantOptionsViewModel,
  TenantViewModel,
  TupleOfQuestionnaireViewModelAndNullableDouble,
} from "../../../../types/auto/types";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import DashboardScoreDisplay from "./DashboardScoreDisplay";
import { useEffect, useState } from "react";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { useMountedState, useUnmountPromise } from "react-use";
import { PatientDashboardOptionsModel } from "../../questionnaires/builder/QuestionnaireBuilderDataModelHelper";

interface Props {
  patient?: PatientViewModel;
}

const PatientDashboard = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    container: {
      marginTop: "20px",
    },
    heading: {
      marginBottom: "10px",
    },
  }));
  const classes = useStyle(theme);

  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  const [tenancyOptions, setTenancyOptions] = useState<
    TenantOptionsViewModel | undefined
  >(undefined);

  const [dashboardOptions, setDashboardOptions] = useState<
    PatientDashboardOptionsModel | undefined
  >(undefined);

  const [score, setScore] = useState<number>();
  const [title, setTitle] = useState<string>();
  const [severityIndex, setSeverityIndex] = useState<number>();
  const [severityColour, setSeverityColour] = useState<string>();

  const [error, setError] = useState<string | undefined>();
  const [noResponsesError, setNoResponsesError] = useState<boolean>(false);

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        await resolveWhileMounted(
          new TenantClient(Configuration.SERVER_ROOT, FetchOverride).getTenant(
            undefined
          )
        )
          .then(async (tenant: TenantViewModel) => {
            if (tenant.tenantOptions !== null) {
              setTenancyOptions(tenant.tenantOptions);

              await resolveWhileMounted(
                new DashboardClient(
                  Configuration.SERVER_ROOT,
                  FetchOverride
                ).getLatestPatientTotalScore()
              ).then(
                (
                  questionnaireScore: TupleOfQuestionnaireViewModelAndNullableDouble
                ) => {
                  if (questionnaireScore !== null) {
                    if (
                      questionnaireScore.item1!.dashboardOptions !== undefined
                    ) {
                      setDashboardOptions(
                        questionnaireScore.item1!.dashboardOptions
                      );
                    } else {
                      setError(
                        "No patient dashboard options for selected questionnaire"
                      );
                    }
                    setTitle(questionnaireScore.item1!.name);
                    if (questionnaireScore.item2 == null) {
                      setNoResponsesError(true);
                    } else {
                      setScore(questionnaireScore.item2);
                      setSeverityIndex(-1);
                    }
                  }
                }
              );
            }
          })
          .catch(() => setError("Dashboard Has not been configured."));
      }
    };
    effect();
  }, [resolveWhileMounted, isMountedState]);

  const tenancyOptionsToValues = (
    tenancyOptions: TenantOptionsViewModel | undefined
  ) => {
    var values: number[] = [];

    if (dashboardOptions?.clearRegion !== undefined) {
      values.push(dashboardOptions?.clearRegion?.score);
    }
    if (dashboardOptions?.stableRegion !== undefined) {
      values.push(dashboardOptions?.stableRegion?.score);
    }
    if (dashboardOptions?.borderlineRegion !== undefined) {
      values.push(dashboardOptions?.borderlineRegion?.score);
    }
    if (dashboardOptions?.clinicalRegion !== undefined) {
      values.push(dashboardOptions?.clinicalRegion?.score);
    }

    return values;
  };

  const severityIndexToDashboardSection = (
    severityIndex: number | undefined
  ) => {
    var total = tenancyOptionsToValues(tenancyOptions).length;

    if (total > 0) {
      if (total > 3) {
        if (severityIndex === 0) {
          return dashboardOptions?.clearRegion;
        }
        if (severityIndex === 1) {
          return dashboardOptions?.stableRegion;
        }
        if (severityIndex === 2) {
          return dashboardOptions?.borderlineRegion;
        }
        if (severityIndex === 3) {
          return dashboardOptions?.clinicalRegion;
        }
      } else {
        if (severityIndex === 0) {
          return dashboardOptions?.stableRegion;
        }
        if (severityIndex === 1) {
          return dashboardOptions?.borderlineRegion;
        }
        if (severityIndex === 2) {
          return dashboardOptions?.clinicalRegion;
        }
      }
    }
  };

  return (
    <>
      <Container className={classes.container}>
        <Typography variant="h4" className={classes.heading}>
          Dashboard
        </Typography>
        <Divider style={{ marginBottom: "15px" }} />
        {!error && (
          <Grid container direction="row">
            <Grid item xs>
              <Typography variant="h5" className={classes.heading}>
                {noResponsesError ? (
                  <b>No responses have been recorded for this questionnaire.</b>
                ) : (
                  "Latest Score"
                )}
              </Typography>
              {!noResponsesError && (
                <>
                  <DashboardScoreDisplay
                    values={tenancyOptionsToValues(tenancyOptions)}
                    score={score ?? 0}
                    title={title ?? "None"}
                    onChangeSeverityIndex={(index, colour) => {
                      setSeverityIndex(index);
                      setSeverityColour(colour);
                    }}
                  />
                  <Typography color={severityColour}>
                    {severityIndexToDashboardSection(severityIndex)?.scoreText}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs>
              <Typography variant="h5" className={classes.heading}>
                Instructions
              </Typography>
              <Grid item className={classes.heading}>
                <Typography>
                  {severityIndexToDashboardSection(severityIndex)?.infoText}
                </Typography>
              </Grid>
              {dashboardOptions?.disclaimerText !== undefined && (
                <Grid item className={classes.heading}>
                  <Typography variant="h6">Disclaimer</Typography>
                  <Typography>{dashboardOptions?.disclaimerText}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {error && <Alert severity="error">{error}</Alert>}
      </Container>
    </>
  );
};

export default PatientDashboard;
