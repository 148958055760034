import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import {
  ScoringDataModel,
  QuestionnaireBuilderDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import nameof from "../../../utils/NameOf";
import { useEffect, useState } from "react";
import {
  CategoryType,
  QuestionnaireViewModel,
} from "../../../../types/auto/types";
import ScoringRulesModal from "./ScoringRulesModal";
import AdditionalPlotsEditModal from "./AdditionalPlotsEditModal";

interface Props {
  open: boolean;
  handleCancel: () => void;
  handleUpdate: (scoreCategory: ScoringDataModel, arrayString: string) => void;
  questionnaire: QuestionnaireBuilderDataModel;
  allQuestionnaires: QuestionnaireViewModel[];
  allQuestionsArr: number[];
  questionsDropdown: JSX.Element[];
  scoreCategory: ScoringDataModel;
}

const ScoringAddModal = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    scoringContainer: {
      margin: 0,
      paddingTop: 10,
      paddingRight: 0,
      paddingLeft: 0,
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      flexDirection: "column",
      gap: 15,
    },
    checkbox: {
      width: "100%",
      justifyContent: "flex-start",
    },
    checkboxSelectAll: {
      width: "30%",
      justifyContent: "flex-start",
    },
    textField: {
      width: "100%",
    },
    textFieldContainer: {
      display: "flex",
      flexDirection: "row",
      gap: 10,
      width: "100%",
      marginBottom: 15,
      justifyContent: "space-between",
    },
    checkboxes: {
      display: "flex",
      flexDirection: "row",
      gap: 10,
      width: "100%",
      marginBottom: 15,
      justifyContent: "flex-start",
    },
    infoContainer: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: 10,
    },
  });
  const classes = useStyle(theme);

  const [scoreCategory, setScoreCategory] = useState<ScoringDataModel>(
    props.scoreCategory
  );
  const [selectAllQuestionsBool, setSelectAllQuestionsBool] =
    useState<boolean>(false);
  const [openTransformationRulesModal, setOpenTransformationRulesModal] =
    useState<boolean>(false);
  const [openAdditionalPlotsModal, setOpenAdditionalPlotsModal] =
    useState<boolean>(false);
  const [arrayString, setArrayString] = useState<string>(
    props.scoreCategory?.maxScoringSubscales
      ? props.scoreCategory?.maxScoringSubscales
          .map((subscale) => {
            return "[" + subscale.join(",") + "]";
          })
          .join(",")
      : ""
  );
  const [inputError, setInputError] = useState<boolean>(false);

  const selectAllQuestions = "selectAllQuestions";

  function handleLocalChange(field: string, value: any) {
    if (
      field === nameof<ScoringDataModel>("meanScoring") ||
      field === nameof<ScoringDataModel>("meanScoreDecimal") ||
      field === nameof<ScoringDataModel>("meanScoreMissing") ||
      field === nameof<ScoringDataModel>("scoreLessThanThreshold")
    ) {
      setScoreCategory({
        ...scoreCategory,
        [field]: value,
      });
    } else if (field === selectAllQuestions) {
      value
        ? setScoreCategory({
            ...scoreCategory,
            [nameof<ScoringDataModel>("questions")]: props.allQuestionsArr,
          })
        : setScoreCategory({
            ...scoreCategory,
            [nameof<ScoringDataModel>("questions")]: [],
          });
      setSelectAllQuestionsBool(value);
    } else if (Array.isArray(value)) {
      setScoreCategory({
        ...scoreCategory,
        [field]: value,
      });
    } else {
      setScoreCategory({
        ...scoreCategory,
        [field]: value,
      });
    }
  }

  function validateInputs() {
    if (arrayString.includes("][")) {
      return false;
    }
    const newSubArr: number[][] = arrayString
      .replaceAll("[", "")
      .split("],")
      .map((subArr): number[] => {
        return subArr
          .replaceAll("]", "")
          .split(",")
          .map((num) => Number(num));
      });

    for (let i = 0; i < newSubArr.length; i++) {
      if (newSubArr[i].length !== 0) {
        for (let x = 0; x < newSubArr[i].length; x++) {
          if (Number.isNaN(newSubArr[i][x])) {
            return false;
          }
        }
      }
    }

    return true;
  }

  useEffect(() => {
    setScoreCategory(props.scoreCategory);
    setSelectAllQuestionsBool(false);
    setArrayString(
      props.scoreCategory?.maxScoringSubscales
        ? props.scoreCategory?.maxScoringSubscales
            .map((subscale) => {
              return "[" + subscale.join(",") + "]";
            })
            .join(",")
        : ""
    );
  }, [props.scoreCategory]);

  return (
    <>
      <Dialog
        open={props.open}
        maxWidth="lg"
        fullWidth
        key={"scoring-add-modal"}
      >
        <DialogTitle key={"scoring-add-modal-title"}>
          Score Catergory
        </DialogTitle>
        <DialogContent key={"scoring-add-modal-content"}>
          <Box
            className={classes.scoringContainer}
            key={"scoring-add-modal-container"}
          >
            <Box
              className={classes.textFieldContainer}
              key={"name-questions-container"}
            >
              <TextField
                label="Name"
                className={classes.textField}
                onChange={(e) =>
                  handleLocalChange(
                    nameof<ScoringDataModel>("name"),
                    e.target.value
                  )
                }
                value={scoreCategory.name ?? ""}
                sx={{ width: "100%" }}
                key={"scoring-name-field"}
              />
              <FormControl
                sx={{ width: "100%" }}
                key={"scoring-question-select-fromcontrol"}
              >
                <InputLabel
                  id="score-question-label"
                  key={"scoring-question-select-inputlabel"}
                >
                  Questions
                </InputLabel>
                <Select
                  labelId="score-question-label"
                  id="score-question-select"
                  key={"score-question-select"}
                  label="Question"
                  onChange={(e) =>
                    handleLocalChange(
                      nameof<ScoringDataModel>("questions"),
                      e.target.value
                    )
                  }
                  value={scoreCategory.questions ?? []}
                  multiple
                  disabled={selectAllQuestionsBool}
                >
                  {props.questionsDropdown}
                </Select>
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      handleLocalChange(selectAllQuestions, e.target.checked)
                    }
                    checked={selectAllQuestionsBool}
                    key={"select-all-questions-bool"}
                  />
                }
                label="Select All"
                className={classes.checkboxSelectAll}
                key={"select-all-questions-label"}
              />
            </Box>
            <Box className={classes.infoContainer} key={"subscale-container"}>
              <TextField
                label="Max Scoring Subscales"
                className={classes.textField}
                onChange={(e) => {
                  setArrayString(e.target.value);
                  setInputError(false);
                }}
                value={arrayString}
                sx={{ width: "100%" }}
                error={inputError}
                helperText={
                  inputError ? "Please follow the described format." : ""
                }
                key={"scoring-max-scopring-subscale-field"}
              />
              <Alert severity="info" key={"subscale-info"}>
                {
                  "Please use the following format for this input: To define a subscale, please provide a comma separated list of numbers surrounded by square brackets, e.g. [1,2,3,4]. To define multiple subscales, separate each subscale with a comma, e.g. [1,2,3,4],[7,8,9,10],[15,16,17]"
                }
              </Alert>
            </Box>
            <Box className={classes.checkboxes} key={"checkboxes-container"}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      handleLocalChange(
                        nameof<ScoringDataModel>("meanScoring"),
                        e.target.checked
                      )
                    }
                    checked={scoreCategory.meanScoring ?? false}
                    key={"mean-scoring"}
                  />
                }
                label="Mean Scoring?"
                className={classes.checkbox}
                key={"mean-scoring-label"}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      handleLocalChange(
                        nameof<ScoringDataModel>("meanScoreDecimal"),
                        e.target.checked
                      )
                    }
                    checked={scoreCategory.meanScoreDecimal ?? false}
                    key={"decimal-scoring"}
                  />
                }
                label="Decimal Mean Score?"
                className={classes.checkbox}
                key={"decimal-scoring-label"}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      handleLocalChange(
                        nameof<ScoringDataModel>("meanScoreMissing"),
                        e.target.checked
                      )
                    }
                    checked={scoreCategory.meanScoreMissing ?? false}
                    key={"mean-score-missing"}
                  />
                }
                label="Mean Score Missing?"
                className={classes.checkbox}
                key={"mean-score-missing-label"}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      handleLocalChange(
                        nameof<ScoringDataModel>("scoreLessThanThreshold"),
                        e.target.checked
                      )
                    }
                    checked={scoreCategory.scoreLessThanThreshold ?? false}
                    key={"score-less-than-threshold"}
                  />
                }
                label="Invert Status Thresholds?"
                className={classes.checkbox}
                key={"score-less-than-threshold-label"}
              />
            </Box>
            <Box
              className={classes.textFieldContainer}
              key={"min-max-response-container"}
            >
              <TextField
                label="Min Responses"
                className={classes.textField}
                onChange={(e) =>
                  handleLocalChange(
                    nameof<ScoringDataModel>("minResponses"),
                    e.target.value === "" ? undefined : Number(e.target.value)
                  )
                }
                value={scoreCategory.minResponses ?? ""}
                sx={{ width: "100%" }}
                key={"min-responses-field"}
                type="number"
              />
              <TextField
                label="Max Score"
                className={classes.textField}
                onChange={(e) =>
                  handleLocalChange(
                    nameof<ScoringDataModel>("maxScore"),
                    e.target.value === "" ? undefined : Number(e.target.value)
                  )
                }
                value={scoreCategory.maxScore ?? ""}
                sx={{ width: "100%" }}
                key={"max-score-field"}
                type="number"
              />
            </Box>

            <Box
              className={classes.textFieldContainer}
              key={"borderline-clinical-container"}
            >
              <TextField
                label="Borderline Score"
                className={classes.textField}
                onChange={(e) =>
                  handleLocalChange(
                    nameof<ScoringDataModel>("borderlineScore"),
                    e.target.value === "" ? undefined : Number(e.target.value)
                  )
                }
                value={scoreCategory.borderlineScore ?? ""}
                sx={{ width: "100%" }}
                key={"borderline-score-field"}
                type="number"
              />

              <TextField
                label="Clinical Score"
                className={classes.textField}
                onChange={(e) =>
                  handleLocalChange(
                    nameof<ScoringDataModel>("clinicalScore"),
                    e.target.value === "" ? undefined : Number(e.target.value)
                  )
                }
                value={scoreCategory.clinicalScore ?? ""}
                sx={{ width: "100%" }}
                key={"clinical-score-field"}
                type="number"
              />
            </Box>
            <Box
              className={classes.textFieldContainer}
              key={"SNOMED-subscale-container"}
            >
              <FormControl
                sx={{ width: "100%" }}
                key={"scoring-categoryType-select-fromcontrol"}
              >
                <InputLabel
                  id="score-categoryType-label"
                  key={"scoring-categoryType-select-inputlabel"}
                >
                  Category Type
                </InputLabel>
                <Select
                  labelId="score-categoryType-label"
                  id="score-categoryType-select"
                  key={"score-categoryType-select"}
                  label="Category Type"
                  onChange={(e) =>
                    handleLocalChange(
                      nameof<ScoringDataModel>("categoryType"),
                      e.target.value
                    )
                  }
                  value={scoreCategory.categoryType ?? ""}
                >
                  <MenuItem
                    key={"subscale-category-type"}
                    value={CategoryType.Subscale}
                  >
                    Subscale
                  </MenuItem>
                  <MenuItem
                    key={"scale-category-type"}
                    value={CategoryType.Scale}
                  >
                    Scale
                  </MenuItem>
                  <MenuItem
                    key={"total-category-type"}
                    value={CategoryType.Total}
                  >
                    Total
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="SNOMED Code"
                className={classes.textField}
                onChange={(e) =>
                  handleLocalChange(
                    nameof<ScoringDataModel>("snomed"),
                    e.target.value
                  )
                }
                value={scoreCategory.snomed ?? ""}
                sx={{ width: "100%" }}
                key={"scoring-snomed-field"}
              />
            </Box>
            <Box
              className={classes.textFieldContainer}
              key={"transformations-container"}
            >
              <TextField
                label="Min Transformed Score"
                className={classes.textField}
                onChange={(e) =>
                  handleLocalChange(
                    nameof<ScoringDataModel>("minTransformedScore"),
                    e.target.value === "" ? undefined : Number(e.target.value)
                  )
                }
                value={scoreCategory.minTransformedScore ?? ""}
                sx={{ width: "100%" }}
                key={"min-transformed-score-field"}
                type="number"
              />

              <TextField
                label="Max Transformed Score"
                className={classes.textField}
                onChange={(e) =>
                  handleLocalChange(
                    nameof<ScoringDataModel>("maxTransformedScore"),
                    e.target.value === "" ? undefined : Number(e.target.value)
                  )
                }
                value={scoreCategory.maxTransformedScore ?? ""}
                sx={{ width: "100%" }}
                key={"max-transformed-score-field"}
                type="number"
              />
              <Button
                variant="contained"
                onClick={() => setOpenTransformationRulesModal(true)}
                fullWidth
                key={"transformation-rules-button"}
              >
                Edit Transformation Rules
              </Button>
            </Box>

            <TextField
              label="Skipped Warning Threshold"
              className={classes.textField}
              onChange={(e) =>
                handleLocalChange(
                  nameof<ScoringDataModel>("skippedWarningThreshold"),
                  e.target.value === "" ? undefined : Number(e.target.value)
                )
              }
              value={scoreCategory.skippedWarningThreshold ?? ""}
              sx={{ width: "100%" }}
              key={"skipped-warning-threshold-field"}
              type="number"
            />
            <Button
              variant="contained"
              onClick={() => setOpenAdditionalPlotsModal(true)}
              fullWidth
              key={"additional-plots-button"}
            >
              Edit Additional Plots
            </Button>
            <AdditionalPlotsEditModal
              open={openAdditionalPlotsModal}
              questionnaire={props.questionnaire}
              allQuestionnaires={props.allQuestionnaires}
              category={scoreCategory}
              additionalPlots={scoreCategory.additionalPlots}
              handleClose={() => setOpenAdditionalPlotsModal(false)}
              handleSubmit={(ap) => {
                handleLocalChange(
                  nameof<ScoringDataModel>("additionalPlots"),
                  ap
                );
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions key={"scoring-add-modal-actions"}>
          <Button
            onClick={() => {
              props.handleCancel();
            }}
            key={"scoring-add-modal-close"}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              if (validateInputs()) {
                props.handleUpdate(scoreCategory, arrayString);
              } else {
                setInputError(true);
              }
            }}
            key={"scoring-add-modal-submit"}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ScoringRulesModal
        open={openTransformationRulesModal}
        transformation={scoreCategory.scoreRules ?? []}
        handleCancel={() => {
          setOpenTransformationRulesModal(false);
        }}
        setTransformationWIP={(transformation) => {
          handleLocalChange(
            nameof<ScoringDataModel>("scoreRules"),
            transformation
          );
        }}
      ></ScoringRulesModal>
    </>
  );
};

export default ScoringAddModal;
