import FormErrorText from "./FormErrorText";

export const Error401 = (): JSX.Element => {
  return (
    <FormErrorText
      errorText="You do not have permission for this action."
      isInvalid
    />
  );
};
