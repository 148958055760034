import { Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  MedicationCategoryViewModel,
  MedicationViewModel,
} from "../../../types/auto/types";
import { CheckIsMobile } from "../../utils/MobileStatus";
import CreateEditMedication from "./CreateEditMedication";

interface Props {
  medication: MedicationViewModel;
  medicationCategories: MedicationCategoryViewModel[];
  medicationCategoryId: number;
  refresh: () => void;
  resetMedications: () => void;
}
const MedicationCard = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    cardContent: {
      display: isMobile ? "block" : "flex",
      padding: "6px",
      paddingLeft: "12px",
      "&:last-child": {
        paddingBottom: 5,
      },
    },
  });
  const classes = useStyle();
  return (
    <Card variant="outlined" elevation={0}>
      <CardContent className={classes.cardContent}>
        <Typography>
          {props.medication.name}{" "}
          {"(" +
            props.medication.minDosage +
            " to " +
            props.medication.maxDosage +
            props.medication.units +
            ")"}
        </Typography>
        <CreateEditMedication
          medication={props.medication}
          medicationCategoryId={props.medicationCategoryId}
          refresh={props.refresh}
          resetMedications={props.resetMedications}
        />
      </CardContent>
    </Card>
  );
};

export default MedicationCard;
