import {
  Button,
  Checkbox,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  AnswerViewModel,
  TableQuestionType,
} from "../../../../types/auto/types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface DataModel {
  [key: string]: any;
}

interface Props {
  model: DataModel;
  answers: AnswerViewModel[];
  editResponseFromKey: (key: string) => void;
  removeResponseFromKey: (key: string) => void;
  readOnly: boolean;
  headersLength: number;
}

const MultiSelectorTableRow = (props: Props): JSX.Element => {
  const formValues: JSX.Element[] = [];
  const buttons: JSX.Element[] = [];
  var answerIndex = 0;
  for (const [key, value] of Object.entries(props.model)) {
    if (key === "RowId") {
      if (!props.readOnly) {
        buttons.push(
          <TableCell key={"EditButton-cell-" + value}>
            <Button
              id={"edit-row-" + value}
              variant="contained"
              key={"EditButton-" + value}
              onClick={() => {
                props.editResponseFromKey(value);
              }}
            >
              Edit
            </Button>
          </TableCell>
        );
        buttons.push(
          <TableCell key={"DeleteButton-cell-" + value}>
            <Button
              id={"delete-row-" + value}
              variant="contained"
              key={"DeleteButton-" + value}
              onClick={() => {
                props.removeResponseFromKey(value);
              }}
            >
              Delete
            </Button>
          </TableCell>
        );
      }
    } else {
      switch (props.answers[answerIndex].tableType) {
        case TableQuestionType.Textbox:
        case TableQuestionType.Number:
        case TableQuestionType.Dropdown: {
          formValues.push(
            <TableCell
              key={"table-cell-" + key + props.model["RowId"]}
              style={{
                minWidth: props.readOnly
                  ? (100 / props.headersLength).toString() + "%"
                  : "auto",
              }}
            >
              {props.readOnly ? (
                value
              ) : (
                <TextField
                  value={value}
                  key={"input-" + key + props.model["RowId"]}
                  variant="outlined"
                  multiline
                  maxRows={4}
                ></TextField>
              )}
            </TableCell>
          );
          break;
        }
        case TableQuestionType.Date: {
          formValues.push(
            <TableCell
              key={"table-cell-" + key + props.model["RowId"]}
              style={{
                minWidth: props.readOnly
                  ? (100 / props.headersLength).toString() + "%"
                  : "auto",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                key={"form-localisation-" + key + props.model["RowId"]}
              >
                <DatePicker
                  key={"form-date-" + key + props.model["RowId"]}
                  onChange={() => {}}
                  disableFuture={true}
                  readOnly={true}
                  views={["year", "month", "day"]}
                  inputFormat="DD/MM/YYYY"
                  value={value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={false}
                      sx={{
                        fieldset: {
                          legend: {
                            width: "unset",
                          },
                        },
                      }}
                      key={"form-textfield-date-" + key + props.model["RowId"]}
                      multiline
                      maxRows={2}
                    />
                  )}
                ></DatePicker>
              </LocalizationProvider>
            </TableCell>
          );
          break;
        }
        case TableQuestionType.Checkbox: {
          formValues.push(
            <TableCell
              key={"table-cell-" + key + props.model["RowId"]}
              style={{
                minWidth: props.readOnly
                  ? (100 / props.headersLength).toString() + "%"
                  : "auto",
              }}
            >
              <Checkbox
                checked={value === "" ? false : (value as boolean)}
                key={"input-" + key + props.model["RowId"]}
              ></Checkbox>
            </TableCell>
          );
          break;
        }
      }
      answerIndex++;
    }
  }
  const localRow = [...formValues, ...buttons];
  return (
    <TableRow
      key={"table-row-" + props.model["RowId"]}
      sx={{ gap: props.readOnly ? 1 : 2 }}
    >
      {localRow}
    </TableRow>
  );
};

export default MultiSelectorTableRow;
