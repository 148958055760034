import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../../Theme";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import DateSelect from "../../elements/form-elements/DateSelect";
import {
  CaseloadClient,
  CaseloadType,
  CaseloadViewModel,
  EnumClient,
  PatientViewModel,
  PromptFrequency,
  PromptScheduleViewModel,
  QuestionnaireViewModel,
  ScheduleViewModel,
  StaleDuration,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import InfoHint from "../../elements/form-elements/InfoHint";
import { ScheduleFormReceivePrompts } from "../../../HelpText";
import PromptSchedulingForm from "./PromptSchedulingForm";
import CaseloadQuestionnaireDropdowns from "./CaseloadQuestionnaireDropdowns";
import { useUnmountPromise } from "react-use";
import { GetStringTimeOfDay } from "./PromptScheduling/AtTimeScheduling";

interface Props {
  schedule: ScheduleViewModel;
  setSchedule: Dispatch<SetStateAction<ScheduleViewModel>>;
  patient: PatientViewModel;
  editing: boolean;
  selectedQuestionnaires: QuestionnaireViewModel[];
  setSelectedQuestionnaires: Dispatch<SetStateAction<QuestionnaireViewModel[]>>;
  caseloadError?: boolean;
  questionnaireError?: boolean;
  promptSchedule?: PromptScheduleViewModel;
  setPromptSchedule: React.Dispatch<
    React.SetStateAction<PromptScheduleViewModel | undefined>
  >;
  promptCheckBox: boolean;
  setPromptCheckBox: React.Dispatch<React.SetStateAction<boolean>>;
  disabledPromt: boolean;
}

const CreateEditScheduleForm = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    form: { width: "100%" },
    formControl: { marginBottom: 20 },
    receivePrompts: { display: "flex" },
    multiSelect: { width: "100%" },
    hint: { marginTop: 10 },
    staleDurationToggle: {
      marginBottom: 20,
      display: "block",
    },
  });
  const classes = useStyle(customTheme);

  const [, setRerender] = useState(false); // Force the page to rerender

  const [allQuestionnaires, setAllQuestionnaires] = useState<
    QuestionnaireViewModel[]
  >([]);

  const [allCaseloads, setAllCaseloads] = useState<CaseloadViewModel[]>([]);
  const [staleDurationDescriptions, setStaleDurationDescriptions] = useState<{
    [x: number]: string | undefined;
  }>({});

  const resolveWhileMounted = useUnmountPromise();
  props.setPromptCheckBox(
    props.promptSchedule !== undefined &&
      props.promptSchedule !== null &&
      props.selectedQuestionnaires.filter((x) => x.authenticatedOnly).length ===
        0
  );

  useEffect(() => {
    const effect = async () => {
      await resolveWhileMounted(
        new CaseloadClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).getCaseloadsForPatient(props.patient.id, CaseloadType.Shared, false)
      ).then((c) => setAllCaseloads(c));
      await new EnumClient(Configuration.SERVER_ROOT, FetchOverride)
        .getStaleDurationDescriptions()
        .then((descriptions) => {
          setStaleDurationDescriptions(descriptions);
        });
    };
    effect();
  }, [props.patient.id, resolveWhileMounted, setStaleDurationDescriptions]);

  return (
    <FormControl className={classes.form}>
      {!props.editing && (
        <CaseloadQuestionnaireDropdowns
          allCaseloads={allCaseloads}
          patient={props.patient}
          caseloadId={props.schedule.caseloadId}
          caseloadError={props.caseloadError}
          setCaseloadId={(caseloadId) =>
            props.setSchedule((s) => {
              s.caseloadId = allCaseloads.filter(
                (x) => x.id === caseloadId
              )[0].id;

              // Get the questionnaires for the caseload
              resolveWhileMounted(
                new CaseloadClient(
                  Configuration.SERVER_ROOT,
                  FetchOverride
                ).getQuestionnairesForCaseload(caseloadId)
              ).then((questionnaires: QuestionnaireViewModel[]) => {
                setAllQuestionnaires(questionnaires);
                props.setSelectedQuestionnaires([]);
              });

              setRerender((x) => !x);
              return s;
            })
          }
          selectedQuestionnaires={props.selectedQuestionnaires}
          setSelectedQuestionnaires={props.setSelectedQuestionnaires}
          selectedQuestionnairesError={props.questionnaireError}
          allQuestionnaires={allQuestionnaires}
          caseloadType={CaseloadType.Shared}
          setPromptSchedule={props.setPromptSchedule}
        />
      )}
      <FormControl className={classes.formControl}>
        <DateSelect
          editing={props.editing}
          startDate={props.schedule.startDate}
          setStartDate={(startDate: Date) => {
            setRerender((x) => !x);
            props.setSchedule((x) => {
              x.startDate = startDate;
              return x;
            });
          }}
          endDate={props.schedule.endDate}
          setEndDate={(endDate: Date) => {
            setRerender((x) => !x);
            props.setSchedule((x) => {
              x.endDate = endDate;
              return x;
            });
          }}
          indefiniteEndDate={props.schedule.continueIndefinitely || false}
          setIndefiniteEndDate={(indefinite: boolean) => {
            setRerender((x) => !x);
            props.setSchedule((x) => {
              x.continueIndefinitely = indefinite;
              return x;
            });
          }}
        />
      </FormControl>
      <FormLabel>Prompt Clinician When Stale</FormLabel>
      <ToggleButtonGroup
        value={props.schedule.staleDuration ?? StaleDuration.Never}
        exclusive
        onChange={(_, value) => {
          if (value !== null) {
            props.setSchedule((x) => {
              x.staleDuration = value;
              return x;
            });
            setRerender((y) => !y);
          }
        }}
        aria-label="stale-duration"
        className={classes.staleDurationToggle}
      >
        {Object.keys(StaleDuration)
          .filter((x) => isNaN(Number(x)))
          .map((x) => (
            <ToggleButton
              key={x}
              value={StaleDuration[x as keyof typeof StaleDuration]}
              aria-label={x}
            >
              {staleDurationDescriptions === undefined
                ? x
                : staleDurationDescriptions[x as unknown as number] === null
                ? x
                : staleDurationDescriptions[x as unknown as number]}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={props.schedule.sendNotificationOnSubmission ?? false}
              color="secondary"
              onChange={(e) => {
                props.setSchedule((x) => {
                  x.sendNotificationOnSubmission =
                    !x.sendNotificationOnSubmission;
                  return x;
                });
                setRerender((y) => !y);
              }}
            />
          }
          label="Prompt Clinician On Submission"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <div className={classes.receivePrompts}>
          <FormControlLabel
            control={
              <Switch
                checked={props.promptCheckBox}
                disabled={
                  props.selectedQuestionnaires.filter(
                    (x) => x.authenticatedOnly
                  ).length > 0 ||
                  props.disabledPromt ||
                  (props.patient.contactEmail == null &&
                    props.patient.mobileNumber == null)
                }
                color="secondary"
                onChange={() => {
                  props.setPromptSchedule((x) => {
                    if (x === undefined) {
                      x = new PromptScheduleViewModel();
                      x.frequency = PromptFrequency.Daily;
                      x.timeOfDay = GetStringTimeOfDay(new Date());
                    } else {
                      x = undefined;
                    }
                    return x;
                  });
                  setRerender((y) => !y);
                }}
              />
            }
            label="Prompt Patient"
          />
          <InfoHint
            helpText={ScheduleFormReceivePrompts}
            className={classes.hint}
          />
        </div>
      </FormControl>
      {props.promptSchedule !== undefined &&
        props.promptSchedule !== null &&
        props.setPromptSchedule !== undefined && (
          <PromptSchedulingForm
            promptSchedule={props.promptSchedule}
            setPromptSchedule={props.setPromptSchedule}
            setRerender={setRerender}
          />
        )}
    </FormControl>
  );
};

export default CreateEditScheduleForm;
