import {
  Alert,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import { QuestionDataModel } from "./QuestionnaireBuilderDataModelHelper";
import {
  RequiredQuestionFields,
  ValidatorError,
} from "./SectionBuilderQuestionAnswerHelper";
import nameof from "../../../utils/NameOf";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { GoalCategory } from "../../../../types/auto/types";
import { ToTitleCase } from "../../../utils/TitleCase";

interface Props {
  handleChange: (
    field: string,
    value: string | boolean | GoalCategory | undefined,
    labelIndex?: number,
    rowHeader?: boolean
  ) => void;
  questionWIP: QuestionDataModel;
  requiredQuestionFields: RequiredQuestionFields[];
  rowHeaderField: string;
  tableError: ValidatorError;
}

const QuestionForm = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    textFieldContainer: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "row",
      gap: 10,
      width: "100%",
      marginBottom: 15,
      justifyContent: "flex-end",
    },
    checkboxes: {
      margin: 0,
      padding: 0,
      display: isMobile ? "block" : "flex",
      flexDirection: "row",
      gap: 10,
      width: "100%",
      marginBottom: 15,
      justifyContent: "flex-end",
    },
    checkbox: {
      width: "100%",
      justifyContent: "flex-end",
    },
    textField: {
      width: "100%",
    },
    infoContainer: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: 10,
    },
    rowsContainer: {
      margin: 0,
      padding: 0,
      display: "grid",
      width: "100%",
      gridTemplateColumns: "1fr 3fr",
      gridTemplateAreas: '"header rows"',
      marginBottom: 10,
    },
    infoContainerRows: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: 10,
      gridArea: "rows",
    },
    rowHeader: {
      width: "100%",
      gridArea: "header",
      margin: 0,
      paddingRight: 10,
      paddingLeft: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: 10,
    },
    headerContainer: {
      margin: 0,
      padding: 0,
      display: "grid",
      width: "100%",
      gridTemplateColumns: "1fr 3fr",
      gridTemplateAreas: '"headerheader headers"',
      marginBottom: 10,
    },
    infoContainerHeaders: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: 10,
      gridArea: "headers",
    },
    headersHeader: {
      width: "100%",
      gridArea: "headerheader",
      margin: 0,
      paddingRight: 10,
      paddingLeft: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: 10,
    },
    tooltip: {
      display: "block",
    },
  });
  const classes = useStyle(theme);

  return (
    <>
      {props.requiredQuestionFields.includes(RequiredQuestionFields.Base) && (
        <>
          <Container className={classes.textFieldContainer}>
            <TextField
              label="Text"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<QuestionDataModel>("text"),
                  e.target.value ? e.target.value : undefined
                )
              }
              value={props.questionWIP.text ?? ""}
            />
            <TextField
              label="Graph Label"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<QuestionDataModel>("shortText"),
                  e.target.value ? e.target.value : undefined
                )
              }
              value={props.questionWIP.shortText ?? ""}
            />
          </Container>
          <Container className={classes.textFieldContainer}>
            <TextField
              label="Number Override"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<QuestionDataModel>("displayName"),
                  e.target.value ? e.target.value : undefined
                )
              }
              value={props.questionWIP.displayName ?? ""}
            />
            <TextField
              label="SNOMED Code"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<QuestionDataModel>("snomed"),
                  e.target.value ? e.target.value : undefined
                )
              }
              value={props.questionWIP.snomed ?? ""}
            />
          </Container>
          <Container className={classes.textFieldContainer}>
            <TextField
              label="Subheading"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<QuestionDataModel>("subheading"),
                  e.target.value ? e.target.value : undefined
                )
              }
              value={props.questionWIP.subheading ?? ""}
            />
            <TextField
              label="Note"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<QuestionDataModel>("note"),
                  e.target.value ? e.target.value : undefined
                )
              }
              value={props.questionWIP.note ?? ""}
            />
          </Container>
          <Container className={classes.checkboxes}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) =>
                    props.handleChange(
                      nameof<QuestionDataModel>("mandatory"),
                      e.target.checked
                    )
                  }
                  checked={props.questionWIP.mandatory}
                />
              }
              label="Mandatory?"
              labelPlacement="start"
              className={classes.checkbox}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) =>
                    props.handleChange(
                      nameof<QuestionDataModel>("hideNumber"),
                      e.target.checked
                    )
                  }
                  checked={props.questionWIP.hideNumber}
                />
              }
              label="Hide number?"
              labelPlacement="start"
              className={classes.checkbox}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) =>
                    props.handleChange(
                      nameof<QuestionDataModel>("divider"),
                      e.target.checked
                    )
                  }
                  checked={props.questionWIP.divider}
                />
              }
              label="Divider?"
              labelPlacement="start"
              className={classes.checkbox}
            />
          </Container>
        </>
      )}
      {props.requiredQuestionFields.includes(RequiredQuestionFields.Labels) && (
        <Container className={classes.textFieldContainer}>
          <TextField
            label="Start Label"
            className={classes.textField}
            onChange={(e) =>
              props.handleChange(
                nameof<QuestionDataModel>("labels"),
                e.target.value,
                0
              )
            }
            value={props.questionWIP.labels![0] ?? ""}
          />
          <TextField
            label="Middle Label"
            className={classes.textField}
            onChange={(e) =>
              props.handleChange(
                nameof<QuestionDataModel>("labels"),
                e.target.value,
                1
              )
            }
            value={props.questionWIP.labels![1] ?? ""}
          />
          <TextField
            label="End Label"
            className={classes.textField}
            onChange={(e) =>
              props.handleChange(
                nameof<QuestionDataModel>("labels"),
                e.target.value,
                2
              )
            }
            value={props.questionWIP.labels![2] ?? ""}
          />
        </Container>
      )}
      {props.requiredQuestionFields.includes(RequiredQuestionFields.Snomed) && (
        <Container className={classes.textFieldContainer}>
          <TextField>Some snomed field</TextField>
        </Container>
      )}
      {props.requiredQuestionFields.includes(
        RequiredQuestionFields.MaxAnswers
      ) && (
        <Container className={classes.textFieldContainer}>
          <TextField
            label="Max Answers"
            className={classes.textField}
            onChange={(e) =>
              props.handleChange(
                nameof<QuestionDataModel>("maxAnswers"),
                e.target.value
              )
            }
            value={props.questionWIP.maxAnswers ?? ""}
            type="number"
            key={"answer-form-score-field"}
          />
        </Container>
      )}
      {props.requiredQuestionFields.includes(RequiredQuestionFields.Rows) && (
        <Container className={classes.rowsContainer}>
          <Container className={classes.rowHeader}>
            <TextField
              label="Row Header"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  props.rowHeaderField,
                  e.target.value,
                  undefined,
                  true
                )
              }
              value={props.questionWIP.answers[0].text ?? ""}
            ></TextField>
          </Container>
          <Container className={classes.infoContainerRows}>
            <TextField
              label="Rows"
              className={classes.textField}
              onChange={(e) =>
                props.handleChange(
                  nameof<QuestionDataModel>("rows"),
                  e.target.value
                )
              }
              value={
                props.questionWIP.rows != null
                  ? props.questionWIP.rows.join("\n")
                  : ""
              }
              multiline
              maxRows={6}
              error={props.tableError === ValidatorError.NoError ? false : true}
              helperText={
                props.tableError === ValidatorError.NoRows
                  ? "Rows failed validation. Please add at least one row."
                  : undefined
              }
            ></TextField>
            <Alert severity="info">
              Each row added here will be a row header in the table, inputting
              "Other" will produce an editable textfield rather than a label.
            </Alert>
          </Container>
        </Container>
      )}
      {props.requiredQuestionFields.includes(RequiredQuestionFields.Headers) &&
        props.requiredQuestionFields.includes(
          RequiredQuestionFields.RowObjectName
        ) && (
          <Container className={classes.headerContainer}>
            <Container className={classes.headersHeader}>
              <TextField
                label="Row Name"
                className={classes.textField}
                onChange={(e) =>
                  props.handleChange(
                    nameof<QuestionDataModel>("rowObjectName"),
                    e.target.value
                  )
                }
                value={props.questionWIP.rowObjectName ?? ""}
              ></TextField>
              <Alert severity="info">
                {props.questionWIP.rowObjectName == null ||
                props.questionWIP.rowObjectName === ""
                  ? "Add Row button."
                  : "Add " + props.questionWIP.rowObjectName + " button."}
              </Alert>
            </Container>
            <Container className={classes.infoContainerHeaders}>
              <TextField
                label="Column Headers"
                className={classes.textField}
                onChange={(e) =>
                  props.handleChange(
                    nameof<QuestionDataModel>("headers"),
                    e.target.value
                  )
                }
                value={
                  props.questionWIP.headers != null
                    ? props.questionWIP.headers.join("\n")
                    : ""
                }
                multiline
                maxRows={6}
                error={
                  props.tableError === ValidatorError.NoError ? false : true
                }
                helperText={
                  props.tableError === ValidatorError.NoHeaders
                    ? "Please enter at least one column header."
                    : undefined
                }
              ></TextField>
              <Alert severity="info">
                Each row in this field will be a column header in the
                questionnaire.
              </Alert>
            </Container>
          </Container>
        )}
      {props.requiredQuestionFields.includes(RequiredQuestionFields.Format) && (
        <Container className={classes.textFieldContainer}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  props.handleChange(
                    nameof<QuestionDataModel>("format"),
                    e.target.checked ? "tight" : ""
                  )
                }
                checked={props.questionWIP.format === "tight"}
              />
            }
            label="Wrapped list?"
            labelPlacement="start"
            className={classes.checkbox}
          />
        </Container>
      )}
      {props.requiredQuestionFields.includes(
        RequiredQuestionFields.GoalCategory
      ) && (
        <FormControl className={classes.textField}>
          <InputLabel
            id={"label-for-goal-category-dropdown" + props.questionWIP.number}
          >
            Goal Category
          </InputLabel>
          <Select
            onChange={(e) =>
              props.handleChange(
                nameof<QuestionDataModel>("goalCategory"),
                e.target.value
              )
            }
            value={props.questionWIP.goalCategory}
            labelId={
              "label-for-goal-category-dropdown" + props.questionWIP.number
            }
            label="Goal Category"
            key={"question-form-goal-category"}
          >
            {Object.values(GoalCategory)
              .filter((x) => !isNaN(Number(x)))
              .map((x) => {
                return (
                  <MenuItem
                    key={
                      "question-form-goal-category-" +
                      GoalCategory[Number(x)].toString() +
                      props.questionWIP.number
                    }
                    value={x}
                  >
                    {ToTitleCase(
                      GoalCategory[Number(x)].toString().replace("AndOr", "/")
                    )}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default QuestionForm;
