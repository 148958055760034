import {
  Button,
  Container,
  Paper,
  Theme,
  Tooltip,
  TextField,
  IconButton,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import ReplayIcon from "@mui/icons-material/Replay";
import { AllowListPatientSearchRegex } from "../../Constants";
import { theme as customTheme } from "../../../Theme";
import FormErrorText from "../elements/errors/FormErrorText";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { useMountedState } from "react-use";
import { useDebounce } from "../../utils/UseDebounce";

interface Props {
  filterCallback: (searchTerm: string, enableArchived: boolean) => void;
  searchTerm?: string;
  enableArchived?: boolean;
  clearSearch: () => void;
  helpText?: string;
}

const SimpleSearchFilters = (props: Props): JSX.Element => {
  const [searchText, setSearchText] = useState(props.searchTerm || "");
  const debouncedSearchFilter = useDebounce(searchText, 250);

  const [archive, setArchive] = useState(props.enableArchived || false);

  const [invalidCharacterUsed, setInvalidCharacterUsed] = useState(false);

  const isMobile = CheckIsMobile();

  const useStyle = makeStyles((th: Theme) => ({
    textSearchContainer: {
      display: "flex",
      padding: 0,
      paddingBottom: isMobile ? 10 : 0,
      margin: 0,
    },
    innerContainer: {
      display: isMobile ? "block" : "flex",
      alignItems: "center",
    },
    outerPaper: {
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      paddingTop: "10px",
      paddingBottom: "10px",
      boxShadow: "none",
    },
    inputText: {
      width: isMobile ? "100%" : "75%",
    },
    toggleArchive: {
      width: isMobile ? "100%" : "75%",
      paddingLeft: isMobile ? "0" : "20",
      padding: 0,
      textAlign: "center",
    },
    clearSearchContainer: {
      paddingLeft: isMobile ? "0" : "20",
      padding: 0,
      textAlign: "center",
      width: "50%",
    },
    tooltip: {
      display: "block",
    },
  }));

  const classes = useStyle(customTheme);

  const isMountedState = useMountedState();

  useEffect(() => {
    if (isMountedState()) {
      props.filterCallback(debouncedSearchFilter, archive);
    }
  }, [props, debouncedSearchFilter, archive, isMountedState]);

  return (
    <Paper component="form" className={classes.outerPaper}>
      <Container className={classes.innerContainer}>
        {props.searchTerm !== undefined && (
          <Container className={classes.textSearchContainer}>
            <TextField
              variant="standard"
              value={searchText}
              label="Search"
              onChange={(e: any) => {
                var searchText = e.target.value;
                var regexp = new RegExp(AllowListPatientSearchRegex);
                if (regexp.test(searchText)) {
                  setInvalidCharacterUsed(true);
                  return;
                } else {
                  setInvalidCharacterUsed(false);
                }
                setSearchText(searchText);
              }}
              className={classes.inputText}
              fullWidth
            />
            {props.helpText && (
              <Tooltip
                title={props.helpText ?? ""}
                enterTouchDelay={0}
                className={classes.tooltip}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </Container>
        )}
        {props.enableArchived !== undefined && (
          <Container className={classes.toggleArchive}>
            <FormControlLabel
              control={
                <Switch
                  checked={archive}
                  onChange={() => setArchive((x) => !x)}
                  color="secondary"
                />
              }
              label="Archived Caseloads"
            />
          </Container>
        )}
        <Container className={classes.clearSearchContainer}>
          <Button
            onClick={() => {
              setSearchText("");
              setArchive(false);
              props.clearSearch();
            }}
          >
            <ReplayIcon />
            Clear fields
          </Button>
        </Container>
      </Container>
      <FormErrorText
        errorText="Do not use invalid characters in search i.e. #,!,?"
        isInvalid={invalidCharacterUsed}
      />
    </Paper>
  );
};

export default SimpleSearchFilters;
