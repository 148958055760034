export const ScheduleFormQuestionnaires =
  "If you are scheduling more than one questionnaire for different frequencies (e.g. one daily, one weekly), you have to schedule the questionnaires separately.";

export const ScheduleFormSetupArchived =
  "Prompted schedules can be automatically archived after a specified number of missed responses.";

export const ScheduleFormReceivePrompts =
  "If you select yes then they'll receive prompts by their preferred prompt method at the times you choose.\n\nIf you select no then the participant will not be prompted, they can still however complete questionnaires whenever they want by texting or through the web-site.";

export const CaseloadDownloadGroupQuestionnaire =
  "If you enable this option then responses will be grouped by questionnaire rather than by patient.";

export const CaseloadDownloadExcludeGoals =
  "If you enable this option then goals will be excluded from the caseload download.";

export const CaseloadDownloadExcludeDatasets =
  "If you enable this option then external datasets will be excluded from the caseload download.";
