import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Theme,
  Slider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Select,
  MenuItem,
  Alert,
  Divider,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  FormLabel,
  FormGroup,
} from "@mui/material";
import {
  QuestionnaireResponseViewModel,
  QuestionType,
  TableQuestionType,
} from "../../../../types/auto/types";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../../Theme";
import { CheckIsMobile, CheckIsSmallerThan } from "../../../utils/MobileStatus";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  AnswerDataModel,
  QuestionDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import { useEffect, useState } from "react";
import { DrawingBoard } from "../DrawingBoard";
import BuilderMultiSelectorTable from "./BuilderMultiSelectorTable";
import parse from "html-react-parser";

export interface QuestionBoxProps {
  question: QuestionDataModel;
  responses?: Array<QuestionnaireResponseViewModel>;
  setResponses?: React.Dispatch<
    React.SetStateAction<Array<QuestionnaireResponseViewModel>>
  >;
}

const BuilderQuestionBox = (props: QuestionBoxProps): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallerThan1200 = CheckIsSmallerThan(1200);
  const isSmallerThan800 = CheckIsSmallerThan(800);
  const useStyle = makeStyles((th: Theme) => ({
    buttonGroup: {
      display: isMobile ? "grid" : "flex",
      flexWrap: "wrap",
      gap: 1,
    },
    button: {
      backgroundColor: "#fafafa",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      borderRadius: "5",
    },
    table: {
      backgroundColor: "#fafafa",
      minWidth: "1000px",
    },
    textarea: {
      backgroundColor: "#fafafa",
    },
    textLabelContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    textlabel: {
      fontSize: 12,
    },
    skippableRangeContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      padding: "10px",
    },
    skippableRangeSliderChild: {
      flex: 5,
      minWidth: "400px",
    },
    skippableRangeCheckboxChild: {
      flex: 1,
      justifyContent: isMobile ? "unset" : "end",
      display: "inline-flex",
    },
    divider: {
      background: "#808080",
      marginTop: "10px",
    },
  }));
  const classes = useStyle(customTheme);
  const [skippableRangeChecked, setSkippableRangeChecked] = useState(false);

  const [buttonsAnswers, setButtonsAnswers] = useState<number[]>([]);
  const [dropdownAnswer, setDropdownAnswer] = useState<string>("");
  const [checkboxListAnswers, setCheckboxListAnswers] = useState<boolean[]>(
    props.question.answers.map(() => false)
  );
  const [checkboxSingleChange, setCheckboxSingleChange] =
    useState<boolean>(false);
  const [dateValue, setDateValue] = useState<string>("");
  const [dropdownChoices, setDropdownChoices] = useState<string[][]>(
    generateDropDownArray
  );
  const [dateValuesTable, setDateValuesTable] =
    useState<string[][]>(generateDateArray);

  function generateDropDownArray() {
    return props.question.answers.map((answer) => {
      if (
        answer.tableType != null &&
        answer.tableType === TableQuestionType.Dropdown
      ) {
        if (props.question.rows != null) {
          return props.question.rows?.map((row) => {
            return "";
          });
        } else {
          return [];
        }
      } else {
        return [];
      }
    });
  }

  function generateDateArray() {
    return props.question.answers.map((answer) => {
      if (
        answer.tableType != null &&
        answer.tableType === TableQuestionType.Date
      ) {
        if (props.question.rows != null) {
          return props.question.rows?.map((row) => {
            return "";
          });
        } else {
          return [""];
        }
      } else {
        return [""];
      }
    });
  }

  useEffect(
    () => {
      setDropdownChoices(generateDropDownArray());
      setDateValuesTable(generateDateArray());
    },
    // eslint-disable-next-line
    [props.question.rows, props.question.answers]
  );

  useEffect(
    () => {
      setCheckboxListAnswers(props.question.answers.map(() => false));
    },
    // eslint-disable-next-line
    [props.question.maxAnswers]
  );

  function handleButtonChange(e: any) {
    if (props.question.questionType === QuestionType.Buttons) {
      setButtonsAnswers([Number(e.target.value)]);
    } else {
      var currArr = [...buttonsAnswers];
      var newArr = [];
      if (currArr.includes(Number(e.target.value))) {
        newArr = currArr.filter((x) => x !== Number(e.target.value));
      } else {
        if (currArr.length === props.question.maxAnswers) {
          return;
        }
        newArr = [...currArr, Number(e.target.value)];
      }

      setButtonsAnswers(newArr);
    }
  }

  function handleDropdownQuestionChange(e: any) {
    setDropdownAnswer(e.target.value);
  }

  function handleCheckBoxListChange(e: any, answer: AnswerDataModel) {
    const i = answer.number! - 1;
    const checkedCount = checkboxListAnswers.filter(
      (value) => value === true
    ).length;

    if (
      checkedCount === props.question.maxAnswers &&
      props.question.maxAnswers === 1
    ) {
      setCheckboxListAnswers(
        checkboxListAnswers.map((v, ind) => {
          if (ind === i) {
            return !v;
          } else {
            return false;
          }
        })
      );
    } else if (checkedCount === props.question.maxAnswers) {
      if (checkboxListAnswers[i] === true) {
        setCheckboxListAnswers(
          checkboxListAnswers.map((val, ind) => {
            if (i === ind) {
              return !val;
            } else {
              return val;
            }
          })
        );
      }
    } else {
      setCheckboxListAnswers(
        checkboxListAnswers.map((val, ind) => {
          if (i === ind) {
            return !val;
          } else {
            return val;
          }
        })
      );
    }
  }

  function handleDateChange(
    value: Date | null,
    colIndex?: number,
    index?: number,
    optionalRow?: boolean
  ) {
    if (value != null) {
      value = new Date(value);
      if (!isNaN(value.getDate())) {
        var newDate = value.toLocaleDateString("en-GB", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
        if (index == null) {
          setDateValue(newDate);
        } else if (colIndex != null) {
          const modifiedIndex = optionalRow ? colIndex - 1 : colIndex;
          var newArrDates = dateValuesTable.map((column, colI) => {
            if (modifiedIndex === colI) {
              return column.map((date, i) => {
                if (i === index) {
                  return newDate;
                } else {
                  return date;
                }
              });
            } else if (column.length > 0) {
              return column;
            } else {
              return [];
            }
          });
          setDateValuesTable(newArrDates);
        }
      }
    }
  }

  function handleDropdownChange(
    e: any,
    colIndex: number,
    index: number,
    optionalRow: boolean
  ) {
    const modifiedIndex = optionalRow ? colIndex - 1 : colIndex;
    var newArrDrop = dropdownChoices.map((column, colI) => {
      if (colI === modifiedIndex) {
        return column.map((choice, i) => {
          if (i === index) {
            return e.target.value;
          } else {
            return choice;
          }
        });
      } else if (column.length > 0) {
        return column;
      } else {
        return [];
      }
    });
    setDropdownChoices(newArrDrop);
  }

  // Calculates the mark numbers for a range slider, limited to maximum of 10 marks
  const calculateMarks = (answer: AnswerDataModel) => {
    const marks = [];
    const range = answer.rangeMax! - answer.rangeMin!;
    const markCount = Math.min(range, 10);

    for (var i = 0; i <= markCount; i++) {
      var mark = Math.round(answer.rangeMin! + (i * range) / markCount);
      marks.push({
        value: mark,
        label: mark,
      });
    }
    return marks;
  };

  const loadRows = (rowNames: string[], rowNumber: number) => {
    const rows = [];
    for (let i = 0; i < rowNumber; i++) {
      const optionalRow = rowNames.at(i) === "Other";
      rows.push(
        <TableRow key={"row-" + props.question.number + "-" + i}>
          {rowNames.length > 0 && !optionalRow ? (
            <TableCell key={"questioncell-" + props.question.number + i}>
              {rowNames.at(i)}
            </TableCell>
          ) : null}
          {props.question.answers
            ?.slice(!optionalRow && rowNames.length > 0 ? 1 : 0)
            ?.map((y, index) =>
              y.tableType === TableQuestionType.Dropdown ? (
                <TableCell
                  key={"answercell-" + props.question.number + i + index}
                >
                  <Select
                    variant="outlined"
                    key={
                      "dropdown-" +
                      props.question.number +
                      "-" +
                      i +
                      "-" +
                      index
                    }
                    placeholder={
                      optionalRow ? "Other (please specify)" : undefined
                    }
                    value={
                      dropdownChoices.length <= index + (optionalRow ? 0 : 1)
                        ? "None"
                        : optionalRow
                        ? dropdownChoices[index][i] === ""
                          ? "None"
                          : dropdownChoices[index][i]
                        : dropdownChoices[index + 1][i] === ""
                        ? "None"
                        : dropdownChoices[index + 1][i]
                    }
                    onChange={(e) =>
                      handleDropdownChange(e, index + 1, i, optionalRow)
                    }
                  >
                    <MenuItem key={"select-" + 0} value={"None"}>
                      None
                    </MenuItem>
                    {y.tableDropdownChoices?.map((val, index) => (
                      <MenuItem
                        key={"select-" + (index + 1)}
                        value={val}
                        role="menuitem"
                      >
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              ) : y.tableType === TableQuestionType.Checkbox ? (
                <TableCell
                  key={"answercell-" + props.question.number + i + index}
                >
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    key={"checkbox-" + props.question.number + i + index}
                  />
                </TableCell>
              ) : y.tableType === TableQuestionType.Date ? (
                <TableCell
                  key={"answercell-" + props.question.number + i + index}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    key={"localisation-" + props.question.number + i + index}
                  >
                    <DatePicker
                      disableFuture={true}
                      views={["year", "month", "day"]}
                      inputFormat="DD/MM/YYYY"
                      PopperProps={{
                        placement: "bottom",
                        disablePortal: true,
                      }}
                      value={
                        dateValuesTable.length <= index + (optionalRow ? 0 : 1)
                          ? ""
                          : optionalRow
                          ? dateValuesTable[index][i]
                          : dateValuesTable[index + 1][i]
                      }
                      onChange={(e: Date | null) => {
                        handleDateChange(e, index + 1, i, optionalRow);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={false}
                          sx={{
                            fieldset: {
                              legend: {
                                width: "unset",
                              },
                            },
                          }}
                          className={classes.textarea}
                          key={"date-" + props.question.number + rowNumber}
                        />
                      )}
                      key={"date-picker-" + props.question.number + i + index}
                    />
                  </LocalizationProvider>
                </TableCell>
              ) : (
                <TableCell
                  key={"answercell-" + props.question.number + i + index}
                >
                  <TextField
                    sx={{
                      fieldset: {
                        legend: {
                          width: "unset",
                        },
                      },
                    }}
                    className={classes.textarea}
                    placeholder={optionalRow ? "Other" : undefined}
                    variant="standard"
                    type={
                      y.tableType === TableQuestionType.Number
                        ? "number"
                        : "text"
                    }
                    multiline={y.tableType === TableQuestionType.Textbox}
                    fullWidth
                    key={
                      "text-" + props.question.number + "-" + i + "-" + index
                    }
                    InputProps={{
                      "aria-label": y.text,
                      inputProps: {
                        min: y.rangeMin,
                        max: y.rangeMax,
                      },
                    }}
                  />
                </TableCell>
              )
            )}
        </TableRow>
      );
    }
    return rows;
  };

  const loadColumnHeaders = (columns: AnswerDataModel[] | undefined) => {
    const cells = [];
    if (columns !== undefined) {
      for (let i = 0; i < columns.length; i++) {
        cells.push(loadAnswer(columns.at(i)!));
      }
    }
    return cells;
  };

  const loadQuestion = (props: QuestionBoxProps) => {
    var question =
      (props.question.hideNumber
        ? ""
        : (props.question.displayName ?? "Q" + props.question.number) + ". ") +
      props.question.text;

    if (props.question.mandatory) {
      question += " *";
    }
    return question;
  };
  const loadAnswerGroup = (props: QuestionBoxProps) => {
    switch (props.question.questionType) {
      case QuestionType.Buttons:
      case QuestionType.ButtonsMulti: {
        const sum =
          props.question.answers?.reduce((sum, x) => {
            return sum + x.text?.length!;
          }, 0) ?? 0;
        return (
          <ToggleButtonGroup
            key={"button-group-" + props.question.number}
            className={classes.buttonGroup}
            style={{
              display: isMobile || sum > 80 ? "grid" : "flex",
              flexWrap: "wrap",
            }}
            value={buttonsAnswers}
            onChange={(e: any) => {
              handleButtonChange(e);
            }}
          >
            {props.question.answers?.map((x) => {
              return loadAnswer(x);
            })}
          </ToggleButtonGroup>
        );
      }
      case QuestionType.Textbox:
      case QuestionType.Range:
      case QuestionType.SkippableRange:
      case QuestionType.Number:
      case QuestionType.Date:
      case QuestionType.Day:
        return props.question.answers?.map((x) => {
          return loadAnswer(x);
        });
      case QuestionType.Sketch:
        return <DrawingBoard key={"number-" + props.question.number} />;
      case QuestionType.DropDown: {
        return (
          <FormControl fullWidth component={Paper}>
            <InputLabel id={`dropdown-label-${props.question.number}`}>
              Please Select
            </InputLabel>
            <Select
              key={"drop-down-" + props.question.number}
              labelId={`dropdown-label-${props.question.number}`}
              id={"dropdown-select-" + props.question.number}
              label="Please Select"
              value={dropdownAnswer}
              onChange={(e) => handleDropdownQuestionChange(e)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Adjust this value to fit your content
                  },
                },
              }}
            >
              {props.question.answers?.map((x) => loadAnswer(x))}
            </Select>
          </FormControl>
        );
      }
      case QuestionType.Table: {
        return (
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {loadColumnHeaders(props.question.answers ?? [])}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.question.rows
                  ? loadRows(props.question.rows, props.question.rows.length)
                  : loadRows([], props.question.rowNumber ?? 0)}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
      case QuestionType.MultiSelectorTable: {
        return (
          <BuilderMultiSelectorTable
            question={props.question}
          ></BuilderMultiSelectorTable>
        );
      }
      case QuestionType.Goals:
        return (
          <p style={{ color: "red", fontStyle: "italic" }}>
            Goals disabled for preview
          </p>
        );
      case QuestionType.CheckBoxList: {
        if (props.question.format === "tight") {
          return (
            <FormControl sx={{ display: "flex", flexDirection: "column" }}>
              {props.question.answers?.length !== 1 && (
                <FormLabel>{`Please tick ${
                  props.question.maxAnswers ?? "all that apply"
                }`}</FormLabel>
              )}
              <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                {props.question.answers?.map((x) => loadAnswer(x))}
              </FormGroup>
            </FormControl>
          );
        } else {
          return (
            <FormControl>
              {props.question.answers?.length !== 1 && (
                <FormLabel>{`Select ${
                  props.question.maxAnswers ?? "all that apply"
                }`}</FormLabel>
              )}
              <FormGroup>
                {props.question.answers?.map((x) => loadAnswer(x))}
              </FormGroup>
            </FormControl>
          );
        }
      }
      case QuestionType.CheckBoxSingle: {
        return (
          <FormControl>
            <FormGroup>
              {props.question.answers?.map((x) => loadAnswer(x))}
            </FormGroup>
          </FormControl>
        );
      }
      default:
        break;
    }
  };

  const loadAnswer = (answer: AnswerDataModel) => {
    switch (props.question.questionType) {
      case QuestionType.Buttons:
      case QuestionType.ButtonsMulti:
        return (
          <ToggleButton
            color="secondary"
            key={"button-" + props.question.number + "-" + answer.number}
            className={classes.button}
            value={answer.number ?? 0}
            sx={{
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: "#cfe2f3",
              },
            }}
            aria-label={answer.text}
            style={{
              // These properties need to be added to the style prop, as they get overridden as a class
              borderWidth: "thin",
              borderColor: customTheme.palette.grey[50],
              borderStyle: "solid",
              borderRadius: "5",
              marginLeft: "-2px",
              marginTop: "-2px",
            }}
          >
            {answer.text}
          </ToggleButton>
        );
      case QuestionType.Textbox:
        return (
          <TextField
            sx={{
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
            }}
            label={answer.text}
            className={classes.textarea}
            variant="outlined"
            placeholder={answer.text}
            multiline
            fullWidth
            key={"text-" + props.question.number + "-" + answer.number}
            InputProps={{ "aria-label": answer.text }}
          />
        );
      case QuestionType.Range:
        return (
          <div
            key={
              "slider-outer-container-" +
              props.question.number +
              "-" +
              answer.number
            }
          >
            <div
              className={classes.textLabelContainer}
              key={
                "slider-container-" +
                props.question.number +
                "-" +
                answer.number
              }
            >
              {props.question.labels?.map((x, i) => (
                <Typography
                  className={classes.textlabel}
                  key={"slider-label-" + props.question.number + "-" + i}
                >
                  {x}
                </Typography>
              ))}
            </div>
            <Slider
              key={"slider-" + props.question.number + "-" + answer.number}
              valueLabelDisplay="auto"
              step={answer.rangeDecimal ? 0.1 : 1}
              defaultValue={answer.rangeDefault}
              marks={calculateMarks(answer)}
              min={answer.rangeMin}
              max={answer.rangeMax}
              aria-label={answer.text}
              getAriaValueText={(num) => "label" + num}
              getAriaLabel={() => "slider-" + props.question.number}
            />
          </div>
        );
      case QuestionType.Number:
        return (
          <TextField
            sx={{
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
            }}
            className={classes.textarea}
            variant="outlined"
            fullWidth
            key={"number-" + props.question.number}
            type="number"
            InputProps={{
              inputProps: { min: answer.rangeMin, max: answer.rangeMax },
            }}
          />
        );
      case QuestionType.Date:
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            key={"localisation-" + props.question.number}
          >
            <DatePicker
              views={["year", "month"]}
              inputFormat="MM/YYYY"
              disableFuture={true}
              value={dateValue}
              onChange={(e: Date | null) => {
                handleDateChange(e);
              }}
              PopperProps={{
                placement: "bottom",
                disablePortal: true,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={false}
                  sx={{
                    fieldset: {
                      legend: {
                        width: "unset",
                      },
                    },
                  }}
                  className={classes.textarea}
                  variant="outlined"
                  key={"date-" + props.question.number}
                />
              )}
            />
          </LocalizationProvider>
        );
      case QuestionType.Day:
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            key={"localisation-" + props.question.number}
          >
            <DatePicker
              views={["year", "month", "day"]}
              inputFormat="DD/MM/YYYY"
              disableFuture={true}
              value={dateValue}
              onChange={(e: Date | null) => {
                handleDateChange(e);
              }}
              PopperProps={{
                placement: "bottom",
                disablePortal: true,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={false}
                  sx={{
                    fieldset: {
                      legend: {
                        width: "unset",
                      },
                    },
                  }}
                  className={classes.textarea}
                  key={"day-" + props.question.number}
                />
              )}
            />
          </LocalizationProvider>
        );
      case QuestionType.Table:
        return (
          <TableCell
            key={"header-" + answer.text + answer.number}
            sx={{ fontWeight: "bold" }}
          >
            {answer.text}
          </TableCell>
        );
      case QuestionType.SkippableRange:
        return (
          <div
            key={
              "skippable-slider-outer-container-" +
              props.question.number +
              "-" +
              answer.number
            }
          >
            <div
              className={classes.skippableRangeContainer}
              key={"skippable-slider-container-" + props.question.number}
            >
              <div
                className={classes.skippableRangeSliderChild}
                key={
                  "skippable-slider-container-child-" + props.question.number
                }
              >
                <div
                  className={classes.textLabelContainer}
                  key={
                    "skippable-slider-container-text-" + props.question.number
                  }
                >
                  {props.question.labels?.map((x, i) => (
                    <Typography
                      className={classes.textlabel}
                      key={
                        "skippable-slider-label-text-" +
                        props.question.number +
                        "-" +
                        i
                      }
                    >
                      {x}
                    </Typography>
                  ))}
                </div>
                <Slider
                  key={"skippable-slider-" + props.question.number}
                  valueLabelDisplay="auto"
                  step={answer.rangeDecimal ? 0.1 : 1}
                  marks={calculateMarks(answer)}
                  defaultValue={answer.rangeDefault}
                  min={answer.rangeMin}
                  max={answer.rangeMax}
                  aria-label={answer.text}
                  getAriaValueText={(num) => "label" + num}
                  getAriaLabel={() =>
                    "skippable-slider-" + props.question.number
                  }
                  disabled={skippableRangeChecked}
                />
              </div>
              <div className={classes.skippableRangeCheckboxChild}>
                <FormControlLabel
                  key={"sliderSkip-" + props.question.number}
                  label="Skip Question"
                  labelPlacement="end"
                  control={
                    <Checkbox
                      onChange={() => setSkippableRangeChecked((x) => !x)}
                    />
                  }
                />
              </div>
            </div>
          </div>
        );
      case QuestionType.DropDown:
        return (
          <MenuItem key={answer.number} value={answer.text}>
            {answer.text}
          </MenuItem>
        );
      case QuestionType.CheckBoxList:
        return (
          <FormControlLabel
            control={
              <Checkbox checked={checkboxListAnswers[answer.number! - 1]} />
            }
            key={answer.number}
            label={answer.text}
            value={answer.text}
            onChange={(e) => handleCheckBoxListChange(e, answer)}
          />
        );
      case QuestionType.CheckBoxSingle:
        return (
          <FormControlLabel
            control={<Checkbox checked={checkboxSingleChange} />}
            key={answer.number}
            label={answer.text}
            onChange={() => setCheckboxSingleChange((x) => !x)}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <Box
        key={"box-" + props.question.number}
        sx={{
          width: isMobile
            ? 1
            : isSmallerThan1200 &&
              props.question.questionType === QuestionType.MultiSelectorTable
            ? 0.92
            : isSmallerThan800 &&
              props.question.questionType === QuestionType.MultiSelectorTable
            ? 0.88
            : 0.95,
          mt: 2,
          mb: 2,
          mr:
            isSmallerThan1200 &&
            props.question.questionType === QuestionType.MultiSelectorTable
              ? 0
              : 2,
          ml: isMobile ? 0 : 3,
        }}
      >
        {props.question.subheading && (
          <Typography variant={"subtitle1"} gutterBottom>
            {parse(props.question?.subheading ?? "")}
          </Typography>
        )}
        <Typography variant="body1" component="h1" gutterBottom>
          {loadQuestion(props)}
        </Typography>
        {loadAnswerGroup(props)}
        {props.question.note != null && (
          <Alert key={"alert-" + props.question.number} severity="info">
            {props.question.note}
          </Alert>
        )}
        {props.question.divider && (
          <Divider
            key={"divider-" + props.question.number}
            variant="middle"
            className={classes.divider}
          />
        )}
      </Box>
    </>
  );
};

export default BuilderQuestionBox;
