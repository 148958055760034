import {
  Card,
  CardContent,
  Container,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import {
  PreferredContact,
  Role,
  StaffClient,
  StaffViewModel,
  TenantClient,
} from "../../../types/auto/types";
import AvatarLogo from "../elements/nav/AvatarLogo";
import { theme as customTheme, theme } from "../../../Theme";
import {
  CheckIsLargeDevice,
  CheckIsMobile,
  CheckIsSmallDevice,
} from "../../utils/MobileStatus";
import { useCommonStyle } from "../../utils/CommonStyles";
import CreateEditStaff from "./create-edit-staff/CreateEditStaff";
import { useCallback, useEffect, useState } from "react";
import { useUnmountPromise } from "react-use";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import { makeStyles } from "@mui/styles";
import ContactPreferences from "../elements/contact-preferences/ContactPreferences";

interface Props {
  staff: StaffViewModel;
  reloadStaff: () => void;
  setStaff: React.Dispatch<React.SetStateAction<StaffViewModel | undefined>>;
}

const ViewStaffDetails = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const isLargeDevice = CheckIsLargeDevice();

  const useStyle = useCommonStyle(
    customTheme,
    isSmallDevice,
    isMobile,
    isLargeDevice
  );
  const classes = useStyle(customTheme);

  const useExtraStyles = makeStyles((th: Theme) => ({
    outerBox: { display: "flex", flexDirection: "column", width: "100%" },
    buttonBox: { justifyContent: "flex-end" },
    cardBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    emailTitle: {
      fontWeight: "bold",
      display: "inline",
    },
  }));
  const extraClasses = useExtraStyles(customTheme);

  const staffUsername = props.staff.firstName + " " + props.staff.lastName;
  const [, setReRender] = useState<boolean>(false);
  const [integration, setIntegration] = useState<boolean>(true);
  const resolveWhileMounted = useUnmountPromise();
  const getPreference = useCallback(() => {
    return props.staff.preferredContact === PreferredContact.Email
      ? [PreferredContact.Email]
      : props.staff.preferredContact === PreferredContact.Mobile
      ? [PreferredContact.Mobile]
      : [PreferredContact.Email, PreferredContact.Mobile];
  }, [props.staff.preferredContact]);

  const [messagingPreferences, setMessagingPreferences] = useState<
    PreferredContact[]
  >(getPreference());

  const handlePreferenceChange = (
    event: React.MouseEvent<HTMLElement>,
    newMessagingPreferences: PreferredContact[]
  ) => {
    if (newMessagingPreferences.length) {
      const staffPreference =
        newMessagingPreferences.length === 2
          ? PreferredContact.Both
          : newMessagingPreferences[0];
      props.setStaff((x) => {
        if (x === undefined) {
          return x;
        }
        x.preferredContact = staffPreference;
        return x;
      });
      new StaffClient(Configuration.SERVER_ROOT, FetchOverride)
        .updateDetails(props.staff)
        .then(() => {
          setMessagingPreferences(newMessagingPreferences);
          props.reloadStaff();
        });
    }
  };

  useEffect(() => {
    const effect = async () => {
      setMessagingPreferences(getPreference());
      await resolveWhileMounted(
        new TenantClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).getIsTenantIntegrated()
      ).then((x) => {
        setIntegration(x);
      });
    };
    effect();
  }, [resolveWhileMounted, getPreference]);

  return (
    <Grid container className={classes.cardContainer}>
      {!integration && props.staff.role === Role.Clinician && (
        <CreateEditStaff
          staff={props.staff}
          setReRenderView={setReRender}
          reloadStaff={props.reloadStaff}
          style={{ marginTop: 0 }}
          role={Role.Clinician}
        />
      )}
      {props.staff.role === Role.Admin && (
        <CreateEditStaff
          staff={props.staff}
          setReRenderView={setReRender}
          reloadStaff={props.reloadStaff}
          style={{ marginTop: 0 }}
          role={Role.Admin}
        />
      )}
      <Grid item xs={12} sm={5.9}>
        <Card className={classes.card} elevation={0}>
          <CardContent
            className={classes.cardContent}
            sx={{ ":last-child": { paddingBottom: 1 } }}
          >
            <Container className={classes.avatarContainer}>
              <AvatarLogo
                username={staffUsername}
                backgroundColour={theme.palette.secondary.main}
                colour="white"
                size={56}
              />
            </Container>
            {(
              [
                { name: "First Name", value: props.staff.firstName },
                { name: "Last Name", value: props.staff.lastName },
                {
                  name: "True Colours ID",
                  value: props.staff.id,
                  mobileOnly: true,
                },
              ] as {
                name: string;
                value: string;
              }[]
            ).map((x, key) => (
              <Container className={classes.textContainer} key={key}>
                <Typography className={classes.textField}>{x.name}:</Typography>
                <Typography className={classes.textValue}>{x.value}</Typography>
              </Container>
            ))}
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Typography className={extraClasses.emailTitle}>
                Login email:
              </Typography>
              <Typography sx={{ display: "inline" }}>
                {props.staff.email}
              </Typography>
            </Container>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={5.9}>
        <Card className={classes.card} elevation={0}>
          <CardContent sx={{ ":last-child": { paddingBottom: 1 } }}>
            <Typography className={classes.contactDetails}>
              Contact details
            </Typography>
            {(
              [
                { name: "Mobile Number", value: props.staff.mobileNumber },
                { name: "Email", value: props.staff.contactEmail },
              ] as {
                name: string;
                value: string;
              }[]
            ).map((x, key) => (
              <Container className={classes.textContainer2} key={key}>
                <Typography className={classes.textField2}>
                  {x.name}:
                </Typography>
                <Typography className={classes.textValue2}>
                  {x.value}
                </Typography>
              </Container>
            ))}
            <Typography className={classes.cardTitle}>
              Contact preference
            </Typography>
            <ContactPreferences
              messagingPreferences={messagingPreferences}
              onChange={handlePreferenceChange}
              fromPatientForm={false}
              hasMobile={
                props.staff.mobileNumber !== undefined &&
                props.staff.mobileNumber !== null
              }
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ViewStaffDetails;
