import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { calculateGap } from "./MobileStatus";

export function useCommonStyle(
  th: Theme,
  isSmallDevice: boolean,
  isMobile: boolean,
  isLargeDevice: boolean
) {
  return makeStyles({
    textContainer: {
      display: "flex",
      justifyContent: "center",
    },
    textContainer2: {
      display: isSmallDevice ? "block" : "flex",
      justifyContent: "center",
    },
    textField: {
      fontWeight: "bold",
      width: isSmallDevice ? "default" : "50%",
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    textValue: {
      textIndent: "10px",
      width: "50%",
      textAlign: "left",
    },
    textField2: {
      fontWeight: "bold",
      display: "inline",
      whiteSpace: "nowrap",
    },
    textValue2: {
      textIndent: "10px",
      display: "inline",
    },
    avatarContainer: {
      paddingBottom: "20px",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    },
    cardContent: {
      width: "100%",
      overflow: isMobile ? "scroll" : "auto",
    },
    card: {
      height: isMobile ? "fit-content" : "100%",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      borderBottom: "solid " + th.palette.grey[50] + " thin",
      borderTop: isMobile ? "none" : "solid " + th.palette.grey[50] + " thin",
      marginBottom: isMobile ? "10px" : 0,
    },
    contactDetails: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "24px",
      paddingBottom: "20px",
      paddingTop: 0,
    },
    cardContainer: {
      gap: calculateGap(isMobile, isLargeDevice),
    },
    cardTitle: {
      fontWeight: "bold",
      fontSize: "18px",
      textAlign: "center",
    },
  });
}
