import { useContext, useEffect, useState } from "react";
import {
  GoalClient,
  GoalResponseViewModel,
  QuestionnaireResponseDataViewModel,
  QuestionnaireResponseViewModel,
  QuestionnaireViewModel,
} from "../../types/auto/types";
import { Configuration } from "../Constants";
import { FetchOverride } from "../utils/Request";
import { useNavigate, useSearchParams } from "react-router-dom";
import QuestionnaireForm from "../components/questionnaires/QuestionnaireForm";
import { UserContext } from "../components/elements/stores/UserStore";

const Goal = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokens = searchParams.get("t")?.split(",");
  const patientId = searchParams.get("p");
  const tenantId = searchParams.get("tId");
  const [questionnaire, setQuestionnaire] = useState<QuestionnaireViewModel>();
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const fromNotification = searchParams.get("fn") ? true : false;

  useEffect(() => {
    // If we have a goal id, just get the questionnaire for the goal
    if (tokens && questionnaire === undefined) {
      new GoalClient(Configuration.SERVER_ROOT, FetchOverride)
        .getQuestionnaireForTokens(tokens)
        .then((x) => setQuestionnaire(x));
    } else if (tokens === undefined || tokens?.length === 0) {
      navigate("/");
    }
  }, [tokens, questionnaire, setQuestionnaire, navigate]);

  return (
    <QuestionnaireForm
      hasAutofill={false}
      patientId={patientId}
      questionnaire={questionnaire}
      openSubmitDialog={openSubmitDialog}
      submitResponses={async (responses) =>
        await new GoalClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).respondToGoalsQuestionnaire(
          responses.map((x: QuestionnaireResponseViewModel) => {
            var goalResponse = new GoalResponseViewModel();
            goalResponse.goalId =
              questionnaire?.sections !== undefined
                ? questionnaire.sections[0].questions?.find(
                    (question) => question.number === x.number
                  )?.goalId
                : undefined; // Get the goal id from the question number
            goalResponse.response = parseInt(x.answer ?? "0");
            goalResponse.patientId = parseInt(patientId ?? "0");
            return goalResponse;
          })[0],
          undefined,
          parseInt(tenantId ?? "0"),
          tokens?.at(0)
        )
      }
      getNextQuestionnaire={() => setOpenSubmitDialog(true)}
      questionnaireResponseData={new QuestionnaireResponseDataViewModel()}
      editing={false}
      editingDate={false}
      setEditingDate={null}
      goal={true}
      role={user.role}
      fromNotification={fromNotification}
      responseToken={""}
      tenantId={""}
      editDraftError={false}
      hasUnsubmittedGoals={false}
      setHasUnsubmittedGoals={(x) => {}}
      toggleGoalDeletionWarningModal={() => {}}
      goalDeletionWarningModal={false}
      goalDeletionWarningModalOverride={false}
    />
  );
};

export default Goal;
