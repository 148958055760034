import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import {
  CategoryScoreViewModel,
  ScoreStatus,
} from "../../../../types/auto/types";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import TscoreLineGraphCore from "../graphs/Tscore/TscoreLineGraphCore";

interface Props {
  questionnaireScores: CategoryScoreViewModel[];
  index: number;
}
export interface TScoreLineDataPoint {
  x: string;
  y: number;
}
export interface TScoreGraph {
  lineName: string;
  data: TScoreLineDataPoint[];
}

export const ScoresAccordian = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles(() => ({
    accordianHeading: {
      fontWeight: "bold",
    },
    noResponse: {
      paddingLeft: "15px",
    },
    chip: {
      marginBottom: 5,
      width: isMobile ? "49%" : "32%",
    },
    titleChip: {
      width: "100%",
      marginBottom: "10px",
      marginTop: "10px",
    },
    accordian: { boxShadow: "none", borderTop: "solid 1px lightgray" },
  }));
  const classes = useStyle();
  var hasTscoreGraph =
    props.questionnaireScores.filter(
      (x) => x.tscore !== undefined && x.tscore !== null && !x.hideGraph
    ).length > 0;

  var tscoreGraph = undefined;
  if (hasTscoreGraph) {
    var data = props.questionnaireScores
      .filter(
        (x) => (x.tscore !== undefined || x.tscore !== null) && !x.isTotal
      )
      .map(
        (category) =>
          ({
            x: category.name,
            y: parseInt(category.tscore?.replace(new RegExp("[<>]"), "") ?? ""),
          } as TScoreLineDataPoint)
      );
    tscoreGraph = { lineName: "TScore graph", data: data } as TScoreGraph;
  }

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      className={classes.accordian}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={"panel-content-scores" + props.index}
        id={"panel-header-scores" + props.index}
      >
        <Typography className={classes.accordianHeading}>Scores</Typography>
      </AccordionSummary>
      <AccordionDetails key={"score:" + props.index}>
        {props.questionnaireScores
          ?.filter((x) => x.name !== "Main")
          .map((category, index) => {
            if (isMobile) {
              return (
                <div key={"mobileContainer" + index}>
                  <Stack direction="row" key={"titleStack" + index}>
                    {" "}
                    <Chip
                      key={"titleChip" + index}
                      label={category.name}
                      color={
                        category.scoreStatus === ScoreStatus.Borderline
                          ? "warning"
                          : category.scoreStatus === ScoreStatus.Clinical
                          ? "error"
                          : "default"
                      }
                      className={classes.titleChip}
                    />
                  </Stack>
                  <Stack direction="row" key={"scoreStack" + index} spacing={1}>
                    <Chip
                      key={"scoreChip" + index}
                      label={
                        category.score === -1
                          ? "Invalid"
                          : "Score: " +
                            category.score +
                            (category.maxScore !== null
                              ? "/" + category.maxScore
                              : "")
                      }
                      color={
                        category.scoreStatus === ScoreStatus.Borderline
                          ? "warning"
                          : category.scoreStatus === ScoreStatus.Clinical
                          ? "error"
                          : "default"
                      }
                      variant="outlined"
                      className={classes.chip}
                    />
                    <Chip
                      key={"tscoreChip" + index}
                      hidden={
                        category.tscore === undefined ||
                        category.tscore === null
                      }
                      label={"T-Score: " + category.tscore}
                      variant="outlined"
                      color={
                        category.scoreStatus === ScoreStatus.Borderline
                          ? "warning"
                          : category.scoreStatus === ScoreStatus.Clinical
                          ? "error"
                          : "default"
                      }
                      className={classes.chip}
                    />
                  </Stack>
                  {category.numberSkipped &&
                  category.skippedWarningThreshold &&
                  category.numberSkipped > category.skippedWarningThreshold ? (
                    <p style={{ fontSize: "0.8em" }} key={"scoring" + index}>
                      Please note: more than {category.skippedWarningThreshold}
                      {category.skippedWarningThreshold > 1
                        ? "questions"
                        : "question"}
                      used to calculate the score above was skipped
                    </p>
                  ) : null}
                </div>
              );
            } else {
              return (
                <div key={"desktopContainer" + index}>
                  <Stack direction="row" key={index} spacing={1}>
                    <Chip
                      label={category.name}
                      color={
                        category.scoreStatus === ScoreStatus.Borderline
                          ? "warning"
                          : category.scoreStatus === ScoreStatus.Clinical
                          ? "error"
                          : "default"
                      }
                      className={classes.chip}
                      key={"titleChip" + index}
                    />
                    <Chip
                      label={
                        category.score === -1
                          ? "Invalid"
                          : "Score: " +
                            category.score +
                            (category.maxScore !== null
                              ? "/" + category.maxScore
                              : "")
                      }
                      color={
                        category.scoreStatus === ScoreStatus.Borderline
                          ? "warning"
                          : category.scoreStatus === ScoreStatus.Clinical
                          ? "error"
                          : "default"
                      }
                      variant="outlined"
                      className={classes.chip}
                      key={"scoreChip" + index}
                    />
                    <Chip
                      hidden={
                        category.tscore === undefined ||
                        category.tscore === null
                      }
                      label={"T-Score: " + category.tscore}
                      variant="outlined"
                      color={
                        category.scoreStatus === ScoreStatus.Borderline
                          ? "warning"
                          : category.scoreStatus === ScoreStatus.Clinical
                          ? "error"
                          : "default"
                      }
                      className={classes.chip}
                      key={"tscoreChip" + index}
                    />
                  </Stack>
                  {category.numberSkipped &&
                  category.skippedWarningThreshold &&
                  category.numberSkipped > category.skippedWarningThreshold ? (
                    <p style={{ fontSize: "0.8em" }}>
                      Please note: more than {category.skippedWarningThreshold}
                      {category.skippedWarningThreshold > 1
                        ? "questions"
                        : "question"}
                      used to calculate the score above was skipped
                    </p>
                  ) : null}
                </div>
              );
            }
          })}
        {hasTscoreGraph && (
          <TscoreLineGraphCore data={tscoreGraph!} width={1000} height={300} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
