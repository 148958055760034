import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Theme,
  Slider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Select,
  MenuItem,
  Alert,
  Divider,
  Checkbox,
  FormControlLabel,
  InputLabel,
  FormGroup,
  FormLabel,
  FormControl,
  CircularProgress,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import {
  AnswerViewModel,
  EnumClient,
  GoalCategory,
  GoalClient,
  GoalOwner,
  GoalViewModel,
  MonitoringStatus,
  PatientClient,
  PatientViewModel,
  QuestionnaireResponseViewModel,
  QuestionType,
  QuestionViewModel,
  Role,
  TableQuestionType,
} from "../../../../types/auto/types";
import parse from "html-react-parser";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../../Theme";
import { CheckIsMobile, CheckIsSmallDevice } from "../../../utils/MobileStatus";
import {
  isDependsValid,
  getValue,
  handleChange,
  handleDateChange,
  handleMultiChange,
  handleNumberChange,
  handleSliderChange,
  handleSketchChange,
  getTableResponse,
  getValueResponse,
  handleTableChange,
  handleCheckBoxChange,
  handleCheckBoxSingleChange,
  handleTextboxChange,
} from "./QuestionBoxHelper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DrawingBoard } from "../DrawingBoard";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import MultiSelectorTable from "./MultiSelectorTable";
import { FetchOverride } from "../../../utils/Request";
import { Configuration } from "../../../Constants";
import ActiveGoals from "../../goals/ActiveGoals";
import { useEffect, useState } from "react";
import ErrorComponent from "../../elements/errors/ErrorComponent";
import React from "react";

export interface QuestionBoxProps {
  question: QuestionViewModel;
  responses: Array<QuestionnaireResponseViewModel>;
  setResponses: React.Dispatch<
    React.SetStateAction<Array<QuestionnaireResponseViewModel>>
  >;
  setRerender: () => void;
  patientId?: string;
  questionnaireId?: number;
  role?: Role;
  goalsFromQuestionnaire?: GoalViewModel[];
  setGoalsFromQuestionnaire?: React.Dispatch<
    React.SetStateAction<GoalViewModel[]>
  >;
  preview?: boolean;
  questions: QuestionViewModel[];
  caseloadId?: number;
  sectionCompleteCheck?: () => void;
  readOnly: boolean;
  extraPrintPadding?: boolean[];
  questionnaireResponseId?: number;
  setHasUnsubmittedGoals: (x: boolean) => void;
}

const QuestionBox = (props: QuestionBoxProps): JSX.Element => {
  const [patient, setPatient] = useState<PatientViewModel>();
  const [patientLoaded, setPatientLoaded] = useState<boolean>();
  const [monitoringStatusDescriptions, setMonitoringStatusDescriptions] =
    useState<{ [key in keyof typeof MonitoringStatus]?: string }>({});
  const [goalOwnerDescriptions, setGoalOwnerDescriptions] = useState<{
    [key in keyof typeof GoalOwner]?: string;
  }>({});
  const [goalsFromQuestion, setGoalsFromCategory] =
    useState<GoalViewModel[]>(getGoalsFromCategory);
  const [goalsLoading, setGoalsLoading] = useState<boolean>(true);
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();

  const useStyle = makeStyles((th: Theme) => ({
    buttonGroup: {
      display: isMobile ? "grid" : "flex",
      flexWrap: "wrap",
      gap: 1,
    },
    button: {
      backgroundColor: "#fafafa",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      borderRadius: "5",
    },
    table: {
      backgroundColor: "#fafafa",
    },
    textarea: {
      backgroundColor: "#fafafa",
    },
    textLabelContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    textlabel: {
      fontSize: 12,
    },
    skippableRangeContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      padding: "10px",
    },
    skippableRangeSliderChild: {
      flex: 5,
      minWidth: "400px",
    },
    skippableRangeCheckboxChild: {
      flex: 1,
      justifyContent: isMobile ? "unset" : "end",
      display: "inline-flex",
    },
    divider: {
      background: "#808080",
      marginTop: "10px",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
    headerAndActions: {
      display: "flex",
      paddingRight: "0",
      paddingLeft: "0",
    },
    headingText: {
      fontWeight: "bold",
    },
    vert: {
      marginLeft: "auto",
      padding: "0",
      minWidth: "24px",
    },
    subTitle: {
      color: "gray",
      fontSize: 15,
    },
    card: {
      marginTop: "10px",
      width: isMobile ? "100%" : "49.5%",
      marginRight: isMobile ? "auto" : "0.5%",
      minWidth: isSmallDevice ? "180px" : "250px",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      "&:nth-child(even)": {
        marginRight: isMobile ? "auto" : "0",
        marginLeft: isMobile ? "auto" : "0.5%",
      },
    },
    cardContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: 0,
      padding: 0,
    },
  }));
  const classes = useStyle(customTheme);
  const [firstLoad, setFirstLoad] = useState(true);
  var satisfied = isDependsValid(
    props.question,
    props.responses,
    props.questions,
    props.readOnly
  );

  function containsHeaderTag(str: string) {
    const headerRegex = /<h[1-6][^>]*>/i;
    return headerRegex.test(str);
  }

  function handleBlur() {
    handleTextboxChange(textValue, props);
  }
  function loadTextValue() {
    return getValue(props.question.number, props.responses) ?? "";
  }
  const [textValue, setTextValue] = useState<string>(loadTextValue);

  function getGoalsFromCategory(): GoalViewModel[] {
    var goalsQuestion: GoalViewModel[] = [];
    if (props.question.questionType === QuestionType.Goals) {
      if (isNaN(Number(props.patientId))) {
        return [];
      }
      const patientId = Number(props.patientId);
      new GoalClient(Configuration.SERVER_ROOT, FetchOverride)
        .getQuestionnaireGoals(
          patientId,
          props.questionnaireId,
          props.question.goalCategory ?? GoalCategory.General,
          props.questionnaireResponseId
        )
        .then((goalsQuestionnaire) => {
          if (
            goalsQuestionnaire.length > 0 &&
            goalsQuestionnaire.some((x) => !x.submitted)
          ) {
            props.setHasUnsubmittedGoals(true);
          }
          setGoalsFromCategory(goalsQuestionnaire);
          setGoalsLoading(false);
        });
    }

    return goalsQuestion;
  }

  useEffect(() => {
    if (!satisfied && !props.readOnly) {
      setTextValue("");
      const questionNumbers = props.questions
        .filter((question) => {
          if (
            question.dependsOn &&
            props.question.dependsOn &&
            Object.keys(props.question.dependsOn).some((key) => {
              const propQuestionDependsOn = props.question.dependsOn;
              const questionDependsOn = question.dependsOn;

              if (
                propQuestionDependsOn &&
                propQuestionDependsOn[key] &&
                questionDependsOn &&
                questionDependsOn[key] &&
                propQuestionDependsOn[key].some((num) =>
                  questionDependsOn[key]?.includes(num)
                )
              ) {
                return true;
              }
              return false;
            })
          ) {
            return true;
          }
          return false;
        })
        .map((question) => question.number);
      const filteredResponses = props.responses.filter(
        (response) => !questionNumbers.includes(response.number)
      );
      props.setResponses(filteredResponses);
    }
    // eslint-disable-next-line
  }, [satisfied, props.readOnly]);

  useEffect(() => {
    setTextValue(loadTextValue);
    if (props.question.questionType === QuestionType.Goals && firstLoad) {
      setFirstLoad(false);
      if (
        props.patientId !== undefined &&
        props.questionnaireId !== undefined
      ) {
        if (isNaN(Number(props.patientId))) {
          return;
        }
        const patientId = Number(props.patientId);
        new PatientClient(Configuration.SERVER_ROOT, FetchOverride)
          .getPatient(patientId)
          .then((p) => {
            p !== undefined && setPatient(p);
            setPatientLoaded(true);
          });
        new EnumClient(Configuration.SERVER_ROOT, FetchOverride)
          .getMonitoringStatusDescriptions()
          .then((statusDescriptions) => {
            setMonitoringStatusDescriptions(statusDescriptions);
          });
        new EnumClient(Configuration.SERVER_ROOT, FetchOverride)
          .getGoalOwnerDescriptions()
          .then((x) => {
            setGoalOwnerDescriptions(x);
          });
      }
    }
    // eslint-disable-next-line
  }, [props, patient, firstLoad]);

  // Calculates the mark numbers for a range slider, limited to maximum of 10 marks
  const calculateMarks = (answer: AnswerViewModel) => {
    const marks = [];
    const range = answer.rangeMax! - answer.rangeMin!;
    const markCount = Math.min(range, 10);

    for (var i = 0; i <= markCount; i++) {
      var mark = Math.round(answer.rangeMin! + (i * range) / markCount);
      marks.push({
        value: mark,
        label: mark,
      });
    }
    return marks;
  };

  const loadRows = (rowNames: string[], rowNumber: number) => {
    const rows = [];
    for (let i = 0; i < rowNumber; i++) {
      const optionalRow = rowNames.at(i) === "Other";
      rows.push(
        <TableRow key={"row-" + props.question.number + "-" + i}>
          {rowNames.length > 0 && !optionalRow ? (
            <TableCell key={"questioncell-" + props.question.number + i}>
              {rowNames.at(i)}
            </TableCell>
          ) : null}
          {props.question.answers
            ?.slice(!optionalRow && rowNames.length > 0 ? 1 : 0)
            ?.map((y, index) =>
              y.tableType === TableQuestionType.Dropdown ? (
                <TableCell
                  key={"answercell-" + props.question.number + i + index}
                >
                  <Select
                    variant="outlined"
                    key={
                      "dropdown-" +
                      props.question.number +
                      "-" +
                      i +
                      "-" +
                      index
                    }
                    placeholder={
                      optionalRow ? "Other (please specify)" : undefined
                    }
                    value={
                      getTableResponse(
                        getValueResponse(
                          props.question.number,
                          props.responses
                        )!,
                        i,
                        y?.text ?? ""
                      ) ?? "None"
                    }
                    onChange={(e) =>
                      handleTableChange(i, rowNumber, y?.text ?? "", e, props)
                    }
                    readOnly={props.readOnly}
                  >
                    <MenuItem key={"select-" + 0} value={"None"}>
                      None
                    </MenuItem>
                    {y.tableDropdownChoices?.map((val, index) => (
                      <MenuItem
                        key={"select-" + (index + 1)}
                        value={val}
                        role="menuitem"
                      >
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              ) : y.tableType === TableQuestionType.Checkbox ? (
                <TableCell
                  key={"answercell-" + props.question.number + i + index}
                >
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    onChange={(e) =>
                      handleTableChange(
                        i,
                        rowNumber,
                        y?.text ?? "",
                        e,
                        props,
                        TableQuestionType.Checkbox
                      )
                    }
                    checked={
                      getTableResponse(
                        getValueResponse(
                          props.question.number,
                          props.responses
                        )!,
                        i,
                        y?.text ?? ""
                      ) === true ?? false
                    }
                    readOnly={props.readOnly}
                  />
                </TableCell>
              ) : y.tableType === TableQuestionType.Date ? (
                <TableCell
                  key={"answercell-" + props.question.number + i + index}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    key={"localisation-" + props.question.number}
                  >
                    <DatePicker
                      disableFuture={true}
                      views={["year", "month", "day"]}
                      inputFormat="DD/MM/YYYY"
                      value={
                        getTableResponse(
                          getValueResponse(
                            props.question.number,
                            props.responses
                          )!,
                          i,
                          y?.text ?? ""
                        ) ?? ""
                      }
                      onChange={(e: Date | null) => {
                        e != null &&
                          handleTableChange(
                            i,
                            rowNumber,
                            y?.text ?? "",
                            e,
                            props,
                            TableQuestionType.Date
                          );
                      }}
                      PopperProps={{
                        placement: "bottom",
                        modifiers: [
                          {
                            name: "flip",
                            options: {
                              fallbackPlacements: ["top", "right"],
                            },
                          },
                        ],
                        disablePortal: true,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={false}
                          sx={{
                            fieldset: {
                              legend: {
                                width: "unset",
                              },
                            },
                          }}
                          className={classes.textarea}
                          key={"date-" + props.question.number + rowNumber}
                        />
                      )}
                      readOnly={props.readOnly}
                    />
                  </LocalizationProvider>
                </TableCell>
              ) : (
                <TableCell
                  key={"answercell-" + props.question.number + i + index}
                >
                  <TextField
                    sx={{
                      fieldset: {
                        legend: {
                          width: "unset",
                        },
                      },
                    }}
                    className={classes.textarea}
                    placeholder={optionalRow ? "Other" : undefined}
                    variant="standard"
                    type={
                      y.tableType === TableQuestionType.Number
                        ? "number"
                        : "text"
                    }
                    multiline={y.tableType === TableQuestionType.Textbox}
                    fullWidth
                    key={
                      "text-" + props.question.number + "-" + i + "-" + index
                    }
                    value={
                      getTableResponse(
                        getValueResponse(
                          props.question.number,
                          props.responses
                        )!,
                        i,
                        y?.text ?? ""
                      ) ??
                      (y.tableType === TableQuestionType.Number ? 0 : undefined)
                    }
                    onChange={(e) =>
                      handleTableChange(i, rowNumber, y?.text ?? "", e, props)
                    }
                    InputProps={{
                      "aria-label": y.text,
                      inputProps: {
                        min: y.rangeMin,
                        max: y.rangeMax,
                        readOnly: props.readOnly,
                      },
                    }}
                  />
                </TableCell>
              )
            )}
        </TableRow>
      );
    }
    return rows;
  };

  const loadColumnHeaders = (columns: AnswerViewModel[] | undefined) => {
    const cells = [];
    if (columns !== undefined) {
      for (let i = 0; i < columns.length; i++) {
        cells.push(loadAnswer(columns.at(i)!));
      }
    }
    return cells;
  };

  const loadQuestion = (props: QuestionBoxProps) => {
    var question =
      (props.question.hideNumber
        ? ""
        : (props.question.displayName ?? "Q" + props.question.number) + ". ") +
      props.question.text;

    if (props.question.mandatory && question) {
      question += " *";
    }
    return parse(question);
  };
  const loadAnswerGroup = (props: QuestionBoxProps) => {
    switch (props.question.questionType) {
      case QuestionType.Buttons:
      case QuestionType.ButtonsMulti: {
        const sum =
          props.question.answers?.reduce((sum, x) => {
            return sum + x.text?.length!;
          }, 0) ?? 0;
        return (
          <ToggleButtonGroup
            key={"button-group-" + props.question.number}
            className={classes.buttonGroup}
            style={{
              display: isMobile || sum > 80 ? "grid" : "flex",
              flexWrap: "wrap",
            }}
            id={props.question.number.toString()}
            value={getValue(props.question.number, props.responses)
              ?.split(",")
              .map((i) => Number(i))}
            color="primary"
            exclusive={true}
            onChange={(e) =>
              props.question.questionType === QuestionType.Buttons
                ? handleChange(e, props)
                : handleMultiChange(e, props)
            }
            disabled={props.readOnly}
          >
            {props.question.answers?.map((x) => {
              return loadAnswer(x);
            })}
          </ToggleButtonGroup>
        );
      }
      case QuestionType.Textbox:
      case QuestionType.Range:
      case QuestionType.SkippableRange:
      case QuestionType.Number:
      case QuestionType.Date:
      case QuestionType.Day:
      case QuestionType.Sketch:
        return props.question.answers?.map((x) => {
          return loadAnswer(x);
        });
      case QuestionType.DropDown: {
        return (
          <FormControl
            fullWidth
            component={Paper}
            key={"dropdown-control-" + props.question.number}
          >
            <InputLabel
              id={`dropdown-label-${props.question.number}`}
              key={"dropdown-label-" + props.question.number}
            >
              Please Select
            </InputLabel>
            <Select
              key={"drop-down-" + props.question.number}
              labelId={`dropdown-label-${props.question.number}`}
              id={props.question.number.toString()}
              label="Please Select"
              value={getValue(props.question.number, props.responses) ?? ""}
              onChange={(e) => handleChange(e, props)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Adjust this value to fit your content
                  },
                },
              }}
              readOnly={props.readOnly}
            >
              {props.question.answers?.map((x) => loadAnswer(x))}
            </Select>
          </FormControl>
        );
      }
      case QuestionType.Table: {
        return (
          <TableContainer
            component={Paper}
            key={"table-container-" + props.question.number}
          >
            <Table
              className={classes.table}
              key={"table-" + props.question.number}
            >
              <TableHead key={"table-head-" + props.question.number}>
                <TableRow key={"table-row-headers-" + props.question.number}>
                  {loadColumnHeaders(props.question.answers)}
                </TableRow>
              </TableHead>
              <TableBody key={"table-body-" + props.question.number}>
                {props.question.rows
                  ? loadRows(props.question.rows, props.question.rows.length)
                  : loadRows([], props.question.rowNumber ?? 0)}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
      case QuestionType.MultiSelectorTable: {
        return (
          <MultiSelectorTable
            questionBoxProps={props}
            key={"multi-selector-table-" + props.question.number}
          ></MultiSelectorTable>
        );
      }
      case QuestionType.Goals: {
        if (
          props.patientId !== undefined &&
          props.questionnaireId !== undefined
        ) {
          if (patient === undefined) {
            return patientLoaded ? (
              <ErrorComponent
                errorText="Cannot find patient with specified patient id."
                key={"goals-patient-notloaded-error-" + props.question.number}
              />
            ) : (
              ""
            );
          }
          return (
            <ActiveGoals
              patient={patient}
              goals={goalsFromQuestion ?? []}
              refreshGoals={() => setGoalsFromCategory(getGoalsFromCategory())}
              monitoringStatusDescriptions={monitoringStatusDescriptions}
              goalOwnerDescriptions={goalOwnerDescriptions}
              role={props.role}
              questionnaireId={props.questionnaireId}
              goalCategory={props.question.goalCategory ?? GoalCategory.General}
              caseloadId={props.caseloadId}
              readOnly={props.readOnly}
              questionnaireResponseId={props.questionnaireResponseId}
              key={"goals-" + props.question.number}
            />
          );
        }
        if (props.readOnly && props.responses.length === 0) {
          return (
            <Container
              className={classes.cardContainer}
              key={"blank-container-1-" + props.question.number}
            >
              <Card
                variant="outlined"
                className={classes.card}
                key={"blank-main-card-1-" + props.question.number}
              >
                <CardContent
                  key={"blank-card-content-1-" + props.question.number}
                >
                  <Container
                    className={classes.headerAndActions}
                    key={"blank-card-container-1-" + props.question.number}
                  >
                    <Typography
                      className={classes.headingText}
                      key={"blank-card-heading-1-" + props.question.number}
                    >
                      {"Title:"}
                    </Typography>
                  </Container>
                  <Typography
                    className={classes.subTitle}
                    key={"blank-card-heading-2-" + props.question.number}
                  >
                    {"Created: "}
                  </Typography>
                  <Typography
                    className={classes.subTitle}
                    key={"blank-card-heading-3-" + props.question.number}
                  >
                    {"Prompt: "}
                  </Typography>
                  <>
                    <Typography
                      className={classes.subTitle}
                      key={"blank-card-heading-4-" + props.question.number}
                    >
                      {"Questionnaire: "}
                    </Typography>
                    <Typography
                      className={classes.subTitle}
                      key={"blank-card-heading-5-" + props.question.number}
                    >
                      {"Goal Category: "}
                    </Typography>
                  </>
                </CardContent>
              </Card>
              <Card
                variant="outlined"
                className={classes.card}
                key={"blank-main-card-2-" + props.question.number}
              >
                <CardContent
                  key={"blank-card-content-2-" + props.question.number}
                >
                  <Container
                    className={classes.headerAndActions}
                    key={"blank-card-container-2-" + props.question.number}
                  >
                    <Typography
                      className={classes.headingText}
                      key={"blank-card-heading-6-" + props.question.number}
                    >
                      {"Title:"}
                    </Typography>
                  </Container>
                  <Typography
                    className={classes.subTitle}
                    key={"blank-card-heading-7-" + props.question.number}
                  >
                    {"Created: "}
                  </Typography>
                  <Typography
                    className={classes.subTitle}
                    key={"blank-card-heading-8-" + props.question.number}
                  >
                    {"Prompt: "}
                  </Typography>
                  <>
                    <Typography
                      className={classes.subTitle}
                      key={"blank-card-heading-9-" + props.question.number}
                    >
                      {"Questionnaire: "}
                    </Typography>
                    <Typography
                      className={classes.subTitle}
                      key={"blank-card-heading-10-" + props.question.number}
                    >
                      {"Goal Category: "}
                    </Typography>
                  </>
                </CardContent>
              </Card>
            </Container>
          );
        }
        return (
          <>
            {props.preview ? (
              <p
                style={{ color: "red", fontStyle: "italic" }}
                key={"goal-preview-text-" + props.question.number}
              >
                Goals disabled for preview
              </p>
            ) : (
              <ErrorComponent
                errorText="Goals Can only be viewed while logged in."
                key={"goal-error-" + props.question.number}
              />
            )}
          </>
        );
      }
      case QuestionType.CheckBoxList: {
        if (props.question.format === "tight") {
          return (
            <FormControl
              sx={{ display: "flex", flexDirection: "column" }}
              disabled={props.readOnly}
              key={"checkbox-list-" + props.question.number}
            >
              {props.question.answers?.length !== 1 && (
                <FormLabel
                  key={"checkbox-form-label-" + props.question.number}
                >{`Please tick ${
                  props.question.maxAnswers ?? "all that apply"
                }`}</FormLabel>
              )}
              <FormGroup
                sx={{ display: "flex", flexDirection: "row" }}
                key={"checkbox-list-form-group-" + props.question.number}
              >
                {props.question.answers?.map((x) => loadAnswer(x))}
              </FormGroup>
            </FormControl>
          );
        } else {
          return (
            <FormControl
              key={"checkbox-list-not-tight-" + props.question.number}
            >
              {props.question.answers?.length !== 1 && (
                <FormLabel
                  key={"checkbox-list-not-tight-label-" + props.question.number}
                >{`Select ${
                  props.question.maxAnswers ?? "all that apply"
                }`}</FormLabel>
              )}
              <FormGroup
                key={"checkbox-list-not-tight-group-" + props.question.number}
              >
                {props.question.answers?.map((x) => loadAnswer(x))}
              </FormGroup>
            </FormControl>
          );
        }
      }
      case QuestionType.CheckBoxSingle: {
        return (
          <FormControl key={"checkbox-single-control-" + props.question.number}>
            <FormGroup key={"checkbox-single-group-" + props.question.number}>
              {props.question.answers?.map((x) => loadAnswer(x))}
            </FormGroup>
          </FormControl>
        );
      }
      default:
        break;
    }
  };

  const loadAnswer = (answer: AnswerViewModel) => {
    const keyOfResponse = props.responses.findIndex(
      (r) => r.number === props.question.number
    );
    var answerString =
      keyOfResponse >= 0 ? props.responses[keyOfResponse].answer : "";
    switch (props.question.questionType) {
      case QuestionType.Buttons:
      case QuestionType.ButtonsMulti:
        return (
          <ToggleButton
            color="secondary"
            key={"button-" + props.question.number + "-" + answer.number}
            className={classes.button}
            value={answer.number ?? 0}
            sx={{
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: "#cfe2f3",
              },
            }}
            aria-label={answer.text}
            style={{
              // These properties need to be added to the style prop, as they get overridden as a class
              borderWidth: "thin",
              borderColor: customTheme.palette.grey[50],
              borderStyle: "solid",
              borderRadius: "5",
              marginLeft: "-2px",
              marginTop: "-2px",
            }}
            disabled={props.readOnly}
          >
            {answer.text}
          </ToggleButton>
        );
      case QuestionType.Textbox:
        return (
          <TextField
            sx={{
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
            }}
            label={props.readOnly ? null : answer.text}
            className={classes.textarea}
            variant="outlined"
            placeholder={props.readOnly ? "" : answer.text}
            multiline
            fullWidth
            key={"text-" + props.question.number}
            value={textValue}
            onBlur={handleBlur}
            onChange={(e) => setTextValue(e.target.value)}
            InputProps={{ "aria-label": answer.text, readOnly: props.readOnly }}
          />
        );
      case QuestionType.Range:
        return (
          <>
            <div
              className={classes.textLabelContainer}
              key={"slider-container-" + props.question.number}
            >
              {props.question.labels?.map((x, i) => (
                <Typography
                  className={classes.textlabel}
                  key={"label-range-" + props.question.number + "-" + i}
                >
                  {x}
                </Typography>
              ))}
            </div>
            <Slider
              key={"slider-" + props.question.number}
              value={Number(answerString) || answer.rangeDefault}
              valueLabelDisplay="auto"
              step={answer.rangeDecimal ? 0.1 : 1}
              marks={calculateMarks(answer)}
              min={answer.rangeMin}
              max={answer.rangeMax}
              onChange={(_, value) =>
                handleSliderChange(props, Number(value), props.readOnly)
              }
              aria-label={answer.text}
              getAriaValueText={(num) => "label" + num}
              getAriaLabel={() => "slider-" + props.question.number}
              sx={{
                ".MuiSlider-thumb": {
                  display:
                    props.readOnly && props.responses.length === 0
                      ? "none"
                      : "inline",
                },
              }}
            />
          </>
        );
      case QuestionType.Number:
        return (
          <TextField
            sx={{
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
            }}
            className={classes.textarea}
            variant="outlined"
            fullWidth
            key={"textfield-number-" + props.question.number}
            value={answerString}
            onChange={(e) =>
              handleNumberChange(props, Number(e.target.value), answer)
            }
            type="number"
            InputProps={{
              inputProps: {
                min: answer.rangeMin,
                max: answer.rangeMax,
                readOnly: props.readOnly,
              },
            }}
            placeholder={answer.text}
          />
        );
      case QuestionType.Date:
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            key={"localisation-" + props.question.number}
          >
            <DatePicker
              views={["year", "month"]}
              inputFormat="MM/YYYY"
              disableFuture={!props.question.allowFuture ?? true}
              value={answerString}
              onChange={(e: Date | null) => {
                handleDateChange(e, props, !props.question.allowFuture ?? true);
              }}
              PopperProps={{
                placement: "bottom",
                modifiers: [
                  {
                    name: "flip",
                    options: {
                      fallbackPlacements: ["top", "right"],
                    },
                  },
                ],
                disablePortal: true,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={false}
                  sx={{
                    fieldset: {
                      legend: {
                        width: "unset",
                      },
                    },
                  }}
                  className={classes.textarea}
                  variant="outlined"
                  key={"date-" + props.question.number}
                />
              )}
              readOnly={props.readOnly}
              key={"date-picker-" + props.question.number}
            />
          </LocalizationProvider>
        );
      case QuestionType.Day:
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            key={"localisation-" + props.question.number}
          >
            <DatePicker
              views={["year", "month", "day"]}
              inputFormat="DD/MM/YYYY"
              disableFuture={!props.question.allowFuture ?? true}
              value={answerString}
              onChange={(e: Date | null) => {
                handleDateChange(e, props, !props.question.allowFuture ?? true);
              }}
              PopperProps={{
                placement: "bottom",
                modifiers: [
                  {
                    name: "flip",
                    options: {
                      fallbackPlacements: ["top", "right"],
                    },
                  },
                ],
                disablePortal: true,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={false}
                  sx={{
                    fieldset: {
                      legend: {
                        width: "unset",
                      },
                    },
                  }}
                  className={classes.textarea}
                  key={"day-" + props.question.number}
                />
              )}
              readOnly={props.readOnly}
              key={"day-picker-" + props.question.number}
            />
          </LocalizationProvider>
        );
      case QuestionType.Table:
        return (
          <TableCell
            key={"tablecell" + props.question.number}
            sx={{ fontWeight: "bold" }}
          >
            {answer.text}
          </TableCell>
        );
      case QuestionType.Sketch:
        return (
          <DrawingBoard
            key={"number-" + props.question.number}
            onChange={(e: string) => handleSketchChange(e, props)}
          />
        );
      case QuestionType.SkippableRange:
        return (
          <div
            key={"skippable-range-container-" + props.question.number}
            className={classes.skippableRangeContainer}
          >
            <div className={classes.skippableRangeSliderChild}>
              <div className={classes.textLabelContainer}>
                {props.question.labels?.map((label, i) => (
                  <Typography
                    className={classes.textlabel}
                    key={`label-skippable-range-${props.question.number}-${i}`}
                  >
                    {label}
                  </Typography>
                ))}
              </div>
              <Slider
                value={Number(answerString) || answer.rangeDefault}
                valueLabelDisplay="auto"
                step={answer.rangeDecimal ? 0.1 : 1}
                marks={calculateMarks(answer)}
                min={answer.rangeMin}
                max={answer.rangeMax}
                onChange={(_, value) =>
                  handleSliderChange(props, Number(value), props.readOnly)
                }
                aria-label={answer.text}
                getAriaValueText={(num) => "label" + num}
                getAriaLabel={() => "slider-" + props.question.number}
                disabled={Number(answerString) < 0 ? true : false}
                sx={{
                  ".MuiSlider-thumb": {
                    display:
                      props.readOnly && props.responses.length === 0
                        ? "none"
                        : "inline",
                  },
                }}
              />
            </div>
            <div className={classes.skippableRangeCheckboxChild}>
              <FormControlLabel
                label="Skip Question"
                labelPlacement="end"
                control={
                  <Checkbox
                    checked={Number(answerString) < 0}
                    onChange={(_, value) =>
                      handleSliderChange(
                        props,
                        Number(Number(answerString) < 0 ? answer.rangeMin : -1),
                        props.readOnly
                      )
                    }
                    readOnly={props.readOnly}
                  />
                }
              />
            </div>
          </div>
        );
      case QuestionType.DropDown:
        return (
          <MenuItem key={answer.number} value={answer.text}>
            {answer.text}
          </MenuItem>
        );
      case QuestionType.CheckBoxList:
        var key = props.responses.findIndex(
          (r) => r.number === props.question.number
        );
        if (key >= 0) {
          var valueArray = props.responses[key].answer?.split(",");
          answer.checked = valueArray?.some((value) => value === answer.text);
        }
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={answer.checked ?? false}
                readOnly={props.readOnly}
              />
            }
            key={answer.number}
            label={answer.text}
            value={answer.text}
            onChange={(e) => handleCheckBoxChange(e, props, answer)}
          />
        );
      case QuestionType.CheckBoxSingle:
        key = props.responses.findIndex(
          (r) => r.number === props.question.number
        );
        if (key < 0) {
          props.responses.push(
            new QuestionnaireResponseViewModel({
              number: props.question.number,
              answer: "false",
            })
          );
          props.setResponses(props.responses);
        } else {
          answer.checked = props.responses[key].answer === "true";
        }

        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={answer.checked ?? false}
                readOnly={props.readOnly}
              />
            }
            key={answer.number}
            label={answer.text}
            onChange={(e) => handleCheckBoxSingleChange(e, props, answer)}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      {satisfied ? (
        <Box
          key={"box-" + props.question.number}
          sx={{
            width: isMobile ? 1 : 0.95,
            margin: 2,
            marginLeft: isMobile ? 0 : 3,
            "@media print": {
              breakInside: "avoid",
              paddingTop:
                props.extraPrintPadding == null
                  ? 0
                  : props.extraPrintPadding[0] && props.extraPrintPadding[1]
                  ? 4
                  : props.extraPrintPadding[0] && !props.extraPrintPadding[1]
                  ? 3
                  : 0,
              paddingBottom:
                props.extraPrintPadding == null
                  ? 0
                  : props.extraPrintPadding[0]
                  ? 1
                  : 0,
              paddingLeft: 1,
              paddingRight: 2,
            },
          }}
        >
          {props.question.subheading && (
            <Typography
              component={
                containsHeaderTag(props.question.subheading) ? "div" : "h6"
              }
              variant={"subtitle1"}
              gutterBottom
              key={"subheading-" + props.question.number}
            >
              {parse(props.question?.subheading ?? "")}
            </Typography>
          )}
          {/* if question type 14 we want the answer box to be to the right of the question not below */}
          {props.question.questionType === 14 ? (
            <div
              style={{ display: "flex", alignItems: "center" }}
              key={"checkbox-primary-container-" + props.question.number}
            >
              <Typography
                variant="body1"
                component="h1"
                gutterBottom
                key={"checkbox-single-" + props.question.number}
              >
                {loadQuestion(props)}
              </Typography>
              <div
                style={{ marginLeft: "5px" }}
                key={"checkbox-secondary-container-" + props.question.number}
              >
                {loadAnswerGroup(props)}
              </div>
            </div>
          ) : (
            <>
              <Typography
                variant="body1"
                component="h1"
                gutterBottom
                key={"checkbox-multiple-" + props.question.number}
              >
                {loadQuestion(props)}
              </Typography>
              {props.question.questionType === QuestionType.Goals &&
                goalsLoading &&
                (!props.readOnly ||
                  (props.readOnly && props.responses.length !== 0)) && (
                  <CircularProgress
                    className={classes.loadingSpinner}
                    aria-label="Loading"
                  />
                )}
              {loadAnswerGroup(props)}
            </>
          )}
          {props.question.note !== null && (
            <Alert key={"alert-" + props.question.number} severity="info">
              {props.question.note}
            </Alert>
          )}
          {props.question.divider && (
            <Divider
              key={"divider-" + props.question.number}
              variant="middle"
              className={classes.divider}
            />
          )}
        </Box>
      ) : null}
    </>
  );
};

export default QuestionBox;
