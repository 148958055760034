import React, { useEffect, useState } from "react";
import {
  GraphClient,
  PatientViewModel,
  Role,
  SymptomsBubbleViewModel,
  UserViewModel,
} from "../../../../../types/auto/types";
import { graphColours } from "../../../../../Theme";
import { Configuration } from "../../../../Constants";
import { FetchOverride } from "../../../../utils/Request";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import GraphHeader from "../header/GraphHeader";
import { Grading } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SymptomsGraphCore from "./SymptomsGraphCore";
import GraphLoading from "../../../elements/graph/GraphLoading";
import GraphError from "../../../elements/graph/GraphError";
import { useUnmountPromise } from "react-use";
import GraphSectionTitle from "../header/GraphSectionTitle";
import { CheckIsMobile } from "../../../../utils/MobileStatus";

const BUBBLE_SCALE = 20;

export interface ExtendedBubbleViewModel extends SymptomsBubbleViewModel {
  colour: string;
}

interface Props {
  patient: PatientViewModel;
  user: UserViewModel;
  fromDate: Date | undefined;
  toDate: Date | undefined;
  setNoData: (noData: boolean) => void;
  hideGraph?: boolean;
  firstSymptomsLoad: boolean;
  setFirstSymptomsLoad: React.Dispatch<React.SetStateAction<boolean>>;
  collapsedGraph?: boolean;
}

const graphWidth = 1000;
const graphHeight = 500;

const SymptomsCharts = (props: Props): JSX.Element => {
  const [data, setData] = useState<ExtendedBubbleViewModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const resolveWhileMounted = useUnmountPromise();

  const isMobile = CheckIsMobile();

  const useStyle = makeStyles(() => ({
    splitSymptomsContainer: { marginTop: 30, "@media print": { marginTop: 0 } },
    splitSymptomsTitle: { marginBottom: 0 },
    accordion: {
      marginLeft: isMobile ? 0 : 60,
      marginRight: isMobile ? 0 : 60,
      marginTop: 20,
      marginBottom: 10,
    },
  }));
  const classes = useStyle();

  var isStaff =
    props.user.role === Role.Clinician ||
    props.user.role === Role.Admin ||
    props.user.role === Role.SuperAdmin;

  useEffect(() => {
    const effect = async () => {
      if (props.firstSymptomsLoad) {
        props.setFirstSymptomsLoad(false);
        setLoading(true);
        if (isStaff) {
          await resolveWhileMounted(
            new GraphClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getSymptomsGraph(props.fromDate, props.toDate, props.patient.id)
          )
            .then((x) => {
              if (x.lines === undefined || x.lines.length === 0) {
                props.setNoData(true);
                setLoading(false);
                setError(false);
                return;
              }

              setData(
                x.lines?.map(
                  (x, i) =>
                    ({
                      ...x,
                      colour: graphColours[i % graphColours.length],
                      data: x.data?.map((y) => {
                        y.size = y.size * BUBBLE_SCALE;
                        return y;
                      }),
                    } as ExtendedBubbleViewModel)
                ) || []
              );

              setLoading(false);
              props.setNoData(false);
              setError(false);
            })
            .catch(() => {
              setError(true);
              props.setNoData(false);
              setLoading(false);
            });
        } else {
          await resolveWhileMounted(
            new GraphClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getCurrentSymptomsGraph(props.fromDate, props.toDate)
          )
            .then((x) => {
              if (x.lines === undefined || x.lines.length === 0) {
                props.setNoData(true);
                setLoading(false);
                setError(false);
                return;
              }

              setData(
                x.lines?.map(
                  (x, i) =>
                    ({
                      ...x,
                      colour: graphColours[i % graphColours.length],
                      data: x.data?.map((y) => {
                        y.size = y.size * BUBBLE_SCALE;
                        return y;
                      }),
                    } as ExtendedBubbleViewModel)
                ) || []
              );

              setLoading(false);
              props.setNoData(false);
              setError(false);
            })
            .catch(() => {
              setError(true);
              props.setNoData(false);
              setLoading(false);
            });
        }
      }
    };
    effect();
  }, [setData, props, resolveWhileMounted, isStaff, props.firstSymptomsLoad]);

  if (props.hideGraph) {
    return <></>;
  }

  return (
    <>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        className={classes.accordion}
        disableGutters
        defaultExpanded={!props.collapsedGraph}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <GraphSectionTitle title="Symptoms" />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {loading ? (
            <GraphLoading
              width={graphWidth}
              height={graphHeight}
              type="symptom"
            />
          ) : error ? (
            <GraphError width={graphWidth} height={graphHeight} />
          ) : (
            data.map((x) => (
              <Container
                key={x.chartName + x.colour}
                className={classes.splitSymptomsContainer}
                sx={{
                  paddingLeft: isMobile ? 0 : 1,
                  paddingRight: isMobile ? 0 : 1,
                  paddingBottom: 2,
                }}
              >
                <GraphHeader
                  title={x.chartName ?? ""}
                  icon={
                    <Tooltip title="Questionnaire">
                      <Grading />
                    </Tooltip>
                  }
                >
                  <SymptomsGraphCore
                    data={x}
                    width={graphWidth}
                    fromDate={props.fromDate}
                    toDate={props.toDate}
                  />
                </GraphHeader>
              </Container>
            ))
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SymptomsCharts;
