import React, { useEffect, useState } from "react";
import { Button, Container, Theme } from "@mui/material";
import {
  Add,
  Edit,
  GroupAdd,
  GroupRemove,
  PlaylistAdd,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import CreateEditPatientModal from "./CreateEditPatientModal";
import {
  CaseloadViewModel,
  PatientViewModel,
  Role,
  StaffClient,
  UserViewModel,
} from "../../../../types/auto/types";
import BatchUploadPatientModal from "./BatchUploadPatientModal";
import AssignPatientToCaseload from "../admin-actions/AssignCaseloadsToPatient";
import AssignPatientsToQuestionnaire from "../dashboard/AssignPatientsToQuestionnaire";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { LoadingButton } from "@mui/lab";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";

interface Props {
  patient?: PatientViewModel;
  onSave?: () => void;
  onSuccess?: (response: string) => void;
  style?: React.CSSProperties;
  user?: UserViewModel;
  dashboardSupported?: boolean;
  allCaseloads?: CaseloadViewModel[];
  nhsNumberRequired: boolean;
  customPatientId: boolean;
  customPatientIdDisplayName: string;
  customPatientIdRegex: string;
  customPatientIdMandatory: boolean;
}

const CreateEditPatient = (props: Props): JSX.Element => {
  const creating = !props.patient;
  const isMobile = CheckIsMobile();
  const [
    isPatientInClinicianPersonalCaseload,
    setIsPatientInClinicianPersonalCaseload,
  ] = useState(false);
  const useStyle = makeStyles((th: Theme) => ({
    container: {
      margin: 0,
      padding: 0,
      marginTop: !creating || isMobile ? 0 : 10,
      display: isMobile ? "block" : "flex",
      gap: 10,
      marginBottom: !creating && isMobile ? 10 : 0,
    },
    loadingButton: {
      backgroundColor: !isPatientInClinicianPersonalCaseload
        ? "default"
        : "red",
      "&:hover": {
        backgroundColor: !isPatientInClinicianPersonalCaseload
          ? "default"
          : "crimson",
      },
      width: isMobile ? "100%" : "default",
      marginTop: isMobile ? "10px" : 0,
    },
    button: {
      width: isMobile ? "100%" : "default",
      marginTop: creating && isMobile ? "10px" : 0,
    },
  }));
  const classes = useStyle(theme);
  const [createPatientModalOpen, setCreatePatientModalOpen] = useState(false);
  const [batchPatientUploadModalOpen, setBatchPatientUploadModalOpen] =
    useState(false);

  const [
    loadingIsPatientInClinicianPersonalCaseload,
    setLoadingIsPatientInClinicianPersonalCaseload,
  ] = useState(false);
  useEffect(() => {
    if (props.user && props.patient && props.user.role === Role.Clinician) {
      new StaffClient(Configuration.SERVER_ROOT, FetchOverride)
        .isPatientInPersonalCaseload(props.patient.id)
        .then((x) => setIsPatientInClinicianPersonalCaseload(x));
    }
  }, [setIsPatientInClinicianPersonalCaseload, props.patient, props.user]);
  return (
    <>
      <CreateEditPatientModal
        open={createPatientModalOpen}
        closeModal={() => {
          setCreatePatientModalOpen(false);
        }}
        saveModal={() => {
          setCreatePatientModalOpen(false);
          props.onSave && props.onSave();
        }}
        patient={props.patient}
        allCaseloads={props.allCaseloads}
        nhsNumberRequired={props.nhsNumberRequired}
        customPatientId={props.customPatientId}
        customPatientIdDisplayName={props.customPatientIdDisplayName}
        customPatientIdRegex={props.customPatientIdRegex}
        customPatientIdMandatory={props.customPatientIdMandatory}
      />
      {creating &&
        props.allCaseloads !== undefined &&
        props.allCaseloads !== null && (
          <BatchUploadPatientModal
            open={batchPatientUploadModalOpen}
            closeModal={() => setBatchPatientUploadModalOpen(false)}
            onSuccess={(response) => {
              if (props.onSuccess) {
                props.onSuccess(response);
              }
            }}
            allCaseloads={props.allCaseloads}
            customPatientId={props.customPatientId}
            customPatientIdMandatory={props.customPatientIdMandatory}
          />
        )}
      <Container className={classes.container} style={props.style}>
        <Button
          variant="contained"
          startIcon={creating ? <Add /> : <Edit />}
          onClick={() => setCreatePatientModalOpen(true)}
          className={classes.button}
        >
          {creating ? "Create Patient" : "Edit Patient"}
        </Button>
        {creating && (
          <Button
            variant="contained"
            startIcon={<PlaylistAdd />}
            onClick={() => setBatchPatientUploadModalOpen(true)}
            className={classes.button}
          >
            Batch Upload Patients
          </Button>
        )}
        {props.patient &&
          props.patient !== undefined &&
          props.user &&
          isPatientInClinicianPersonalCaseload !== undefined &&
          props.user.role === Role.Clinician && (
            <LoadingButton
              loading={loadingIsPatientInClinicianPersonalCaseload}
              startIcon={
                !isPatientInClinicianPersonalCaseload ? (
                  <GroupAdd />
                ) : (
                  <GroupRemove />
                )
              }
              variant="contained"
              className={classes.loadingButton}
              onClick={() => {
                setLoadingIsPatientInClinicianPersonalCaseload(true);
                if (isPatientInClinicianPersonalCaseload) {
                  new StaffClient(Configuration.SERVER_ROOT, FetchOverride)
                    .removePatientFromPersonalCaseload(props.patient!.id)
                    .then(
                      (success) =>
                        success &&
                        setIsPatientInClinicianPersonalCaseload(false)
                    )
                    .finally(() =>
                      setLoadingIsPatientInClinicianPersonalCaseload(false)
                    );
                } else {
                  new StaffClient(Configuration.SERVER_ROOT, FetchOverride)
                    .addPatientToPersonalCaseload(props.patient!.id)
                    .then(
                      (success) =>
                        success && setIsPatientInClinicianPersonalCaseload(true)
                    )
                    .finally(() =>
                      setLoadingIsPatientInClinicianPersonalCaseload(false)
                    );
                }
              }}
            >
              {!isPatientInClinicianPersonalCaseload
                ? "Add to "
                : "Remove from "}
              Personal Caseload
            </LoadingButton>
          )}
        {props.patient &&
          props.user &&
          (props.user.role === Role.Admin ||
            props.user.role === Role.SuperAdmin) && (
            <>
              <AssignPatientToCaseload
                userId={props.user.id}
                patientId={props.patient.id}
              />
              {props.dashboardSupported && (
                <AssignPatientsToQuestionnaire
                  questionnaireId={props.patient.dashboardQuestionnaireId ?? 0}
                  patientId={props.patient.id}
                  refreshQuestionnaires={() => {}}
                  onSave={() => {
                    props.onSave && props.onSave();
                  }}
                />
              )}
            </>
          )}
      </Container>
    </>
  );
};

export default CreateEditPatient;
