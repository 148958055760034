import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  TextField,
  createFilterOptions,
  FilterOptionsState,
  Chip,
  Box,
} from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { CheckIsMobile } from "../../../utils/MobileStatus";

interface INameAndId {
  id: number;
  name?: string | undefined;
}

interface Props<OptionType extends INameAndId> {
  label: string;
  options: OptionType[];
  selectedOptions: OptionType[];
  setSelectedOptions: Dispatch<SetStateAction<OptionType[]>>;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  onChange?: (selectedOptions: OptionType[]) => void;
}

const selectAllOption: INameAndId = { name: "Select All", id: -9 };

function MultiSelectChip<OptionType extends INameAndId>(
  props: Props<OptionType>
): JSX.Element {
  const [selectAllOptionEnabled, setSelectAllOptionEnabled] = useState(false);
  const isMobile = CheckIsMobile();
  useEffect(() => {
    setSelectAllOptionEnabled(
      props.selectedOptions.length === props.options.length
    );
  }, [props.selectedOptions, props.options.length]);

  return (
    <FormControl className={props.className}>
      <Autocomplete
        defaultValue={props.selectedOptions}
        disabled={props.disabled}
        multiple
        options={props.options}
        value={props.selectedOptions}
        limitTags={5}
        onChange={(_, selectedOptions, reason) => {
          // If we have selected a new option and `Select All` is included, select all options
          if (
            reason === "selectOption" &&
            !selectAllOptionEnabled &&
            (selectedOptions.some((x) => x.id === selectAllOption.id) ||
              selectedOptions.length === props.options.length)
          ) {
            props.setSelectedOptions(props.options);
            setSelectAllOptionEnabled(true);
          }
          // Clear the list if the select option is enabled and we're still selecting an option (as the select all option is always considered a selection)
          else if (selectAllOptionEnabled && reason === "selectOption") {
            props.setSelectedOptions([]);
            setSelectAllOptionEnabled(false);
          }
          // Else, add all selected options, apart from the select all option
          else {
            props.setSelectedOptions(
              selectedOptions.filter((x) => x.id !== selectAllOption.id)
            );
            setSelectAllOptionEnabled(false);
          }
          if (props.onChange) {
            props.onChange(selectedOptions);
          }
        }}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name || ""}
        renderTags={(data, getTagProps) => (
          <Box
            component="div"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              maxHeight: isMobile ? 100 : 350,
              overflowY: "auto",
            }}
          >
            {data.map((option, index: number) => (
              <Chip
                {...getTagProps({ index: index })}
                label={option.name}
                key={index}
              />
            ))}
          </Box>
        )}
        filterOptions={(
          options: OptionType[],
          params: FilterOptionsState<OptionType>
        ) => {
          const filtered = createFilterOptions()(
            options,
            params as FilterOptionsState<unknown>
          );
          return [selectAllOption, ...filtered] as OptionType[];
        }} // Add the select all option
        renderOption={(props, option, { selected }) => {
          selected =
            option.id === selectAllOption.id
              ? selectAllOptionEnabled
              : selected;

          return (
            <li {...props} key={option.id}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={
                  <CheckBox fontSize="small" aria-label={option.name} />
                }
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={props.error}
            label={props.label}
            helperText={props.error && props.errorText}
          />
        )}
      />
    </FormControl>
  );
}

export default MultiSelectChip;
