import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { theme } from "../../../../Theme";

interface Props {
  open: boolean;
  setOpen?: (open: boolean) => void;
  title: string;
  body: string;
  onCancel: () => void;
  onContinue: () => Promise<void>;
  continueButtonText: string;
  errorText: string;
  linkedObjects?: number;
  linkedErrorText?: string;
}

const WarningDialog = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    button: { textTransform: "capitalize" },
    title: { fontSize: 23, color: "#000000" },
    body: { fontSize: 17, whiteSpace: "pre-wrap" },
  });
  const classes = useStyle(theme);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [linkedError, setLinkedError] = useState(false);

  return (
    <Dialog open={props.open}>
      <DialogTitle className={classes.title}>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.body}>
          {props.body}
        </DialogContentText>
        {linkedError && <Alert severity="error">{props.linkedErrorText}</Alert>}
        {error && <Alert severity="error">{props.errorText}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setError(false);
            setLinkedError(false);
            props.onCancel();
          }}
          className={classes.button}
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={() => {
            if (
              props.linkedObjects !== undefined &&
              props.linkedObjects !== null &&
              props.linkedObjects > 0
            ) {
              setLinkedError(true);
              return;
            }
            setLoading(true);
            props
              .onContinue()
              .then(() => {
                setLoading(false);
                setLinkedError(false);
                setError(false);
              })
              .catch(() => {
                setError(true);
                setLoading(false);
              });
          }}
          loading={loading}
          color="error"
          variant="contained"
          className={classes.button}
        >
          {props.continueButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
